// KENDO
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
//

const TextInputCell = (props: any) => (
  <td>
    <Input
      className='editable-cell'
      placeholder={
        props.field === 'mmkId' || props.field === 'mmk'
          ? 'NO MMK ID'
          : props.field === 'name'
          ? 'Name'
          : props.field
      }
      disabled={props.field === 'mmkId' || props.field === 'mmk' ? true : false}
      value={props.dataItem[props.field] || undefined}
      onChange={(e) =>
        props.onChange({
          dataItem: props.dataItem,
          field: props.field,
          syntheticEvent: e.syntheticEvent,
          value: e.value,
        })
      }
    />
    {props.field === 'name' && (
      <Error>
        {props.dataItem[props.field] === '' && 'Name cannot be empty!'}
      </Error>
    )}
  </td>
);

export default TextInputCell;
