// REACT
import { ReactElement } from 'react';
//

// TYPES
import { CardHeaderProps } from '../../types/typeDefinitions';
//

const CardHeader = ({ ...props }: Partial<CardHeaderProps>): ReactElement => {
  const {
    children,
    color,
    plain,
    image,
    contact,
    signup,
    stats,
    icon,
    text,
    ...rest
  } = props;

  return <div {...rest}>{children}</div>;
};

export default CardHeader;
