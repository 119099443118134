// HOOKS
import { useState } from 'react';
//

// KENDO
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import { DragCell } from '../../../../components/DragCell/DragCell';
import { CommandCell } from '../../../../components/CommandCell/CommandCell';
import { Renderers } from './Renderers';
//

// TYPES
import {
  BoatDetailsSpecificationProps,
  BoatDetailsSpecificationState,
} from '../../../../types/typeDefinitions';
//

const Specification = (props: BoatDetailsSpecificationProps) => {
  const [value, setValue] =
    useState<BoatDetailsSpecificationState['value']>(null);

  const DragCellF = DragCell(
    props.handleItemDragStart,
    props.handleItemReorder
  );

  const renderers = new Renderers(props.enterEdit, props.exitEdit, 'inEdit');

  const handleChange = (event: ComboBoxChangeEvent) => {
    if (event.target.value) {
      props.handleAdd(event.target.value);
      setValue(event.target.value);
    }
  };

  return (
    <>
      <MaterialGrid container direction='column' spacing={3}>
        <MaterialGrid item className='specification_combobox'>
          <ComboBox
            data={props.otherSpecificationParts}
            value={value}
            clearButton={false}
            textField='name'
            dataItemKey='id'
            suggest
            label='Select part...'
            onChange={handleChange}
          />
        </MaterialGrid>

        <MaterialGrid container item>
          <Grid
            scrollable='none'
            data={props.boatSpecificationParts}
            editField='inEdit'
            cellRender={renderers.cellRender}
            rowRender={renderers.rowRender}
            onItemChange={props.handleValueChange}
            style={{ minWidth: '750px' }}
          >
            <Column
              title=''
              width={45}
              groupable={false}
              sortable={false}
              filterable={false}
              resizable={false}
              cell={DragCellF as any}
            />
            {props.source === 'Mmk' ? null : (
              <Column
                field='_command'
                width={70}
                groupable={false}
                sortable={false}
                filterable={false}
                resizable={false}
                title=' '
                cell={(cellProps) => (
                  <CommandCell
                    {...cellProps}
                    hasDeleteCommand={true}
                    handleDelete={props.handleDelete}
                  />
                )}
              />
            )}
            <Column field='partName' title='Name' editable={false} />
            <Column field='value' title='Value' />
          </Grid>
        </MaterialGrid>
      </MaterialGrid>
    </>
  );
};

export default Specification;
