// REACT
import React from 'react';
//

// TYPES
import { rowRender } from '../../../services/gridRowRender';
import {
  Website,
  ManufacturerGridProps,
  ManufacturerGridItem,
} from '../../../types/typeDefinitions';
//

// KENDO
import {
  Grid,
  GridColumn as Column,
  GridRowClickEvent,
  GridDataStateChangeEvent,
  GridCellProps,
  GridToolbar,
} from '@progress/kendo-react-grid';
//

// COMPONENTS
import { CommandCell } from '../../../components/CommandCell/CommandCell';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

const WebsiteCell: (props: GridCellProps) => React.ReactElement = ({
  dataItem: { websites },
}: GridCellProps) => <td>{websites.map((w: Website) => w.code).join(', ')}</td>;

const ManufacturerGrid = (props: ManufacturerGridProps) => {
  const handleRowClick = (event: GridRowClickEvent) => {
    props.handleManufacturerSelect(event);
  };

  const handleDataStateChange = (event: GridDataStateChangeEvent) => {
    props.getManufacturers(event.dataState);
  };

  const handleEdit = (itemType: string) => (dataItem: any) => {
    props.handleEdit(itemType, dataItem);
  };

  const handleDelete = (dataItem: any) =>
    props.handleDelete ? props.handleDelete(dataItem) : null;

  const data: ManufacturerGridItem[] = props.manufacturers.map((manuf) => {
    return {
      ...manuf,
      selected: manuf.id === props.selectedManufacturerId,
    };
  });

  return (
    <MaterialGrid width={'100%'} height={'100%'}>
      <Grid
        data={data}
        {...props.dataState}
        scrollable='scrollable'
        pageable={true}
        total={props.total}
        sortable={true}
        resizable={true}
        selectedField='selected'
        onRowClick={handleRowClick}
        onDataStateChange={handleDataStateChange}
        rowRender={rowRender}
        className='manufacturer_element'
        width={'100%'}
      >
        <GridToolbar>
          <MaterialGrid item>
            <div className='grid-layout'>MANUFACTURERS</div>
          </MaterialGrid>
        </GridToolbar>

        <Column
          field='_command'
          groupable={false}
          sortable={false}
          filterable={false}
          resizable={false}
          title=' '
          width={40}
          cell={(props) => (
            <CommandCell
              {...props}
              hasEditCommand={true}
              handleEdit={handleEdit('Manufacturer')}
            />
          )}
        />
        <Column
          field='_command'
          groupable={false}
          sortable={false}
          filterable={false}
          resizable={false}
          title=' '
          width={40}
          cell={(props) => (
            <CommandCell
              {...props}
              // hasEditCommand={true}
              // handleEdit={handleEdit('Manufacturer')}
              hasDeleteCommand={true}
              handleDelete={handleDelete}
            />
          )}
        />
        <Column field='id' title='ID' />
        <Column field='name' title='Name' />
        <Column
          field='websites'
          title='Websites'
          sortable={false}
          cell={(props) => <WebsiteCell {...props} />}
        />
      </Grid>
    </MaterialGrid>
  );
};

export default ManufacturerGrid;
