// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// KENDO
import { GridItemChangeEvent } from '@progress/kendo-react-grid';
//

// HOOKS
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useGetSailingAreasQuery,
  useUpdateSailingAreasMutation,
} from '../locationsApiSlice';
import useFocusNewItem from '../../../../hooks/useFocusNewItem';
import useNotifications from '../../../../hooks/useNotifications';
import { useLazyGetRegenerateSearchIndexesQuery } from '../../Boats/boatsApiSlice';
//

// TYPES
import { SailingArea } from '../../../../types/typeDefinitions';
//

// COMPONENTS
import SailingAreasGrid from './SailingAreasGrid';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import ReactRouterPrompt from 'react-router-prompt';
//

const SailingAreasContainer = () => {
  const { data: sailingAreasList, isFetching: isSailingAreasListLoading } =
    useGetSailingAreasQuery();

  const { handleGenerationOfNewItemInUI } = useFocusNewItem('nameFirst', true);

  const { handleUserActionNotification, handlePromiseNotification } =
    useNotifications();

  const handleCloningEntity = useCallback(
    (dataset: any) => cloneDeep(dataset),
    []
  );

  const clonedListData = useMemo(
    () =>
      handleCloningEntity(
        sailingAreasList?.map((single) => {
          return { ...single, isToUpdate: false };
        })
      ),
    [handleCloningEntity, sailingAreasList]
  );

  const [localDataState, setLocalDataState] = useState<any[]>(clonedListData);
  const [userHasChanges, setUserHasChanges] = useState<boolean>(false);

  const handleAddNewSailingArea = () => {
    let newItem = {
      name: '',
      mmkId: null,
      isToUpdate: true,
      tempId: new Date().getMilliseconds(),
    };
    setLocalDataState((prev: any[]) => {
      return [...prev, newItem] as any[];
    });
    handleGenerationOfNewItemInUI();
    setUserHasChanges(true);
  };
  const handleExitEdit = () => {};

  const handleSailingAreaEdit = (dataItem: any, field: string) => null;

  const prepareLocalDataState = useCallback(() => {
    if (
      clonedListData &&
      clonedListData?.length > 0 &&
      !isSailingAreasListLoading
    ) {
      setLocalDataState(clonedListData);
    }
  }, [clonedListData, isSailingAreasListLoading]);

  useEffect(() => {
    prepareLocalDataState();
  }, [prepareLocalDataState]);

  const handleSailingAreaValueChange = (event: GridItemChangeEvent) => {
    const field = String(event.field);
    setUserHasChanges(true);

    const modifiedLocalState = localDataState?.map((item) => {
      if (
        item.id === event.dataItem.id &&
        'tempId' in event.dataItem === false
      ) {
        item[field] = event.value;
        item.isToUpdate = true;
      } else if (
        item.tempId === event.dataItem.tempId &&
        event.dataItem.id === undefined
      ) {
        item[field] = event.value;
        item.isToUpdate = true;
      }
      return item;
    });
    setLocalDataState(modifiedLocalState);
  };

  const [updateSailingAreas] = useUpdateSailingAreasMutation();

  const validateForm = (data: any[] | SailingArea[]) => {
    return data.filter((item) => item.name === '');
  };

  const handleSave = () => {
    const listToSave = localDataState?.filter(
      (singleItem) => singleItem.isToUpdate === true
    );

    if (validateForm(listToSave)?.length === 0) {
      try {
        handlePromiseNotification(
          updateSailingAreas(listToSave as SailingArea[]).unwrap(),
          {
            pending: { message: 'Processing...', type: 'info' },
            success: {
              message: 'Sailing areas list updated successfully!',
              type: 'success',
            },
            error: {
              message: 'There was an error with your request.',
              type: 'error',
            },
          }
        );

        setUserHasChanges(false);
      } catch (error) {
        handleUserActionNotification({
          message: 'There was an error with your request.',
          autoClose: 2500,
          type: 'error',
        });
      }
    } else
      handleUserActionNotification({
        type: 'warning',
        autoClose: 2500,
        message: 'One or more validation errors occurred!',
      });
  };

  const handleCancelChanges = () => {
    setLocalDataState(handleCloningEntity(sailingAreasList) as SailingArea[]);
    setUserHasChanges(false);
  };

  const handleRemoveFromUi = (dataItem: any) => {
    if ('id' in dataItem && dataItem.tempId === undefined) {
      const tmp = localDataState.filter(
        (singleItem) => singleItem.id !== dataItem.id
      );
      setLocalDataState(tmp);
    } else {
      const tmp = localDataState.filter(
        (singleItem) => singleItem.tempId !== dataItem.tempId
      );
      setLocalDataState(tmp);
      handleUserActionNotification({
        message: 'Sailing area successfully deleted!',
        autoClose: 2500,
        type: 'success',
      });
    }
  };

  const [getRegenerateSearchIndexes] = useLazyGetRegenerateSearchIndexesQuery();

  const handleRegenerate = () => {
    try {
      handlePromiseNotification(getRegenerateSearchIndexes().unwrap(), {
        pending: { message: 'Saving...', type: 'info' },
        success: {
          message: 'Public data generated successfully!',
          type: 'success',
        },
        error: {
          message: 'Something went wrong with your request.',
          type: 'error',
        },
      });
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        message: `${error.status} : \n ${error.error}`,
        autoClose: 2500,
      });
    }
  };

  return (
    <>
      <ReactRouterPrompt when={userHasChanges}>
        {({ isActive, onConfirm, onCancel }) => (
          <CustomDialog
            open={isActive}
            title='You have unsaved changes. Are you sure you want to leave?'
            confirmText='Confirm'
            onClose={onCancel}
            onConfirm={onConfirm}
          />
        )}
      </ReactRouterPrompt>

      <MaterialGrid container direction='column' spacing={2}>
        <MaterialGrid
          container
          position='fixed'
          top={16.6}
          right={16.6}
          zIndex={1201}
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
        >
          <MaterialGrid item>
            <button onClick={handleRegenerate} className='light-button'>
              Generate public data
            </button>
          </MaterialGrid>

          <MaterialGrid item>
            <button className='pink-button' onClick={handleAddNewSailingArea}>
              Add new sailing area
            </button>
          </MaterialGrid>
        </MaterialGrid>

        <MaterialGrid item width={'100%'} height='calc(100vh - (9em))'>
          <SailingAreasGrid
            data={localDataState as SailingArea[]}
            isLoading={isSailingAreasListLoading}
            handleAddNew={handleAddNewSailingArea}
            enterEdit={handleSailingAreaEdit}
            exitEdit={handleExitEdit}
            handleValueChange={handleSailingAreaValueChange}
            handleRemoveFromUi={handleRemoveFromUi}
          />
        </MaterialGrid>

        <MaterialGrid item container spacing={1} justifyContent='flex-end'>
          <MaterialGrid item>
            <button className='red-button' onClick={handleCancelChanges}>
              Cancel
            </button>
          </MaterialGrid>
          <MaterialGrid item>
            <button
              onClick={handleSave}
              className='primary-button'
              disabled={!userHasChanges}
            >
              Save
            </button>
          </MaterialGrid>
        </MaterialGrid>
      </MaterialGrid>
    </>
  );
};

export default SailingAreasContainer;
