// REACT
import React from 'react';
//

// MUI
import { FormControl, InputLabel, FormHelperText, Input } from '@mui/material';
//

// TYPES
import { CustomInputProps } from '../../types/typeDefinitions';
//

function CustomInput({ ...props }: CustomInputProps): React.ReactElement {
  const { formControlProps, labelText, id, labelProps, inputProps, helpText } =
    props;

  return (
    <FormControl {...formControlProps}>
      {labelText !== undefined && (
        <InputLabel htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      )}

      <Input id={id} {...inputProps} />

      {helpText !== undefined && (
        <FormHelperText id={`${id}-text`}>{helpText}</FormHelperText>
      )}
    </FormControl>
  );
}

export default CustomInput;
