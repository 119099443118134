// REACT
import React, { ReactElement } from 'react';
//

// SERVICES
import {
  isValidDate,
  isValidNumber,
  isValidText,
} from '../../services/validations';
//

// TYPES
import { isDefined } from '../../types/typeDefinitions';
//

export interface FormElementUtils {
  value: any;
  isTouched: boolean;
  isValid: boolean;
  change: (event: any, type?: string) => void;
  focus: (event: any, type?: string) => void;
  open: (event: any, type?: string) => void;
  reset: () => void;
}

export const stateChangeTypes = {
  reset: '__value_reset__',
  change: '__value_change__',
  open: '__element_open__',
  focus: '__element_focus__',
  validate: '__element_validate__',
};

export const elementTypes = {
  input: '__input__',
  numericTextBox: '__numerictextbox__',
  dropDown: '__dropdown__',
  multiSelect: '__multiselect__',
  datePicker: '__datepicker__',
};

interface Props {
  children?: any;
  isLoaded?: boolean;
  initialElementType?: string;
  initialValue?: any;
  initialValidations?: { touched: boolean; valid: boolean };
  value?: any;
  onValueChange?: any;
  onFocus?: any;
  onOpen?: any;
  onValidate?: any;
  onReset?: any;
  onStateChange?: any;
  stateReducer?: (state: any, changes: any) => any;
  [k: string]: any;
}

interface State {
  elementType: string;
  value: any;
  validations: { touched: boolean; valid: boolean };
  [k: string]: any;
}

class FormElement extends React.Component<Props, State> {
  static defaultProps: Props = {
    isLoaded: true,
    initialElementType: '__input__',
    initialValue: null,
    initialValidations: { touched: false, valid: false },
    onValueChange: () => {},
    onFocus: () => {},
    onOpen: () => {},
    onValidate: () => {},
    onReset: () => {},
    onStateChange: () => {},
    stateReducer: (_state, changes) => changes,
  };

  static stateChangeTypes = {
    reset: '__value_reset__',
    change: '__value_change__',
    open: '__element_open__',
    focus: '__element_focus__',
    validate: '__element_validate__',
  };

  static elementTypes = {
    input: '__input__',
    numericTextBox: '__numerictextbox__',
    dropDown: '__dropdown__',
    multiSelect: '__multiselect__',
    datePicker: '__datepicker__',
  };

  initialState: State = {
    elementType: this.props.initialElementType as State['elementType'],
    value:
      this.props.initialElementType === FormElement.elementTypes.input &&
      this.props.initialValue === null
        ? ''
        : this.props.initialValue,
    validations: {
      ...(this.props.initialValidations as State['validations']),
      valid:
        this.props.initialElementType === FormElement.elementTypes.dropDown ||
        this.props.initialElementType ===
          FormElement.elementTypes.numericTextBox
          ? isValidNumber(this.props.initialValue)
          : this.props.initialElementType ===
            FormElement.elementTypes.datePicker
          ? isValidDate(this.props.initialValue)
          : isValidText(this.props.initialValue),
    },
  };
  state = this.initialState;

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.isLoaded !== this.props.isLoaded) {
      this.validate();
    }
  }

  isControlled = (prop: string) => {
    return this.props[prop] !== undefined;
  };

  isValid = (value: any) => {
    return this.getState().elementType === FormElement.elementTypes.dropDown ||
      this.getState().elementType === FormElement.elementTypes.numericTextBox
      ? isValidNumber(value)
      : this.getState().elementType === FormElement.elementTypes.datePicker
      ? isValidDate(value)
      : isValidText(value);
  };

  getState = (state = this.state) => {
    return Object.entries(state).reduce((combinedState: any, [key, value]) => {
      if (this.isControlled(key)) {
        combinedState[key] = this.props[key];
      } else {
        combinedState[key] = value;
      }

      return combinedState;
    }, {});
  };

  internalSetState(changes: any, callback: () => void = () => {}): void {
    let allChanges: any;

    this.setState(
      (state) => {
        const combinedState: State = this.getState(state);

        // handle function setState call
        const changesObj: any =
          typeof changes === 'function' ? changes(combinedState) : changes;

        // apply state reducer
        allChanges = isDefined(this.props.stateReducer)
          ? this.props.stateReducer(combinedState, changesObj) || {}
          : {};

        // remove the type so it's not set into state
        const { type, ...onlyChanges } = allChanges;

        const nonControlledChanges: any = Object.keys(combinedState).reduce(
          (newChanges: any, stateKey) => {
            if (!this.isControlled(stateKey)) {
              newChanges[stateKey] = onlyChanges.hasOwnProperty(stateKey)
                ? onlyChanges[stateKey]
                : combinedState[stateKey];
            }

            return newChanges;
          },
          {}
        );

        // return null if there are no changes to be made
        return Object.keys(nonControlledChanges || {})?.length
          ? nonControlledChanges
          : null;
      },
      () => {
        this.props.onStateChange(allChanges, this.getStateAndHelpers());

        callback();
      }
    );
  }

  change = (event: any, type = FormElement.stateChangeTypes.change) => {
    const newValue: any = event.target.value;

    this.internalSetState(
      (state: State) => ({
        type,
        value: newValue,
        validations: { ...state.validations, valid: this.isValid(newValue) },
      }),
      this.props.onValueChange(newValue, this.getState().validations)
    );
  };

  focus = (_event: any, type = FormElement.stateChangeTypes.focus) => {
    if (!this.getState().validations.touched) {
      this.internalSetState(
        ({ validations }: State) => ({
          type,
          validations: {
            ...validations,
            touched: true,
            valid: this.isValid(this.getState().value),
          },
        }),
        this.props.onFocus(this.getState().validations)
      );
    }
  };

  open = (_event: any, type = FormElement.stateChangeTypes.open) => {
    if (!this.getState().validations.touched) {
      this.internalSetState(
        ({ validations }: State) => ({
          type,
          validations: {
            ...validations,
            touched: true,
            valid: this.isValid(this.getState().value),
          },
        }),
        this.props.onOpen(this.getState().validations)
      );
    }
  };

  validate = (type = FormElement.stateChangeTypes.validate) => {
    this.internalSetState(
      (state: State) => ({
        type,
        validations: {
          ...state.validations,
          valid: this.isValid(this.getState().value),
        },
      }),
      this.props.onValidate(this.getState().validations.valid)
    );
  };

  reset = () => {
    this.internalSetState(
      {
        ...this.initialState,
        type: FormElement.stateChangeTypes.reset,
      },
      () => this.props.onReset(this.getState().value)
    );
  };

  getStateAndHelpers = () => {
    return {
      value: this.getState().value,
      isTouched: this.getState().validations.touched,
      isValid: this.getState().validations.valid,
      change: this.change,
      focus: this.focus,
      open: this.open,
      reset: this.reset,
    };
  };

  render(): ReactElement {
    return this.props.children(this.getStateAndHelpers());
  }
}

export default FormElement;
