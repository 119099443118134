// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import ExtrasGrid from './ExtrasGrid';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import KendoLoader from '../../../components/Loader/KendoLoader';
import ExtrasFilterBar from './ExtrasFilterBar';
import ExtrasApplyFilterBar from './ExtrasApplyFilterBar';
//

// KENDO
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
//

// HOOKS
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import useNotifications from '../../../hooks/useNotifications';
import {
  extrasApiSlice,
  useAddSingleExtraMutation,
  useLazyGetExtraBoatDetailsQuery,
  useLazyGetExtrasDetailsQuery,
  useUpdateExtraAppliedListMutation,
  useUpdateSingleExtraMutation,
} from './extrasApiSlice';
import {
  clearBoatListForUi,
  clearBoatsInExtra,
  saveBoatsExtraList,
  resetExtrasApplyWindowFilterState,
  resetExtraIdForManage,
  onApplyWindowCheckboxChange,
} from './extrasStateSlice';
import { useLazyGetRegenerateSearchIndexesQuery } from '../Boats/boatsApiSlice';
import { toggleShowOnlyApplied } from '../Discounts/discountsStateSlice';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
//

// TYPES
import { Extra, ExtraBoatDetails } from '../../../types/typeDefinitions';
//

const ExtrasContainer = () => {
  // init state values
  const generateTempId = () => new Date().getMilliseconds();

  const initialExtraValue = useMemo(() => {
    return {
      // name: '',
      translations: [
        {
          languageId: 1,
          languageCode: 'En',
          text: '',
        },
        {
          languageId: 2,
          languageCode: 'Hr',
          text: '',
        },
        {
          languageId: 3,
          languageCode: 'De',
          text: '',
        },
        {
          languageId: 4,
          languageCode: 'Fr',
          text: '',
        },
        {
          languageId: 5,
          languageCode: 'Es',
          text: '',
        },
      ],
      price: null,
      sailingDateFrom: null,
      sailingDateTo: null,
      validDaysFrom: null,
      validDaysTo: null,
      tempId: generateTempId(),
      isPerPerson: false,
      isRequired: false,
      isIncludedInBasePrice: false,
      isPayableOnInovice: false,
      availableInBaseId: null,
      availableInBase: '',
      appliedTo: null,
      timeUnitId: null,
      timeUnit: '',
      isToUpdate: true,
    };
  }, []);
  //

  const currentFilterState = useAppSelector(
    (state) => state.extrasState.extrasFilters.filter.filters
  ).find((singleFilter) => singleFilter.name === 'extraId');

  const isInManageMode = useCallback(() => {
    if (currentFilterState?.value !== null) {
      return true;
    }
    return false;
  }, [currentFilterState]);

  // query hooks
  const [
    updateSingleExtra,
    {
      isLoading: isUpdateSingleExtraLoading,
      isError: isUpdateSingleExtraError,
    },
  ] = useUpdateSingleExtraMutation();

  const [
    addSingleExtra,
    { isLoading: isAddSingleExtraLoading, isError: isAddSingleExtraError },
  ] = useAddSingleExtraMutation();

  const [
    updateExtraAppliedList,
    {
      isError: isUpdateExtraAppliedError,
      isLoading: isUpdateExtraAppliedLoading,
      isSuccess: isUpdateExtraAppliedSuccess,
    },
  ] = useUpdateExtraAppliedListMutation();

  const [
    getExtraBoatDetails,
    {
      isLoading: isGetExtraBoatDetailsLoading,
      isError: isGetExtraBoatDetailsError,
      isFetching: isGetExtraBoatDetailsFetching,
      isSuccess: isGetExtraBoatDetailsSuccess,

      currentData: CURRENT_DATA,
    },
  ] = useLazyGetExtraBoatDetailsQuery();
  //

  // selectors
  const getExtraListFromStore: any = useAppSelector(
    (state) => state.extrasState.data.extrasList
  );

  const getExtraBoatDetailsListFromStore = useAppSelector(
    (state) => state.extrasState.data.boatListForUI
  );

  const getExtrasApplyFilterState = useAppSelector(
    (state) => state.extrasState.extrasApplyWindowFilters
  );
  //

  // utilities
  const dispatch = useAppDispatch();

  const handleCloningEntity = useCallback(
    (entity: any) => cloneDeep(entity),
    []
  );

  const { handleUserActionNotification, handlePromiseNotification } =
    useNotifications();
  //

  // memoized values
  const allExtrasList: any = useMemo(
    () =>
      getExtraListFromStore && 'data' in getExtraListFromStore
        ? handleCloningEntity(getExtraListFromStore.data)
        : handleCloningEntity(getExtraListFromStore),
    [getExtraListFromStore, handleCloningEntity]
  );

  const [newOrSelectedExtra, setNewOrSelectedExtra] = useState<
    Partial<Extra> | any | null
  >(initialExtraValue);

  const extraBoatDetailsList: ExtraBoatDetails[] = useMemo(
    () => handleCloningEntity(getExtraBoatDetailsListFromStore),
    [getExtraBoatDetailsListFromStore, handleCloningEntity]
  );

  const clearNullValuesForQueryString = useMemo(() => {
    const tmp = getExtrasApplyFilterState.filter.filters.filter(
      (singleFilter: any) => singleFilter.value !== null
    );

    if (tmp?.length === 0) {
      return null;
    } else {
      return {
        ...getExtrasApplyFilterState,
        filter: {
          filters:
            newOrSelectedExtra.mmkId !== null
              ? [
                  ...tmp,
                  {
                    field: 'boatSource',
                    name: 'boatSource',
                    operator: 'eq',
                    value: 1,
                  },
                ]
              : tmp,
          logic: 'and',
        },
      };
    }
  }, [getExtrasApplyFilterState, newOrSelectedExtra]);

  // local component state
  const [typeOfWindow, setTypeOfWindow] = useState('');

  const [extraId, setExtraId] = useState<number | null>();

  const [selectAll, setSelectAll] = useState(false);

  const [isWindowVisible, setIsWindowVisible] = useState<boolean>(false);

  const validateForm = (data: any | Partial<Extra>) => {
    return (
      // data.name !== '' &&
      data.translations.find((tr: any) => tr.languageCode === 'En').text !==
        '' &&
      data.price !== null &&
      data.price >= 0 &&
      new Date(data.sailingDateFrom) <= new Date(data.sailingDateTo) &&
      data.validDaysFrom >= 0 &&
      data.validDaysFrom <= data.validDaysTo &&
      data.timeUnit !== ''
    );
  };

  const handleSubmit = useCallback(
    (data: any | Partial<Extra>) => {
      if (validateForm(data)) {
        setNewOrSelectedExtra(initialExtraValue);
        setNewOrSelectedExtra((prev: any) => {
          return {
            ...prev,
            // name: '',
            translations: [
              {
                languageId: 1,
                languageCode: 'En',
                text: '',
              },
              {
                languageId: 2,
                languageCode: 'Hr',
                text: '',
              },
              {
                languageId: 3,
                languageCode: 'De',
                text: '',
              },
              {
                languageId: 4,
                languageCode: 'Fr',
                text: '',
              },
              {
                languageId: 5,
                languageCode: 'Es',
                text: '',
              },
            ],
            price: null,
            sailingDateFrom: null,
            sailingDateTo: null,
            validDaysFrom: null,
            validDaysTo: null,
            tempId: generateTempId(),
            isPerPerson: false,
            isRequired: false,
            isIncludedInBasePrice: false,
            isPayableOnInovice: false,
            availableInBaseId: null,
            availableInBase: '',
            appliedTo: null,
            timeUnitId: null,
            timeUnit: '',
            isToUpdate: true,
          };
        });

        try {
          if ('tempId' in data) {
            handlePromiseNotification(addSingleExtra(data).unwrap(), {
              pending: { message: 'Processing...', type: 'info' },
              success: {
                message: 'Extra added successfully!',
                type: 'success',
              },
              error: {
                message: 'There was an error with your request.',
                type: 'error',
              },
            });
            if (!isAddSingleExtraError && !isAddSingleExtraLoading) {
              setNewOrSelectedExtra(initialExtraValue);
              setNewOrSelectedExtra((prev: any) => {
                return {
                  ...prev,
                  // name: '',
                  translations: [
                    {
                      languageId: 1,
                      languageCode: 'En',
                      text: '',
                    },
                    {
                      languageId: 2,
                      languageCode: 'Hr',
                      text: '',
                    },
                    {
                      languageId: 3,
                      languageCode: 'De',
                      text: '',
                    },
                    {
                      languageId: 4,
                      languageCode: 'Fr',
                      text: '',
                    },
                    {
                      languageId: 5,
                      languageCode: 'Es',
                      text: '',
                    },
                  ],
                  price: null,
                  sailingDateFrom: null,
                  sailingDateTo: null,
                  validDaysFrom: null,
                  validDaysTo: null,
                  tempId: generateTempId(),
                  isPerPerson: false,
                  isRequired: false,
                  isIncludedInBasePrice: false,
                  isPayableOnInovice: false,
                  availableInBaseId: null,
                  availableInBase: '',
                  appliedTo: null,
                  timeUnitId: null,
                  timeUnit: '',
                  isToUpdate: true,
                };
              });
              setTypeOfWindow('');
              setIsWindowVisible(false);
            }
          } else {
            setNewOrSelectedExtra(initialExtraValue);
            setNewOrSelectedExtra((prev: any) => {
              return {
                ...prev,
                // name: '',
                translations: [
                  {
                    languageId: 1,
                    languageCode: 'En',
                    text: '',
                  },
                  {
                    languageId: 2,
                    languageCode: 'Hr',
                    text: '',
                  },
                  {
                    languageId: 3,
                    languageCode: 'De',
                    text: '',
                  },
                  {
                    languageId: 4,
                    languageCode: 'Fr',
                    text: '',
                  },
                  {
                    languageId: 5,
                    languageCode: 'Es',
                    text: '',
                  },
                ],
                price: null,
                sailingDateFrom: null,
                sailingDateTo: null,
                validDaysFrom: null,
                validDaysTo: null,
                tempId: generateTempId(),
                isPerPerson: false,
                isRequired: false,
                isIncludedInBasePrice: false,
                isPayableOnInovice: false,
                availableInBaseId: null,
                availableInBase: '',
                appliedTo: null,
                timeUnitId: null,
                timeUnit: '',
                isToUpdate: true,
              };
            });
            handlePromiseNotification(updateSingleExtra(data).unwrap(), {
              pending: { message: 'Processing...', type: 'info' },
              success: {
                message: 'Extra updated successfully!',
                type: 'success',
              },
              error: {
                message: 'There was an error with your request.',
                type: 'error',
              },
            });
            if (!isUpdateSingleExtraError && !isUpdateSingleExtraLoading) {
              setNewOrSelectedExtra(initialExtraValue);
              setTypeOfWindow('');
              setIsWindowVisible(false);
            }
          }
        } catch (error: any) {
          handleUserActionNotification({
            type: 'error',
            autoClose: 2500,
            message: `${error.status} : \n ${error.data.title}`,
          });
        }
      } else
        handleUserActionNotification({
          type: 'warning',
          autoClose: 2500,
          message: 'One or more validation errors occurred!',
        });
    },
    [
      updateSingleExtra,
      handleUserActionNotification,
      isUpdateSingleExtraError,
      isUpdateSingleExtraLoading,
      addSingleExtra,
      isAddSingleExtraError,
      isAddSingleExtraLoading,
      initialExtraValue,
      handlePromiseNotification,
    ]
  );

  const handleClose = () => {
    setIsWindowVisible(false);
    setTypeOfWindow('');
    setNewOrSelectedExtra(initialExtraValue);
    setExtraId(null);
  };

  const [GET_EXTRA_DETAILS] = useLazyGetExtrasDetailsQuery();

  const handleEdit = async (dataItem: Partial<Extra>) => {
    const req = await GET_EXTRA_DETAILS(dataItem.id as number);

    setIsWindowVisible(true);
    setTypeOfWindow('edit');

    if ('data' in req) {
      setNewOrSelectedExtra(
        handleCloningEntity({
          ...dataItem,
          translations: req.data.translations,
        })
      );
    }
  };

  const handleAddNewExtra = () => {
    setTypeOfWindow('add');
    setIsWindowVisible(true);
  };

  const handleSubmitApply = () => {
    // hotfix #2
    dispatch(clearBoatsInExtra());
    dispatch(clearBoatListForUi());
    const filterItemsForUpdate = [
      {
        Id: extraId,
        AddOnBoats: extraBoatDetailsList
          .filter((item) =>
            item.appliedOn.some((val: any) => val.id === extraId)
          )
          .map((item) => {
            return item.id;
          }),
        RemoveFromBoats: extraBoatDetailsList
          .filter((item) =>
            item.appliedOn.every((val: any) => val.id !== extraId)
          )
          .map((item) => {
            return item.id;
          }),
      },
    ];

    try {
      handlePromiseNotification(
        updateExtraAppliedList(filterItemsForUpdate)
          .unwrap()
          .then(() => {
            dispatch(extrasApiSlice.util.invalidateTags(['Extra-Boats-List']));
            dispatch(
              onApplyWindowCheckboxChange({
                name: 'boatsWithService',
                eventValue: true,
              })
            );
          }),
        {
          pending: { message: 'Processing...', type: 'info' },
          success: {
            message: 'Extra boat list updated successfully!',
            type: 'success',
          },
          error: {
            message: 'There was an error with your request.',
            type: 'error',
          },
        }
      );

      if (
        !isUpdateExtraAppliedError &&
        !isUpdateExtraAppliedLoading &&
        isUpdateExtraAppliedSuccess
      ) {
        dispatch(resetExtrasApplyWindowFilterState());
        // hotfix #3
        dispatch(extrasApiSlice.util.invalidateTags(['Extra-Boats-List']));
      }
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        autoClose: 2500,
        message: `${error.status} : \n ${error.error}`,
      });
    }
  };

  const prepareApplyWindowBoatListOnFilterUpdate = useCallback(async () => {
    if (extraId !== null && extraId !== undefined) {
      dispatch(toggleShowOnlyApplied(true));
      const tmp = clearNullValuesForQueryString
        ? clearNullValuesForQueryString
        : null;

      try {
        await getExtraBoatDetails({
          id: extraId,
          datasourceReqStr: tmp,
        }).unwrap();

        if (
          !isGetExtraBoatDetailsLoading &&
          !isGetExtraBoatDetailsError &&
          isGetExtraBoatDetailsSuccess
        ) {
          CURRENT_DATA !== undefined &&
            dispatch(saveBoatsExtraList(CURRENT_DATA['data']));
        }
      } catch (error: any) {
        console.log(error);
        handleUserActionNotification({
          message: `There was something wrong with your request: ${error}`,
          autoClose: 2500,
          type: 'error',
        });
        setExtraId(null);
        setNewOrSelectedExtra(null);
        setIsWindowVisible(false);
        dispatch(resetExtrasApplyWindowFilterState());
        return;
      }
    }
  }, [
    getExtraBoatDetails,
    extraId,
    dispatch,
    isGetExtraBoatDetailsError,
    isGetExtraBoatDetailsLoading,
    handleUserActionNotification,
    clearNullValuesForQueryString,
    isGetExtraBoatDetailsSuccess,
    CURRENT_DATA,
  ]);

  const handleApply = (dataItem: Extra) => {
    setExtraId(dataItem.id);
    setTypeOfWindow('apply');
    setIsWindowVisible(true);

    setNewOrSelectedExtra(dataItem);
  };

  const handleCloseApply = () => {
    dispatch(clearBoatsInExtra());
    dispatch(clearBoatListForUi());
    dispatch(resetExtrasApplyWindowFilterState());
    setTypeOfWindow('');
    setIsWindowVisible(false);
    setExtraId(null);

    setNewOrSelectedExtra(initialExtraValue);
  };

  useEffect(() => {
    prepareApplyWindowBoatListOnFilterUpdate();
  }, [prepareApplyWindowBoatListOnFilterUpdate, extraId]);

  const singleItemCheckboxChangeHandler = (
    event: CheckboxChangeEvent,
    item: any
  ) => {
    const tmp = extraBoatDetailsList.map((singleItem: ExtraBoatDetails) => {
      if (event.value === true && singleItem.id === item.id) {
        if (singleItem.appliedOn.some((val: any) => val.id === extraId)) {
          return singleItem;
        } else {
          singleItem.appliedOn = [...item.appliedOn, { id: extraId }];
        }
      } else {
        if (event.value === false && singleItem.id === item.id) {
          if (singleItem.appliedOn.some((val: any) => val.id === extraId)) {
            singleItem.appliedOn = [...singleItem.appliedOn].filter(
              (singleBoatId: any) => singleBoatId.id !== extraId
            );
          }
        }
      }
      return singleItem;
    });

    dispatch(saveBoatsExtraList(tmp));
  };

  const selectAllItemsCheckboxChangeHandler = (event: CheckboxChangeEvent) => {
    setSelectAll(event.target.value as boolean);

    const tmp: ExtraBoatDetails[] = extraBoatDetailsList.map((item) => {
      if (event.value === true) {
        if (item.appliedOn.some((val: any) => val.id === extraId)) {
          return item;
        } else {
          item.appliedOn = [...item.appliedOn, { id: extraId }];
        }

        return item;
      } else {
        if (event.value === false) {
          if (item.appliedOn.some((val: any) => val.id === extraId)) {
            item.appliedOn = [...item.appliedOn].filter(
              (singleBoatId) => singleBoatId.id !== extraId
            );
          }
        }
        return item;
      }
    });

    dispatch(saveBoatsExtraList(tmp));
  };

  // optional
  useEffect(() => {
    return () => {
      dispatch(resetExtraIdForManage());
    };
  }, [dispatch]);

  const [getRegenerateSearchIndexes] = useLazyGetRegenerateSearchIndexesQuery();

  const handleRegenerate = () => {
    try {
      handlePromiseNotification(getRegenerateSearchIndexes().unwrap(), {
        pending: { message: 'Saving...', type: 'info' },
        success: {
          message: 'Public data generated successfully!',
          type: 'success',
        },
        error: {
          message: 'Something went wrong with your request.',
          type: 'error',
        },
      });
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        message: `${error.status} : \n ${error.error}`,
        autoClose: 2500,
      });
    }
  };

  const isLoading = useAppSelector((state) => {
    return Object.values(state['Extras-Api-Slice'].queries).some(
      (query) =>
        query &&
        query.endpointName === 'getAllExtras' &&
        query.status === QueryStatus.pending
    );
  });

  const extrasFiltersHeight = useAppSelector(
    (state) => state.generalState.data.extrasFiltersHeight
  );

  return (
    <>
      <MaterialGrid container direction='column' spacing={2}>
        <MaterialGrid
          container
          position='fixed'
          top={16.6}
          right={16.6}
          zIndex={1201}
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
        >
          <MaterialGrid item>
            <button onClick={handleRegenerate} className='light-button'>
              Generate public data
            </button>
          </MaterialGrid>

          <MaterialGrid item>
            {!isInManageMode() && (
              <button className='pink-button' onClick={handleAddNewExtra}>
                Add new extra
              </button>
            )}
          </MaterialGrid>
        </MaterialGrid>

        <MaterialGrid pl={2} pr={2}>
          {<ExtrasFilterBar />}
        </MaterialGrid>

        <MaterialGrid
          item
          width={'100%'}
          height={`calc(100vh - ${extrasFiltersHeight}px - 100px)`}
        >
          <ExtrasGrid
            data={
              isInManageMode() === true
                ? allExtrasList.filter(
                    (singleExtra: any) =>
                      singleExtra.id === currentFilterState?.value
                  )
                : (allExtrasList as Extra[])
            }
            isLoading={isLoading}
            handleEdit={handleEdit}
            handleApply={handleApply}
            isWindowVisible={isWindowVisible}
            typeOfWindow={typeOfWindow}
            extraDataItem={newOrSelectedExtra}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            generateTempId={generateTempId}
          />
        </MaterialGrid>

        {isInManageMode() && (
          <MaterialGrid item container spacing={1} justifyContent='flex-end'>
            <button
              onClick={() => {
                dispatch(resetExtraIdForManage());
              }}
              className='red-button'
            >
              See all extras
            </button>
          </MaterialGrid>
        )}
      </MaterialGrid>

      {/* APPLY DIALOG */}

      <CustomDialog
        open={isWindowVisible && typeOfWindow === 'apply'}
        title={'Apply Extra'}
        onClose={handleCloseApply}
        onConfirm={handleSubmitApply}
      >
        <MaterialGrid item container>
          <ExtrasApplyFilterBar />
        </MaterialGrid>

        <MaterialGrid container className='apply-dialog-content'>
          <MaterialGrid item container>
            {isGetExtraBoatDetailsFetching && <KendoLoader />}
            {extraBoatDetailsList !== undefined &&
              extraId !== null &&
              extraBoatDetailsList?.length !== 0 && (
                <MaterialGrid item container direction='row'>
                  <MaterialGrid item className='select-all-checkbox'>
                    <Checkbox
                      value={selectAll}
                      label={'Select/unselect all'}
                      onChange={(event: CheckboxChangeEvent) =>
                        selectAllItemsCheckboxChangeHandler(event)
                      }
                    />
                  </MaterialGrid>
                </MaterialGrid>
              )}
          </MaterialGrid>

          <MaterialGrid
            item
            container
            spacing={1}
            className='boat-details-apply'
          >
            {extraBoatDetailsList?.length !== 0 &&
              extraBoatDetailsList.map((item, index) => {
                return (
                  <MaterialGrid item container direction='row' key={index}>
                    <Checkbox
                      value={
                        item.appliedOn.some(
                          (value: any) => value.id === extraId
                        )
                          ? true
                          : false
                      }
                      label={
                        item
                          ? `${
                              item.name === null || item.name === undefined
                                ? 'Unnamed'
                                : `${item.name}`
                            } ${
                              item.manufacturer === null ||
                              item.manufacturer === undefined
                                ? 'No manufacturer'
                                : `${item.manufacturer}`
                            } ${
                              item.model === null || item.model === undefined
                                ? 'No model'
                                : `${item.model}`
                            } ${
                              item.variation === null ||
                              item.variation === undefined
                                ? 'No variation'
                                : `${item.variation}`
                            } ${
                              item.type === null || item.type === undefined
                                ? 'No type'
                                : `${item.type}`
                            } ${
                              item.yearOfProduction === null ||
                              item.yearOfProduction === 0 ||
                              item.yearOfProduction === undefined
                                ? 'No year'
                                : `${item.yearOfProduction}`
                            } ${
                              item.company === null ||
                              item.company === undefined
                                ? 'No company'
                                : `${item.company}`
                            }`
                          : ''
                      }
                      onChange={(event) =>
                        singleItemCheckboxChangeHandler(event, item)
                      }
                    />
                  </MaterialGrid>
                );
              })}
          </MaterialGrid>
        </MaterialGrid>
      </CustomDialog>
    </>
  );
};

export default ExtrasContainer;
