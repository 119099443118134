// KENDO
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Error } from '@progress/kendo-react-labels';
//

const DropdownCell = (props: any) => {
  return (
    <td>
      <DropDownList
        {...props}
        className='inline-cell editable-cell'
        value={props.dataItem[props.field]}
        onChange={(e) =>
          props.onChange({
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.value,
          })
        }
      />
      {props.field === 'countryName' && (
        <Error>
          {(props.dataItem[props.field] === null ||
            props.dataItem[props.field] === undefined) &&
            `Country cannot be empty!`}
        </Error>
      )}
    </td>
  );
};

export default DropdownCell;
