// REACT
import React from 'react';
//

// TYPES
import { rowRender } from '../../../services/gridRowRender';
import {
  ModelGridItem,
  ModelGridProps,
  ModelGridState,
} from '../../../types/typeDefinitions';
//

// KENDO
import {
  Grid,
  GridColumn as Column,
  GridExpandChangeEvent,
  GridDataStateChangeEvent,
  GridCellProps,
  GridToolbar,
} from '@progress/kendo-react-grid';
//

// COMPONENTS
import { CommandCell } from '../../../components/CommandCell/CommandCell';
import { LanguageCell } from '../../../components/LanguageCell/LanguageCell';
import ModelDetailRow from './ModelDetailRow';
//

// ICONS
import { MdDone as Done } from 'react-icons/md';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';

//

function PhotoCell(props: GridCellProps): React.ReactElement {
  return <td>{props.dataItem.hasPhoto ? <Done /> : null}</td>;
}

const ModelGrid = (props: ModelGridProps) => {
  const [expandedModelIds, setExpandedModelIds] = React.useState<
    ModelGridState['expandedModelIds']
  >([]);

  const handleDataStateChange = (event: GridDataStateChangeEvent) =>
    props.getModels(event.dataState);
  const handleCreate = (itemType: string) => (modelId: number) => {
    props.handleCreate(itemType)(modelId);
  };

  const handleEdit = (itemType: string) => (dataItem: any) => {
    props.handleEdit(itemType, dataItem);
  };

  const handleDelete = (dataItem: any) =>
    props.handleDelete ? props.handleDelete(dataItem) : null;

  const handleExpandChange = (event: GridExpandChangeEvent) => {
    if (expandedModelIds.includes(event.dataItem.id) === false) {
      setExpandedModelIds([...expandedModelIds, event.dataItem.id]);
    } else {
      setExpandedModelIds(
        expandedModelIds.filter((id) => id !== event.dataItem.id)
      );
    }
  };

  const models: ModelGridItem[] = props.models.map((m) => ({
    ...m,
    expanded: expandedModelIds.includes(m.id),
  }));

  const handleDeleteVar = (dataItem: any) =>
    props.handleDeleteVariation ? props.handleDeleteVariation(dataItem) : null;

  return (
    <MaterialGrid width={'100%'} height={'100%'}>
      <Grid
        data={models}
        scrollable='scrollable'
        pageable={true}
        total={props.total}
        sortable={true}
        resizable={true}
        detail={(detailProps) => (
          <ModelDetailRow
            {...detailProps}
            variations={props.variations}
            handleCreate={handleCreate}
            handleEdit={handleEdit}
            updateVariations={props.updateVariations}
            handleDelete={handleDeleteVar}
          />
        )}
        {...props.dataState}
        expandField='expanded'
        onExpandChange={handleExpandChange}
        onDataStateChange={handleDataStateChange}
        rowRender={rowRender}
        width={'100%'}
      >
        <GridToolbar>
          <MaterialGrid item>
            <div className='grid-layout'>MODELS</div>
          </MaterialGrid>
        </GridToolbar>

        <Column
          field='_command'
          groupable={false}
          sortable={false}
          resizable={true}
          filterable={false}
          title=' '
          width={40}
          cell={(props) => (
            <CommandCell
              {...props}
              hasEditCommand={true}
              handleEdit={handleEdit('Model')}
            />
          )}
        />
        <Column
          field='_command'
          groupable={false}
          sortable={false}
          resizable={true}
          filterable={false}
          title=' '
          width={40}
          cell={(props) => (
            <CommandCell
              {...props}
              hasDeleteCommand={true}
              handleDelete={handleDelete}
              // hasEditCommand={true}
              // handleEdit={handleEdit('Model')}
            />
          )}
        />
        <Column field='id' title='ID' />
        <Column field='name' title='Name' />
        <Column field='manufacturer' title='Manufacturer' />
        <Column
          field='hasPhoto'
          title='Photo'
          cell={(props) => <PhotoCell {...props} />}
        />
        <Column
          field='numberOfDescriptionTranslations'
          title='Description translations'
          sortable={false}
          cell={(props) => <LanguageCell {...props} />}
        />
      </Grid>
    </MaterialGrid>
  );
};

export default ModelGrid;
