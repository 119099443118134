//@ts-nocheck

// TYPES
import documentTypes from '../../../../types/enums/documentType';
import {
  DocumentUploadProps,
  DocumentUploadState,
  User,
} from '../../../../types/typeDefinitions';
//

// HOOKS
import { useState } from 'react';
//

// KENDO
import {
  Upload,
  UploadFileStatus,
  UploadOnBeforeUploadEvent,
  UploadOnBeforeRemoveEvent,
  UploadOnStatusChangeEvent,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
  UploadOnProgressEvent,
} from '@progress/kendo-react-upload';
//

// MUI
import { Grid as MaterialGrid, InputLabel } from '@mui/material';
//

// SERVICES
import { getUserFromStorage } from '../../../../services/storage';
//

const DocumentUpload = (props: DocumentUploadProps) => {
  // console.log('DOKUMENTI IZ CHILDA KOJI SU DOSLI', props.documents);

  const documentEn = props.documents.find(
    (doc) => doc.languageCode === 'En' && doc.id
  );

  const documentHr = props.documents.find(
    (doc) => doc.languageCode === 'Hr' && doc.id
  );

  const documentDe: any = props.documents.find(
    (doc) => doc.languageCode === 'De' && doc.id
  );

  const documentFr: any = props.documents.find(
    (doc) => doc.languageCode === 'Fr' && doc.id
  );

  const documentEs: any = props.documents.find(
    (doc) => doc.languageCode === 'Es' && doc.id
  );

  // console.log('DOKUMENTI NAKON OBRADE', documentEn, documentHr);

  const documentEnFileName =
    documentEn !== undefined && documentEn.fileName ? documentEn.fileName : '';
  const documentHrFileName =
    documentHr !== undefined && documentHr.fileName ? documentHr.fileName : '';

  const documentDeFileName =
    documentDe && documentDe.fileName ? documentDe?.fileName : '';

  const documentFrFileName =
    documentFr && documentFr.fileName ? documentFr?.fileName : '';

  const documentEsFileName =
    documentEs && documentEs.fileName ? documentEs?.fileName : '';

  const [state, setState] = useState<DocumentUploadState>({
    document_En:
      documentEn !== undefined
        ? [
            {
              uid: `${props.boatId}_documents_en_${props.documentType}`,
              name: documentEnFileName,
              extension: documentEnFileName.slice(
                documentEnFileName.lastIndexOf('.') - 1
              ),
              progress: 100,
              status: UploadFileStatus.Uploaded,
            },
          ]
        : [],
    document_Hr:
      documentHr !== undefined
        ? [
            {
              uid: `${props.boatId}_documents_hr_${props.documentType}`,
              name: documentHrFileName,
              extension: documentHrFileName.slice(
                documentHrFileName.lastIndexOf('.') - 1
              ),
              progress: 100,
              status: UploadFileStatus.Uploaded,
            },
          ]
        : [],

    document_De: documentDe
      ? [
          {
            uid: `${props.boatId}_documents_de_${props.documentType}`,
            name: documentDeFileName,
            extension: documentDeFileName?.slice(
              documentDeFileName.lastIndexOf('.') - 1
            ),
            progress: 100,
            status: UploadFileStatus.Uploaded,
          },
        ]
      : [],

    document_Fr: documentFr
      ? [
          {
            uid: `${props.boatId}_documents_fr_${props.documentType}`,
            name: documentFrFileName,
            extension: documentFrFileName?.slice(
              documentFrFileName.lastIndexOf('.') - 1
            ),
            progress: 100,
            status: UploadFileStatus.Uploaded,
          },
        ]
      : [],

    document_Es: documentEs
      ? [
          {
            uid: `${props.boatId}_documents_es_${props.documentType}`,
            name: documentEsFileName,
            extension: documentEsFileName?.slice(
              documentEsFileName.lastIndexOf('.') - 1
            ),
            progress: 100,
            status: UploadFileStatus.Uploaded,
          },
        ]
      : [],
  });

  const handleAdd = (name: string) => (event: UploadOnAddEvent) => {
    setState((prev: any) => {
      return { ...prev, [name]: event.newState };
    });
  };

  const handleRemove = (name: string) => (_event: UploadOnRemoveEvent) => {
    setState((prev: any) => {
      return { ...prev, [name]: [] };
    });
  };

  const handleProgress = (name: string) => (event: UploadOnProgressEvent) => {
    setState((prev: any) => {
      return { ...prev, [name]: event.newState };
    });
  };

  const handleStatusChange =
    (name: string) => (event: UploadOnStatusChangeEvent) => {
      setState((prev: any) => {
        return { ...prev, [name]: event.newState };
      });
    };

  const handleBeforeUpload =
    (languageId: number) => (event: UploadOnBeforeUploadEvent) => {
      event.additionalData.documentType = props.documentType;
      event.additionalData.languageId = languageId;

      let userJson: string | null = getUserFromStorage();
      let user: User = userJson ? JSON.parse(userJson) : {};
      event.headers = {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/pdf',
      };
    };

  const handleBeforeRemove =
    (languageId: number) => (event: UploadOnBeforeRemoveEvent) => {
      event.additionalData.documentType = props.documentType;
      event.additionalData.languageId = languageId;

      let userJson: string | null = getUserFromStorage();
      let user: User = userJson ? JSON.parse(userJson) : {};
      event.headers = { Authorization: `Bearer ${user.token}` };
    };

  return (
    <>
      {props.documents.map((doc) => {
        if (doc.languageCode === props.currentChosenLang.code) {
          return (
            <MaterialGrid
              key={`document_${doc.languageCode}`}
              item
              xs={6}
              lg={3}
              container
              direction='column'
              className='form-element'
              alignItems={'flex-start'}
            >
              <MaterialGrid item>
                <InputLabel shrink>
                  {props.documentType === documentTypes.BROCHURE
                    ? 'Brochure'
                    : 'Offer'}
                  {`(${doc.languageCode})`}
                </InputLabel>
              </MaterialGrid>
              <MaterialGrid item container>
                <MaterialGrid item xs={10}>
                  <Upload
                    autoUpload={false}
                    multiple={false}
                    files={state[`document_${doc.languageCode}`]}
                    onAdd={handleAdd(`document_${doc.languageCode}`)}
                    onRemove={handleRemove(`document_${doc.languageCode}`)}
                    onProgress={handleProgress(`document_${doc.languageCode}`)}
                    onStatusChange={handleStatusChange(
                      `document_${doc.languageCode}`
                    )}
                    onBeforeUpload={handleBeforeUpload(doc.languageId)}
                    onBeforeRemove={handleBeforeRemove(doc.languageId)}
                    saveUrl={`${process.env.REACT_APP_BASE_URL}/boats/${props.boatId}/document/save`}
                    removeUrl={`${process.env.REACT_APP_BASE_URL}/boats/${props.boatId}/document/remove`}
                    withCredentials={false}
                    restrictions={{
                      maxFileSize: 2147483647,
                      allowedExtensions: ['.pdf'],
                    }}
                  />
                </MaterialGrid>
              </MaterialGrid>
            </MaterialGrid>
          );
        } else return <></>;
      })}
    </>
  );
};

export default DocumentUpload;
