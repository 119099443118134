// HOOKS
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import useNotifications from '../../../../hooks/useNotifications';
import { cloneDeep } from 'lodash';
//

// COMPONENTS
import PhotoUploadModal from './PhotoUploadModal';

import { ReactSortable } from 'react-sortablejs';
//

// MUI
import {
  Grid as MaterialGrid,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormLabel,
} from '@mui/material';
//

// KENDO
import {
  Upload,
  UploadFileStatus,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
  UploadOnProgressEvent,
  UploadOnStatusChangeEvent,
  UploadOnBeforeUploadEvent,
} from '@progress/kendo-react-upload';
import { Input } from '@progress/kendo-react-inputs';
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
//

// ICONS
import {
  MdEdit as EditIcon,
  MdClose as CloseIcon,
  MdDelete as DeleteIcon,
} from 'react-icons/md';
//

// SERVICES
import { getUserFromStorage } from '../../../../services/storage';
//

// TYPES
import {
  BoatPhoto,
  User,
  Translation,
  isDefined,
  PhotoType,
  BoatDetailsEditPhotoModalProps,
  PhotoCardProps,
  DeletePhotoModalProps,
  EditPhotoModalState,
  BoatDetailsPhotosState,
  BoatDetailsPhotosProps,
} from '../../../../types/typeDefinitions';
import photoTypes from '../../../../types/enums/photoType';
import photoFeaturedTypes from '../../../../types/enums/photoFeaturedType';
//

// ASSETS
import placeholder from '../../../../assets/img/boat_placeholder.jpg';
import { useUpdateCurrentPhotoOrderMutation } from '../boatsApiSlice';
import { FormGridElement } from '../../../../components/FormGridElement/FormGridElement';
import { useAppSelector } from '../../../../app/hooks';
//

const INIT_LANG_STATE_SELECT = { id: 1, code: 'En', displayName: 'English' };

const EditPhotoModal = (props: BoatDetailsEditPhotoModalProps) => {
  const [photo, setPhoto] = useState<EditPhotoModalState['photo']>(null);
  const [newFile, setNewFile] = useState<EditPhotoModalState['newFile']>([]);
  const [imagePreviewUrl, setImagePreviewUrl] =
    useState<EditPhotoModalState['imagePreviewUrl']>(null);

  // LANG SELECTOR AND STATE
  const allLanguagesList = useAppSelector(
    (state) => state.languagesState.data.languages
  );

  const [currentLanguageChosenForm, setCurrentLanguageChosenForm] =
    useState<any>(INIT_LANG_STATE_SELECT);

  useEffect(() => {
    setPhoto(props.photo);
    setNewFile([]);
  }, [props.photo]);

  const handleCloseWindowF = () => {
    props.handleCloseWindow();
    setCurrentLanguageChosenForm(INIT_LANG_STATE_SELECT);
  };

  const handleInputChange =
    (languageCode: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!photo) {
        return;
      }

      const newValue: string = event.target.value;
      const changedDescriptions: Translation[] = photo.descriptions.map((d) => {
        if (d.languageCode === languageCode) {
          d.text = newValue;
        }
        return d;
      });

      setPhoto({
        ...(photo as BoatPhoto),
        descriptions: changedDescriptions,
      });
    };

  const handleDropDownChange =
    (name: string) => (event: DropDownListChangeEvent) => {
      const newValue: any = event.target.value;

      setPhoto({
        ...(photo as BoatPhoto),
        [name]: newValue.value,
      });
    };

  const handleUploadAdd = (event: UploadOnAddEvent) => {
    let reader: FileReader = new FileReader();
    let file: File | null = isDefined(event.newState[0].getRawFile)
      ? event.newState[0].getRawFile()
      : null;

    if (file) {
      reader.onloadend = () => {
        setNewFile(event.newState);
        setImagePreviewUrl(reader.result as string | null);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadRemove = (event: UploadOnRemoveEvent) => {
    setNewFile(event.newState);
  };

  const handleUploadProgress = (event: UploadOnProgressEvent) => {
    setNewFile(event.newState);
  };

  const { handleUserActionNotification } = useNotifications();

  const handleUploadStatusChange = (event: UploadOnStatusChangeEvent) => {
    if (!photo) {
      return;
    }

    if (
      event.newState[0].status === UploadFileStatus.Uploaded &&
      event.response
    ) {
      props.handleChangePhotoUrl({
        id: photo.id,
        url: event.response.response.url,
      });
      handleUserActionNotification({
        type: 'success',
        message: 'Photo updated successfully!',
        autoClose: 2500,
      });
    }

    setNewFile(event.newState);
  };

  const handleBeforeUpload = (event: UploadOnBeforeUploadEvent) => {
    if (!photo) {
      return;
    }

    event.additionalData.photoId = photo.id;

    let userJson: string | null = getUserFromStorage();
    let user: User = userJson ? JSON.parse(userJson) : {};
    event.headers = { Authorization: `Bearer ${user.token}` };
  };

  const handleSaveChanges = () => {
    if (!props.boatId || !photo) {
      return;
    }

    props.handleEdit({ boatId: props.boatId, ...photo });
  };

  return (
    photo && (
      <Dialog
        open={props.isWindowVisible}
        keepMounted={false}
        maxWidth='lg'
        onClose={handleCloseWindowF}
        className='dialog'
      >
        <DialogTitle className='dialog-title'>
          <div className='action-name'>Edit photo</div>

          <button className='close-button' onClick={handleCloseWindowF}>
            <CloseIcon />
          </button>
        </DialogTitle>

        <DialogContent className='form-content'>
          <MaterialGrid container spacing={2}>
            <MaterialGrid item xs={12} className='form-element'>
              <img
                src={imagePreviewUrl ? imagePreviewUrl : photo.url}
                onError={(event: any) => {
                  event.target.src = placeholder;
                }}
                alt='New preview'
                height={150}
                width={250}
              />
            </MaterialGrid>

            <MaterialGrid
              item
              container
              justifyContent='center'
              className='form-element'
            >
              <Upload
                autoUpload={false}
                files={newFile}
                restrictions={{
                  allowedExtensions: ['.jpg', '.jpeg'],
                }}
                multiple={false}
                onAdd={handleUploadAdd}
                onRemove={handleUploadRemove}
                onProgress={handleUploadProgress}
                onStatusChange={handleUploadStatusChange}
                onBeforeUpload={handleBeforeUpload}
                saveUrl={`${process.env.REACT_APP_BASE_URL}/boats/${props.boatId}/photo/replace`}
                withCredentials={false}
              />
            </MaterialGrid>

            <MaterialGrid
              item
              xs={12}
              container
              spacing={2}
              justifyContent='center'
              className='form-element'
            >
              <MaterialGrid item xs={3}>
                <FormLabel>Type</FormLabel>
              </MaterialGrid>
              <MaterialGrid item xs={7}>
                <DropDownList
                  data={Object.values(photoTypes.properties)}
                  value={photoTypes.properties[photo.type]}
                  dataItemKey='value'
                  textField='name'
                  onChange={handleDropDownChange('type')}
                />
              </MaterialGrid>
            </MaterialGrid>

            <MaterialGrid
              item
              xs={12}
              container
              spacing={2}
              justifyContent='center'
              className='form-element'
            >
              <MaterialGrid item xs={3}>
                <FormLabel>Featured</FormLabel>
              </MaterialGrid>
              <MaterialGrid item xs={7}>
                <DropDownList
                  data={Object.values(photoFeaturedTypes.properties)}
                  value={photoFeaturedTypes.properties[photo.featuredType]}
                  dataItemKey='value'
                  textField='name'
                  onChange={handleDropDownChange('featuredType')}
                />
              </MaterialGrid>
            </MaterialGrid>

            <FormGridElement
              component={DropDownList}
              label='Description language'
              additionalProps={{
                data: allLanguagesList,
                dataItemKey: 'id',
                textField: 'displayName',
                defaultValue: allLanguagesList.find(
                  (item) => item.id === currentLanguageChosenForm.id
                ),
              }}
              onChange={(e: DropDownListChangeEvent) => {
                setCurrentLanguageChosenForm(e.value);
              }}
            />

            {photo.descriptions.map((item) => {
              if (item.languageCode === currentLanguageChosenForm.code) {
                return (
                  <MaterialGrid
                    key={`item_${item.languageCode}`}
                    item
                    xs={12}
                    container
                    spacing={2}
                    justifyContent='center'
                    className='form-element'
                  >
                    <MaterialGrid item xs={3}>
                      <FormLabel>Alt ({item.languageCode})</FormLabel>
                    </MaterialGrid>

                    <MaterialGrid item xs={7}>
                      <Input
                        value={item.text !== null ? item.text : ''}
                        onChange={handleInputChange(item.languageCode) as any}
                      />
                    </MaterialGrid>
                  </MaterialGrid>
                );
              }
              return <></>;
            })}
          </MaterialGrid>
        </DialogContent>

        <DialogActions className='dialog-actions'>
          <button onClick={handleCloseWindowF} className='red-button'>
            Cancel
          </button>
          <button onClick={handleSaveChanges} className='primary-button'>
            Save
          </button>
        </DialogActions>
      </Dialog>
    )
  );
};

const DeletePhotoModal = (props: DeletePhotoModalProps) => {
  return (
    <Dialog open={props.isWindowVisible} keepMounted={false} className='dialog'>
      <DialogTitle className='delete-photo-title'>
        <button onClick={props.handleCloseWindow} className='close-button'>
          <CloseIcon />
        </button>
      </DialogTitle>
      <DialogContent>
        <h5>Are you sure you want to permanently delete this photo?</h5>
      </DialogContent>
      <DialogActions className='dialog-actions'>
        <button onClick={props.handleCloseWindow} className='blue-button'>
          Cancel
        </button>
        <button onClick={props.handleDelete} className='red-button'>
          Yes
        </button>
      </DialogActions>
    </Dialog>
  );
};

const PhotoCard = (props: PhotoCardProps) => {
  const handleOpenWindow = (name: string) => () => {
    props.handleOpenWindow(name)(props.photo.id);
  };

  const valueRender = (element: any, value: any) => {
    if (value.value === photoFeaturedTypes.NONE) {
      return element;
    }

    const children: any = [
      <span key={value.value}>{element.props.children}</span>,
    ];

    return React.cloneElement(element, { ...element.props }, children);
  };

  const addDefaultSrc = (event: any) => {
    event.target.src = placeholder;
  };

  return (
    <Card className='photo-card'>
      <CardMedia
        component='img'
        image={props.photo.url}
        onError={addDefaultSrc}
        loading='lazy'
        height={150}
        width={250}
      />

      <CardContent className='card-content'>
        <MaterialGrid item container className='card-element'>
          <MaterialGrid item>
            <FormLabel className='element-label'>Featured</FormLabel>
          </MaterialGrid>
          <MaterialGrid item>
            <DropDownList
              data={Object.values(photoFeaturedTypes.properties)}
              value={photoFeaturedTypes.properties[props.photo.featuredType]}
              valueRender={valueRender}
              dataItemKey='value'
              textField='name'
              onChange={props.handleDropDownChange}
              className='featured-dropdown'
            />
          </MaterialGrid>
        </MaterialGrid>

        <MaterialGrid
          item
          xs={4}
          container
          direction='column'
          alignItems='center'
        >
          <MaterialGrid item className='card-element'>
            <MaterialGrid item>
              <FormLabel className='element-label'>Alt trans.</FormLabel>
            </MaterialGrid>
            <MaterialGrid item>
              <span>
                {
                  props.photo.descriptions.filter(
                    (d) => d.text && d.text?.length > 0
                  )?.length
                }
              </span>
            </MaterialGrid>
          </MaterialGrid>
        </MaterialGrid>

        <MaterialGrid item xs={4} container justifyContent='center'>
          <MaterialGrid item className='card-element'>
            <button
              onClick={handleOpenWindow('editWindow')}
              className='edit-button'
            >
              <EditIcon />
            </button>

            <button
              onClick={handleOpenWindow('confirmDeleteWindow')}
              className='remove-button'
            >
              <DeleteIcon />
            </button>
          </MaterialGrid>
        </MaterialGrid>
      </CardContent>
    </Card>
  );
};

const Photos = (props: BoatDetailsPhotosProps) => {
  const [addWindowVisible, setAddWindowVisible] =
    useState<BoatDetailsPhotosState['addWindowVisible']>(false);
  const [editWindowVisible, setEditWindowVisible] =
    useState<BoatDetailsPhotosState['editWindowVisible']>(false);
  const [confirmDeleteWindowVisible, setConfirmDeleteWindowVisible] =
    useState<BoatDetailsPhotosState['confirmDeleteWindowVisible']>(false);
  const [photoIdToUpdate, setPhotoIdToUpdate] =
    useState<BoatDetailsPhotosState['photoIdToUpdate']>(null);

  const [updateCurrentPhotoState] = useUpdateCurrentPhotoOrderMutation();

  const handleCloningEntity = useCallback(
    (entity: any) => cloneDeep(entity),
    []
  );

  const handleOpenWindow = (name: string) => (photoIdToUpdate: number) => {
    name === 'confirmDeleteWindow' &&
      setConfirmDeleteWindowVisible((prev) => !prev);
    name === 'editWindow' && setEditWindowVisible((prev) => !prev);
    name === 'addWindow' && setAddWindowVisible((prev) => !prev);
    setPhotoIdToUpdate(photoIdToUpdate);

    updateCurrentPhotoState({
      boatId: props.boatId as number,
      currentPhotoState: props.currentPhotoState as BoatPhoto[],
    });
  };

  const handleCloseWindowF = (name: string) => () => {
    setPhotoIdToUpdate(null);
    name === 'editWindow' && setEditWindowVisible((prev) => !prev);
    name === 'confirmDeleteWindow' &&
      setConfirmDeleteWindowVisible((prev) => !prev);
    name === 'addWindow' && setAddWindowVisible((prev) => !prev);
  };

  const handleEdit = (data: BoatPhoto & { boatId: number }) => {
    props.handleUpdatePhoto(data);

    handleCloseWindowF('editWindow')();
  };

  const handleDelete = () => {
    const photoToDelete: any = {
      boatId: props.boatId,
      photoId: photoIdToUpdate,
    };
    props.handleDeletePhoto(photoToDelete);

    handleCloseWindowF('confirmDeleteWindow')();
  };

  const handleChange = (type: PhotoType) => (order: any) => {
    if (Array.isArray(order)) {
      const newPhotoValues: any = order.map((num: any, index: number) => {
        return {
          id: +num.id,
          order: index + 1,
        };
      });

      return props.handleReorder(type, newPhotoValues);
    }
  };

  const handleDropDownChange =
    (photoId: number) => (event: DropDownListChangeEvent) => {
      props.handleFeaturedTypeChange(photoId, event);
    };

  return (
    <>
      <MaterialGrid container direction='column' spacing={3}>
        {!props.photos ||
          (props.photos?.length === 0 && (
            <MaterialGrid item container justifyContent='flex-end'>
              <button
                onClick={handleOpenWindow('addWindow') as MouseEvent | any}
                className='pink-button'
              >
                Add photos
              </button>
            </MaterialGrid>
          ))}

        <MaterialGrid item container direction='column'>
          {props.photos &&
            props.photos?.length > 0 &&
            Object.values(photoTypes.properties).map((type, index) => {
              return (
                <div key={type.value} style={{ minHeight: 100 }}>
                  {index === 0 ? (
                    <MaterialGrid item container justifyContent='space-between'>
                      <MaterialGrid item>
                        <div className='page-title'>{type.name}</div>
                      </MaterialGrid>

                      <MaterialGrid item>
                        <button
                          onClick={
                            handleOpenWindow('addWindow') as MouseEvent | any
                          }
                          className='pink-button'
                        >
                          Add photos
                        </button>
                      </MaterialGrid>
                    </MaterialGrid>
                  ) : (
                    <MaterialGrid item>
                      <div className='page-title'>{type.name}</div>
                    </MaterialGrid>
                  )}

                  <MaterialGrid item>
                    <ReactSortable
                      sort={true}
                      group={'shared'}
                      animation={150}
                      list={handleCloningEntity(
                        props.photos
                          ? props.photos
                              .filter((p) => p.type === type.value)
                              .sort((a, b) => {
                                return a.order - b.order;
                              })
                          : []
                      )}
                      setList={handleChange(type.value as PhotoType)}
                      swap
                    >
                      {props.photos
                        ? props.photos
                            .filter((p) => p.type === type.value)
                            .sort((a, b) => {
                              return a.order - b.order;
                            })
                            .map((photo) => (
                              <div
                                key={photo.id}
                                id={String(photo.id)}
                                data-id={photo.id}
                                style={{ display: 'inline-block' }}
                                className='cards-container'
                              >
                                <PhotoCard
                                  photo={photo}
                                  handleDropDownChange={handleDropDownChange(
                                    photo.id
                                  )}
                                  handleEdit={handleEdit}
                                  handleOpenWindow={handleOpenWindow}
                                />
                              </div>
                            ))
                        : []}
                    </ReactSortable>
                  </MaterialGrid>
                </div>
              );
            })}
        </MaterialGrid>
      </MaterialGrid>

      <PhotoUploadModal
        state={props.state}
        isWindowVisible={addWindowVisible}
        boatId={props.boatId}
        maxOrderNums={props.maxOrderNums}
        handleCloseWindow={handleCloseWindowF('addWindow')}
        handleAddPhoto={props.handleAddPhoto}
      />

      {editWindowVisible && (
        <EditPhotoModal
          isWindowVisible={editWindowVisible}
          state={props.state}
          photo={
            (props.photos || []).find(
              (p) => p.id === photoIdToUpdate
            ) as BoatPhoto
          }
          boatId={props.boatId}
          handleEdit={handleEdit}
          handleChangePhotoUrl={props.handleChangePhotoUrl}
          handleCloseWindow={handleCloseWindowF('editWindow')}
        />
      )}

      <DeletePhotoModal
        isWindowVisible={confirmDeleteWindowVisible}
        handleDelete={handleDelete}
        handleCloseWindow={handleCloseWindowF('confirmDeleteWindow')}
      />
    </>
  );
};

export default Photos;
