// KENDO
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { formatDate } from '@telerik/kendo-intl';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import { CommandCell } from '../../../../components/CommandCell/CommandCell';
//

// HOOKS
import { useCallback, useState } from 'react';
import useNotifications from '../../../../hooks/useNotifications';
import {
  SpecialOffer,
  SpecialOfferColumn,
} from '../../../../types/typeDefinitions';
import { useDeleteBoatSpecialOfferMutation } from '../boatsApiSlice';
//

const SpecialOffers = (props: any) => {
  const initialSort: Array<SortDescriptor> =
    props.boatMmkId === null ? [{ field: 'dates', dir: 'desc' }] : [];

  // const navigateTo = useNavigate();

  const [sort, setSort] = useState(initialSort);

  const [specialOffersList, setSpecialOffersList] = useState<any[]>(
    props.boatSpecialOffers || []
  );

  const { handleUserActionNotification } = useNotifications();

  // const dispatch = useAppDispatch();

  const formatData = useCallback((data: SpecialOfferColumn[] | any) => {
    if (data) {
      return data.map((item: any) => {
        item.dateFrom = new Date(item.dateFrom as Date);
        item.dateTo = new Date(item.dateTo as Date);

        item.dates = `${formatDate(item.dateFrom, 'dd/MM/yyyy')} - ${formatDate(
          item.dateTo,
          'dd/MM/yyyy'
        )}`;

        item.bases = `${item.startBase ? item.startBase : 'ANY'} - ${
          item.endBase ? item.endBase : 'ANY'
        }`;

        return item;
      });
    }
  }, []);

  // const handleManage = useCallback(
  //   (dataItem: any) => {
  //     dispatch(
  //       setSpecialOfferForManage({
  //         name: 'specialOfferId',
  //         eventValue: dataItem.id,
  //       })
  //     );
  //     navigateTo('/special-offers');
  //   },
  //   [navigateTo, dispatch]
  // );

  const removeSpecialOfferFromUi = (dataSet: any[], specialOfferId: number) => {
    const tmp = dataSet.filter(
      (singleItem: any) => singleItem.id !== specialOfferId
    );
    setSpecialOffersList(tmp);
  };

  const [
    deleteSingleSpecialOffer,
    {
      isLoading: isDeleteSingleSpecialOfferLoading,
      isError: isDeleteSingleSpecialOfferError,
    },
  ] = useDeleteBoatSpecialOfferMutation();

  const handleDelete = async (dataItem: Partial<SpecialOffer>) => {
    try {
      await deleteSingleSpecialOffer({
        id: dataItem.id as number,
        boatId: props.boatId,
      }).unwrap();

      if (
        !isDeleteSingleSpecialOfferError &&
        !isDeleteSingleSpecialOfferLoading
      ) {
        removeSpecialOfferFromUi(specialOffersList, dataItem.id as number);
        handleUserActionNotification({
          message: 'Special offer successfully deleted!',
          autoClose: 2500,
          type: 'success',
        });
      }
    } catch (error: any) {
      console.error(error);
      handleUserActionNotification({
        message: error.data.message,
        autoClose: 2500,
        type: 'error',
      });
    }
  };

  return (
    <>
      <MaterialGrid width={'100%'} className='special-offers-grid'>
        <Grid
          scrollable='scrollable'
          data={orderBy(
            formatData(specialOffersList as SpecialOfferColumn[]),
            sort
          )}
          sortable={props.boatMmkId === null ? true : false}
          resizable={true}
          sort={sort}
          onSortChange={(event) => setSort(event.sort)}
        >
          {props.manualEntry === 'Mmk' ? null : (
            <Column
              width={70}
              field='_command'
              groupable={false}
              sortable={false}
              filterable={false}
              resizable={false}
              title=' '
              cell={(cellProps) => (
                <CommandCell
                  {...cellProps}
                  // hasManageCommand={true}
                  // handleManage={handleManage}
                  hasDeleteCommand={true}
                  handleDelete={handleDelete}
                />
              )}
            />
          )}

          <Column field='name' title='Name' sortable={true} />

          <Column field='offer' title='Type' sortable={true} />

          <Column field='bases' title='Bases' sortable={true} />

          <Column field='dates' title='Dates' sortable={true} />

          <Column field='price' title='Price' sortable={true} />

          <Column field='discountPercentage' title='Discount' sortable={true} />

          <Column
            field='discountedPrice'
            title='Discounted Price'
            sortable={true}
          />
        </Grid>
      </MaterialGrid>
    </>
  );
};

export default SpecialOffers;
