// TYPES
import { Enumeration } from '../../types/typeDefinitions';
//

type PhotoType = {
  ATSEA: 1;
  CABINSANDBATHROOMS: 2;
  EXTERIOR: 3;
  INTERIOR: 4;
  PLANS: 5;
};

const photoTypes: Enumeration & PhotoType = {
  ATSEA: 1,
  CABINSANDBATHROOMS: 2,
  EXTERIOR: 3,
  INTERIOR: 4,
  PLANS: 5,
  properties: {
    1: { name: 'At sea', value: 1 },
    2: { name: 'Cabins and bathrooms', value: 2 },
    3: { name: 'Exterior', value: 3 },
    4: { name: 'Interior', value: 4 },
    5: { name: 'Plans', value: 5 },
  },
};

export default photoTypes;
