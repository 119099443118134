// KENDO
import { NumericTextBox } from '@progress/kendo-react-inputs';
//

// HOOKS
import useNotifications from '../../hooks/useNotifications';
//

export const NumericCell = (props: any) => {
  const { handleUserActionNotification } = useNotifications();

  return (
    <td>
      <NumericTextBox
        width='100%'
        className='inline-cell'
        spinners={false}
        placeholder='1000'
        value={props.dataItem[props.field]}
        onChange={(e) => {
          if ((e.value as number) <= 0)
            handleUserActionNotification({
              type: 'warning',
              message: 'Price cannot be a negative number or zero!',
              autoClose: 2500,
            });

          props.onChange({
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: (e.value as number) <= 0 ? 1 : e.value,
          });
        }}
      />
    </td>
  );
};
