// REACT
import { ReactElement } from 'react';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

interface Props {
  children: any;
  [k: string]: any;
}

const GridItem = ({ ...props }: Partial<Props>): ReactElement => {
  const { children, ...rest } = props;

  return (
    <MaterialGrid item {...rest}>
      {children}
    </MaterialGrid>
  );
};

export default GridItem;
