// REACT
import { ReactElement } from 'react';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
import { FormLabel as MaterialFormLabel } from '@mui/material';
//

// TYPES
import { FormGridElementProps } from '../../types/typeDefinitions';
//

// KENDO
import { Error } from '@progress/kendo-react-labels';
//

export function FormGridElement(props: FormGridElementProps): ReactElement {
  return (
    <MaterialGrid item xs={12} container spacing={2} justifyContent='center'>
      {props.label !== undefined && (
        <MaterialGrid
          item
          xs={4}
          className={props.topLabel ? 'form-label-top' : 'form-label'}
        >
          <MaterialFormLabel>{`${props.label}`}</MaterialFormLabel>
        </MaterialGrid>
      )}

      {props.children ? (
        props.children
      ) : (
        <MaterialGrid item xs={7}>
          <props.component
            value={props.value}
            onChange={props.onChange}
            {...props.additionalProps}
          />

          {props.additionalProps && props.additionalProps.errorMessage && (
            <Error>{props.additionalProps.errorMessage}</Error>
          )}
        </MaterialGrid>
      )}
    </MaterialGrid>
  );
}
