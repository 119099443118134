// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import DiscountsGrid from './DiscountsGrid';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import DiscountsFilterBar from './DiscountsFilterBar';
import DiscountsApplyFilterBar from './DiscountsApplyFilterBar';
import KendoLoader from '../../../components/Loader/KendoLoader';
//

// KENDO
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
//

// TYPES
import { Discount, DiscountBoatDetails } from '../../../types/typeDefinitions';
//

// HOOKS
import {
  discountsApiSlice,
  useAddSingleDiscountMutation,
  useLazyGetDiscountBoatDetailsQuery,
  useUpdateDiscountAppliedListMutation,
  useUpdateSingleDiscountMutation,
} from './discountsApiSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  clearBoatsInDiscount,
  saveBoatsDiscountList,
  clearBoatListForUi,
  resetDiscountApplyWindowFilterState,
  resetDiscountIdForManage,
  onApplyWindowFilterCheckboxChange,
  toggleShowOnlyApplied,
} from './discountsStateSlice';
import useNotifications from '../../../hooks/useNotifications';
import { useLazyGetRegenerateSearchIndexesQuery } from '../Boats/boatsApiSlice';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
//

const DiscountsContainer = () => {
  // init state values
  const generateTempId = () => new Date().getMilliseconds();
  const initialDiscountValue = useMemo(() => {
    return {
      name: '',
      percentage: null,
      type: '',
      typeId: null,
      sailingDateFrom: null,
      sailingDateTo: null,
      bookingDateFrom: null,
      bookingDateTo: null,
      daysFrom: null,
      daysTo: null,
      availableInBaseId: null,
      availableInBase: '',
      appliedTo: null,
      includedInBasePrice: false,
      excludesOtherDiscounts: false,
      affectedByMaximum: false,
      tempId: generateTempId(),
      isToUpdate: true,
    };
  }, []);

  const currentFilterState = useAppSelector(
    (state) => state.discountsState.discountFilters.filter.filters
  ).find((singleFilter) => singleFilter.name === 'discountId');

  const isInManageMode = useCallback(() => {
    if (currentFilterState?.value !== null) {
      return true;
    }
    return false;
  }, [currentFilterState]);
  //

  // query hooks
  const [
    updateSingleDiscount,
    {
      isLoading: isUpdateSingleDiscountLoading,
      isError: isUpdateSingleDiscountError,
    },
  ] = useUpdateSingleDiscountMutation();

  const [
    addSingleDiscount,
    {
      isLoading: isAddSingleDiscountLoading,
      isError: isAddSingleDiscountError,
    },
  ] = useAddSingleDiscountMutation();

  const [
    updateDiscountAppliedList,
    {
      isError: isUpdateDiscountAppliedError,
      isLoading: isUpdateDiscountAppliedLoading,
    },
  ] = useUpdateDiscountAppliedListMutation();

  const [
    getDiscountBoatDetails,
    {
      isLoading: isGetDiscountBoatDetailsLoading,
      isFetching: isGetDiscountBoatDetailsFetching,
      isError: isGetDiscountBoatDetailsError,
      isSuccess: isGetDiscountBoatDetailsSuccess,
      currentData: CURRENT_DATA,
    },
  ] = useLazyGetDiscountBoatDetailsQuery();

  //

  // selectors
  const getDiscountListFromStore = useAppSelector(
    (state) => state.discountsState.data.discountsList
  );
  const getDiscountBoatDetailsListFromStore = useAppSelector(
    (state) => state.discountsState.data.boatListForUI
  );
  const getDiscountApplyFilterState = useAppSelector(
    (state) => state.discountsState.discountApplyWindowFilters
  );

  // utilities
  const handleCloningEntity = useCallback(
    (entity: any) => cloneDeep(entity),
    []
  );
  const dispatch = useAppDispatch();
  const { handleUserActionNotification, handlePromiseNotification } =
    useNotifications();
  //

  const [discountId, setDiscountId] = useState<number | undefined | null>(null);
  // memoized values
  const allDiscountsList: Discount[] = useMemo(
    () => handleCloningEntity(getDiscountListFromStore),
    [getDiscountListFromStore, handleCloningEntity]
  );

  const discountBoatDetailsList: DiscountBoatDetails[] = useMemo(
    () => handleCloningEntity(getDiscountBoatDetailsListFromStore),
    [getDiscountBoatDetailsListFromStore, handleCloningEntity]
  );

  const clearNullValuesForQueryString = useMemo(() => {
    const tmp = getDiscountApplyFilterState.filter.filters.filter(
      (singleFilter: any) => singleFilter.value !== null
    );

    if (tmp?.length === 0) {
      return null;
    } else {
      return {
        ...getDiscountApplyFilterState,
        filter: { filters: tmp, logic: 'and' },
      };
    }
  }, [getDiscountApplyFilterState]);

  // local component state
  const [typeOfWindow, setTypeOfWindow] = useState('');
  const [selectAll, setSelectAll] = useState(true);

  const [isWindowVisible, setIsWindowVisible] = useState<boolean>(false);
  const [newOrSelectedDiscount, setNewOrSelectedDiscount] = useState<
    Partial<Discount> | any | null
  >(initialDiscountValue);

  //

  const validateForm = (data: any | Partial<Discount>) => {
    return (
      data.name !== '' &&
      data.percentage !== null &&
      data.percentage >= 0 &&
      data.percentage <= 100 &&
      new Date(data.sailingDateFrom) <= new Date(data.sailingDateTo) &&
      new Date(data.bookingDateFrom) <= new Date(data.bookingDateTo) &&
      data.daysFrom <= data.daysTo &&
      data.type !== '' &&
      data.daysFrom >= 0 &&
      data.availableInBaseId !== null
    );
  };

  const handleSubmit = useCallback(
    (data: any | Partial<Discount>) => {
      if (validateForm(data)) {
        setNewOrSelectedDiscount(initialDiscountValue);
        setNewOrSelectedDiscount((prev: any) => {
          return {
            ...prev,
            name: '',
            percentage: null,
            type: '',
            typeId: null,
            sailingDateFrom: null,
            sailingDateTo: null,
            bookingDateFrom: null,
            bookingDateTo: null,
            daysFrom: null,
            daysTo: null,
            availableInBaseId: null,
            availableInBase: '',
            appliedTo: null,
            includedInBasePrice: false,
            excludesOtherDiscounts: false,
            affectedByMaximum: false,
            tempId: generateTempId(),
            isToUpdate: true,
          };
        });
        try {
          if ('tempId' in data) {
            handlePromiseNotification(addSingleDiscount(data).unwrap(), {
              pending: { message: 'Processing...', type: 'info' },
              success: {
                message: 'Discount added successfully!',
                type: 'success',
              },
              error: {
                message: 'There was an error with your request.',
                type: 'error',
              },
            });
            if (!isAddSingleDiscountError && !isAddSingleDiscountLoading) {
              setIsWindowVisible(false);
              setTypeOfWindow('');
              setNewOrSelectedDiscount(initialDiscountValue);

              setNewOrSelectedDiscount(initialDiscountValue);
              setNewOrSelectedDiscount((prev: any) => {
                return {
                  ...prev,
                  name: '',
                  percentage: null,
                  type: '',
                  typeId: null,
                  sailingDateFrom: null,
                  sailingDateTo: null,
                  bookingDateFrom: null,
                  bookingDateTo: null,
                  daysFrom: null,
                  daysTo: null,
                  availableInBaseId: null,
                  availableInBase: '',
                  appliedTo: null,
                  includedInBasePrice: false,
                  excludesOtherDiscounts: false,
                  affectedByMaximum: false,
                  tempId: generateTempId(),
                  isToUpdate: true,
                };
              });
            }
          } else {
            setNewOrSelectedDiscount(initialDiscountValue);

            setNewOrSelectedDiscount(initialDiscountValue);
            setNewOrSelectedDiscount((prev: any) => {
              return {
                ...prev,
                name: '',
                percentage: null,
                type: '',
                typeId: null,
                sailingDateFrom: null,
                sailingDateTo: null,
                bookingDateFrom: null,
                bookingDateTo: null,
                daysFrom: null,
                daysTo: null,
                availableInBaseId: null,
                availableInBase: '',
                appliedTo: null,
                includedInBasePrice: false,
                excludesOtherDiscounts: false,
                affectedByMaximum: false,
                tempId: generateTempId(),
                isToUpdate: true,
              };
            });
            handlePromiseNotification(
              updateSingleDiscount(data)
                .unwrap()
                .then(() => setNewOrSelectedDiscount(initialDiscountValue)),
              {
                pending: { message: 'Processing...', type: 'info' },
                success: {
                  message: 'Discount updated successfully!',
                  type: 'success',
                },
                error: {
                  message: 'There was an error with your request.',
                  type: 'error',
                },
              }
            );
            if (
              !isUpdateSingleDiscountError &&
              !isUpdateSingleDiscountLoading
            ) {
              setIsWindowVisible(false);
              setTypeOfWindow('');
              setNewOrSelectedDiscount(initialDiscountValue);
            }
          }
        } catch (error: any) {
          handleUserActionNotification({
            type: 'error',
            autoClose: 2500,
            message: error.data.message,
          });
        }
      } else
        handleUserActionNotification({
          type: 'warning',
          autoClose: 2500,
          message: 'One or more validation errors occurred!',
        });
    },
    [
      updateSingleDiscount,
      handleUserActionNotification,
      isUpdateSingleDiscountError,
      isUpdateSingleDiscountLoading,
      addSingleDiscount,
      isAddSingleDiscountError,
      isAddSingleDiscountLoading,
      initialDiscountValue,
      handlePromiseNotification,
    ]
  );

  const handleClose = () => {
    setIsWindowVisible(false);
    setTypeOfWindow('');
    setNewOrSelectedDiscount(initialDiscountValue);
    setDiscountId(null);
  };

  const handleEdit = (dataItem: Partial<Discount>) => {
    setIsWindowVisible(true);
    setTypeOfWindow('edit');
    setNewOrSelectedDiscount(dataItem);
  };

  const handleAddNewDiscount = () => {
    setTypeOfWindow('add');
    setIsWindowVisible(true);
  };

  const handleSubmitApply = () => {
    dispatch(clearBoatListForUi());
    dispatch(resetDiscountApplyWindowFilterState());
    const filterItemsForUpdate = [
      {
        Id: discountId,
        AddOnBoats: discountBoatDetailsList
          .filter((item) =>
            item.appliedOn.some((val: any) => val.id === discountId)
          )
          .map((item) => {
            return item.id;
          }),
        RemoveFromBoats: discountBoatDetailsList
          .filter((item) =>
            item.appliedOn.every((val: any) => val.id !== discountId)
          )
          .map((item) => {
            return item.id;
          }),
      },
    ];

    try {
      handlePromiseNotification(
        updateDiscountAppliedList(filterItemsForUpdate)
          .unwrap()
          .then(() => {
            dispatch(
              discountsApiSlice.util.invalidateTags(['DiscountBoatsList'])
            );
            dispatch(
              onApplyWindowFilterCheckboxChange({
                eventValue: true,
                name: 'boatsWithService',
              })
            );
          }),
        {
          pending: { message: 'Processing...', type: 'info' },
          success: {
            message: 'Discount boat list updated successfully!',
            type: 'success',
          },
          error: {
            message: 'There was an error with your request.',
            type: 'error',
          },
        }
      );

      if (!isUpdateDiscountAppliedError && !isUpdateDiscountAppliedLoading) {
        dispatch(resetDiscountApplyWindowFilterState());
        // prepareDiscountsList();
      }
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        autoClose: 2500,
        message: error.data.message,
      });
    }
  };

  const prepareApplyWindowBoatListOnFilterUpdate = useCallback(async () => {
    if (discountId !== null && discountId !== undefined) {
      dispatch(toggleShowOnlyApplied(true));
      const tmp = clearNullValuesForQueryString
        ? clearNullValuesForQueryString
        : null;
      try {
        await getDiscountBoatDetails({
          id: discountId,
          datasourceReqStr: tmp,
        }).unwrap();

        if (
          !isGetDiscountBoatDetailsLoading &&
          !isGetDiscountBoatDetailsError &&
          isGetDiscountBoatDetailsSuccess
        ) {
          // dispatch(saveBoatsDiscountList(getBoatsByQueryParams['data']));
          CURRENT_DATA !== undefined &&
            dispatch(saveBoatsDiscountList(CURRENT_DATA['data']));
        }
      } catch (error: any) {
        handleUserActionNotification({
          message: error.data.message,
          autoClose: 2500,
          type: 'error',
        });
        return;
      }
    }
  }, [
    getDiscountBoatDetails,
    discountId,
    clearNullValuesForQueryString,
    dispatch,
    isGetDiscountBoatDetailsError,
    isGetDiscountBoatDetailsLoading,
    handleUserActionNotification,
    isGetDiscountBoatDetailsSuccess,
    CURRENT_DATA,
  ]);

  const handleApply = (dataItem: Discount) => {
    setDiscountId(dataItem.id);
    setTypeOfWindow('apply');
    setIsWindowVisible(true);
  };

  const handleCloseApply = () => {
    dispatch(clearBoatsInDiscount());
    dispatch(clearBoatListForUi());
    dispatch(resetDiscountApplyWindowFilterState());
    setTypeOfWindow('');
    setIsWindowVisible(false);
    setDiscountId(null);
  };

  // useEffect(() => {
  //   prepareDiscountsList();
  //   prepareApplyWindowBoatListOnFilterUpdate();
  // }, [prepareDiscountsList, prepareApplyWindowBoatListOnFilterUpdate]);

  useEffect(() => {
    prepareApplyWindowBoatListOnFilterUpdate();
  }, [prepareApplyWindowBoatListOnFilterUpdate, discountId]);

  const singleItemCheckboxChangeHandler = (
    event: CheckboxChangeEvent,
    item: any
  ) => {
    const tmp = discountBoatDetailsList.map(
      (singleItem: DiscountBoatDetails) => {
        if (event.value === true && singleItem.id === item.id) {
          if (singleItem.appliedOn.some((val: any) => val.id === discountId)) {
            return singleItem;
          } else {
            singleItem.appliedOn = [...item.appliedOn, { id: discountId }];
          }
        } else {
          if (event.value === false && singleItem.id === item.id) {
            if (
              singleItem.appliedOn.some((val: any) => val.id === discountId)
            ) {
              singleItem.appliedOn = [...singleItem.appliedOn].filter(
                (singleBoatId: any) => singleBoatId.id !== discountId
              );
            }
          }
        }
        return singleItem;
      }
    );

    dispatch(saveBoatsDiscountList(tmp));
  };

  const selectAllItemsCheckboxChangeHandler = (event: CheckboxChangeEvent) => {
    setSelectAll(event.target.value as boolean);

    const tmp: DiscountBoatDetails[] = discountBoatDetailsList.map((item) => {
      if (event.value === true) {
        if (item.appliedOn.some((val: any) => val.id === discountId)) {
          return item;
        } else {
          item.appliedOn = [...item.appliedOn, { id: discountId }];
        }

        return item;
      } else {
        if (event.value === false) {
          if (item.appliedOn.some((val: any) => val.id === discountId)) {
            item.appliedOn = [...item.appliedOn].filter(
              (singleBoatId) => singleBoatId.id !== discountId
            );
          }
        }
        return item;
      }
    });

    dispatch(saveBoatsDiscountList(tmp));
  };

  // optional
  useEffect(() => {
    return () => {
      dispatch(resetDiscountIdForManage());
    };
  }, [dispatch]);

  const [getRegenerateSearchIndexes] = useLazyGetRegenerateSearchIndexesQuery();

  const handleRegenerate = () => {
    try {
      handlePromiseNotification(getRegenerateSearchIndexes().unwrap(), {
        pending: { message: 'Saving...', type: 'info' },
        success: {
          message: 'Public data generated successfully!',
          type: 'success',
        },
        error: {
          message: 'Something went wrong with your request.',
          type: 'error',
        },
      });
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        message: `${error.status} : \n ${error.error}`,
        autoClose: 2500,
      });
    }
  };

  const isLoading = useAppSelector((state) => {
    return Object.values(state.DiscountsApiSlice.queries).some(
      (query) =>
        query &&
        query.endpointName === 'getAllDiscounts' &&
        query.status === QueryStatus.pending
    );
  });

  const discountsFiltersHeight = useAppSelector(
    (state) => state.generalState.data.discountsFiltersHeight
  );

  return (
    <>
      <MaterialGrid container direction='column' spacing={2}>
        <MaterialGrid
          container
          position='fixed'
          top={16.6}
          right={16.6}
          zIndex={1201}
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
        >
          <MaterialGrid item>
            <button onClick={handleRegenerate} className='light-button'>
              Generate public data
            </button>
          </MaterialGrid>

          <MaterialGrid item>
            {!isInManageMode() && (
              <button className='pink-button' onClick={handleAddNewDiscount}>
                Add new discount
              </button>
            )}
          </MaterialGrid>
        </MaterialGrid>

        <MaterialGrid pl={2} pr={2}>
          <DiscountsFilterBar />
        </MaterialGrid>

        <MaterialGrid
          item
          width={'100%'}
          height={`calc(100vh - ${discountsFiltersHeight}px - 100px)`}
        >
          <DiscountsGrid
            data={
              isInManageMode() === true
                ? allDiscountsList.filter(
                    (singleDiscount) =>
                      singleDiscount.id === currentFilterState?.value
                  )
                : (allDiscountsList as Discount[])
            }
            isLoading={isLoading}
            handleEdit={handleEdit}
            handleApply={handleApply}
            isWindowVisible={isWindowVisible}
            typeOfWindow={typeOfWindow}
            discountDataItem={newOrSelectedDiscount}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            generateTempId={generateTempId}
          />
        </MaterialGrid>

        {isInManageMode() && (
          <MaterialGrid item container spacing={1} justifyContent='flex-end'>
            <button
              onClick={() => {
                dispatch(resetDiscountIdForManage());
              }}
              className='red-button'
            >
              See all discounts
            </button>
          </MaterialGrid>
        )}
      </MaterialGrid>

      {/* APPLY DIALOG */}

      <CustomDialog
        open={isWindowVisible && typeOfWindow === 'apply'}
        title={'Apply Discount'}
        onClose={handleCloseApply}
        onConfirm={handleSubmitApply}
      >
        <MaterialGrid item container>
          <DiscountsApplyFilterBar />
        </MaterialGrid>

        <MaterialGrid container className='apply-dialog-content'>
          <MaterialGrid item container>
            {isGetDiscountBoatDetailsFetching && <KendoLoader />}
            {discountBoatDetailsList !== undefined &&
              discountId !== null &&
              discountBoatDetailsList?.length !== 0 && (
                <MaterialGrid item container direction='row'>
                  <MaterialGrid item className='select-all-checkbox'>
                    <Checkbox
                      value={selectAll}
                      label={'Select/unselect all'}
                      onChange={(event: CheckboxChangeEvent) =>
                        selectAllItemsCheckboxChangeHandler(event)
                      }
                    />
                  </MaterialGrid>
                </MaterialGrid>
              )}
          </MaterialGrid>

          <MaterialGrid
            item
            container
            spacing={1}
            className='boat-details-apply'
          >
            {discountBoatDetailsList?.length !== 0 &&
              discountBoatDetailsList.map((item, index) => {
                return (
                  <MaterialGrid item container direction='row' key={index}>
                    <Checkbox
                      value={
                        item.appliedOn.some(
                          (value: any) => value.id === discountId
                        )
                          ? true
                          : false
                      }
                      label={
                        item
                          ? `${
                              item.name === null || item.name === undefined
                                ? 'Unnamed'
                                : `${item.name}`
                            } ${
                              item.manufacturer === null ||
                              item.manufacturer === undefined
                                ? 'No manufacturer'
                                : `${item.manufacturer}`
                            } ${
                              item.model === null || item.model === undefined
                                ? 'No model'
                                : `${item.model}`
                            } ${
                              item.variation === null ||
                              item.variation === undefined
                                ? 'No variation'
                                : `${item.variation}`
                            } ${
                              item.type === null || item.type === undefined
                                ? 'No type'
                                : `${item.type}`
                            } ${
                              item.yearProduced === null ||
                              item.yearProduced === 0 ||
                              item.yearProduced === undefined
                                ? 'No year'
                                : `${item.yearProduced}`
                            } ${
                              item.companyName === null ||
                              item.companyName === undefined
                                ? 'No company'
                                : `${item.companyName}`
                            }`
                          : ''
                      }
                      onChange={(event) =>
                        singleItemCheckboxChangeHandler(event, item)
                      }
                    />
                  </MaterialGrid>
                );
              })}
          </MaterialGrid>
        </MaterialGrid>
      </CustomDialog>
    </>
  );
};

export default DiscountsContainer;
