// TYPES
import {
  EquipmentCategory,
  EquipmentCategoryGridItem,
  EquipmentCategoryListItem,
  EquipmentContainerState,
  EquipmentPart,
  EquipmentPartListItem,
  Translation,
} from '../../../types/typeDefinitions';
//

// HOOKS
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  useAddCategoryMutation,
  useAddPartMutation,
  useLazyGetCategoryDetailsQuery,
  useGetEquipmentCategoriesQuery,
  useGetEquipmentPartsQuery,
  useLazyGetPartDetailsQuery,
  useUpdateCategoriesAndPartsMutation,
  useUpdateCategoryMutation,
  useUpdatePartMutation,
  useDeleteUnusedEquipmentMutation,
} from './equipmentApiSlice';
import {
  clearCategoryInEdit,
  clearPartInEdit,
  setCategoryInEdit,
  setEquipmentCategoriesInMemory,
  setEquipmentPartsInMemory,
  setPartInEdit,
} from './equipmentStateSlice';
import { useLazyGetRegenerateSearchIndexesQuery } from '../Boats/boatsApiSlice';
import Reorder, { UseReorderUtils } from '../../../hooks/useReorder';
import useNotifications from '../../../hooks/useNotifications';
//

// COMPONENTS
import EquipmentCategoryGrid from './EquipmentCategoryGrid';
import { Form, FormUtils } from '../../../components/Form/Form';
import { FormGridElement } from '../../../components/FormGridElement/FormGridElement';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import KendoLoader from '../../../components/Loader/KendoLoader';
import ReactRouterPrompt from 'react-router-prompt';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// KENDO
import { Input } from '@progress/kendo-react-inputs';
//

// UTILITIES
import { cloneDeep } from 'lodash';
import { getInitialState } from '../../../models/initialState/equipmentItem';
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { buildLocalizedValuesArray } from '../../../services/localizedValues';
//

const INIT_LANG_STATE_SELECT = { id: 1, code: 'En', displayName: 'English' };

const EquipmentContainer = () => {
  const dispatch = useAppDispatch();

  const {
    data: categoriesList,
    isFetching: isCategoriesFetching,
    isError: isFetchCategoriesError,
  } = useGetEquipmentCategoriesQuery();

  const {
    data: partsList,
    isFetching: isPartsFetching,
    isError: isFetchPartsError,
  } = useGetEquipmentPartsQuery();

  const [getCategoryDetails] = useLazyGetCategoryDetailsQuery();

  const [getPartDetails] = useLazyGetPartDetailsQuery();
  const [addPart] = useAddPartMutation();
  const [addCategory] = useAddCategoryMutation();
  const [updatePart] = useUpdatePartMutation();
  const [updateCategory] = useUpdateCategoryMutation();
  const [updateCategoriesAndParts] = useUpdateCategoriesAndPartsMutation();

  const [hasUnsavedListChanges, setHasUnsavedListChanges] =
    useState<EquipmentContainerState['hasUnsavedListChanges']>(false);

  const [isWindowVisible, setIsWindowVisible] =
    useState<EquipmentContainerState['isWindowVisible']>(false);

  const [isCreateOrEdit, setIsCreateOrEdit] =
    useState<EquipmentContainerState['isCreateOrEdit']>('');

  const [newOrSelectedItem, setNewOrSelectedItem] = useState<
    EquipmentContainerState['newOrSelectedItem']
  >(getInitialState());

  const [categoryIdForPart, setCategoryIdForPart] =
    useState<EquipmentContainerState['categoryIdForPart']>(null);

  const defaultLanguage = useAppSelector(
    (state) => state.languagesState.data.defaultLanguage
  );

  const getCategoryListFromStore = useAppSelector(
    (state) => state.equipmentCategoryAndParts.data.categories
  );

  const getPartsListFromStore = useAppSelector(
    (state) => state.equipmentCategoryAndParts.data.parts
  );

  const handleCloningEntity = useCallback(
    (entity: any) => cloneDeep(entity),
    []
  );

  const categoriesFromStore: EquipmentCategoryListItem[] = useMemo(
    () => handleCloningEntity(getCategoryListFromStore),
    [getCategoryListFromStore, handleCloningEntity]
  );
  const partsFromStore: EquipmentPartListItem[] = useMemo(
    () => handleCloningEntity(getPartsListFromStore),
    [getPartsListFromStore, handleCloningEntity]
  );

  const { handleUserActionNotification, handlePromiseNotification } =
    useNotifications();

  const getCategoryInEdit = useAppSelector(
    (state) => state.equipmentCategoryAndParts.data.categoryInEdit
  );

  const categoryInEdit: EquipmentCategory = useMemo(
    () => handleCloningEntity(getCategoryInEdit),
    [getCategoryInEdit, handleCloningEntity]
  );

  const getPartInEdit = useAppSelector(
    (state) => state.equipmentCategoryAndParts.data.partInEdit
  );

  const partInEdit: EquipmentPart = useMemo(
    () => handleCloningEntity(getPartInEdit),
    [getPartInEdit, handleCloningEntity]
  );

  // LANG SELECTOR AND STATE
  const allLanguagesList = useAppSelector(
    (state) => state.languagesState.data.languages
  );

  const [currentLanguageChosenForm, setCurrentLanguageChosenForm] =
    useState<any>(INIT_LANG_STATE_SELECT);

  useEffect(() => {
    if (Object.values(categoryInEdit).some((value) => value !== null)) {
      setNewOrSelectedItem(categoryInEdit);
    }

    if (
      Object.values(partInEdit).some((value) => value !== null) &&
      categoryIdForPart
    ) {
      setNewOrSelectedItem(partInEdit);
    }

    return () => setNewOrSelectedItem(getInitialState());
  }, [categoryInEdit, partInEdit, categoryIdForPart]);

  const handleCreateCategory = () => {
    setIsWindowVisible(true);
    setIsCreateOrEdit('create');
    setNewOrSelectedItem(getInitialState());
  };

  const handleCreatePart = (categoryId: number) => {
    setIsWindowVisible(true);
    setIsCreateOrEdit('create');
    setCategoryIdForPart(categoryId);
  };

  const handleCategoryEdit = async (dataItem: EquipmentCategoryGridItem) => {
    try {
      const requestCategoryDetails = await getCategoryDetails(dataItem.id);
      if (
        !requestCategoryDetails.isError &&
        !requestCategoryDetails.isLoading &&
        requestCategoryDetails.data &&
        requestCategoryDetails.isSuccess
      ) {
        dispatch(setCategoryInEdit(requestCategoryDetails['data']));
        setIsWindowVisible(true);
        setIsCreateOrEdit('edit');
      }
    } catch (error: any) {
      handleUserActionNotification({
        message: error.data.message,
        type: 'error',
        autoClose: 2500,
      });
    }
  };

  const handlePartEdit = async (dataItem: EquipmentPartListItem) => {
    setCategoryIdForPart(dataItem.categoryId);
    try {
      const requestPartDetails = await getPartDetails(dataItem.id);

      if (
        !requestPartDetails.isError &&
        !requestPartDetails.isLoading &&
        requestPartDetails.isSuccess &&
        requestPartDetails.data
      ) {
        dispatch(setPartInEdit(requestPartDetails.data));
        setIsWindowVisible(true);
        setIsCreateOrEdit('edit');
      } else {
        handleUserActionNotification({
          message: 'There was an error with your request!',
          type: 'error',
          autoClose: 2500,
        });
        setNewOrSelectedItem(getInitialState());
        setIsWindowVisible(false);
      }
    } catch (error: any) {
      handleUserActionNotification({
        message: error.data.message,
        type: 'error',
        autoClose: 2500,
      });
    }
  };

  useEffect(() => {
    setCurrentLanguageChosenForm(INIT_LANG_STATE_SELECT);
    if (
      !isCategoriesFetching &&
      !isFetchCategoriesError &&
      categoriesList?.length !== 0
    ) {
      dispatch(
        setEquipmentCategoriesInMemory(
          categoriesList as EquipmentCategoryListItem[]
        )
      );
    }
    if (!isPartsFetching && !isFetchPartsError && partsList?.length !== 0) {
      dispatch(setEquipmentPartsInMemory(partsList as EquipmentPartListItem[]));
    }
  }, [
    categoriesList,
    isCategoriesFetching,
    isFetchCategoriesError,
    partsList,
    isFetchPartsError,
    isPartsFetching,
    dispatch,
  ]);

  const handleSubmit = (data: any) => {
    if (isCreateOrEdit === 'create') {
      if (categoryIdForPart) {
        try {
          const newOrderNumber: number =
            Math.max(...partsFromStore.map((item) => item.order || 0)) + 1;

          handlePromiseNotification(
            addPart({
              ...data,
              categoryId: categoryIdForPart,
              order: newOrderNumber,
            }).unwrap(),
            {
              pending: { message: 'Processing...', type: 'info' },
              success: {
                message: 'Equipment part successfully added!',
                type: 'success',
              },
              error: {
                message: 'There was something wrong with your request.',
                type: 'error',
              },
            }
          );
        } catch (error: any) {
          handleUserActionNotification({
            message: error.data.message,
            type: 'error',
            autoClose: 2500,
          });
        }
      } else {
        try {
          const newOrderNumber: number =
            Math.max(...categoriesFromStore.map((item) => item.order || 0)) + 1;

          handlePromiseNotification(
            addCategory({
              ...data,
              order: newOrderNumber,
            }).unwrap(),
            {
              pending: { message: 'Processing...', type: 'info' },
              success: {
                message: 'Equipment category successfully added!',
                type: 'success',
              },
              error: {
                message: 'There was something wrong with your request.',
                type: 'error',
              },
            }
          );
        } catch (error: any) {
          handleUserActionNotification({
            message: error.data.message,
            type: 'error',
            autoClose: 2500,
          });
        }
      }
    } else if (isCreateOrEdit === 'edit') {
      if (categoryIdForPart) {
        try {
          handlePromiseNotification(
            updatePart({
              ...data,
              categoryId: categoryIdForPart,
            }).unwrap(),
            {
              pending: { message: 'Processing...', type: 'info' },
              success: {
                message: 'Equipment part successfully updated!',
                type: 'success',
              },
              error: {
                message: 'There was something wrong with your request.',
                type: 'error',
              },
            }
          );
        } catch (error: any) {
          handleUserActionNotification({
            message: error.data.message,
            type: 'error',
            autoClose: 2500,
          });
        }
      } else {
        try {
          handlePromiseNotification(updateCategory(data).unwrap(), {
            pending: { message: 'Processing...', type: 'info' },
            success: {
              message: 'Equipment category successfully updated!',
              type: 'success',
            },
            error: {
              message: 'There was something wrong with your request.',
              type: 'error',
            },
          });
        } catch (error: any) {
          handleUserActionNotification({
            message: error.data.message,
            type: 'error',
            autoClose: 2500,
          });
        }
      }
    }

    setIsWindowVisible(false);
    setIsCreateOrEdit('');
    setNewOrSelectedItem(getInitialState());
    setCategoryIdForPart(null);
    setCurrentLanguageChosenForm(INIT_LANG_STATE_SELECT);
  };

  const handleClose = () => {
    setIsWindowVisible(false);
    setIsCreateOrEdit('');
    setNewOrSelectedItem(getInitialState());

    setCategoryIdForPart(null);
    dispatch(clearCategoryInEdit());
    dispatch(clearPartInEdit());
    setFieldValidityOnSubmit({
      name: false,
    });
    setCurrentLanguageChosenForm(INIT_LANG_STATE_SELECT);
  };

  const handleCancelListChanges = () => {
    setHasUnsavedListChanges(false);

    dispatch(
      setEquipmentCategoriesInMemory(
        categoriesList as EquipmentCategoryListItem[]
      )
    );
    dispatch(setEquipmentPartsInMemory(partsList as EquipmentPartListItem[]));
  };

  const handleSaveListChanges = () => {
    handlePromiseNotification(
      updateCategoriesAndParts({
        categoriesList: categoriesFromStore,
        partsList: partsFromStore,
      }),
      {
        pending: { message: 'Processing...', type: 'info' },
        success: {
          message: 'Changes saved successfully!',
          type: 'success',
        },
        error: {
          message: 'There was something wrong with your request.',
          type: 'error',
        },
      }
    );

    setHasUnsavedListChanges(false);
  };

  const handleReorderStateChange = (stateKey: string) => (changes: any) => {
    if (
      changes.type === Reorder.stateChangeTypes.rootOrParentReorder ||
      changes.type === Reorder.stateChangeTypes.childReorder
    ) {
      setHasUnsavedListChanges(true);

      if (stateKey === 'categories') {
        if (changes.data !== categoriesList) {
          dispatch(
            setEquipmentCategoriesInMemory(
              changes.data as EquipmentCategoryListItem[]
            )
          );
        }
      }

      if (stateKey === 'parts') {
        if (changes.data !== partsList) {
          dispatch(
            setEquipmentPartsInMemory(changes.data as EquipmentPartListItem[])
          );
        }
      }
    }
  };

  const [getRegenerateSearchIndexes] = useLazyGetRegenerateSearchIndexesQuery();

  const handleRegenerate = () => {
    try {
      handlePromiseNotification(getRegenerateSearchIndexes().unwrap(), {
        pending: { message: 'Saving...', type: 'info' },
        success: {
          message: 'Public data generated successfully!',
          type: 'success',
        },
        error: {
          message: 'Something went wrong with your request.',
          type: 'error',
        },
      });
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        message: `${error.status} : \n ${error.error}`,
        autoClose: 2500,
      });
    }
  };

  const [deleteAllUnusedEquipment] = useDeleteUnusedEquipmentMutation();

  const handleDeleteUnusedEquipment = () => {
    try {
      handlePromiseNotification(deleteAllUnusedEquipment().unwrap(), {
        pending: { message: 'Saving...', type: 'info' },
        success: {
          message: 'All unused equipment deleted successfully!',
          type: 'success',
        },
        error: {
          message: 'Something went wrong with your request.',
          type: 'error',
        },
      });
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        message: `${error.status} : \n ${error.error}`,
        autoClose: 2500,
      });
    }
  };

  const [fieldValidityOnSubmit, setFieldValidityOnSubmit] = useState<any>({
    name: false,
  });

  const handleFieldValidity = (data: any) => {
    if (data.translations[0].text === '') {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, name: true };
      });
    }
  };

  const equipmentPartIsFromMMK = newOrSelectedItem && 'mmkId' in newOrSelectedItem && newOrSelectedItem.mmkId

  return (
    <>
      <ReactRouterPrompt when={hasUnsavedListChanges}>
        {({ isActive, onConfirm, onCancel }) => (
          <CustomDialog
            open={isActive}
            title='You have unsaved changes. Are you sure you want to leave?'
            confirmText='Confirm'
            onClose={onCancel}
            onConfirm={onConfirm}
          />
        )}
      </ReactRouterPrompt>

      <MaterialGrid container direction='column' spacing={2}>
        <MaterialGrid
          container
          position='fixed'
          top={16.6}
          right={16.6}
          zIndex={1201}
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
        >
          <MaterialGrid item>
            <button
              onClick={handleDeleteUnusedEquipment}
              className='red-button'
            >
              Delete all unused equipment
            </button>
          </MaterialGrid>

          <MaterialGrid item>
            <button onClick={handleRegenerate} className='light-button'>
              Generate public data
            </button>
          </MaterialGrid>

          <MaterialGrid item>
            <button onClick={handleCreateCategory} className='pink-button'>
              Add new category
            </button>
          </MaterialGrid>
        </MaterialGrid>

        <MaterialGrid item width={'100%'} height='calc(100vh - (9em))'>
          {isCategoriesFetching || isPartsFetching ? (
            <KendoLoader />
          ) : (
            <Reorder
              data={categoriesFromStore}
              onReorder={handleReorderStateChange('categories')}
            >
              {(reorderParentUtils: UseReorderUtils) => (
                <Reorder
                  data={partsFromStore}
                  parentKey='categoryId'
                  onReorder={handleReorderStateChange('parts')}
                >
                  {(reorderChildUtils: UseReorderUtils) => (
                    <EquipmentCategoryGrid
                      categories={categoriesFromStore}
                      parts={handleCloningEntity(partsFromStore)}
                      handleCategoryDragStart={reorderParentUtils.dragStart}
                      handleCategoryReorder={
                        reorderParentUtils.rootOrParentReorder
                      }
                      handlePartDragStart={reorderChildUtils.dragStart}
                      handlePartReorder={reorderChildUtils.childReorder}
                      handleCreatePart={handleCreatePart}
                      handleCategoryEdit={handleCategoryEdit}
                      handlePartEdit={handlePartEdit}
                    />
                  )}
                </Reorder>
              )}
            </Reorder>
          )}
        </MaterialGrid>
        <MaterialGrid item container spacing={1} justifyContent='flex-end'>
          <MaterialGrid item>
            <button
              disabled={!hasUnsavedListChanges}
              onClick={handleCancelListChanges}
              className='red-button'
            >
              Cancel
            </button>
          </MaterialGrid>
          <MaterialGrid item>
            <button
              onClick={handleSaveListChanges}
              disabled={!hasUnsavedListChanges}
              className='primary-button'
            >
              Save
            </button>
          </MaterialGrid>
        </MaterialGrid>
      </MaterialGrid>

      <Form
        data={
          newOrSelectedItem !== undefined &&
          Object.values(newOrSelectedItem as any)?.length === 0
            ? getInitialState()
            : newOrSelectedItem
        }
        requiredData={{
          textFields: [`translations_${defaultLanguage}`],
        }}
        onSubmit={handleSubmit}
        render={({
          data: newOrSelectedItemData,
          onLocalizedInputChange,
          onSubmit,
        }: FormUtils) => (
          // props.extraDataItem.name?.length > 70
          // ? props.extraDataItem.name?.slice(0, 70) + '...'
          // : props.extraDataItem.name
          <CustomDialog
            open={isWindowVisible}
            title={
              isCreateOrEdit !== ''
                ? `${isCreateOrEdit === 'create' ? 'New' : 'Edit'} ${`${
                    newOrSelectedItem?.translations.find(
                      (item) => item.languageId === 1
                    )?.text
                  }`}`
                : ''
            }
            onClose={handleClose}
            onConfirm={() => {
              onSubmit();
              handleFieldValidity(newOrSelectedItemData);
            }}
          >
            <FormGridElement
              component={DropDownList}
              label='Name translation for'
              additionalProps={{
                data: allLanguagesList,
                dataItemKey: 'id',
                textField: 'displayName',
                defaultValue: allLanguagesList.find(
                  (item) => item.id === currentLanguageChosenForm.id
                ),
              }}
              onChange={(e: DropDownListChangeEvent) => {
                setCurrentLanguageChosenForm(e.value);
              }}
            />
            {newOrSelectedItemData &&
              newOrSelectedItemData.translations?.length > 0 &&
              newOrSelectedItemData.translations.map((item: Translation) => {
                if (item.languageCode === currentLanguageChosenForm.code) {
                  return (
                    <FormGridElement
                      key={`item_${item.languageCode}`}
                      component={Input}
                      label={`Name (${item.languageCode.toLowerCase()})${
                        item.languageCode === defaultLanguage ? '*' : ''
                      }`}
                      value={item.text}
                      onChange={onLocalizedInputChange(
                        'translations',
                        item.languageCode
                      )}
                      additionalProps={
                        item.languageCode === defaultLanguage
                          ? {
                              validityStyles: fieldValidityOnSubmit.name,
                              valid: !fieldValidityOnSubmit.name,
                              onFocus: () =>
                                setFieldValidityOnSubmit((prev: any) => {
                                  return { ...prev, name: false };
                                }),
                              disabled: equipmentPartIsFromMMK
                            }
                          : null
                      }
                    />
                  );
                }
                return <></>;
              })}

            {categoryIdForPart && (
              <>
                <FormGridElement
                  component={Input}
                  label='Category*'
                  value={
                    categoriesFromStore &&
                    categoriesFromStore.find(
                      (item) => item.id === categoryIdForPart
                    )
                      ? (
                          categoriesFromStore.find(
                            (item) => item.id === categoryIdForPart
                          ) || {
                            name: undefined,
                          }
                        ).name
                      : ''
                  }
                  additionalProps={{
                    disabled: true,
                  }}
                />
              </>
            )}
          </CustomDialog>
        )}
      />
    </>
  );
};

export default EquipmentContainer;
