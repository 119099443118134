// REACT ROUTER
import { Outlet, RouteObject } from 'react-router-dom';
//

// ICONS
import { IconType } from 'react-icons/lib';
import { TbLetterC, TbLetterP, TbLetterS, TbLetterN } from 'react-icons/tb';
import {
  MdDirectionsBoat,
  MdWaves,
  MdLocationPin,
  MdLibraryAdd,
} from 'react-icons/md';
//

// ROUTES
import ProtectedRoute from './ProtectedRoute';

import EquipmentContainer from '../features/admin/Equipment/EquipmentContainer';
import AuthLayout from '../features/auth/AuthLayout';
import AdminLayout from '../features/admin/AdminLayout';
import ManufacturerAndModelContainer from '../features/admin/ManufacturersAndModels/ManufacturerAndModelContainer';
import SpecificationPartsContainer from '../features/admin/SpecificationParts/SpecificationPartsContainer';
import BoatListContainer from '../features/admin/Boats/List/BoatListContainer';
import BoatDetailsContainer from '../features/admin/Boats/Details/BoatDetailsContainer';
import DiscountsContainer from '../features/admin/Discounts/DiscountsContainer';
import ExtrasContainer from '../features/admin/Extras/ExtrasContainer';
import SpecialOffersContainer from '../features/admin/SpecialOffers/SpecialOffersContainer';
import BasesContainer from '../features/admin/Locations/Bases/BasesContainer';
import CountriesContainer from '../features/admin/Locations/Countries/CountriesContainer';
import SailingAreasContainer from '../features/admin/Locations/SailingAreas/SailingAreasContainer';
import CompaniesContainer from '../features/admin/Companies/CompaniesContainer';
//

// TYPES
import boatStates from '../types/enums/boatState';
//

export interface CustomRouteObject extends RouteObject {
  name: string;
  icon?: IconType;
  mini?: string;
  collapse?: boolean;
  children?: CustomRouteObject[];
  invisible?: boolean;
}

export let Routes: CustomRouteObject[] = [
  {
    path: '/login',
    name: 'Login',
    element: <AuthLayout />,
  },
  {
    path: '/',
    name: 'Dashboard',
    element: (
      <ProtectedRoute>
        <AdminLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        collapse: true,
        path: '',
        name: 'Boats',
        icon: MdDirectionsBoat,
        element: <Outlet />,
        children: [
          {
            path: 'charter-boats',
            name: 'Charter boats',
            mini: 'Chr',
            icon: TbLetterC,
            element: <BoatListContainer boatState={boatStates.CHARTER} />,
          },
          {
            path: 'new-boats',
            name: 'New boats',
            mini: 'New',
            icon: TbLetterN,
            element: <BoatListContainer boatState={boatStates.NEW} />,
          },
          {
            path: 'preowned-boats',
            name: 'Preowned boats',
            mini: 'Pre',
            icon: TbLetterP,
            element: <BoatListContainer boatState={boatStates.PREOWNED} />,
          },
          {
            path: 'stock-boats',
            name: 'Stock boats',
            mini: 'Stk',
            icon: TbLetterS,
            element: <BoatListContainer boatState={boatStates.STOCK} />,
          },
          {
            path: 'charter-boats/details/:id',
            name: 'Boat details',
            element: <BoatDetailsContainer boatState={boatStates.CHARTER} />,
            invisible: true,
          },
          {
            path: 'charter-boats/details/',
            name: 'Boat details',
            element: <BoatDetailsContainer boatState={boatStates.CHARTER} />,
            invisible: true,
          },
          {
            path: 'new-boats/details/:id',
            name: 'Boat details',
            element: <BoatDetailsContainer boatState={boatStates.NEW} />,
            invisible: true,
          },
          {
            path: 'new-boats/details/',
            name: 'Boat details',
            element: <BoatDetailsContainer boatState={boatStates.NEW} />,
            invisible: true,
          },
          {
            path: 'preowned-boats/details/:id',
            name: 'Boat details',
            element: <BoatDetailsContainer boatState={boatStates.PREOWNED} />,
            invisible: true,
          },
          {
            path: 'preowned-boats/details/',
            name: 'Boat details',
            element: <BoatDetailsContainer boatState={boatStates.PREOWNED} />,
            invisible: true,
          },
          {
            path: 'stock-boats/details/:id',
            name: 'Boat details',
            element: <BoatDetailsContainer boatState={boatStates.STOCK} />,
            invisible: true,
          },
          {
            path: 'stock-boats/details/',
            name: 'Boat details',
            element: <BoatDetailsContainer boatState={boatStates.STOCK} />,
            invisible: true,
          },
        ],
      },
      {
        collapse: true,
        path: '',
        name: 'Additionals',
        icon: MdLibraryAdd,
        element: <Outlet />,
        children: [
          {
            path: 'discounts',
            name: 'Discounts',
            icon: MdWaves,
            element: <DiscountsContainer />,
          },
          {
            path: 'equipment',
            name: 'Equipment',
            icon: MdWaves,
            element: <EquipmentContainer />,
          },
          {
            path: 'extras',
            name: 'Extras',
            icon: MdWaves,
            element: <ExtrasContainer />,
          },
          {
            path: 'specification-parts',
            name: 'Specification parts',
            icon: MdWaves,
            element: <SpecificationPartsContainer />,
          },
        ],
      },
      {
        path: 'manufacturers',
        name: 'Manufacturers',
        icon: MdWaves,
        element: <ManufacturerAndModelContainer />,
      },
      {
        collapse: true,
        path: '',
        name: 'Locations',
        icon: MdLocationPin,
        element: <Outlet />,
        children: [
          {
            path: 'bases',
            name: 'Bases',
            mini: 'New',
            icon: MdWaves,
            element: <BasesContainer />,
          },
          {
            path: 'countries',
            name: 'Countries',
            mini: 'New',
            icon: MdWaves,
            element: <CountriesContainer />,
          },
          {
            path: 'sailing-areas',
            name: 'Sailing areas',
            mini: 'New',
            icon: MdWaves,
            element: <SailingAreasContainer />,
          },
        ],
      },
      {
        path: 'companies',
        name: 'Companies',
        icon: MdWaves,
        element: <CompaniesContainer />,
      },
      {
        path: 'special-offers',
        name: 'Special offers',
        icon: MdWaves,
        element: <SpecialOffersContainer />,
      },
    ],
  },
];
