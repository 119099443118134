// HOOKS
import { useState } from 'react';
import useNotifications from '../../../hooks/useNotifications';
import { useDeleteEquipmentCategoryMutation } from './equipmentApiSlice';
//

// KENDO
import {
  Grid,
  GridColumn as Column,
  GridExpandChangeEvent,
} from '@progress/kendo-react-grid';
//

// COMPONENTS
import { DragCell } from '../../../components/DragCell/DragCell';
import { CommandCell } from '../../../components/CommandCell/CommandCell';
import { LanguageCell } from '../../../components/LanguageCell/LanguageCell';
import { CategoryDetailRow } from './CategoryDetailRow';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// SERVICES
import { rowRender } from '../../../services/gridRowRender';
//

//TYPES
import {
  EquipmentCategoryGridItem,
  EquipmentCategoryGridProps,
  EquipmentCategoryGridState,
} from '../../../types/typeDefinitions';
//

const EquipmentCategoryGrid = (props: EquipmentCategoryGridProps) => {
  const [expandedCategoryIds, setExpandedCategoryIds] = useState<
    EquipmentCategoryGridState['expandedCategoryIds']
  >([]);

  const DragCellF = DragCell(
    props.handleCategoryDragStart,
    props.handleCategoryReorder
  );

  const CategoryDetailRowF = CategoryDetailRow(
    props.handlePartDragStart,
    props.handlePartReorder,
    props.handleCreatePart,
    props.handlePartEdit
  );

  const handleExpandChange = (event: GridExpandChangeEvent) => {
    if (expandedCategoryIds?.includes(event.dataItem.id) === false) {
      setExpandedCategoryIds([...expandedCategoryIds, event.dataItem.id]);
    } else {
      setExpandedCategoryIds(
        expandedCategoryIds?.filter((id) => id !== event.dataItem.id)
      );
    }
  };

  const handleEdit = (dataItem: EquipmentCategoryGridItem) => {
    props.handleCategoryEdit(dataItem);
  };

  const boatCategories = props.categories.map((categories) => {
    if (props.parts && props.categories) {
      return {
        ...categories,
        parts: props.parts.filter((part) => part.categoryId === categories.id),
        expanded: expandedCategoryIds?.includes(categories.id),
      };
    }
    return null;
  });

  const { handleUserActionNotification } = useNotifications();

  const [
    deleteEquipmentCategory,
    {
      isLoading: isDeleteEquipmentCategoryLoading,
      isError: isDeleteEquipmentCategoryError,
    },
  ] = useDeleteEquipmentCategoryMutation();

  const handleDeleteCategory = async (dataItem: any) => {
    if (dataItem.hasEquipment === true) {
      handleUserActionNotification({
        type: 'error',
        message:
          'This equipment category has equipment parts attached to it. Please remove parts from category before deleting.',
        autoClose: 2500,
      });
    } else
      try {
        await deleteEquipmentCategory(dataItem.id).unwrap();
        if (
          !isDeleteEquipmentCategoryLoading &&
          !isDeleteEquipmentCategoryError
        ) {
          handleUserActionNotification({
            message: 'Equipment category successfully deleted!',
            autoClose: 2500,
            type: 'success',
          });
        }
      } catch (error: any) {
        console.error(error);
        handleUserActionNotification({
          message: error.data.message,
          autoClose: 2500,
          type: 'error',
        });
      }
  };

  if (props.categories && props.parts)
    return (
      <MaterialGrid width={'100%'} height={'100%'}>
        <Grid
          style={{ height: '100%' }}
          data={boatCategories}
          scrollable='scrollable'
          resizable={true}
          detail={CategoryDetailRowF as any}
          expandField='expanded'
          onExpandChange={handleExpandChange}
          rowRender={rowRender}
        >
          <Column
            title=''
            width={45}
            groupable={false}
            sortable={false}
            filterable={false}
            resizable={false}
            cell={DragCellF as any}
          />
          <Column
            field='_command'
            width={70}
            groupable={false}
            sortable={false}
            filterable={false}
            resizable={false}
            title=' '
            cell={({ ...props }) => (
              <CommandCell
                {...props}
                hasEditCommand={true}
                handleEdit={handleEdit}
                hasDeleteCommand={true}
                handleDelete={handleDeleteCategory}
              />
            )}
          />
          <Column field='id' title='ID' />
          <Column field='name' title='Category' />
          <Column
            field='numberOfTranslations'
            title='Name translations'
            cell={(props) => <LanguageCell {...props} />}
          />
        </Grid>
      </MaterialGrid>
    );

  return <></>;
};

export default EquipmentCategoryGrid;
