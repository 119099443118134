// ICONS
import { MdClose as CloseIcon } from 'react-icons/md';
//

// MUI
import {
  Grid as MaterialGrid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
//

// TYPES
import { CustomDialogProps } from '../../types/typeDefinitions';

const CustomDialog = (props: CustomDialogProps) => {
  const {
    open,
    title,
    closeText,
    confirmText,
    dialogProps,
    onClose,
    onConfirm,
    children,
  } = props;

  return (
    <Dialog
      open={open}
      maxWidth='md'
      {...dialogProps}
      onClose={onClose}
      className='dialog'
    >
      <DialogTitle className='dialog-title'>
        <div className='action-name'>{title}</div>

        <button color='transparent' onClick={onClose} className='close-button'>
          <CloseIcon />
        </button>
      </DialogTitle>

      <DialogContent className='dialog-content'>
        <MaterialGrid container spacing={2}>
          {children}
        </MaterialGrid>
      </DialogContent>

      <DialogActions className='dialog-actions'>
        <button onClick={onClose} className='red-button'>
          {closeText || 'Cancel'}
        </button>
        <button onClick={onConfirm} className='primary-button'>
          {confirmText || 'Save'}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
