import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

// API SLICE
import { authApiSlice } from '../features/auth/authApiSlice';
import { equipmentApiSlice } from '../features/admin/Equipment/equipmentApiSlice';
import { manufacturersAndModelsApiSlice } from '../features/admin/ManufacturersAndModels/manufacturersAndModelsApiSlice';
import { specificationPartsApiSlice } from '../features/admin/SpecificationParts/specificationPartsApiSlice';
import { boatsApiSlice } from '../features/admin/Boats/boatsApiSlice';
import { languagesApiSlice } from '../features/admin/LanguagesState/languagesApiSlice';
import { discountsApiSlice } from '../features/admin/Discounts/discountsApiSlice';
import { extrasApiSlice } from '../features/admin/Extras/extrasApiSlice';
import { specialOffersApiSlice } from '../features/admin/SpecialOffers/specialOffersApiSlice';
import { locationsApiSlice } from '../features/admin/Locations/locationsApiSlice';
import { companiesApiSlice } from '../features/admin/Companies/companiesApiSlice';
//

// STATE SLICE
import equipmentStateSliceReducer from '../features/admin/Equipment/equipmentStateSlice';
import manufacturersStateSliceReducer from '../features/admin/ManufacturersAndModels/manufacturersStateSlice';
import specificationStateSliceReducer from '../features/admin/SpecificationParts/specificationPartsStateSlice';
import boatsStateSliceReducer from '../features/admin/Boats/boatsStateSlice';
import boatsFilterStateSliceReducer from '../features/admin/Boats/boatsFilterStateSlice';
import modelsStateSliceReducer from '../features/admin/ManufacturersAndModels/modelsStateSlice';
import modelVariationsStateSliceReducer from '../features/admin/ManufacturersAndModels/modelVariationsStateSlice';
import languagesStateSliceReducer from '../features/admin/LanguagesState/languagesStateSlice';
import authStateSliceReducer from '../features/auth/authStateSlice';
import selectedBoatStateSliceReducer from '../features/admin/Boats/selectedBoatStateSlice';
import discountsStateSliceReducer from '../features/admin/Discounts/discountsStateSlice';
import extrasStateSliceReducer from '../features/admin/Extras/extrasStateSlice';
import charterBoatsFilterReducer from '../features/admin/Boats/charterBoatsFilterSlice';
import specialOffersStateSliceReducer from '../features/admin/SpecialOffers/specialOffersStateSlice';
import generalStateSliceReducer from '../features/admin/generalStateSlice';
//

export const store = configureStore({
  reducer: {
    [authApiSlice.reducerPath]: authApiSlice.reducer,
    [equipmentApiSlice.reducerPath]: equipmentApiSlice.reducer,
    [manufacturersAndModelsApiSlice.reducerPath]:
      manufacturersAndModelsApiSlice.reducer,
    [specificationPartsApiSlice.reducerPath]:
      specificationPartsApiSlice.reducer,
    [languagesApiSlice.reducerPath]: languagesApiSlice.reducer,
    [boatsApiSlice.reducerPath]: boatsApiSlice.reducer,
    [discountsApiSlice.reducerPath]: discountsApiSlice.reducer,
    [extrasApiSlice.reducerPath]: extrasApiSlice.reducer,
    [specialOffersApiSlice.reducerPath]: specialOffersApiSlice.reducer,
    [locationsApiSlice.reducerPath]: locationsApiSlice.reducer,
    [companiesApiSlice.reducerPath]: companiesApiSlice.reducer,
    equipmentCategoryAndParts: equipmentStateSliceReducer,
    manufacturersState: manufacturersStateSliceReducer,
    specificationPartsState: specificationStateSliceReducer,
    boatsState: boatsStateSliceReducer,
    boatsFilterState: boatsFilterStateSliceReducer,
    modelsState: modelsStateSliceReducer,
    modelVariationsState: modelVariationsStateSliceReducer,
    languagesState: languagesStateSliceReducer,
    userState: authStateSliceReducer,
    selectedBoatState: selectedBoatStateSliceReducer,
    discountsState: discountsStateSliceReducer,
    extrasState: extrasStateSliceReducer,
    charterBoatsFilters: charterBoatsFilterReducer,
    specialOffersState: specialOffersStateSliceReducer,
    generalState: generalStateSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authApiSlice.middleware,
      languagesApiSlice.middleware,
      equipmentApiSlice.middleware,
      specificationPartsApiSlice.middleware,
      boatsApiSlice.middleware,
      manufacturersAndModelsApiSlice.middleware,
      discountsApiSlice.middleware,
      extrasApiSlice.middleware,
      specialOffersApiSlice.middleware,
      locationsApiSlice.middleware,
      companiesApiSlice.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
