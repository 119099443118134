// TYPES
import {
  SpecificationListItem,
  Specification,
  Translation,
  SpecificationPartsContainerState,
} from '../../../types/typeDefinitions';
//

// HOOKS
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Reorder, { UseReorderUtils } from '../../../hooks/useReorder';
import {
  useAddSpecificationPartMutation,
  useDeleteUnusedPartsMutation,
  useGetSpecificationPartsQuery,
  useLazyGetSpecificationPartDetailsQuery,
  useLazyGetSpecificationPartsQuery,
  useUpdateSpecificationPartMutation,
  useUpdateSpecificationPartsMutation,
} from './specificationPartsApiSlice';
import {
  clearPartInEdit,
  getSpecificationPartsListSuccess,
  setPartInEdit,
} from './specificationPartsStateSlice';
import useNotifications from '../../../hooks/useNotifications';
import { useLazyGetRegenerateSearchIndexesQuery } from '../Boats/boatsApiSlice';
//

// COMPONENTS
import SpecificationPartsGrid from './SpecificationPartsGrid';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { Form, FormUtils } from '../../../components/Form/Form';
import { FormGridElement } from '../../../components/FormGridElement/FormGridElement';
import KendoLoader from '../../../components/Loader/KendoLoader';
import ReactRouterPrompt from 'react-router-prompt';
//

// KENDO
import { Input } from '@progress/kendo-react-inputs';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// UTILITIES
import cloneDeep from 'lodash/cloneDeep';
import { getInitialState } from '../../../models/initialState/specificationPart';
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
//

const INIT_LANG_STATE_SELECT = { id: 1, code: 'En', displayName: 'English' };

const SpecificationPartsContainer = () => {
  const handleCloningEntity = useCallback(
    (entity: any) => cloneDeep(entity),
    []
  );

  const { handleUserActionNotification, handlePromiseNotification } =
    useNotifications();

  const dispatch = useAppDispatch();

  const dataState = useAppSelector(
    (state) => state.specificationPartsState.data.dataState
  );

  const [getSpecificationParts] = useLazyGetSpecificationPartsQuery();

  const {
    data: specificationPartsData,
    isError: isGetSpecPartsError,
    isFetching: isGetSpecPartsFetching,
  } = useGetSpecificationPartsQuery(dataState, {
    skip: dataState === null || dataState === undefined,
  });

  const [
    getSpecificationPartDetails,
    {
      isFetching: isGetSpecificationPartDetailsLoading,
      isError: isGetSpecificationPartDetailsError,
    },
  ] = useLazyGetSpecificationPartDetailsQuery();

  const [addSpecificationPart] = useAddSpecificationPartMutation();

  const [updateSpecificationParts] = useUpdateSpecificationPartsMutation();

  const [updateSingleSpecificationPart] = useUpdateSpecificationPartMutation();

  const [hasUnsavedListChanges, setHasUnsavedListChanges] =
    useState<SpecificationPartsContainerState['hasUnsavedListChanges']>(false);

  const [isWindowVisible, setIsWindowVisible] =
    useState<SpecificationPartsContainerState['isWindowVisible']>(false);

  const [isCreateOrEdit, setIsCreateOrEdit] =
    useState<SpecificationPartsContainerState['isCreateOrEdit']>('');

  const defaultLanguage = useAppSelector(
    (state) => state.languagesState.data.defaultLanguage
  );

  const specificationPartsFromStore = useAppSelector(
    (state) => state.specificationPartsState.data.specificationParts
  );

  const partInEditOrNewFromStore = useAppSelector(
    (state) => state.specificationPartsState.data.partInEdit
  );

  // LANG SELECTOR AND STATE
  const allLanguagesList = useAppSelector(
    (state) => state.languagesState.data.languages
  );

  const [currentLanguageChosenForm, setCurrentLanguageChosenForm] =
    useState<any>(INIT_LANG_STATE_SELECT);

  const newOrSelectedPart = useMemo(() => {
    return handleCloningEntity(partInEditOrNewFromStore);
  }, [partInEditOrNewFromStore, handleCloningEntity]);

  const allSpecificationParts: SpecificationListItem[] = useMemo(() => {
    return handleCloningEntity(specificationPartsFromStore);
  }, [specificationPartsFromStore, handleCloningEntity]);

  const handleCreate = () => {
    setIsWindowVisible(true);
    setIsCreateOrEdit('create');
  };

  const handleEdit = async (dataItem: SpecificationListItem) => {
    try {
      const specificationPartDetailData = await getSpecificationPartDetails(
        dataItem.id
      ).unwrap();
      if (
        !isGetSpecificationPartDetailsError &&
        !isGetSpecificationPartDetailsLoading &&
        specificationPartDetailData
      ) {
        dispatch(setPartInEdit(specificationPartDetailData as Specification));
      }
    } catch (error: any) {
      handleUserActionNotification({
        message: error.data.message,
        autoClose: 2500,
        type: 'error',
      });
    }

    setIsWindowVisible(true);
    setIsCreateOrEdit('edit');
  };

  const prepareSpecificationParts = useCallback(() => {
    try {
      if (dataState !== null && dataState !== undefined) {
        if (!isGetSpecPartsError && !isGetSpecPartsFetching) {
          dispatch(
            getSpecificationPartsListSuccess(
              specificationPartsData?.data as SpecificationListItem[]
            )
          );
        }
      }
    } catch (error: any) {
      handleUserActionNotification({
        type: error,
        message: error.data.message,
        autoClose: 2500,
      });
    }
  }, [
    dataState,
    dispatch,
    isGetSpecPartsError,
    isGetSpecPartsFetching,
    handleUserActionNotification,
    specificationPartsData,
  ]);

  useEffect(() => {
    prepareSpecificationParts();
  }, [prepareSpecificationParts]);

  const handleClose = () => {
    setIsWindowVisible(false);
    setIsCreateOrEdit('');
    dispatch(clearPartInEdit());
    setFieldValidityOnSubmit({
      name: false,
    });
    setCurrentLanguageChosenForm(INIT_LANG_STATE_SELECT);
  };

  const handleCreateMode = useCallback(
    (data: any) => {
      const newOrderNumber: number =
        Math.max(...allSpecificationParts.map((sp) => sp.order || 0)) + 1;

      try {
        handlePromiseNotification(
          addSpecificationPart({
            ...data,
            order: newOrderNumber,
          }).unwrap(),
          {
            pending: { message: 'Processing...', type: 'info' },
            success: {
              message: 'Specification part added successfully!',
              type: 'success',
            },
            error: {
              message: 'There was something wrong with your request.',
              type: 'error',
            },
          }
        );
      } catch (error: any) {
        handleUserActionNotification({
          message: error.data.message,
          autoClose: 2500,
          type: 'error',
        });
      }
    },
    [
      addSpecificationPart,
      handleUserActionNotification,
      allSpecificationParts,
      handlePromiseNotification,
    ]
  );

  const handleEditMode = useCallback(
    (data: any) => {
      try {
        handlePromiseNotification(
          updateSingleSpecificationPart(data).unwrap(),
          {
            pending: { message: 'Processing...', type: 'info' },
            success: {
              message: 'Specification part updated successfully!',
              type: 'success',
            },
            error: {
              message: 'There was something wrong with your request.',
              type: 'error',
            },
          }
        );
      } catch (error: any) {
        handleUserActionNotification({
          message: error.data.message,
          autoClose: 2500,
          type: 'error',
        });
      }
    },
    [
      handleUserActionNotification,
      handlePromiseNotification,
      updateSingleSpecificationPart,
    ]
  );

  const handleSubmit = async (data: any) => {
    isCreateOrEdit === 'create' && handleCreateMode(data);
    isCreateOrEdit === 'edit' && handleEditMode(data);

    setIsWindowVisible(false);
    setIsCreateOrEdit('');
    dispatch(clearPartInEdit());
    setCurrentLanguageChosenForm(INIT_LANG_STATE_SELECT);
  };

  const handleCancelListChanges = () => {
    setHasUnsavedListChanges(false);
    dispatch(
      getSpecificationPartsListSuccess(
        specificationPartsData?.data as SpecificationListItem[]
      )
    );
  };

  const handleSaveListChanges = () => {
    try {
      handlePromiseNotification(
        updateSpecificationParts(allSpecificationParts),
        {
          pending: { message: 'Processing...', type: 'info' },
          success: {
            message: 'Specification part list updated successfully!',
            type: 'success',
          },
          error: {
            message: 'There was something wrong with your request.',
            type: 'error',
          },
        }
      );
    } catch (error: any) {
      handleUserActionNotification({
        message: error.data.message,
        autoClose: 2500,
        type: 'error',
      });
    }

    setHasUnsavedListChanges((state) => !state);
  };

  const handleReorderStateChange = (changes: any) => {
    if (changes.type === Reorder.stateChangeTypes.rootOrParentReorder) {
      setHasUnsavedListChanges(true);

      if (specificationPartsData !== changes) {
        dispatch(
          getSpecificationPartsListSuccess(
            changes.data as SpecificationListItem[]
          )
        );
      }
    }
  };

  const [getRegenerateSearchIndexes] = useLazyGetRegenerateSearchIndexesQuery();

  const handleRegenerate = () => {
    try {
      handlePromiseNotification(getRegenerateSearchIndexes().unwrap(), {
        pending: { message: 'Saving...', type: 'info' },
        success: {
          message: 'Public data generated successfully!',
          type: 'success',
        },
        error: {
          message: 'Something went wrong with your request.',
          type: 'error',
        },
      });
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        message: `${error.status} : \n ${error.error}`,
        autoClose: 2500,
      });
    }
  };

  const [fieldValidityOnSubmit, setFieldValidityOnSubmit] = useState<any>({
    name: false,
  });

  const handleFieldValidity = (data: any) => {
    if (data.translations[0].text === '') {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, name: true };
      });
    }
  };

  const [deleteAllUnusedParts] = useDeleteUnusedPartsMutation();

  const handleDeleteUnusedParts = () => {
    try {
      handlePromiseNotification(deleteAllUnusedParts().unwrap(), {
        pending: { message: 'Saving...', type: 'info' },
        success: {
          message: 'All unused specification parts deleted successfully!',
          type: 'success',
        },
        error: {
          message: 'Something went wrong with your request.',
          type: 'error',
        },
      });
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        message: `${error.status} : \n ${error.error}`,
        autoClose: 2500,
      });
    }
  };

  return (
    <>
      <ReactRouterPrompt when={hasUnsavedListChanges}>
        {({ isActive, onConfirm, onCancel }) => (
          <CustomDialog
            open={isActive}
            title='You have unsaved changes. Are you sure you want to leave?'
            confirmText='Confirm'
            onClose={onCancel}
            onConfirm={onConfirm}
          />
        )}
      </ReactRouterPrompt>

      <MaterialGrid container direction='column' spacing={2}>
        <MaterialGrid
          container
          position='fixed'
          top={16.6}
          right={16.6}
          zIndex={1201}
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
        >
          <MaterialGrid item>
            <button onClick={handleDeleteUnusedParts} className='red-button'>
              Delete all unused parts
            </button>
          </MaterialGrid>

          <MaterialGrid item>
            <button onClick={handleRegenerate} className='light-button'>
              Generate public data
            </button>
          </MaterialGrid>

          <MaterialGrid item>
            <button onClick={handleCreate} className='pink-button'>
              Add new part
            </button>
          </MaterialGrid>
        </MaterialGrid>

        <MaterialGrid item width={'100%'} height={'calc(100vh - (9em))'}>
          {!allSpecificationParts || allSpecificationParts?.length === 0 ? (
            <KendoLoader />
          ) : null}
          {allSpecificationParts?.length !== 0 && (
            <Reorder
              data={allSpecificationParts}
              onReorder={handleReorderStateChange}
            >
              {(reorderUtils: UseReorderUtils) => (
                <SpecificationPartsGrid
                  specificationParts={allSpecificationParts}
                  getSpecificationParts={getSpecificationParts}
                  dataState={dataState}
                  handleDragStart={reorderUtils.dragStart}
                  handleReorder={reorderUtils.rootOrParentReorder}
                  handleEdit={handleEdit}
                />
              )}
            </Reorder>
          )}
        </MaterialGrid>

        <MaterialGrid
          item
          container
          gap={1}
          justifyContent={'flex-end'}
          alignContent={'center'}
          direction={'row'}
        >
          <MaterialGrid item>
            <button
              onClick={handleCancelListChanges}
              disabled={!hasUnsavedListChanges}
              className='red-button'
            >
              Cancel
            </button>
          </MaterialGrid>
          <MaterialGrid item>
            <button
              onClick={handleSaveListChanges}
              disabled={!hasUnsavedListChanges}
              className='primary-button'
            >
              Save
            </button>
          </MaterialGrid>
        </MaterialGrid>
      </MaterialGrid>

      <Form
        data={
          newOrSelectedPart !== undefined &&
          Object.keys(newOrSelectedPart)?.length !== 0
            ? newOrSelectedPart
            : getInitialState()
        }
        requiredData={{
          textFields: [`translations_${defaultLanguage}`],
        }}
        onSubmit={handleSubmit}
        render={({ data, onLocalizedInputChange, onSubmit }: FormUtils) => (
          <CustomDialog
            open={isWindowVisible}
            title={
              isCreateOrEdit !== ''
                ? `${isCreateOrEdit === 'create' ? 'New part' : 'Edit'} part`
                : ''
            }
            onClose={handleClose}
            onConfirm={() => {
              onSubmit();
              handleFieldValidity(data);
            }}
          >
            <FormGridElement
              component={DropDownList}
              label='Name translation for'
              additionalProps={{
                data: allLanguagesList,
                dataItemKey: 'id',
                textField: 'displayName',
                defaultValue: allLanguagesList.find(
                  (item) => item.id === currentLanguageChosenForm.id
                ),
              }}
              onChange={(e: DropDownListChangeEvent) => {
                setCurrentLanguageChosenForm(e.value);
              }}
            />
            {data.translations?.length > 0 &&
              data.translations.map((part: Translation) => {
                if (part.languageCode === currentLanguageChosenForm.code) {
                  return (
                    <FormGridElement
                      key={`part_${part.languageCode}`}
                      component={Input}
                      label={`Part name (${part.languageCode.toLowerCase()})${
                        part.languageCode === defaultLanguage ? '*' : ''
                      }`}
                      value={part.text}
                      onChange={onLocalizedInputChange(
                        'translations',
                        part.languageCode
                      )}
                      additionalProps={
                        part.languageCode === defaultLanguage
                          ? {
                              validityStyles: fieldValidityOnSubmit.name,
                              valid: !fieldValidityOnSubmit.name,
                              onFocus: () =>
                                setFieldValidityOnSubmit((prev: any) => {
                                  return { ...prev, name: false };
                                }),
                            }
                          : null
                      }
                    />
                  );
                }
                return <></>;
              })}
          </CustomDialog>
        )}
      />
    </>
  );
};

export default SpecificationPartsContainer;
