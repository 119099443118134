// STORE
import { store } from '../app/store';
//

// TYPES
import boatStatuses from '../types/enums/boatStatus';
import boatTypes from '../types/enums/boatTypes';
import { Filter, GridFilter } from '../types/typeDefinitions';
//

// KENDO
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
//

interface FiltersObj {
  [k: string]: Filter;
}

export function buildGridFilters(
  filters: FiltersObj
): CompositeFilterDescriptor {
  let filtersObj: CompositeFilterDescriptor = {
    filters: [],
    logic: 'and',
  };
  const filterKeys: string[] = Object.keys(filters);

  filterKeys.forEach((key) => {
    let currentFilter: Filter = filters[key];

    if (currentFilter.serverValue) {
      if (!filtersObj.filters) {
        filtersObj.filters = [];
      }

      filtersObj.filters = [
        ...filtersObj.filters,
        {
          field: currentFilter.serverKey,
          operator: currentFilter.operator,
          value: currentFilter.serverValue,
        },
      ];
    }
  });

  if (filtersObj.filters && filtersObj.filters?.length > 1) {
    filtersObj.logic = 'and';
  }

  return filtersObj;
}

export function constructGridFiltersForRender(): GridFilter[] {
  const {
    boatsFilterState: { Manufacturer, Model, ModelVariation, Status, Type },
    manufacturersState: {
      data: { dropDownList: manufacturersData },
    },
    modelVariationsState: {
      data: { dropDownList: modelsData },
    },
    modelVariationsState: {
      data: { dropDownList: modelVariationsData },
    },
  }: any = store.getState();

  const filtersArr: GridFilter[] = [
    {
      name: 'Manufacturer',
      placeholder: 'Manufacturer',
      type: 'dropDownList',
      data:
        manufacturersData && manufacturersData?.length > 0
          ? [{ id: null, name: 'All manufacturers' }, ...manufacturersData]
          : [],
      value: getFilterDataById({
        data: manufacturersData,
        id: Manufacturer.value as any,
      }),
      dataItemKey: 'id',
      textField: 'name',
      selected: true,
      isDisabled: false,
      isRemovable: false,
    },
    {
      name: 'Model',
      placeholder: 'Model',
      type: 'dropDownList',
      data:
        modelsData && modelsData?.length > 0
          ? [{ id: null, name: 'All models' }, ...modelsData]
          : [],
      value: getFilterDataById({ data: modelsData, id: Model.value as any }),
      dataItemKey: 'id',
      textField: 'name',
      selected: true,
      isDisabled: Manufacturer.value ? false : true,
      isRemovable: false,
    },
    {
      name: 'ModelVariation',
      placeholder: 'Variation',
      type: 'dropDownList',
      data:
        modelVariationsData && modelVariationsData?.length > 0
          ? [{ id: null, name: 'All variations' }, ...modelVariationsData]
          : [],
      value: getFilterDataById({
        data: modelVariationsData,
        id: ModelVariation.value as any,
      }),
      dataItemKey: 'id',
      textField: 'name',
      selected: true,
      isDisabled: Model.value ? false : true,
      isRemovable: false,
    },
    {
      name: 'Type',
      placeholder: 'Type',
      type: 'dropDownList',
      data: Object.values(boatTypes.properties),
      value: {
        value: Type.value,
        name: Type.value ? boatTypes.properties[Type.value as any].name : '',
      },
      dataItemKey: 'value',
      textField: 'name',
      selected: Type.value ? true : false,
      isDisabled: false,
      isRemovable: true,
    },
    {
      name: 'Status',
      placeholder: 'Status',
      type: 'dropDownList',
      data: Object.values(boatStatuses.properties),
      value: {
        value: Status.value,
        name: Status.value
          ? boatStatuses.properties[Status.value as any].name
          : '',
      },
      dataItemKey: 'value',
      textField: 'name',
      selected: Status.value ? true : false,
      isDisabled: false,
      isRemovable: true,
    },
  ];

  return filtersArr;
}

function getFilterDataById({
  data,
  id,
  dataItemKey = 'id',
}: {
  data: any;
  id: number;
  dataItemKey?: string;
}): any {
  let result: any = { [dataItemKey]: null, name: '' };

  if (!id && id !== 0) {
    return result;
  }

  result[dataItemKey] = id;

  if (!data || data?.length === 0) {
    return result;
  }

  const filterItem: any = data.find(
    (item: any) =>
      item[dataItemKey] &&
      item[dataItemKey].toString().toLowerCase() === id.toString().toLowerCase()
  );

  if (filterItem) {
    result.name = filterItem.name;
  }

  return result;
}
