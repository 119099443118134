// REACT
import { ReactElement } from 'react';
//

// TYPES
import { CardBodyProps } from '../../types/typeDefinitions';
//

const CardBody = ({ ...props }: Partial<CardBodyProps>): ReactElement => {
  const {
    children,
    background,
    plain,
    formHorizontal,
    pricing,
    signup,
    color,
    profile,
    calendar,
    ...rest
  } = props;

  return <div {...rest}>{children}</div>;
};

export default CardBody;
