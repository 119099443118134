// KENDO
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import KendoLoader from '../../../components/Loader/KendoLoader';
import { CommandCell } from '../../../components/CommandCell/CommandCell';
import TextInputCell from '../../../components/InputCell/InputCell';
import { Renderers } from '../Boats/Details/Renderers';
//

// TYPES
import {
  CompaniesGridProps,
  LazyInterface,
} from '../../../types/typeDefinitions';
//

// HOOKS
import { useState } from 'react';
import { useDeleteCompanyMutation } from './companiesApiSlice';
import useNotifications from '../../../hooks/useNotifications';
//

const CompaniesGrid = (props: CompaniesGridProps) => {
  const [
    deleteCompany,
    { isLoading: isDeleteCompanyLoading, isError: isDeleteCompanyError },
  ] = useDeleteCompanyMutation();

  const { handleUserActionNotification } = useNotifications();

  const handleDelete = async (dataItem: Partial<LazyInterface>) => {
    if ('id' in dataItem) {
      try {
        await deleteCompany(dataItem.id).unwrap();

        handleUserActionNotification({
          type: 'success',
          message: 'Company sucessfully deleted!',
          autoClose: 2500,
        });

        if (!isDeleteCompanyLoading && !isDeleteCompanyError) {
          props.handleRemoveFromUi(dataItem as any);
        }
      } catch (error: any) {
        console.error(error);
        handleUserActionNotification({
          type: 'error',
          message: `${error.status} : \n ${error.data.message}`,
          autoClose: 2500,
        });
      }
    } else {
      props.handleRemoveFromUi(dataItem as any);
    }
  };

  const renderers = new Renderers(props.enterEdit, props.exitEdit, 'inEdit');

  const initialSort: Array<SortDescriptor> = [];

  const [sort, setSort] = useState(initialSort);

  return (
    <MaterialGrid width={'100%'} height={'100%'}>
      <Grid
        style={{ height: '100%' }}
        scrollable='scrollable'
        data={orderBy(props.data, sort)}
        sortable={true}
        sort={sort}
        onSortChange={(e: GridSortChangeEvent) => {
          setSort(e.sort);
        }}
        resizable={true}
        editField={'inEdit'}
        cellRender={renderers.cellRender}
        rowRender={renderers.rowRender}
        onItemChange={props.handleValueChange}
      >
        <Column
          field='_command'
          width={50}
          groupable={false}
          sortable={false}
          filterable={false}
          resizable={false}
          title=' '
          cell={(cellProps) => (
            <CommandCell
              {...cellProps}
              hasDeleteCommand={true}
              handleDelete={handleDelete}
            />
          )}
        />
        <Column field='id' title='ID' />
        <Column
          field='name'
          title='Name'
          editable={true}
          cell={TextInputCell}
        />
        <Column
          field='mmkId'
          title='MMK ID'
          editable={false}
          cell={TextInputCell}
        />
      </Grid>
      {props.isLoading && <KendoLoader />}
    </MaterialGrid>
  );
};

export default CompaniesGrid;
