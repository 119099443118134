// TYPES
import { Language, Translation } from '../types/typeDefinitions';
//

// STORE
import { store } from '../app/store';
//

export function buildLocalizedValuesArray(): Translation[] {
  const languages = store.getState().languagesState.data.languages;

  if (languages?.length === 0) {
    return [];
  }

  return languages.map((language: Language) => ({
    languageId: language.id,
    languageCode: language.code || '',
    text: '',
  }));
}
