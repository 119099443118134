// REACT
import React, { useCallback } from 'react';
import { ReactElement } from 'react';
//

// TYPES
import boatStates from '../../../../types/enums/boatState';
import boatStatuses from '../../../../types/enums/boatStatus';
import boatTypes from '../../../../types/enums/boatTypes';
import {
  BoatListGridProps,
  BoatListItem,
  DataState,
} from '../../../../types/typeDefinitions';
//

// HOOKS
import { useLocation, useNavigate } from 'react-router-dom';
import { boatsApiSlice, useDeleteBoatMutation } from '../boatsApiSlice';
import useNotifications from '../../../../hooks/useNotifications';
import { useAppDispatch } from '../../../../app/hooks';
import { updateBoatDataState } from '../boatsStateSlice';
//

// SERVICES
import { rowRender } from '../../../../services/gridRowRender';
//

// ICONS
import { MdDone as DoneIcon } from 'react-icons/md';
//

// KENDO
import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridCellProps,
} from '@progress/kendo-react-grid';
//

// COMPONENTS
import { CommandCell } from '../../../../components/CommandCell/CommandCell';
import { DragCell } from '../../../../components/DragCell/DragCell';
import KendoLoader from '../../../../components/Loader/KendoLoader';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

export const EnumCell: (props: GridCellProps) => ReactElement = ({
  field,
  dataItem,
}: GridCellProps) => (
  <td>
    {field === 'type' && boatTypes.properties[dataItem[field]].name}
    {field === 'status' && boatStatuses.properties[dataItem[field]].name}
  </td>
);

const EnabledCell = (props: GridCellProps): ReactElement => (
  <td>
    {props.dataItem.isEnabled ? <DoneIcon className='enabled-icon' /> : null}
  </td>
);

const BoatGrid = (props: BoatListGridProps) => {
  const dispatch = useAppDispatch();

  const { handleUserActionNotification } = useNotifications();

  const DragCellF = DragCell(props.handleDragStart, props.handleReorder);
  const currentLocation = useLocation();
  const navigateTo = useNavigate();

  const handleEditClick = (dataItem: BoatListItem) => {
    const newLocation: any = {
      pathname: `${currentLocation.pathname}/details/${dataItem.id}`,
      state: { isEdit: true, modelId: dataItem.modelId },
    };
    navigateTo(newLocation.pathname, { state: newLocation.state });
  };

  const [
    deleteBoat,
    { isLoading: isDeleteBoatLoading, isError: isDeleteBoatError },
  ] = useDeleteBoatMutation();

  const handleDeleteBoat = async (dataItem: Partial<BoatListItem>) => {
    try {
      await deleteBoat(dataItem.id as number).unwrap();

      if (!isDeleteBoatLoading && !isDeleteBoatError) {
        handleUserActionNotification({
          message: 'Boat successfully deleted!',
          autoClose: 2500,
          type: 'success',
        });
        dispatch(boatsApiSlice.util.invalidateTags(['Boats-List']));
      }
    } catch (error: any) {
      console.error(error);
      handleUserActionNotification({
        message: error.data.message,
        autoClose: 2500,
        type: 'error',
      });
    }
  };

  const handleDataStateChange = (event: GridDataStateChangeEvent) => {
    props.getBoats(event.dataState);
    dispatch(updateBoatDataState(event.dataState as DataState));
  };

  const formatData = useCallback((data: BoatListItem[]) => {
    if (data) {
      return data.map((item) => {
        item.manufacturerModelVariation = `${item.manufacturer} ${item.model} ${item.modelVariation}`;

        return item;
      });
    }
  }, []);

  const { sort, filter, skip, take } = props.dataState;

  const columns = [
    { field: 'id', title: 'ID' },
    {
      field: 'manufacturerModelVariation',
      title: 'Manufacturer Model Variation',
    },
    { field: 'type', title: 'Type' },
    { field: 'produced', title: 'Produced' },
    { field: 'salePrice', title: 'Sale Price' },
    { field: 'lengthOverAll', title: 'Length' },
    {
      field: 'berthsCabinsDraft',
      title: 'Berths  Cabins  Draft',
    },
    { field: 'status', title: 'Status' },
    { field: 'isEnabled', title: 'Enabled?' },
  ];

  return (
    <MaterialGrid width={'100%'} height={'100%'}>
      <Grid
        style={{ height: '100%' }}
        data={formatData(props.boats)}
        scrollable='scrollable'
        resizable={true}
        sort={sort}
        filter={filter}
        // we don't have pagination for stock boats because of reordering
        skip={props.boatState === boatStates.STOCK ? undefined : skip}
        take={props.boatState === boatStates.STOCK ? undefined : take}
        pageable={props.boatState === boatStates.STOCK ? false : true}
        total={props.total}
        sortable={true}
        onDataStateChange={handleDataStateChange}
        rowRender={rowRender}
      >
        {props.boatState === boatStates.STOCK && (
          <Column
            title=' '
            width={50}
            groupable={false}
            sortable={false}
            filterable={false}
            resizable={false}
            cell={DragCellF as any}
          />
        )}

        <Column
          field='_command'
          groupable={false}
          sortable={false}
          filterable={false}
          resizable={false}
          width={50}
          title=' '
          cell={(props) => (
            <CommandCell
              {...props}
              hasEditCommand={true}
              handleEdit={handleEditClick}
              hasDeleteCommand={true}
              handleDelete={handleDeleteBoat}
              isBoat={true}
            />
          )}
        />

        {columns.map((column, index) => {
          return (
            <Column
              field={column.field}
              title={column.title}
              key={index}
              sortable={
                column.field === 'id' || column.field === 'status'
                  ? props.boatState === boatStates.STOCK
                    ? true
                    : false
                  : column.field === 'berthsCabinsDraft'
                  ? false
                  : true
              }
              cell={
                column.field === 'type' || column.field === 'status'
                  ? (props) => <EnumCell {...props} />
                  : column.field === 'isEnabled'
                  ? (props) => <EnabledCell {...props} />
                  : undefined
              }
              format={
                column.field === 'salePrice' ? "{0: '€'#,#.##}" : undefined
              }
            />
          );
        })}
      </Grid>
      {props.loading && <KendoLoader />}
    </MaterialGrid>
  );
};

export default React.memo(BoatGrid);
