// TYPES
import { Validator } from '../types/typeDefinitions';
//

export const isValidText: Validator = (value) => {
  return !!value && typeof value === 'string' && value?.length > 0;
};

export const isValidNumber: Validator = (value) => {
  return !!value && Number.isFinite(value);
};

export const isValidDate: Validator = (value) => {
  return !!value;
};

export const isValidEmail: Validator = (value) => {
  let emailRex: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRex.test(value);
};

export const isEmpty: Validator = (value) => {
  return value === undefined || value === null || value === '';
};
