// ICONS
import { MdReorder as ReorderIcon } from 'react-icons/md';
//

// TYPES
import { DragCellProps } from '../../types/typeDefinitions';

export function DragCell(
  handleItemDragStart: DragCellProps['handleItemDragStart'],
  handleItemReorder: DragCellProps['handleItemReorder']
) {
  return (props: DragCellProps) => {
    const handleDragOver = () => handleItemReorder(props.dataItem);

    const handleDragStart = (event: React.DragEvent<HTMLSpanElement>) => {
      handleItemDragStart(props.dataItem);
      event.dataTransfer.setData('dragging', '');
    };

    return (
      <td onDragOver={handleDragOver}>
        <div>
          <button
            className='reorder-button'
            aria-label='Reorder'
            draggable={true}
            onDragStart={handleDragStart}
          >
            <ReorderIcon />
          </button>
        </div>
      </td>
    );
  };
}
