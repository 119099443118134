// SERVICES
import { getUserFromStorage } from '../../services/storage';
//

// ASSETS
import avatar from '../../assets/img/avatar.svg';
//

const getUsername = () => {
  const userInfo = getUserFromStorage();

  if (userInfo) return JSON.parse(userInfo).username;
  else return '';
};

const SidebarUser = (props: { open: boolean }) => {
  return (
    <div className='sidebar-user'>
      <div>
        <img src={avatar} className='sidebar-avatar' alt='avatar' />
      </div>
      {props.open && <div>{getUsername()}</div>}
    </div>
  );
};

export default SidebarUser;
