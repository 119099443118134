// ICONS
import { RiErrorWarningLine as WarningIcon } from 'react-icons/ri';
import { MdDoneAll as DoneIcon } from 'react-icons/md';
import { MdErrorOutline as ErrorIcon } from 'react-icons/md';
import CustomLoader from '../Loader/CustomLoader';
//

const CustomIcon = (type: any) => {
  if (type === 'success') {
    return <DoneIcon className='toast-icon' />;
  }
  if (type === 'info') {
    return <CustomLoader />;
  }

  if (type === 'warning') {
    return <WarningIcon className='toast-icon' />;
  }
  if (type === 'error') {
    return <ErrorIcon className='toast-icon' />;
  }
};

export default CustomIcon;
