// KENDO
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// HOOKS
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  useGetManufacturersForDropDownQuery,
  useGetModelsForDropDownQuery,
  useGetModelVariationsForDropDownQuery,
} from '../Boats/boatsApiSlice';
import { useGetCompaniesForDropdownQuery } from '../Discounts/discountsApiSlice';
import {
  onApplyWindowCheckboxChange,
  onApplyWindowDropdownChange,
  onApplyWindowInputChange,
  onApplyWindowNumericTextboxChange,
  resetExtrasApplyWindowFilterState,
  setLocalFilterStatus,
} from './extrasStateSlice';
import useNotifications from '../../../hooks/useNotifications';
//

// TYPES
import boatTypes from '../../../types/enums/boatTypes';
//

const ExtrasApplyFilterBar = () => {
  const initLocalFilterValues = useMemo(() => {
    return {
      boatType: null,
      wildcardName: '',
      yearProduced: null,
      companyName: null,
      manufacturer: null,
      model: null,
      variation: null,
      boatsWithService: true,
    };
  }, []);

  const [localFilterValues, setLocalFilterValues] = useState<any>(
    initLocalFilterValues
  );

  const [localModels, setLocalModels] = useState<any | null>(null);
  const [localModelVariations, setLocalModelVariations] = useState<any | null>(
    null
  );
  const [manufacturerId, setManufacturerId] = useState<number | null>(null);

  const [modelIdForQuery, setModelIdForQuery] = useState<any>(null);

  const { data: manufacturersDropdownData } =
    useGetManufacturersForDropDownQuery();

  const { data: companiesDropdownData } = useGetCompaniesForDropdownQuery();

  const {
    data: modelsDropdownData,
    isError: isModelsDropdownDataError,
    isLoading: isModelsDropdownDataLoading,
  } = useGetModelsForDropDownQuery(manufacturerId as number, {
    skip: manufacturerId === null,
  });

  const {
    data: modelVariationsCharterDropdownData,
    isError: isModelVariationsDropdownDataError,
    isLoading: isModelVariationsDropdownDataLoading,
  } = useGetModelVariationsForDropDownQuery(modelIdForQuery, {
    skip: modelIdForQuery === null,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isModelsDropdownDataError && !isModelsDropdownDataLoading) {
      setLocalModels(modelsDropdownData);
    }
  }, [
    isModelsDropdownDataError,
    isModelsDropdownDataLoading,
    modelsDropdownData,
  ]);

  useEffect(() => {
    if (
      !isModelVariationsDropdownDataError &&
      !isModelVariationsDropdownDataError
    ) {
      setLocalModelVariations(modelVariationsCharterDropdownData);
    }
  }, [
    isModelVariationsDropdownDataError,
    isModelVariationsDropdownDataLoading,
    modelVariationsCharterDropdownData,
  ]);

  const customSetState = useCallback(
    (eventName: string, eventValue: any) => {
      setLocalFilterValues((prev: any) => {
        return { ...prev, [eventName]: eventValue };
      });
      dispatch(setLocalFilterStatus());
    },
    [dispatch]
  );

  const filtersNeedReset = useAppSelector(
    (state) => state.extrasState.resetFilters
  );

  useEffect(() => {
    if (filtersNeedReset === true) {
      setLocalFilterValues(initLocalFilterValues);
    }
  }, [filtersNeedReset, initLocalFilterValues]);

  const resetFilters = useCallback(() => {
    setLocalFilterValues(initLocalFilterValues);
    dispatch(resetExtrasApplyWindowFilterState());
    setManufacturerId(null);
    setModelIdForQuery(null);
  }, [initLocalFilterValues, dispatch]);

  const { handleUserActionNotification } = useNotifications();

  return (
    <MaterialGrid container direction={'column'}>
      <MaterialGrid item container direction='row' className='filter-row'>
        <MaterialGrid item className='filter-item'>
          <Input
            placeholder='Search by name'
            onChange={(event: any) => {
              customSetState('wildcardName', event.target.value);
              dispatch(
                onApplyWindowInputChange({
                  name: 'name',
                  eventValue: event.target.value,
                })
              );
            }}
            value={localFilterValues.wildcardName}
            onKeyDown={(event) =>
              event.key === 'Enter'
                ? dispatch(
                    onApplyWindowInputChange({
                      name: 'name',
                      eventValue: event.target.value,
                    })
                  )
                : ''
            }
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <NumericTextBox
            placeholder='Year produced'
            onChange={(event: NumericTextBoxChangeEvent) => {
              customSetState('yearProduced', event.value);

              if (event.value && event.value < 1996) {
                handleUserActionNotification({
                  message: 'Please select a year after 1996',
                  type: 'warning',
                  autoClose: 2500,
                });
              } else {
                dispatch(
                  onApplyWindowNumericTextboxChange({
                    name: 'yearProduced',
                    eventValue: event.value as number,
                  })
                );
              }
            }}
            format={'#'}
            spinners={false}
            min={1996}
            value={localFilterValues.yearProduced}
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <DropDownList
            data={Object.values(boatTypes.properties)}
            textField={'name'}
            dataItemKey={'value'}
            label={'Boat type'}
            onChange={(event: DropDownListChangeEvent) => {
              customSetState('boatType', event.value.name);

              dispatch(
                onApplyWindowDropdownChange({
                  name: 'type',
                  eventValue: event.value.value,
                })
              );
            }}
            value={{ name: localFilterValues.boatType }}
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <DropDownList
            data={manufacturersDropdownData ? manufacturersDropdownData : []}
            textField={'name'}
            dataItemKey={'id'}
            label={'Manufacturer'}
            onChange={(event: DropDownListChangeEvent) => {
              customSetState('manufacturer', event.value.name);
              setManufacturerId(event.value.id);
              setModelIdForQuery(null);
              setLocalFilterValues((prev: any) => {
                return { ...prev, model: null, variation: null };
              });

              dispatch(
                onApplyWindowDropdownChange({
                  name: 'manufacturer',
                  eventValue: event.value.id,
                })
              );

              dispatch(
                onApplyWindowDropdownChange({
                  name: 'model',
                  eventValue: null,
                })
              );

              dispatch(
                onApplyWindowDropdownChange({
                  name: 'variation',
                  eventValue: null,
                })
              );

              if (event.target.value.id === null) {
                setLocalFilterValues((prev: any) => {
                  return { ...prev, model: null, variation: null };
                });

                dispatch(
                  onApplyWindowDropdownChange({
                    name: 'manufacturer',
                    eventValue: event.value.id,
                  })
                );

                dispatch(
                  onApplyWindowDropdownChange({
                    name: 'model',
                    eventValue: event.value.id,
                  })
                );

                dispatch(
                  onApplyWindowDropdownChange({
                    name: 'variation',
                    eventValue: event.target.value.id,
                  })
                );
              }
            }}
            defaultItem={{ name: 'All manufacturers', id: null }}
            value={{ name: localFilterValues.manufacturer }}
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <DropDownList
            data={localModels ? localModels : []}
            textField={'name'}
            dataItemKey={'id'}
            label={'Model'}
            onChange={(event: DropDownListChangeEvent) => {
              customSetState('model', event.value.name);
              setModelIdForQuery(event.value.id);

              dispatch(
                onApplyWindowDropdownChange({
                  name: 'model',
                  eventValue: event.value.id,
                })
              );

              dispatch(
                onApplyWindowDropdownChange({
                  name: 'variation',
                  eventValue: null,
                })
              );

              setLocalFilterValues((prev: any) => {
                return { ...prev, variation: null };
              });

              if (event.target.value.id === null) {
                setLocalFilterValues((prev: any) => {
                  return { ...prev, variation: null };
                });
                setModelIdForQuery(null);

                dispatch(
                  onApplyWindowDropdownChange({
                    name: 'variation',
                    eventValue: event.target.value.id,
                  })
                );
              }
            }}
            value={{ name: localFilterValues.model }}
            disabled={manufacturerId === null}
            defaultItem={{ name: 'All models', id: null }}
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <DropDownList
            data={localModelVariations ? localModelVariations : []}
            textField={'name'}
            dataItemKey={'id'}
            label={'Variation'}
            onChange={(event: DropDownListChangeEvent) => {
              customSetState('variation', event.value.name);

              dispatch(
                onApplyWindowDropdownChange({
                  name: 'variation',
                  eventValue: event.value.id,
                })
              );
            }}
            value={{ name: localFilterValues.variation }}
            defaultItem={{ name: 'All variations', id: null }}
            disabled={modelIdForQuery === null}
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <DropDownList
            data={companiesDropdownData ? companiesDropdownData : []}
            textField={'name'}
            dataItemKey={'id'}
            label={'Company name'}
            onChange={(event: DropDownListChangeEvent) => {
              customSetState('companyName', event.value.name);
              dispatch(
                onApplyWindowDropdownChange({
                  name: 'companyName',
                  eventValue: event.value.id,
                })
              );
            }}
            value={{ name: localFilterValues.companyName }}
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <button className='light-button'>
            <Checkbox
              label={'Boats with this service?'}
              onChange={(event: CheckboxChangeEvent) => {
                customSetState('boatsWithService', event.target.value);

                dispatch(
                  onApplyWindowCheckboxChange({
                    name: 'boatsWithService',
                    eventValue: event.target.value as boolean,
                  })
                );
              }}
              defaultValue={filtersNeedReset}
              value={localFilterValues.boatsWithService}
            />
          </button>
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <button onClick={resetFilters} className='sky-button'>
            Reset filters
          </button>
        </MaterialGrid>
      </MaterialGrid>
    </MaterialGrid>
  );
};

export default ExtrasApplyFilterBar;
