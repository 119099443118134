// ICONS
import { AiOutlineCloseCircle as CloseCircleIcon } from 'react-icons/ai';
//

const CloseButton = ({ closeToast }: any) => (
  <button className='custom-close close-button' onClick={closeToast}>
    <CloseCircleIcon />
  </button>
);

export default CloseButton;
