// KENDO
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { formatDate } from '@telerik/kendo-intl';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import KendoLoader from '../../../components/Loader/KendoLoader';
import { CommandCell } from '../../../components/CommandCell/CommandCell';
//

// HOOKS
import { ReactElement, useCallback, useState } from 'react';
import useNotifications from '../../../hooks/useNotifications';
import { useRemoveSingleSpecialOfferMutation } from './specialOffersApiSlice';
//

// TYPES
import {
  SpecialOffer,
  SpecialOfferColumn,
  SpecialOffersGridProps,
} from '../../../types/typeDefinitions';
//

const SpecialOffersGrid = (props: SpecialOffersGridProps) => {
  const { handleUserActionNotification } = useNotifications();

  const VariationCell = (props: GridCellProps): ReactElement => {
    return (
      <td
        title={
          props.dataItem.boat.variation?.length > 20
            ? props.dataItem.boat.variation
            : undefined
        }
      >
        {props.dataItem.boatName}
      </td>
    );
  };

  const formatData = useCallback((data: SpecialOfferColumn[] | any) => {
    if (data && data?.length) {
      return data.map((item: any) => {
        item.dateFrom = new Date(item.dateFrom as Date);
        item.dateTo = new Date(item.dateTo as Date);

        item.dates = `${formatDate(item.dateFrom, 'dd/MM/yyyy')} - ${formatDate(
          item.dateTo,
          'dd/MM/yyyy'
        )}`;

        item.validDates =
          item.validDateFrom !== null &&
          item.validDateFrom !== undefined &&
          item.validDateTo !== null &&
          item.validDateTo !== undefined
            ? `${formatDate(
                new Date(item.validDateFrom as Date),
                'dd/MM/yyyy'
              )} - ${formatDate(
                new Date(item.validDateTo as Date),
                'dd/MM/yyyy'
              )}`
            : '---';

        item.bases = `${item.startBase !== null ? item.startBase : 'ANY'} - ${
          item.endBase !== null ? item.endBase : 'ANY'
        }`;

        item.boatName = `${
          item.boat.name === null || item.boat.name === undefined
            ? 'Unnamed'
            : `${item.boat.name}`
        } (${
          item.boat.manufacturer === null ||
          item.boat.manufacturer === undefined
            ? 'No manufacturer'
            : `${item.boat.manufacturer}`
        } ${
          item.boat.model === null || item.boat.model === undefined
            ? 'No model'
            : `${item.boat.model}`
        } ${
          item.boat.variation === null || item.boat.variation === undefined
            ? 'No variation'
            : `${
                item.boat.variation?.length > 20
                  ? item.boat.variation.slice(0, 20) + '...'
                  : item.boat.variation
              }`
        })`;

        return item;
      });
    } else return null;
  }, []);

  const [
    deleteSingleSpecialOffer,
    {
      isLoading: isDeleteSingleSpecialOfferLoading,
      isError: isDeleteSingleSpecialOfferError,
    },
  ] = useRemoveSingleSpecialOfferMutation();

  const handleDeleteSpecialOffer = async (dataItem: Partial<SpecialOffer>) => {
    try {
      await deleteSingleSpecialOffer(dataItem.id as number).unwrap();

      if (
        !isDeleteSingleSpecialOfferError &&
        !isDeleteSingleSpecialOfferLoading
      ) {
        handleUserActionNotification({
          message: 'Special Offer successfully deleted!',
          autoClose: 2500,
          type: 'success',
        });
      }
    } catch (error: any) {
      console.error(error);
      handleUserActionNotification({
        message: error.data.message,
        autoClose: 2500,
        type: 'error',
      });
    }
  };

  const columns = [
    { field: 'name', title: 'Name' },
    {
      field: 'boatName',
      title: 'Boat',
    },
    { field: 'typeName', title: 'Type' },
    { field: 'bases', title: 'Bases' },
    { field: 'dates', title: 'Dates' },
    { field: 'validDates', title: 'Valid dates' },
    { field: 'price', title: 'Price' },
    {
      field: 'discountPercentage',
      title: 'Discount(%)',
    },
    {
      field: 'discountedPrice',
      title: 'Discounted price',
    },
  ];

  const initialSort: Array<SortDescriptor> = [];

  const [sort, setSort] = useState(initialSort);

  //

  return (
    <>
      <MaterialGrid width={'100%'} height={'100%'}>
        {props.isLoading && <KendoLoader />}
        <Grid
          style={{ height: '100%' }}
          scrollable='scrollable'
          resizable={true}
          sortable={true}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
          data={orderBy(formatData(props.data as SpecialOfferColumn[]), sort)}
        >
          <Column
            field='_command'
            title=' '
            width={70}
            sortable={false}
            groupable={false}
            filterable={false}
            resizable={false}
            cell={(cellProps) => (
              <CommandCell
                {...cellProps}
                hasEditCommand={true}
                handleEdit={props.handleEdit}
                hasDeleteCommand={true}
                handleDelete={handleDeleteSpecialOffer}
              />
            )}
          />

          {columns.map((column, index) => {
            return (
              <Column
                field={column.field}
                title={column.title}
                key={index}
                sortable={true}
                groupable={true}
                filterable={true}
                resizable={true}
                cell={
                  column.field === 'boatName'
                    ? (cellProps) => <VariationCell {...cellProps} />
                    : undefined
                }
              />
            );
          })}
        </Grid>
      </MaterialGrid>
    </>
  );
};

export default SpecialOffersGrid;
