// COMPONENTS
import { toast, ToastOptions } from 'react-toastify';

import CloseButton from '../components/Toasts/CloseButton';
import CustomIcon from '../components/Toasts/CustomIcon';
import ToastContent from '../components/Toasts/ToastContent';
//

export interface NotificationConfig {
  content: any;
  type: ToastOptions['type'];
  autoClose: ToastOptions['autoClose'];
  notificationId?: string;
  closeButton: any;
  icon: any;
}

export interface UserActionNotificationConfig {
  message: string;
  type: ToastOptions['type'];
  autoClose: ToastOptions['autoClose'];
  closeButton: any;
  icon: any;
}

export function displayNotification(config: NotificationConfig): void {
  toast(ToastContent(config.type as string, config.content as string), {
    type: config.type,
    autoClose: config.autoClose,
    toastId: config.notificationId,
    hideProgressBar: true,
    closeOnClick: false,
    closeButton: CloseButton,
    icon: CustomIcon(config.type),
  });
}

export function handleFormVerificationError(config: any): void {
  displayNotification({
    content:
      config && config.message
        ? config.message
        : 'Please fill out all required fields!',
    type: 'warning',
    autoClose: 3000,
    closeButton: CloseButton,
    icon: config ? config.icon : CustomIcon('warning'),
  });
}
