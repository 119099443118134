type DocumentType = {
  BROCHURE: 1;
  OFFER: 2;
};

const documentTypes: DocumentType = {
  BROCHURE: 1,
  OFFER: 2,
};

export default documentTypes;
