// REACT
import { Component, ReactElement } from 'react';
//

// KENDO
import { DateRangePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { formatDate } from '@telerik/kendo-intl';
//

// SERVICES
import { handleFormVerificationError } from '../../services/notifications';
//

export interface FormUtils {
  data: State['data'];
  validations: State['validations'];
  onFocus: (name: string) => () => void;
  onInputChange: (name: string) => (event: any) => void;
  onLocalizedInputChange: (
    name: string,
    language: string
  ) => (event: any) => void;
  onDropDownChange: (
    name: string,
    changes?: any,
    includeTextFieldChange?: boolean,
    callback?: any
  ) => (event: any) => void;
  onMultiSelectChange: (name: string) => (event: any) => void;
  onDatePickerChange: (name: string) => (event: any) => void;
  onDateRangePickerChange: (value: {
    start: string;
    end: string;
  }) => (event: any) => void;
  onAutoCompleteChange: (name: string) => (event: any) => void;
  onSubmit: () => void;
}

interface Props {
  data: any;
  requiredData: {
    [k: string]: string[] | any;
  };
  isFetched?: boolean;
  isNew?: boolean;
  onSubmit: any;
  render: any;
}

interface State {
  data: any;
  validations: {
    [k: string]: {
      touched: boolean;
      valid: boolean;
    };
  };
}

export class Form extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      data: { ...this.props.data },
      validations: this.buildValidationsObject(this.props.requiredData),
    };
  }

  componentDidUpdate(prevProps: Props): void {
    if (
      prevProps.data !== this.props.data &&
      (this.props.isFetched === undefined || this.props.isFetched)
    ) {
      this.setState({
        data: this.props.data,
        validations: this.buildValidationsObject(this.props.requiredData),
      });
    }
  }

  // Currently only supports text and number fields validation checks.
  // If you need object field validations, add logic here.
  buildValidationsObject = (requiredData: Props['requiredData']) => {
    let obj: State['validations'] = {};

    if (
      this.props.isNew ||
      (this.props.isFetched !== undefined && this.props.isFetched === false)
    ) {
      if (requiredData.textFields) {
        requiredData.textFields.forEach((input: string) => {
          obj[input] = {
            touched: false,
            valid: false,
          };
        });
      }

      if (requiredData.numberFields) {
        requiredData.numberFields.forEach((input: string) => {
          obj[input] = {
            touched: false,
            valid: false,
          };
        });
      }

      return obj;
    }

    if (requiredData.textFields) {
      requiredData.textFields.forEach((input: string) => {
        const inputParts: string[] = input.split('_');

        const textFieldName: string = inputParts[0];

        if (inputParts?.length === 1) {
          const textFieldValue: any = this.props.data[textFieldName];

          obj[input] = {
            touched: false,
            valid: this.isValidTextField(textFieldValue),
          };
        } else if (
          inputParts?.length > 1 &&
          this.props.data[textFieldName]?.length > 0
        ) {
          const textFieldValue: any = this.props.data[textFieldName].find(
            (x: any) => x.languageCode === inputParts[1]
          ).text;

          obj[input] = {
            touched: false,
            valid: this.isValidTextField(textFieldValue),
          };
        }
      });
    }

    if (requiredData.numberFields) {
      requiredData.numberFields.forEach((input: string) => {
        const numberFieldValue: any = this.props.data[input];

        obj[input] = {
          touched: false,
          valid: this.isValidNumberField(numberFieldValue),
        };
      });
    }

    return obj;
  };

  isValidTextField = (textFieldValue: any) => {
    return (
      !!textFieldValue &&
      typeof textFieldValue === 'string' &&
      textFieldValue?.length > 0
    );
  };

  isValidNumberField = (numberFieldValue: any) => {
    return !!numberFieldValue && Number.isFinite(numberFieldValue);
  };

  validateForm = () => {
    return (
      Object.values(this.state.validations).some(
        (x: { touched: boolean; valid: boolean }) => x.valid === false
      ) === false
    );
  };

  callSetState = (
    fieldName: string,
    newFieldValue: any,
    fieldGroup: string = 'objectFields'
  ) => {
    if (
      this.props.requiredData[fieldGroup] &&
      this.props.requiredData[fieldGroup].includes(fieldName)
    ) {
      this.setState((prevState: State) => ({
        data: { ...prevState.data, [fieldName]: newFieldValue },
        validations: {
          ...prevState.validations,
          [fieldName]: {
            ...prevState.validations[fieldName],
            valid:
              fieldGroup === 'textFields'
                ? this.isValidTextField(newFieldValue)
                : this.isValidNumberField(newFieldValue),
          },
        },
      }));
    } else {
      this.setState((prevState: State) => ({
        data: { ...prevState.data, [fieldName]: newFieldValue },
      }));
    }
  };

  // Used when dealing with arrays of localized values.
  callSetStateForLocalizedValue = (
    fieldName: string,
    newLocalizedFieldValue: any,
    newLocalizedArrayValue: any,
    validationFieldName: string,
    fieldGroup: string = 'objectFields'
  ) => {
    if (
      this.props.requiredData[fieldGroup] &&
      this.props.requiredData[fieldGroup].includes(validationFieldName)
    ) {
      this.setState((prevState: State) => ({
        data: { ...prevState.data, [fieldName]: newLocalizedArrayValue },
        validations: {
          ...prevState.validations,
          [validationFieldName]: {
            ...prevState.validations[validationFieldName],
            valid: this.isValidTextField(newLocalizedFieldValue),
          },
        },
      }));
    } else {
      this.setState((prevState: State) => ({
        data: { ...prevState.data, [fieldName]: newLocalizedArrayValue },
      }));
    }
  };

  onFocus = (name: string) => () => {
    if (this.state.validations[name].touched === false) {
      this.setState((prevState: State) => ({
        validations: {
          ...prevState.validations,
          [name]: { ...prevState.validations[name], touched: true },
        },
      }));
    }
  };

  onInputChange = (name: string) => (event: any) => {
    const newValue: any = event.target.value;

    this.callSetState(name, newValue, 'textFields');
  };

  onLocalizedInputChange = (name: string, language: string) => (event: any) => {
    const newValue: any = event.target.value;

    const changedLocalizedInputArray: any = this.state.data[name].map(
      (t: any) => {
        if (t.languageCode === language) {
          t.text = newValue;
        }
        return t;
      }
    );

    this.callSetStateForLocalizedValue(
      name,
      newValue,
      changedLocalizedInputArray,
      `${name}_${language}`,
      'textFields'
    );
  };

  onDropDownChange =
    (
      name: string,
      changes?: any,
      includeTextFieldChange?: boolean,
      callback?: any
    ) =>
    (event: any) => {
      const newValue: any = event.target.value;

      if (typeof event.target.value === 'object') {
        this.callSetState(`${name}`, newValue.name, 'numberFields');
        this.callSetState(`${name}Id`, newValue.id, 'numberFields');
      } else {
        this.callSetState(`${name}Id`, newValue.id, 'numberFields');
      }

      if (changes) {
        let validationsObj: State['validations'] = this.state.validations;
        for (let key in changes) {
          if (validationsObj.hasOwnProperty(key)) {
            validationsObj[key] = {
              touched: false,
              valid: false,
            };
          }
        }

        this.setState((prevState: State) => ({
          data: {
            ...prevState.data,
            [name]: includeTextFieldChange
              ? newValue.name
              : prevState.data[name],
            ...changes,
          },
          validations: validationsObj,
        }));
      }

      if (callback) {
        callback(newValue.id);
      }
    };

  onMultiSelectChange = (name: string) => (event: any) => {
    const newValue: any = event.target.value;

    this.callSetState(name, newValue);
  };

  onDatePickerChange = (name: string) => (event: any) => {
    const newValue: any = event.target.value;
    const formattedDate: string = formatDate(newValue, 'yyyy-MM-ddTHH:mm:ss');

    this.callSetState(name, formattedDate);
  };

  onDateRangePickerChange =
    (value: { start: string; end: string }) =>
    (event: DateRangePickerChangeEvent) => {
      const newValue: any = {
        start: event.target.value.start,
        end: event.target.value.end,
      };

      const formattedDateStart: string = formatDate(
        newValue.start,
        'yyyy-MM-ddTHH:mm:ss'
      );

      const formattedDateEnd: string = formatDate(
        newValue.end,
        'yyyy-MM-ddTHH:mm:ss'
      );

      this.callSetState(value.start, formattedDateStart);
      this.callSetState(value.end, formattedDateEnd);
    };

  onAutoCompleteChange = (name: string) => (event: any) => {
    console.log(name, event);
  };

  onSubmit = () => {
    if (this.validateForm()) {
      this.props.onSubmit(this.state.data);
    } else {
      handleFormVerificationError(null);
      return;
    }
  };

  getStateAndHelpers = () => {
    const { data, validations } = this.state;
    const {
      onFocus,
      onInputChange,
      onLocalizedInputChange,
      onDropDownChange,
      onMultiSelectChange,
      onDatePickerChange,
      onDateRangePickerChange,
      onAutoCompleteChange,
      onSubmit,
    } = this;

    return {
      data,
      validations,
      onFocus,
      onInputChange,
      onLocalizedInputChange,
      onDropDownChange,
      onMultiSelectChange,
      onDatePickerChange,
      onDateRangePickerChange,
      onAutoCompleteChange,
      onSubmit,
    };
  };

  render(): ReactElement {
    return this.props.render(this.getStateAndHelpers());
  }
}
