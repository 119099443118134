// TYPES
import { ToastContentInterface } from '../../types/typeDefinitions';
//
const ToastContent = (
  typeOfToast: ToastContentInterface['typeOfToast'],
  toastBody: ToastContentInterface['toastBody']
): JSX.Element => {
  const prepareToastTitle = (title: string) =>
    `${title.charAt(0).toUpperCase()}${title.slice(1)}`;

  return (
    <div className='toast-custom-content'>
      <h2>{prepareToastTitle(typeOfToast)}</h2>
      <p>{toastBody}</p>
    </div>
  );
};

export default ToastContent;
