// KENDO
import {
  DropDownList,
  DropDownListChangeEvent,
  MultiSelect,
} from '@progress/kendo-react-dropdowns';
import { Input, NumericTextBox, Checkbox } from '@progress/kendo-react-inputs';
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import { parseDate } from '@telerik/kendo-intl';
import { formatDate } from '@telerik/kendo-intl';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import DocumentUpload from './DocumentUpload';
import FormElement, {
  elementTypes,
  FormElementUtils,
} from '../../../../components/Form/FormElement';
import TextInput from './TextInputFormElement';
import CustomInput from '../../../../components/CustomInput/CustomInput';
//

// TYPES
import boatStates from '../../../../types/enums/boatState';
import boatTypes from '../../../../types/enums/boatTypes';
import documentTypes from '../../../../types/enums/documentType';
import { BoatDetailsGeneralInformationProps } from '../../../../types/typeDefinitions';
import { useAppSelector } from '../../../../app/hooks';
import { useEffect, useState } from 'react';
import { FormGridElement } from '../../../../components/FormGridElement/FormGridElement';
import { Editor, EditorTools } from '@progress/kendo-react-editor';
//

const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
} = EditorTools;

const INIT_LANG_STATE_SELECT = { id: 1, code: 'En', displayName: 'English' };

const GeneralInformation = (props: BoatDetailsGeneralInformationProps) => {
  const prepareBoatSource = (): string => {
    if (props.source) {
      if (props.source === 'ManualEntry') {
        return 'Imported manually';
      }
      return 'Imported from MMK';
    }
    return '';
  };

  // LANG SELECTOR AND STATE
  const allLanguagesList = useAppSelector(
    (state) => state.languagesState.data.languages
  );

  const [currentLanguageChosenForm, setCurrentLanguageChosenForm] =
    useState<any>();

  useEffect(() => setCurrentLanguageChosenForm(INIT_LANG_STATE_SELECT), []);

  const isJSONString = (str: any) => {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      {props.state === boatStates.CHARTER && (
        <>
          <MaterialGrid container className='page-content-container'>
            <MaterialGrid
              item
              xs={12}
              lg={6}
              container
              className='form-element'
            >
              <MaterialGrid item xs={10}>
                <CustomInput
                  labelText='Name*'
                  formControlProps={{
                    fullWidth: true,
                    error: props.fieldValidityOnSubmit.name,
                  }}
                  inputProps={{
                    multiline: true,
                    value: props.name || '',
                    onChange: props.handleTextInputChange('name'),
                    error: props.fieldValidityOnSubmit.name,
                  }}
                />
              </MaterialGrid>
            </MaterialGrid>

            <MaterialGrid
              item
              xs={12}
              lg={6}
              container
              className='form-element'
            >
              <MaterialGrid item xs={10}></MaterialGrid>
            </MaterialGrid>
          </MaterialGrid>

          <hr className='divider-line' />

          <MaterialGrid container className='page-content-container'>
            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <FormElement
                isLoaded={props.id !== null}
                initialElementType={elementTypes.dropDown}
                initialValue={props.company ? props.company.id : null}
                value={props.company ? props.company.id : null}
                onStateChange={props.handleStateChange('companyId')}
                stateReducer={props.formElementStateReducer('companyId')}
              >
                {(formElementUtils: FormElementUtils) => (
                  <DropDownList
                    data={props.allCompanies}
                    value={
                      props.company !== undefined && props.company.id !== null
                        ? props.company
                        : null
                    }
                    dataItemKey='id'
                    textField='name'
                    label='Company*'
                    className='four-elements'
                    onOpen={formElementUtils.open}
                    required
                    onChange={formElementUtils.change}
                    validityStyles={formElementUtils.isTouched}
                  />
                )}
              </FormElement>
            </MaterialGrid>

            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <Input
                value={prepareBoatSource()}
                label='Source'
                disabled={true}
                className='four-elements'
              />
            </MaterialGrid>

            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <Input
                value={props.mmkId ? (props.mmkId as number) : 'No MMK ID'}
                label='MmkId'
                disabled={true}
                className='four-elements'
              />
            </MaterialGrid>

            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <Input
                value={
                  props.importedDate !== undefined &&
                  props.importedDate !== null
                    ? formatDate(new Date(props.importedDate), 'dd/MM/yyyy')
                    : undefined
                }
                label='Imported Date'
                className='four-elements'
                disabled={true}
              />
            </MaterialGrid>
          </MaterialGrid>

          <hr className='divider-line' />
        </>
      )}

      <MaterialGrid container className='page-content-container'>
        <MaterialGrid item xs={6} lg={3} className='form-element'>
          <FormElement
            isLoaded={props.id !== null}
            initialElementType={elementTypes.dropDown}
            initialValue={props.manufacturer.id}
            value={props.manufacturer.id}
            onStateChange={props.handleStateChange('manufacturerId')}
            stateReducer={props.formElementStateReducer('manufacturerId')}
          >
            {(formElementUtils: FormElementUtils) => (
              <DropDownList
                data={props.allManufacturers}
                value={
                  props.manufacturer.id !== null ? props.manufacturer : null
                }
                dataItemKey='id'
                textField='name'
                label='Manufacturer*'
                className='four-elements'
                disabled={props.isInEditMode || props.isInCopyMode}
                onOpen={formElementUtils.open}
                onChange={formElementUtils.change}
                required
                validityStyles={
                  props.fieldValidityOnSubmit.manufacturerId &&
                  formElementUtils.isValid === false
                }
              />
            )}
          </FormElement>
        </MaterialGrid>

        <MaterialGrid item xs={6} lg={3} className='form-element'>
          <FormElement
            isLoaded={props.id !== null}
            initialElementType={elementTypes.dropDown}
            initialValue={props.model.id}
            value={props.model.id}
            onStateChange={props.handleStateChange('modelId')}
            stateReducer={props.formElementStateReducer('modelId')}
          >
            {(formElementUtils: FormElementUtils) => (
              <DropDownList
                data={props.allModels}
                value={props.model.id !== null ? props.model : null}
                dataItemKey='id'
                textField='name'
                label='Model*'
                className='four-elements'
                disabled={
                  props.isInEditMode ||
                  props.isInCopyMode ||
                  !props.manufacturer.id
                }
                onOpen={formElementUtils.open}
                onChange={formElementUtils.change}
                required
                validityStyles={
                  props.fieldValidityOnSubmit.modelId && !!props.manufacturer.id
                }
              />
            )}
          </FormElement>
        </MaterialGrid>

        <MaterialGrid item xs={6} lg={3} className='form-element'>
          <FormElement
            isLoaded={props.id !== null}
            initialElementType={elementTypes.dropDown}
            initialValue={props.modelVariation.id}
            value={props.modelVariation.id}
            onStateChange={props.handleStateChange('modelVariationId')}
            stateReducer={props.formElementStateReducer('modelVariationId')}
          >
            {(formElementUtils: FormElementUtils) => (
              <DropDownList
                data={props.allModelVariations}
                value={
                  props.modelVariation.id !== null ? props.modelVariation : null
                }
                dataItemKey='id'
                textField='name'
                label='Variation*'
                className='four-elements'
                disabled={!props.isInEditMode && !props.model.id}
                onOpen={formElementUtils.open}
                onChange={formElementUtils.change}
                required
                validityStyles={
                  props.fieldValidityOnSubmit.modelVariationId &&
                  !!props.model.id
                }
              />
            )}
          </FormElement>
        </MaterialGrid>

        <MaterialGrid item xs={6} lg={3} className='form-element'>
          <FormElement
            initialValue={props.subvariation}
            value={props.subvariation}
            onStateChange={props.handleStateChange('subvariation')}
          >
            {(formElementUtils: FormElementUtils) => (
              <Input
                value={props.subvariation ? props.subvariation : ''}
                label='Subvariation'
                onChange={formElementUtils.change}
                disabled={true}
                className='four-elements'
              />
            )}
          </FormElement>
        </MaterialGrid>
      </MaterialGrid>

      <hr className='divider-line' />

      <MaterialGrid container className='page-content-container'>
        <MaterialGrid item xs={6} lg={3} className='form-element'>
          <FormElement
            isLoaded={props.id !== null}
            initialElementType={elementTypes.dropDown}
            initialValue={props.type ? boatTypes.properties[props.type] : null}
            value={
              props.type
                ? props.id
                  ? boatTypes.properties[props.type].value
                  : boatTypes.properties[props.type]
                : null
            }
            onStateChange={props.handleStateChange('type')}
            stateReducer={props.formElementStateReducer('type')}
          >
            {(formElementUtils: FormElementUtils) => (
              <DropDownList
                data={Object.values(boatTypes.properties)}
                dataItemKey='value'
                textField='name'
                value={props.type ? boatTypes.properties[props.type] : null}
                label='Type*'
                className='four-elements'
                onOpen={formElementUtils.open}
                onChange={formElementUtils.change}
                required
                validityStyles={props.fieldValidityOnSubmit.type}
              />
            )}
          </FormElement>
        </MaterialGrid>

        <MaterialGrid item xs={6} lg={3} className='form-element'>
          <FormElement
            isLoaded={props.id !== null}
            initialElementType={elementTypes.dropDown}
            initialValue={
              props.state ? boatStates.properties[props.state] : null
            }
            value={
              props.state
                ? props.id
                  ? boatStates.properties[props.state].value
                  : boatStates.properties[props.state]
                : null
            }
            onStateChange={props.handleStateChange('state')}
            stateReducer={props.formElementStateReducer('state')}
          >
            {(formElementUtils: FormElementUtils) => (
              <DropDownList
                data={Object.values(boatStates.properties).filter(
                  (boat) => boat.value !== boatStates.CHARTER
                )}
                dataItemKey='value'
                textField='name'
                value={props.state ? boatStates.properties[props.state] : null}
                label='State*'
                className='four-elements'
                onOpen={formElementUtils.open}
                onChange={formElementUtils.change}
                required
                validityStyles={props.fieldValidityOnSubmit.state}
                disabled={props.state === boatStates.CHARTER}
              />
            )}
          </FormElement>
        </MaterialGrid>

        {props.state === boatStates.CHARTER && (
          <>
            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <FormElement
                isLoaded={props.id !== null}
                initialElementType={elementTypes.dropDown}
                initialValue={props.mainBase.id}
                value={props.mainBase.id}
                onStateChange={props.handleStateChange('mainBase')}
                stateReducer={props.formElementStateReducer('mainBase')}
              >
                {(formElementUtils: FormElementUtils) => (
                  <DropDownList
                    data={props.allBases}
                    value={props.mainBase.id !== null ? props.mainBase : null}
                    dataItemKey='id'
                    textField='name'
                    label='Main Base*'
                    className='four-elements'
                    onOpen={formElementUtils.open}
                    onChange={formElementUtils.change}
                    required
                    validityStyles={props.fieldValidityOnSubmit.mainBaseId}
                  />
                )}
              </FormElement>
            </MaterialGrid>

            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <FormElement
                initialElementType={elementTypes.numericTextBox}
                initialValue={props.deposit}
                value={props.deposit}
                onStateChange={props.handleStateChange('deposit')}
              >
                {(formElementUtils: FormElementUtils) => (
                  <NumericTextBox
                    value={props.deposit}
                    label='Deposit(€)*'
                    className='four-elements'
                    format={'#,#.##'}
                    spinners={false}
                    onChange={formElementUtils.change}
                    required
                  />
                )}
              </FormElement>
            </MaterialGrid>
          </>
        )}
      </MaterialGrid>

      <hr className='divider-line' />

      <MaterialGrid
        container
        className='page-content-container row-with-multiple-items-in-form-elements'
      >
        <MaterialGrid item xs={6} lg={3} className='form-element'>
          <FormElement
            initialElementType={elementTypes.numericTextBox}
            initialValue={props.produced}
            value={props.produced}
            onStateChange={props.handleStateChange('produced')}
          >
            {(formElementUtils: FormElementUtils) => (
              <NumericTextBox
                value={props.produced}
                label='Produced'
                className='four-elements'
                spinners={false}
                format='#####'
                onChange={formElementUtils.change}
              />
            )}
          </FormElement>
        </MaterialGrid>

        {props.state !== boatStates.CHARTER ? (
          <>
            <MaterialGrid
              item
              xs={6}
              lg={3}
              className='form-element'
              style={{
                flexDirection:
                  props.state === boatStates.PREOWNED ? 'column' : 'row',
                gap: '8px',
              }}
            >
              <FormElement
                initialElementType={elementTypes.numericTextBox}
                initialValue={props.salePrice}
                value={props.salePrice}
                onStateChange={props.handleStateChange('salePrice')}
              >
                {(formElementUtils: FormElementUtils) => (
                  <NumericTextBox
                    value={props.salePrice}
                    label='Sale price(€)'
                    className='four-elements'
                    format={'#,#.##'}
                    spinners={false}
                    onChange={formElementUtils.change}
                  />
                )}
              </FormElement>

              {props.state === boatStates.PREOWNED && (
                <FormElement
                  initialValue={props.salePriceHasTax}
                  value={props.salePriceHasTax}
                  onStateChange={props.handleStateChange('salePriceHasTax')}
                >
                  {(formElementUtils: FormElementUtils) => (
                    <Checkbox
                      label={'Sale price has tax?'}
                      className='four-elements'
                      defaultValue={false}
                      value={
                        props.salePriceHasTax ? props.salePriceHasTax : false
                      }
                      size='medium'
                      onChange={formElementUtils.change}
                    />
                  )}
                </FormElement>
              )}
            </MaterialGrid>

            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <FormElement
                initialElementType={elementTypes.numericTextBox}
                initialValue={props.promotionalPrice}
                value={props.promotionalPrice}
                onStateChange={props.handleStateChange('promotionalPrice')}
              >
                {(formElementUtils: FormElementUtils) => (
                  <NumericTextBox
                    value={props.promotionalPrice}
                    label='Promotional price(€)'
                    className='four-elements'
                    format={'#,#.##'}
                    spinners={false}
                    onChange={formElementUtils.change}
                  />
                )}
              </FormElement>
            </MaterialGrid>

            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <FormElement
                initialElementType={elementTypes.datePicker}
                initialValue={props.availableFrom}
                value={props.availableFrom}
                onStateChange={props.handleStateChange('availableFrom')}
                stateReducer={props.formElementStateReducer('availableFrom')}
              >
                {(formElementUtils: FormElementUtils) => (
                  <DatePicker
                    label='Available from'
                    className='four-elements'
                    value={
                      props.availableFrom
                        ? parseDate(props.availableFrom, 'yyyy-MM-ddTHH:mm:ss')
                        : null
                    }
                    format='dd/MM/yyyy'
                    formatPlaceholder='formatPattern'
                    onChange={formElementUtils.change}
                  />
                )}
              </FormElement>
            </MaterialGrid>
          </>
        ) : (
          <>
            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <FormElement
                isLoaded={props.id !== null}
                initialElementType={elementTypes.dropDown}
                initialValue={props.defaultProduct.id}
                value={props.defaultProduct.id}
                onStateChange={props.handleStateChange('defaultProduct')}
                stateReducer={props.formElementStateReducer('defaultProduct')}
              >
                {(formElementUtils: FormElementUtils) => (
                  <DropDownList
                    data={props.allDefaultProducts}
                    value={
                      props.defaultProduct.id !== null
                        ? props.defaultProduct
                        : null
                    }
                    dataItemKey='id'
                    textField='name'
                    label='Default Product*'
                    className='four-elements'
                    onOpen={formElementUtils.open}
                    onChange={formElementUtils.change}
                    required
                    validityStyles={
                      props.fieldValidityOnSubmit.defaultProductId
                    }
                  />
                )}
              </FormElement>
            </MaterialGrid>

            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <FormElement
                isLoaded={props.id !== null}
                initialElementType={elementTypes.multiSelect}
                initialValue={props.alternativeProducts}
                value={props.alternativeProducts}
                onStateChange={props.handleStateChange('alternativeProducts')}
                stateReducer={props.formElementStateReducer(
                  'alternativeProducts'
                )}
              >
                {(formElementUtils: FormElementUtils) => (
                  <MultiSelect
                    data={props.allAlternativeProducts}
                    value={
                      props.alternativeProducts !== null &&
                      props.alternativeProducts?.length !== 0 &&
                      props.alternativeProducts[0].productId !== null
                        ? props.alternativeProducts
                        : []
                    }
                    dataItemKey='productId'
                    textField='product'
                    label='Alternative Products'
                    className='four-elements'
                    onOpen={formElementUtils.open}
                    onChange={formElementUtils.change}
                    validityStyles={props.hasUnsavedChangesFlag}
                  />
                )}
              </FormElement>
            </MaterialGrid>

            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <FormElement
                initialElementType={elementTypes.numericTextBox}
                initialValue={props.maximumDiscount}
                value={props.maximumDiscount}
                onStateChange={props.handleStateChange('maximumDiscount')}
              >
                {(formElementUtils: FormElementUtils) => (
                  <NumericTextBox
                    value={props.maximumDiscount}
                    label='Maximum Discount(%)'
                    className='four-elements'
                    format={'#,#.##'}
                    spinners={false}
                    onChange={formElementUtils.change}
                  />
                )}
              </FormElement>
            </MaterialGrid>
          </>
        )}
      </MaterialGrid>

      <hr className='divider-line' />

      <MaterialGrid container className='page-content-container'>
        <MaterialGrid item xs={6} lg={3} className='form-element'>
          <TextInput
            label='Length*'
            value={props.lengthOverAll || ''}
            isRequired={true}
            className='four-elements'
            change={props.handleTextInputChange('lengthOverAll')}
            isSubmit={props.fieldValidityOnSubmit.lengthOverAll}
          />
        </MaterialGrid>

        <MaterialGrid item xs={6} lg={3} className='form-element'>
          <TextInput
            label='Berths*'
            value={props.berths || ''}
            isRequired={true}
            className='four-elements'
            change={props.handleTextInputChange('berths')}
            isSubmit={props.fieldValidityOnSubmit.berths}
          />
        </MaterialGrid>

        <MaterialGrid item xs={6} lg={3} className='form-element'>
          <TextInput
            label='Cabins*'
            value={props.cabins || ''}
            isRequired={true}
            className='four-elements'
            change={props.handleTextInputChange('cabins')}
            isSubmit={props.fieldValidityOnSubmit.cabins}
          />
        </MaterialGrid>

        <MaterialGrid item xs={6} lg={3} className='form-element'>
          <TextInput
            label='Draft*'
            value={props.draft || ''}
            isRequired={true}
            className='four-elements'
            change={props.handleTextInputChange('draft')}
            isSubmit={props.fieldValidityOnSubmit.draft}
          />
        </MaterialGrid>
      </MaterialGrid>

      <hr className='divider-line' />

      {props.state === boatStates.CHARTER && (
        <>
          <MaterialGrid container className='page-content-container'>
            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <FormElement
                initialElementType={elementTypes.numericTextBox}
                initialValue={props.toilets}
                value={props.toilets}
                onStateChange={props.handleStateChange('toilets')}
              >
                {(formElementUtils: FormElementUtils) => (
                  <NumericTextBox
                    value={props.toilets}
                    label='Toilets'
                    className='four-elements'
                    format={'#'}
                    spinners={false}
                    onChange={formElementUtils.change}
                  />
                )}
              </FormElement>
            </MaterialGrid>

            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <FormElement
                initialElementType={elementTypes.datePicker}
                initialValue={props.checkInTime}
                value={props.checkInTime}
                onStateChange={props.handleStateChange('checkInTime')}
                stateReducer={props.formElementStateReducer('checkInTime')}
              >
                {(formElementUtils: FormElementUtils) => (
                  <TimePicker
                    label='Check In Time'
                    className='four-elements'
                    value={
                      props.checkInTime
                        ? parseDate(props.checkInTime, 'yyyy-MM-ddTHH:mm:ss')
                        : null
                    }
                    format='HH:mm:ss'
                    onChange={formElementUtils.change}
                  />
                )}
              </FormElement>
            </MaterialGrid>

            <MaterialGrid item xs={6} lg={3} className='form-element'>
              <FormElement
                initialElementType={elementTypes.datePicker}
                initialValue={props.checkOutTime}
                value={props.checkOutTime}
                onStateChange={props.handleStateChange('checkOutTime')}
                stateReducer={props.formElementStateReducer('checkOutTime')}
              >
                {(formElementUtils: FormElementUtils) => (
                  <TimePicker
                    label='Check Out Time'
                    className='four-elements'
                    value={
                      props.checkOutTime
                        ? parseDate(props.checkOutTime, 'yyyy-MM-ddTHH:mm:ss')
                        : null
                    }
                    format='HH:mm:ss'
                    onChange={formElementUtils.change}
                  />
                )}
              </FormElement>
            </MaterialGrid>

            <MaterialGrid
              item
              xs={6}
              lg={3}
              className='form-element joined-form-elements'
            >
              <FormElement
                initialValue={props.hasSkipperCabin}
                value={props.hasSkipperCabin}
                onStateChange={props.handleStateChange('hasSkipperCabin')}
              >
                {(formElementUtils: FormElementUtils) => (
                  <Checkbox
                    label={'Has skipper cabin?'}
                    className='checkbox-long-text-elements'
                    defaultValue={false}
                    value={
                      props.hasSkipperCabin ? props.hasSkipperCabin : false
                    }
                    onChange={formElementUtils.change}
                  />
                )}
              </FormElement>
              <FormElement
                initialValue={props.isLuxury}
                value={props.isLuxury}
                onStateChange={props.handleStateChange('isLuxury')}
              >
                {(formElementUtils: FormElementUtils) => (
                  <Checkbox
                    label={'Is luxury?'}
                    className='checkbox-small-elements'
                    defaultValue={false}
                    value={props.isLuxury ? props.isLuxury : false}
                    onChange={formElementUtils.change}
                  />
                )}
              </FormElement>
            </MaterialGrid>
          </MaterialGrid>

          <hr className='divider-line' />
        </>
      )}

      <MaterialGrid container className='page-content-container'>
        <MaterialGrid item xs={12} lg={6} container className='form-element'>
          <MaterialGrid item xs={10}>
            <CustomInput
              labelText='Video'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                value: props.youTubeVideoUrl || '',
                onChange: props.handleTextInputChange('youTubeVideoUrl'),
              }}
            />
          </MaterialGrid>
        </MaterialGrid>

        <MaterialGrid item xs={12} lg={6} container className='form-element'>
          <MaterialGrid item xs={10}>
            <CustomInput
              labelText='Virtual tour'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                value: props.virtualTourUrl || '',
                onChange: props.handleTextInputChange('virtualTourUrl'),
              }}
            />
          </MaterialGrid>
        </MaterialGrid>
      </MaterialGrid>

      <div className='' style={{ padding: '.5em 0' }}></div>

      {/* <div className='' style={{ background: '#ebebeb' }}> */}
      <MaterialGrid
        lg={16}
        xs={12}
        container
        className='page-content-container'
      >
        {currentLanguageChosenForm && (
          <div
            className=''
            style={{ background: '#ebebeb', marginBottom: '1em' }}
          >
            <MaterialGrid item lg={8}>
              <div className='' style={{ padding: '.5em  0 0 0' }}></div>
              <FormGridElement
                component={DropDownList}
                label={`Boat description and document translation for`}
                additionalProps={{
                  data: allLanguagesList,
                  dataItemKey: 'id',
                  textField: 'displayName',
                  defaultValue: allLanguagesList.find(
                    (item) => item.id === currentLanguageChosenForm.id
                  ),
                }}
                onChange={(e: DropDownListChangeEvent) => {
                  setCurrentLanguageChosenForm(e.value);
                }}
              />

              <div className='' style={{ padding: '0 0 .5em 0' }}></div>
            </MaterialGrid>
          </div>
        )}
      </MaterialGrid>

      {props.descriptions.map((description) => {
        if (
          currentLanguageChosenForm &&
          description.languageCode === currentLanguageChosenForm.code
        ) {
          return (
            <MaterialGrid
              container
              className='page-content-container-desc-upload'
            >
              {props.id ? (
                <>
                  <MaterialGrid
                    item
                    key={`description_${description.languageCode}`}
                    lg={7}
                  >
                    <Editor
                      tools={[
                        [Bold, Italic, Underline],
                        [AlignLeft, AlignCenter, AlignRight],
                        [Indent, Outdent],
                        [OrderedList, UnorderedList],
                        [Undo, Redo],
                      ]}
                      defaultContent={
                        description && description.text
                          ? isJSONString(description.text)
                            ? JSON.parse(description.text as string)
                            : description.text
                          : ''
                      }
                      onChange={props.handleDescriptionChange(
                        description.languageId
                      )}
                      onPasteHtml={props.handleDescriptionChange(
                        description.languageId
                      )}
                      onBlur={props.handleDescriptionChange(
                        description.languageId
                      )}
                    />
                  </MaterialGrid>

                  {props.isInEditMode && props.id && (
                    <MaterialGrid item lg={5} style={{ paddingLeft: '2em' }}>
                      <MaterialGrid item>
                        {
                          <DocumentUpload
                            documentType={documentTypes.BROCHURE}
                            boatId={props.id}
                            documents={props.brochures}
                            state={props.state}
                            currentChosenLang={currentLanguageChosenForm}
                          />
                        }
                      </MaterialGrid>

                      <MaterialGrid item>
                        {
                          <DocumentUpload
                            documentType={documentTypes.OFFER}
                            boatId={props.id}
                            documents={props.offers}
                            state={props.state}
                            currentChosenLang={currentLanguageChosenForm}
                          />
                        }
                      </MaterialGrid>
                    </MaterialGrid>
                  )}
                </>
              ) : (
                <MaterialGrid
                  item
                  key={`description_${description.languageCode}`}
                  lg={12}
                >
                  <Editor
                    tools={[
                      [Bold, Italic, Underline],
                      [AlignLeft, AlignCenter, AlignRight],
                      [Indent, Outdent],
                      [OrderedList, UnorderedList],
                      [Undo, Redo],
                    ]}
                    // defaultContent=''
                    defaultContent={
                      description && description.text
                        ? JSON.parse(description.text as string)
                        : ''
                    }
                    onChange={props.handleDescriptionChange(
                      description.languageId
                    )}
                    onPasteHtml={props.handleDescriptionChange(
                      description.languageId
                    )}
                    onBlur={props.handleDescriptionChange(
                      description.languageId
                    )}
                  />
                </MaterialGrid>
              )}
            </MaterialGrid>
          );
        }
        return <></>;
      })}
      {/* </div> */}

      {/* <hr className='divider-line' /> */}
    </>
  );
};

export default GeneralInformation;
