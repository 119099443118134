type InitialStateGetter = () => {
  name: string;
  subvariation: string | null;
  modelId: number | null;
  order?: number | null;
};

export const getInitialState: InitialStateGetter = () => ({
  name: '',
  subvariation: '',
  modelId: null,
});
