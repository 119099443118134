// TYPES
import { Enumeration } from '../../types/typeDefinitions';
//

type BoatStatus = {
  DRAFT: 1;
  PUBLISHED: 2;
  OUTOFPRODUCTIONORSOLD: 3;
  IMPORTEDNOTAPPROVED: 4;
};

const boatStatuses: Enumeration & BoatStatus = {
  DRAFT: 1,
  PUBLISHED: 2,
  OUTOFPRODUCTIONORSOLD: 3,
  IMPORTEDNOTAPPROVED: 4,
  properties: {
    1: { name: 'Draft/Unpublished', value: 1 },
    2: { name: 'Published', value: 2 },
    3: { name: 'Out of Production/Sold', value: 3 },
    4: { name: 'Imported / Not Approved', value: 4 },
  },
};

export default boatStatuses;
