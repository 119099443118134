// REACT
import { ReactElement } from 'react';
//

// TYPES
import { CardFooterProps } from '../../types/typeDefinitions';
//

const CardFooter = ({ ...props }: Partial<CardFooterProps>): ReactElement => {
  const {
    children,
    plain,
    profile,
    pricing,
    testimonial,
    stats,
    chart,
    product,
    ...rest
  } = props;

  return <div {...rest}>{children}</div>;
};

export default CardFooter;
