// TYPES
import {
  Filter,
  ManufacturerAndModelContainerState,
  ManufacturerListItem,
  ModelListItem,
  ModelVariationListItem,
  Translation,
  User,
  Website,
} from '../../../types/typeDefinitions';
//

// HOOKS
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState, memo, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  manufacturersAndModelsApiSlice,
  useAddManufacturerMutation,
  useAddModelMutation,
  useAddVariationMutation,
  useDeleteManufacturerMutation,
  useDeleteModelMutation,
  useDeleteModelVariationMutation,
  useGetWebsitesForMultiSelectQuery,
  useLazyGetManufacturersQuery,
  useLazyGetModelDetailsQuery,
  useLazyGetModelsQuery,
  useLazyGetModelVariationsQuery,
  useUpdateManufacturerMutation,
  useUpdateModelMutation,
  useUpdateVariationMutation,
  useUpdateVariationsMutation,
  useGetManufacturersQuery,
} from './manufacturersAndModelsApiSlice';
import {
  getManufacturersRequest,
  getManufacturersSuccess,
} from './manufacturersStateSlice';
import {
  changeModelsDataStateFilter,
  getModelsRequest,
  getModelsSuccess,
} from './modelsStateSlice';
import { getModelVariationsSuccess } from './modelVariationsStateSlice';
import useNotifications from '../../../hooks/useNotifications';
import { useLazyGetRegenerateSearchIndexesQuery } from '../Boats/boatsApiSlice';
//

// COMPONENTS
import ManufacturerGrid from './ManufacturerGrid';
import ModelGrid from './ModelGrid';
import { Form, FormUtils } from '../../../components/Form/Form';
import { FormGridElement } from '../../../components/FormGridElement/FormGridElement';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import CustomInput from '../../../components/CustomInput/CustomInput';
import KendoLoader from '../../../components/Loader/KendoLoader';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// KENDO
import { GridRowClickEvent } from '@progress/kendo-react-grid';
import {
  Upload,
  UploadFileStatus,
  UploadOnAddEvent,
  UploadOnBeforeRemoveEvent,
  UploadOnBeforeUploadEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from '@progress/kendo-react-upload';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import {
  DropDownList,
  DropDownListChangeEvent,
  MultiSelect,
} from '@progress/kendo-react-dropdowns';
//

// SERVICES
import { getInitialState as getManufacturerInitialState } from '../../../models/initialState/manufacturer';
import { getInitialState as getModelInitialState } from '../../../models/initialState/model';
import { getInitialState as getVariationInitialState } from '../../../models/initialState/modelVariation';
import { getUserFromStorage } from '../../../services/storage';
import { buildGridFilters } from '../../../services/gridFilters';
//

// ASSETS
import placeholder from '../../../assets/img/boat_placeholder.jpg';
import { buildLocalizedValuesArray } from '../../../services/localizedValues';

const INIT_LANG_STATE_SELECT = { id: 1, code: 'En', displayName: 'English' };
//

const ManufacturerAndModelContainer = () => {
  const [selectedManufacturerId, setSelectedManufacturerId] =
    useState<ManufacturerAndModelContainerState['selectedManufacturerId']>(
      null
    );
  const [isWindowVisible, setIsWindowVisible] =
    useState<ManufacturerAndModelContainerState['isWindowVisible']>(false);
  const [isCreateOrEdit, setIsCreateOrEdit] =
    useState<ManufacturerAndModelContainerState['isCreateOrEdit']>('');
  const [itemTypeInWindow, setItemTypeInWindow] =
    useState<ManufacturerAndModelContainerState['itemTypeInWindow']>('');
  const [newOrSelectedManufacturer, setNewOrSelectedManufacturer] = useState<
    ManufacturerAndModelContainerState['newOrSelectedManufacturer']
  >(getManufacturerInitialState());
  const [newOrSelectedModel, setNewOrSelectedModel] = useState<
    ManufacturerAndModelContainerState['newOrSelectedModel']
  >(getModelInitialState());
  const [newOrSelectedVariation, setNewOrSelectedVariation] = useState<
    ManufacturerAndModelContainerState['newOrSelectedVariation']
  >(getVariationInitialState());
  const [modelPhotoFile, setModelPhotoFile] = useState<
    ManufacturerAndModelContainerState['modelPhotoFile']
  >([]);
  const [emptyVariation, setEmptyVariation] = useState(false);

  const dispatch = useAppDispatch();

  const [addManufacturer, { isLoading: isAddManufacturerLoading }] =
    useAddManufacturerMutation();
  const [addModel, { isLoading: isAddModelLoading }] = useAddModelMutation();
  const [addVariation, { isLoading: isAddVariationLoading }] =
    useAddVariationMutation();
  const [modelDetailsList] = useLazyGetModelDetailsQuery();

  const {
    data: WebsitesForMultiSelectList,
    isFetching: isFetchingWebsitesForMultiSelect,
  } = useGetWebsitesForMultiSelectQuery();
  const [updateManufacturer, { isLoading: isUpdateManufacturerLoading }] =
    useUpdateManufacturerMutation();
  const [updateModel, { isLoading: isUpdateModelLoading }] =
    useUpdateModelMutation();
  const [updateVariation, { isLoading: isUpdateVariationLoading }] =
    useUpdateVariationMutation();
  const [updateVariations, { isLoading: isUpdateVariationsLoading }] =
    useUpdateVariationsMutation();
  const [lazyGetManufacturers, { isFetching: isGetManufacturersLoading }] =
    useLazyGetManufacturersQuery();
  const [lazyGetModels, { isFetching: isGetModelsLoading }] =
    useLazyGetModelsQuery();
  const [lazyGetModelVariations, { isFetching: isGetModelVariationsLoading }] =
    useLazyGetModelVariationsQuery();
  const [deleteManufacturer, { isLoading: isDeleteManufacturerLoading }] =
    useDeleteManufacturerMutation();

  const { isFetching: isGetManuLoad } = useGetManufacturersQuery('');

  const [deleteModel, { isLoading: isDeleteModelLoading }] =
    useDeleteModelMutation();

  const [deleteVariation, { isLoading: isDeleteVariationLoading }] =
    useDeleteModelVariationMutation();

  const handleCloningEntity = useCallback(
    (entity: any) => cloneDeep(entity),
    []
  );

  const totalManufacturers = useAppSelector(
    (state) => state.manufacturersState.data.total
  );

  const manufacturersDataState = useAppSelector(
    (state) => state.manufacturersState.data.dataState
  );

  const modelsDataState = useAppSelector(
    (state) => state.modelsState.data.dataState
  );

  const manufacturersFromStore = useAppSelector(
    (state) => state.manufacturersState.data.manufacturers
  );

  const allManufacturers: ManufacturerListItem[] = useMemo(() => {
    return handleCloningEntity(manufacturersFromStore);
  }, [manufacturersFromStore, handleCloningEntity]);

  const modelsFromStore = useAppSelector(
    (state) => state.modelsState.data.models
  );

  const allModels: ModelListItem[] = useMemo(() => {
    return handleCloningEntity(modelsFromStore);
  }, [modelsFromStore, handleCloningEntity]);

  const totalModels = useAppSelector((state) => state.modelsState.data.total);

  const modelVariationsFromStore = useAppSelector(
    (state) => state.modelVariationsState.data.variations
  );

  const allModelVariations: ModelVariationListItem[] = useMemo(() => {
    return handleCloningEntity(modelVariationsFromStore);
  }, [modelVariationsFromStore, handleCloningEntity]);

  const defaultLanguage = useAppSelector(
    (state) => state.languagesState.data.defaultLanguage
  );

  // LANG SELECTOR AND STATE
  const allLanguagesList = useAppSelector(
    (state) => state.languagesState.data.languages
  );

  const [currentLanguageChosenForm, setCurrentLanguageChosenForm] =
    useState<any>(INIT_LANG_STATE_SELECT);

  useEffect(() => {
    setNewOrSelectedModel(getModelInitialState());
  }, []);
  //

  const { handleUserActionNotification, handlePromiseNotification } =
    useNotifications();

  const getManufacturersFromServer = useCallback(
    async (dataState: any) => {
      dispatch(getManufacturersRequest(dataState));
      try {
        await lazyGetManufacturers(dataState)
          .unwrap()
          .then((data) => dispatch(getManufacturersSuccess(data)));
      } catch (error: any) {
        handleUserActionNotification({
          message: error.data.message,
          type: 'error',
          autoClose: 2500,
        });
      }
    },
    [dispatch, lazyGetManufacturers, handleUserActionNotification]
  );

  const getModelsFromServer = useCallback(
    async (dataState: any) => {
      try {
        dispatch(getModelsRequest(dataState));

        const requestModelsList = await lazyGetModels(dataState);

        if (!requestModelsList.isError && !requestModelsList.isLoading) {
          dispatch(getModelsSuccess(requestModelsList.data));
        }
      } catch (error: any) {
        handleUserActionNotification({
          message: error.data.message,
          type: 'error',
          autoClose: 2500,
        });
      }
    },
    [dispatch, lazyGetModels, handleUserActionNotification]
  );

  const getModelVariationsFromServer = useCallback(async () => {
    try {
      const requestModelVariations = await lazyGetModelVariations();

      if (
        !requestModelVariations.isError &&
        !requestModelVariations.isLoading
      ) {
        dispatch(getModelVariationsSuccess(requestModelVariations.data));
      }
    } catch (error: any) {
      handleUserActionNotification({
        message: error.data.message,
        type: 'error',
        autoClose: 2500,
      });
    }
  }, [dispatch, lazyGetModelVariations, handleUserActionNotification]);

  useEffect(() => {
    getManufacturersFromServer(
      manufacturersDataState !== null && manufacturersDataState !== undefined
        ? manufacturersDataState
        : ''
    );

    getModelsFromServer(
      modelsDataState !== null && modelsDataState !== undefined
        ? modelsDataState
        : ''
    );

    getModelVariationsFromServer();
    setCurrentLanguageChosenForm(INIT_LANG_STATE_SELECT);
  }, [
    handleCloningEntity,
    getManufacturersFromServer,
    manufacturersDataState,
    getModelsFromServer,
    dispatch,
    getModelVariationsFromServer,
    modelsDataState,
    isAddVariationLoading,
    isAddManufacturerLoading,
    isAddModelLoading,
    isUpdateManufacturerLoading,
    isUpdateModelLoading,
    isUpdateVariationsLoading,
    isUpdateVariationLoading,
    isDeleteManufacturerLoading,
    isDeleteModelLoading,
    isDeleteVariationLoading,
    isGetManuLoad,
  ]);

  const prepareModelsForFormGridElement = (formVariation: any) => {
    if (allModels !== undefined && allModels !== null) {
      return allModels.find((x: any) => x.id === formVariation.modelId)
        ? (
            allModels.find((x: any) => x.id === formVariation.modelId) || {
              name: '',
            }
          ).name
        : ([] as any[]);
    }
  };

  const handleManufacturerSelect = (event: GridRowClickEvent) => {
    let newValue: number | null = null;
    let newFilter: {
      Manufacturer: Pick<Filter, 'operator' | 'serverKey' | 'serverValue'>;
    } = {
      Manufacturer: {
        operator: 'eq',
        serverKey: 'manufacturerId',
        serverValue: null,
      },
    };
    if (selectedManufacturerId !== event.dataItem.id) {
      newValue = event.dataItem.id;
      newFilter.Manufacturer.serverValue = newValue;
    } else {
      newValue = null;
    }

    setSelectedManufacturerId(newValue);

    dispatch(changeModelsDataStateFilter(buildGridFilters(newFilter as any)));
  };

  const handleManufacturerCreate = () => {
    setIsWindowVisible(true);
    setIsCreateOrEdit('create');
    setItemTypeInWindow('Manufacturer');
  };

  const handleModelCreate = () => {
    setIsWindowVisible(true);
    setIsCreateOrEdit('create');
    setItemTypeInWindow('Model');

    setNewOrSelectedModel(getModelInitialState());

    setNewOrSelectedModel({
      ...newOrSelectedModel,
      manufacturerId: selectedManufacturerId,
      descriptions: buildLocalizedValuesArray(),
    });
  };

  const handleVariationCreate =
    (itemType: string) => (modelIdForVariation: number) => {
      setIsWindowVisible(true);
      setIsCreateOrEdit('create');
      setItemTypeInWindow(itemType);
      setNewOrSelectedVariation({
        ...newOrSelectedModel,
        modelId: modelIdForVariation,
      });
    };

  const handleEdit = async (itemType: string, dataItem: any) => {
    try {
      if (itemType === 'Model') {
        const requestModelDetails = await modelDetailsList(dataItem.id);

        const modelDetailsForUiLayer = handleCloningEntity(
          requestModelDetails.data
        );

        setNewOrSelectedModel(modelDetailsForUiLayer);
        setIsWindowVisible(true);
        setIsCreateOrEdit('edit');
        setItemTypeInWindow('Model');
      } else if (itemType === 'Manufacturer') {
        setNewOrSelectedManufacturer(dataItem);
        setIsWindowVisible(true);
        setIsCreateOrEdit('edit');
        setItemTypeInWindow(itemType);
      } else {
        setItemTypeInWindow('Variation');
        setIsCreateOrEdit('edit');
        setNewOrSelectedVariation(dataItem);
        setIsWindowVisible(true);
      }
    } catch (error: any) {
      handleUserActionNotification({
        message: error.data.message,
        autoClose: 2500,
        type: 'error',
      });
    }
  };

  const handleModelPhotoUploadAdd = (event: UploadOnAddEvent) =>
    setModelPhotoFile(event.newState);

  const handleModelPhotoUploadRemove = (_event: UploadOnRemoveEvent) => {
    setNewOrSelectedModel({ ...newOrSelectedModel, photoUrl: '' });
    setModelPhotoFile([]);
  };

  const handleModelPhotoUploadProgress = (event: UploadOnProgressEvent) =>
    setModelPhotoFile(event.newState);

  const handleModelPhotoUploadStatusChange = (
    event: UploadOnStatusChangeEvent
  ) => {
    if (event.newState[0].status === UploadFileStatus.Uploaded) {
      setNewOrSelectedModel({
        ...newOrSelectedModel,
        photoUrl: event.response ? event.response.response.fileName : '',
      });
      setModelPhotoFile(event.newState);
      handleUserActionNotification({
        message: 'Photo updated successfully!',
        type: 'success',
        autoClose: 2500,
      });
      dispatch(
        manufacturersAndModelsApiSlice.util.invalidateTags(['ModelDetails'])
      );
    } else {
      setModelPhotoFile(event.newState);
    }
  };

  const handleModelPhotoBeforeUpload = (event: UploadOnBeforeUploadEvent) => {
    let userJson: string | null = getUserFromStorage();
    let user: User = userJson ? JSON.parse(userJson) : {};
    event.headers = { Authorization: `Bearer ${user.token}` };
  };

  const handleModelPhotoBeforeRemove = (event: UploadOnBeforeRemoveEvent) => {
    let userJson: string | null = getUserFromStorage();
    let user: User = userJson ? JSON.parse(userJson) : {};
    event.headers = { Authorization: `Bearer ${user.token}` };
  };

  const handleClose = () => {
    setIsWindowVisible(false);
    setIsCreateOrEdit('');
    setItemTypeInWindow('');
    setCurrentLanguageChosenForm(INIT_LANG_STATE_SELECT);
    if (itemTypeInWindow === 'Manufacturer') {
      setNewOrSelectedManufacturer(getManufacturerInitialState());
      setFieldValidityOnSubmit({
        name: false,
      });
    } else if (itemTypeInWindow === 'Model') {
      setNewOrSelectedModel(getModelInitialState());
      setModelPhotoFile([]);
      setFieldValidityOnSubmit({
        name: false,
        manufacturerId: false,
        description: false,
      });
    } else if (itemTypeInWindow === 'Variation') {
      setNewOrSelectedVariation(getVariationInitialState());
      setFieldValidityOnSubmit({
        name: false,
      });
    }
  };

  const handleSubmitManufacturer = (data: any) => {
    try {
      if (isCreateOrEdit === 'edit') {
        handlePromiseNotification(
          updateManufacturer({
            id: data.id,
            name: data.name,
            websiteIds: data.websites.map((item: Website) => item.id),
          }).unwrap(),
          {
            pending: { message: 'Processing...', type: 'info' },
            success: {
              message: 'Manufacturer successfully updated!',
              type: 'success',
            },
            error: {
              message: 'There was something wrong with your request.',
              type: 'error',
            },
          }
        );
        getManufacturersFromServer(manufacturersDataState);
      } else {
        handlePromiseNotification(
          addManufacturer({
            name: data.name,
            websiteIds: data.websites.map((item: Website) => item.id),
          }).unwrap(),
          {
            pending: { message: 'Processing...', type: 'info' },
            success: {
              message: 'Manufacturer added successfully!',
              type: 'success',
            },
            error: {
              message: 'There was something wrong with your request.',
              type: 'error',
            },
          }
        );
        getManufacturersFromServer(manufacturersDataState);
      }
    } catch (error: any) {
      handleUserActionNotification({
        message: error.data.message,
        type: 'error',
        autoClose: 2500,
      });
    }

    setIsWindowVisible(false);
    setIsCreateOrEdit('');
    setItemTypeInWindow('');
    setNewOrSelectedManufacturer(getManufacturerInitialState());
  };

  const handleSubmitModel = (data: any) => {
    try {
      if (isCreateOrEdit === 'edit') {
        handlePromiseNotification(updateModel(data).unwrap(), {
          pending: { message: 'Processing...', type: 'info' },
          success: {
            message: 'Model successfully updated!',
            type: 'success',
          },
          error: {
            message: 'There was something wrong with your request.',
            type: 'error',
          },
        });
      } else {
        handlePromiseNotification(addModel(data).unwrap(), {
          pending: { message: 'Processing...', type: 'info' },
          success: {
            message: 'Model added successfully!',
            type: 'success',
          },
          error: {
            message: 'There was something wrong with your request.',
            type: 'error',
          },
        });
      }
    } catch (error: any) {
      handleUserActionNotification({
        message: error.data.message,
        type: 'error',
        autoClose: 2500,
      });
    }
    setIsWindowVisible(false);
    setIsCreateOrEdit('');
    setItemTypeInWindow('');
    setNewOrSelectedModel(getModelInitialState());
    setModelPhotoFile([]);
    setCurrentLanguageChosenForm(INIT_LANG_STATE_SELECT);
  };

  const handleUpdateVariations = (data: ModelVariationListItem[]) => {
    try {
      handlePromiseNotification(updateVariations(data).unwrap(), {
        pending: { message: 'Processing...', type: 'info' },
        success: {
          message: 'Variations list updated successfully!',
          type: 'success',
        },
        error: {
          message: 'There was something wrong with your request.',
          type: 'error',
        },
      });
    } catch (error: any) {
      handleUserActionNotification({
        message: error.data.message,
        type: 'error',
        autoClose: 2500,
      });
    }
  };

  const handleSubmitVariation = (data: any) => {
    if (isCreateOrEdit === 'edit') {
      try {
        handlePromiseNotification(updateVariation(data).unwrap(), {
          pending: { message: 'Processing...', type: 'info' },
          success: {
            message: 'Model variation updated successfully!',
            type: 'success',
          },
          error: {
            message: 'There was something wrong with your request.',
            type: 'error',
          },
        });
      } catch (error: any) {
        handleUserActionNotification({
          message: error.data.message,
          type: 'error',
          autoClose: 2500,
        });
      }
    } else {
      try {
        handlePromiseNotification(addVariation(data).unwrap(), {
          pending: { message: 'Processing...', type: 'info' },
          success: {
            message: 'Model variation added successfully!',
            type: 'success',
          },
          error: {
            message: 'There was something wrong with your request.',
            type: 'error',
          },
        });
      } catch (error: any) {
        handleUserActionNotification({
          message: error.data.message,
          type: 'error',
          autoClose: 2500,
        });
      }
    }

    setIsWindowVisible(false);
    setIsCreateOrEdit('');
    setItemTypeInWindow('');
    setNewOrSelectedVariation(getVariationInitialState());
  };

  const handleDeleteManufacturer = async (dataItem: any) => {
    if (dataItem.hasModel === true) {
      handleUserActionNotification({
        type: 'error',
        message:
          'This manufacturer has models assigned. Please remove the models from manufacturer before deleting',
        autoClose: 2500,
      });
    } else {
      try {
        await deleteManufacturer(dataItem.id).unwrap();
        handleUserActionNotification({
          type: 'success',
          message: 'Manufacturer successfully deleted!',
          autoClose: 2500,
        });

        getManufacturersFromServer(manufacturersDataState);
      } catch (error: any) {
        console.log(error);
        handleUserActionNotification({
          type: 'error',
          message: `${error.status} : \n ${error.data.message}`,
          autoClose: 2500,
        });
      }
    }
  };

  const handleDeleteModel = async (dataItem: any) => {
    if (dataItem.hasVariation === true) {
      handleUserActionNotification({
        type: 'error',
        message:
          'This model has variations assigned. Please remove the variations from model before deleting',
        autoClose: 2500,
      });
    } else
      try {
        await deleteModel(dataItem.id).unwrap();

        handleUserActionNotification({
          type: 'success',
          message: 'Model sucessfully deleted!',
          autoClose: 2500,
        });
      } catch (error: any) {
        console.log(error);

        handleUserActionNotification({
          type: 'error',
          message: `${error.status} : \n ${error.data.message}`,
          autoClose: 2500,
        });
      }
  };

  const handleDeleteVariation = async (dataItem: any) => {
    if (dataItem.hasBoat === true) {
      handleUserActionNotification({
        type: 'error',
        message:
          'Boats with this model variation exist. Please remove the boats with this variation before deleting.',
        autoClose: 2500,
      });
    } else {
      try {
        await deleteVariation(dataItem.id).unwrap();

        handleUserActionNotification({
          type: 'success',
          message: 'Model variation sucessfully deleted!',
          autoClose: 2500,
        });
      } catch (error: any) {
        console.log(error);
        handleUserActionNotification({
          type: 'error',
          message: `${error.status} : \n ${error.data.message}`,
          autoClose: 2500,
        });
      }
    }
  };

  const prepareManufacturersRenderLayer = () => (
    <ManufacturerGrid
      manufacturers={
        allManufacturers !== undefined && allManufacturers?.length !== 0
          ? allManufacturers
          : ([] as any[])
      }
      total={totalManufacturers}
      getManufacturers={getManufacturersFromServer}
      dataState={manufacturersDataState}
      selectedManufacturerId={selectedManufacturerId}
      handleManufacturerSelect={handleManufacturerSelect}
      handleEdit={handleEdit}
      handleDelete={handleDeleteManufacturer}
    />
  );

  const prepareModelsRenderLayer = () => {
    if (
      isGetModelsLoading ||
      isGetModelVariationsLoading ||
      isGetManufacturersLoading
    ) {
      return <KendoLoader />;
    }

    return (
      <ModelGrid
        models={
          allModels !== undefined && allModels !== null
            ? (allModels as ModelListItem[])
            : ([] as any[])
        }
        total={totalModels}
        variations={
          allModelVariations !== undefined && allModelVariations !== null
            ? allModelVariations
            : ([] as any[])
        }
        handleCreate={handleVariationCreate}
        handleEdit={handleEdit}
        updateVariations={handleUpdateVariations}
        dataState={modelsDataState}
        getModels={getModelsFromServer}
        handleDelete={handleDeleteModel}
        handleDeleteVariation={handleDeleteVariation}
      />
    );
  };

  const addDefaultSrc = (event: any) => {
    event.target.src = placeholder;
  };

  const [getRegenerateSearchIndexes] = useLazyGetRegenerateSearchIndexesQuery();

  const handleRegenerate = () => {
    try {
      handlePromiseNotification(getRegenerateSearchIndexes().unwrap(), {
        pending: { message: 'Saving...', type: 'info' },
        success: {
          message: 'Public data generated successfully!',
          type: 'success',
        },
        error: {
          message: 'Something went wrong with your request.',
          type: 'error',
        },
      });
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        message: `${error.status} : \n ${error.error}`,
        autoClose: 2500,
      });
    }
  };

  const [fieldValidityOnSubmit, setFieldValidityOnSubmit] = useState<any>({
    name: false,
    manufacturerId: false,
    description: false,
  });

  const handleFieldValidity = (data: any) => {
    if (data.name === '') {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, name: true };
      });
    }

    if (
      data.manufacturerId === null ||
      !data.manufacturerId ||
      data.manufacturer === ''
    ) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, manufacturerId: true };
      });
    }

    if (data.descriptions[0].text === '') {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, description: true };
      });
    }
  };

  // NEW

  const handleEmptyVariation = () => {
    setEmptyVariation(!emptyVariation);
    setNewOrSelectedVariation({
      ...newOrSelectedVariation,
      name: '/',
    });
  };

  return (
    <>
      <MaterialGrid container direction='column' spacing={2}>
        <MaterialGrid
          container
          position='fixed'
          top={16.6}
          right={16.6}
          zIndex={1201}
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
        >
          <MaterialGrid item>
            <button onClick={handleRegenerate} className='light-button'>
              Generate public data
            </button>
          </MaterialGrid>

          <MaterialGrid item>
            <button onClick={handleManufacturerCreate} className='pink-button'>
              Add new manufacturer
            </button>
          </MaterialGrid>

          <MaterialGrid item>
            <button onClick={handleModelCreate} className='pink-button'>
              Add new model
            </button>
          </MaterialGrid>
        </MaterialGrid>

        <MaterialGrid item>{prepareManufacturersRenderLayer()}</MaterialGrid>

        <br />

        <MaterialGrid item>{prepareModelsRenderLayer()}</MaterialGrid>
      </MaterialGrid>

      {/* MANUFACTURER DIALOG */}

      <Form
        data={newOrSelectedManufacturer}
        requiredData={{
          textFields: ['name'],
        }}
        onSubmit={handleSubmitManufacturer}
        render={({
          data,
          validations,
          onFocus,
          onInputChange,
          onMultiSelectChange,
          onSubmit,
        }: FormUtils) => (
          <CustomDialog
            open={isWindowVisible && itemTypeInWindow === 'Manufacturer'}
            title={`${
              isCreateOrEdit === 'create' ? 'New' : 'Edit'
            } manufacturer`}
            onClose={handleClose}
            onConfirm={() => {
              onSubmit();
              handleFieldValidity(data);
            }}
          >
            <FormGridElement
              component={Input}
              label={'Name*'}
              value={data.name}
              onChange={onInputChange('name')}
              additionalProps={{
                validityStyles: fieldValidityOnSubmit.name,
                valid: !fieldValidityOnSubmit.name,
                onKeyDown: () =>
                  setFieldValidityOnSubmit((prev: any) => {
                    return { ...prev, name: false };
                  }),
              }}
            />

            <FormGridElement
              component={
                isFetchingWebsitesForMultiSelect ? <KendoLoader /> : MultiSelect
              }
              label='Websites'
              value={data.websites}
              onChange={onMultiSelectChange('websites')}
              additionalProps={{
                data: WebsitesForMultiSelectList,
                dataItemKey: 'id',
                textField: 'code',
              }}
            />
          </CustomDialog>
        )}
      />

      {/* MODEL DIALOG */}

      <Form
        data={newOrSelectedModel}
        requiredData={{
          textFields: ['name', `descriptions_${defaultLanguage}`],
          numberFields: ['manufacturerId'],
        }}
        onSubmit={handleSubmitModel}
        render={({
          data,
          validations,
          onFocus,
          onInputChange,
          onLocalizedInputChange,
          onDropDownChange,
          onSubmit,
        }: FormUtils) => (
          <CustomDialog
            open={isWindowVisible && itemTypeInWindow === 'Model'}
            title={`${isCreateOrEdit === 'create' ? 'New' : 'Edit'} model`}
            onClose={handleClose}
            onConfirm={() => {
              onSubmit();
              handleFieldValidity(data);
            }}
          >
            <FormGridElement
              component={Input}
              label={'Model name*'}
              value={data.name}
              onChange={onInputChange('name')}
              additionalProps={{
                validityStyles: fieldValidityOnSubmit.name,
                valid: !fieldValidityOnSubmit.name,
                onKeyDown: () =>
                  setFieldValidityOnSubmit((prev: any) => {
                    return { ...prev, name: false };
                  }),
              }}
            />

            <FormGridElement
              component={DropDownList}
              label={'Manufacturer*'}
              value={
                allManufacturers !== undefined &&
                allManufacturers.find((m) => m.id === data.manufacturerId)
              }
              onChange={onDropDownChange('manufacturer')}
              additionalProps={{
                data: allManufacturers,
                dataItemKey: 'id',
                textField: 'name',
                validityStyles: fieldValidityOnSubmit.manufacturerId,
                valid: !fieldValidityOnSubmit.manufacturerId,
                onFocus: () =>
                  setFieldValidityOnSubmit((prev: any) => {
                    return { ...prev, manufacturerId: false };
                  }),
                disabled: isCreateOrEdit === 'edit',
                onOpen: onFocus('manufacturerId'),
              }}
            />

            <hr
              style={{
                display: 'block',
                height: '0.063em',
                border: '0',
                borderTop: '0.063em solid #ccc',
                width: '88%',
                marginTop: '18px',
              }}
            />

            <FormGridElement
              component={DropDownList}
              label='Description language'
              additionalProps={{
                data: allLanguagesList,
                dataItemKey: 'id',
                textField: 'displayName',
                defaultValue: allLanguagesList.find(
                  (item) => item.id === currentLanguageChosenForm.id
                ),
              }}
              onChange={(e: DropDownListChangeEvent) => {
                setCurrentLanguageChosenForm(e.value);
              }}
            />

            {data.descriptions?.length > 0 &&
              data.descriptions.map((desc: Translation) => {
                if (desc.languageCode === currentLanguageChosenForm.code) {
                  return (
                    <FormGridElement
                      key={`description_model_${desc.languageCode}`}
                      component={CustomInput}
                      label={`Model description (${desc.languageCode.toLowerCase()})${
                        desc.languageCode === defaultLanguage ? '*' : ''
                      }`}
                      additionalProps={
                        desc.languageCode === defaultLanguage
                          ? {
                              formControlProps: {
                                fullWidth: true,
                                error: fieldValidityOnSubmit.description,
                              },
                              inputProps: {
                                multiline: true,
                                value: desc.text,
                                error: fieldValidityOnSubmit.description,
                                onChange: onLocalizedInputChange(
                                  'descriptions',
                                  desc.languageCode
                                ),
                                onKeyDown: () =>
                                  setFieldValidityOnSubmit((prev: any) => {
                                    return { ...prev, description: false };
                                  }),
                              },
                            }
                          : {
                              formControlProps: {
                                fullWidth: true,
                              },
                              inputProps: {
                                multiline: true,
                                value: desc.text,
                                onChange: onLocalizedInputChange(
                                  'descriptions',
                                  desc.languageCode
                                ),
                              },
                            }
                      }
                    />
                  );
                }
                return <></>;
              })}

            {data.id ? (
              <>
                {data.photoDescriptions?.length > 0 &&
                  data.photoDescriptions.map((desc: Translation) => {
                    if (desc.languageCode === currentLanguageChosenForm.code) {
                      return (
                        <FormGridElement
                          key={`photo_description_${desc.languageCode}`}
                          component={Input}
                          label={`Photo description (${desc.languageCode.toLowerCase()})`}
                          value={desc.text}
                          onChange={onLocalizedInputChange(
                            'photoDescriptions',
                            desc.languageCode
                          )}
                        />
                      );
                    }
                    return <></>;
                  })}

                <FormGridElement label={'Photo'} topLabel={true}>
                  <MaterialGrid item xs={7}>
                    {data.photoUrl !== '' && (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_URL}/models/${
                          data.photoUrl
                        }?${new Date().getTime()}`}
                        alt='model'
                        onError={addDefaultSrc}
                        height={150}
                        width={250}
                      />
                    )}

                    <Upload
                      autoUpload={false}
                      multiple={false}
                      files={modelPhotoFile}
                      restrictions={{
                        allowedExtensions: ['.jpg', '.jpeg'],
                      }}
                      onAdd={handleModelPhotoUploadAdd}
                      onRemove={handleModelPhotoUploadRemove}
                      onProgress={handleModelPhotoUploadProgress}
                      onStatusChange={handleModelPhotoUploadStatusChange}
                      onBeforeUpload={handleModelPhotoBeforeUpload}
                      onBeforeRemove={handleModelPhotoBeforeRemove}
                      saveUrl={`${process.env.REACT_APP_BASE_URL}/models/${data.id}/photo/save`}
                      removeUrl={`${process.env.REACT_APP_BASE_URL}/models/${data.id}/photo/remove`}
                      withCredentials={false}
                    />
                  </MaterialGrid>
                </FormGridElement>
              </>
            ) : null}
          </CustomDialog>
        )}
      />

      {/* VARIATION DIALOG */}

      <Form
        data={newOrSelectedVariation}
        requiredData={{
          textFields: ['name'],
        }}
        onSubmit={handleSubmitVariation}
        render={({ data, onInputChange, onSubmit }: FormUtils) => (
          <CustomDialog
            open={isWindowVisible && itemTypeInWindow === 'Variation'}
            title={
              isCreateOrEdit !== ''
                ? `${isCreateOrEdit === 'create' ? 'New' : 'Edit'} variation`
                : ''
            }
            onClose={handleClose}
            onConfirm={() => {
              onSubmit();
              handleFieldValidity(data);
            }}
          >
            <FormGridElement
              component={Checkbox}
              label={'Empty variation'}
              value={emptyVariation}
              onChange={handleEmptyVariation}
            />
            <FormGridElement
              component={Input}
              label={'Name*'}
              value={data.name}
              onChange={onInputChange('name')}
              additionalProps={{
                validityStyles: fieldValidityOnSubmit.name,
                valid: !fieldValidityOnSubmit.name,
                onKeyDown: () =>
                  setFieldValidityOnSubmit((prev: any) => {
                    return { ...prev, name: false };
                  }),
                disabled: emptyVariation ? true : false,
              }}
            />

            <FormGridElement
              component={Input}
              label='Subvariation'
              value={data.subvariation ? data.subvariation : ''}
              onChange={onInputChange('subvariation')}
              additionalProps={{
                disabled: emptyVariation ? true : false,
              }}
            />

            <FormGridElement
              component={Input}
              label='Model*'
              value={prepareModelsForFormGridElement(data)}
              additionalProps={{
                disabled: true,
              }}
            />
          </CustomDialog>
        )}
      />
    </>
  );
};

export default memo(ManufacturerAndModelContainer);
