// REACT
import { ReactElement } from 'react';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

interface Props {
  children: any;
  [k: string]: any;
}

const GridContainer = ({ ...props }: Partial<Props>): ReactElement => {
  const { children, ...rest } = props;

  return (
    <MaterialGrid {...rest} container>
      {children}
    </MaterialGrid>
  );
};

export default GridContainer;
