// TYPES
import {
  BoatBase,
  BoatDetailsBasesProps,
} from '../../../../types/typeDefinitions';
//

// KENDO
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import { CommandCell } from '../../../../components/CommandCell/CommandCell';
import DropdownCell from '../../../../components/DropdownCell/DropdownCell';
import { DateCell } from '../../../../components/DateCell/DateCell';
import { Renderers } from './Renderers';
//

// HOOKS
import { useCallback, useState } from 'react';
import { useDeleteBoatBaseMutation } from '../boatsApiSlice';
import useFocusNewItem from '../../../../hooks/useFocusNewItem';
import { useGetBasesForDropdownQuery } from '../../Discounts/discountsApiSlice';
//

const Bases = (props: BoatDetailsBasesProps) => {
  const [deleteBase] = useDeleteBoatBaseMutation();

  const { data: basesDropdownData } = useGetBasesForDropdownQuery();

  const formatData = useCallback((data: BoatDetailsBasesProps['data']) => {
    if (data) {
      return data.map((item) => {
        item.dateFrom = new Date(String(item.dateFrom));
        item.dateTo = new Date(String(item.dateTo));

        return item;
      });
    }
  }, []);

  const renderers = new Renderers(props.enterEdit, props.exitEdit, 'inEdit');
  const { handleGenerationOfNewItemInUI } = useFocusNewItem('dateFirst');

  const initialSort: Array<SortDescriptor> = [
    { field: 'dateFrom', dir: 'asc' },
  ];

  const [sort, setSort] = useState(initialSort);

  const addNew = () => {
    setSort([{ field: 'dateFrom', dir: 'desc' }]);

    let newTempID = new Date().getMilliseconds();
    props.handleAddNewBase(newTempID);
    handleGenerationOfNewItemInUI();
  };

  const handleBaseDelete = async (dataItem: any) => {
    if ('tempId' in dataItem) {
      props.handleDelete(dataItem);
    } else {
      await deleteBase({
        baseIdToDelete: dataItem.id,
        boatIdToDelete: props.boatId,
      });
      props.handleDelete(dataItem);
    }
  };

  return (
    <>
      <MaterialGrid container direction='column' spacing={1}>
        <MaterialGrid item container justifyContent='flex-end'>
          {props.hasMMK !== null && props.hasMMK !== undefined ? null : (
            <button onClick={addNew} title='Add base' className='pink-button'>
              Add base
            </button>
          )}
        </MaterialGrid>
        <MaterialGrid item>
          <Grid
            scrollable='none'
            data={orderBy(formatData(props.data) as BoatBase[], sort)}
            cellRender={renderers.cellRender}
            rowRender={renderers.rowRender}
            onItemChange={props.handleValueChange}
            sortable={true}
            resizable={true}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
            }}
          >
            <Column
              field='_command'
              width={45}
              groupable={false}
              sortable={false}
              filterable={false}
              resizable={false}
              title=' '
              cell={(cellProps) => (
                <CommandCell
                  {...cellProps}
                  hasDeleteCommand={true}
                  handleDelete={handleBaseDelete}
                />
              )}
            />
            <Column
              field='dateFrom'
              title='Date From'
              editable={true}
              sortable={true}
              cell={(cellProps) =>
                props.hasMMK !== null && props.hasMMK !== undefined ? (
                  <DateCell {...cellProps} disabled />
                ) : (
                  <DateCell {...cellProps} />
                )
              }
            />
            <Column
              field='dateTo'
              title='Date To'
              editable={true}
              sortable={true}
              cell={(cellProps) =>
                props.hasMMK !== null && props.hasMMK !== undefined ? (
                  <DateCell {...cellProps} disabled />
                ) : (
                  <DateCell {...cellProps} />
                )
              }
            />
            <Column
              field='base'
              title='Base'
              sortable={true}
              editable={true}
              cell={(cellProps) =>
                props.hasMMK !== null && props.hasMMK !== undefined ? (
                  <DropdownCell
                    data={
                      basesDropdownData !== undefined
                        ? basesDropdownData.map((n: any) => n.name)
                        : []
                    }
                    disabled
                    {...cellProps}
                  />
                ) : (
                  <DropdownCell
                    data={
                      basesDropdownData !== undefined
                        ? basesDropdownData.map((n: any) => n.name)
                        : []
                    }
                    {...cellProps}
                  />
                )
              }
            />
          </Grid>
        </MaterialGrid>
      </MaterialGrid>
    </>
  );
};

export default Bases;
