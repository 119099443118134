// HOOKS
import useFocus from '../../../../hooks/useFocus';
//

// SERVICES
import { isValidText } from '../../../../services/validations';
//

// KENDO
import { Input } from '@progress/kendo-react-inputs';
//

// TYPES
import { BoatDetailsTextInputFormElementType } from '../../../../types/typeDefinitions';
//

const TextInputFormElement: BoatDetailsTextInputFormElementType = ({
  label = '',
  value = '',
  isRequired = false,
  isValid = isRequired && isValidText(value),
  change = () => {},
  className = '',
  isSubmit,
}) => {
  const [isTouched, handleFocus] = useFocus();

  return (
    <Input
      className={className}
      label={label}
      value={value}
      onFocus={isRequired ? handleFocus : undefined}
      onChange={change}
      validityStyles={isRequired && isSubmit ? true : isTouched}
      // validityStyles={isRequired ? isTouched : undefined}
      valid={isRequired ? isValid : undefined}
    />
  );
};

export default TextInputFormElement;
