// KENDO
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import KendoLoader from '../../../../components/Loader/KendoLoader';
import { CommandCell } from '../../../../components/CommandCell/CommandCell';
import DropdownCell from '../../../../components/DropdownCell/DropdownCell';
import TextInputCell from '../../../../components/InputCell/InputCell';
import { Renderers } from '../../Boats/Details/Renderers';
import MultiSelectCell from '../../../../components/MultiSelectCell/MultiSelectCell';
//

// TYPES
import {
  BasesGridProps,
  LazyInterface,
} from '../../../../types/typeDefinitions';
//

// HOOKS
import {
  useDeleteBaseMutation,
  useGetCountriesQuery,
  useGetSailingAreasQuery,
} from '../locationsApiSlice';
import useNotifications from '../../../../hooks/useNotifications';
import { useState } from 'react';
//

const BasesGrid = (props: BasesGridProps) => {
  const [
    deleteBase,
    { isLoading: isDeleteBaseLoading, isError: isDeleteBaseError },
  ] = useDeleteBaseMutation();

  const { data: countriesForDropdown } = useGetCountriesQuery();

  const { data: sailingAreasForMultiSelect } = useGetSailingAreasQuery();

  const { handleUserActionNotification } = useNotifications();

  const handleDelete = async (dataItem: Partial<LazyInterface>) => {
    if ('id' in dataItem) {
      try {
        await deleteBase(dataItem.id).unwrap();
        props.handleRemoveFromUi(dataItem as any);
      } catch (error: any) {
        handleUserActionNotification({
          message: `${error.data.message}`,
          autoClose: 2500,
          type: 'error',
        });
      }
    } else {
      props.handleRemoveFromUi(dataItem as any);
    }
  };

  const renderers = new Renderers(props.enterEdit, props.exitEdit, 'inEdit');

  const initialSort: Array<SortDescriptor> = [];

  const [sort, setSort] = useState(initialSort);

  return (
    <MaterialGrid width={'100%'} height={'100%'}>
      <Grid
        style={{ height: '100%' }}
        scrollable='scrollable'
        data={orderBy(props.data, sort)}
        sort={sort}
        onSortChange={(e: GridSortChangeEvent) => {
          setSort(e.sort);
        }}
        sortable={true}
        resizable={true}
        editField={'inEdit'}
        cellRender={renderers.cellRender}
        rowRender={renderers.rowRender}
        onItemChange={props.handleValueChange}
      >
        <Column
          field='_command'
          width={45}
          groupable={false}
          sortable={false}
          filterable={false}
          resizable={false}
          title=' '
          cell={(cellProps) => (
            <CommandCell
              {...cellProps}
              hasDeleteCommand={true}
              handleDelete={handleDelete}
            />
          )}
        />
        <Column field='id' title='ID' />
        <Column
          field='name'
          title='Name*'
          editable={true}
          cell={TextInputCell}
        />
        <Column
          field='countryName'
          title='Country*'
          sortable={true}
          editable={true}
          cell={(cellProps) => (
            <DropdownCell
              data={
                countriesForDropdown !== undefined
                  ? countriesForDropdown.map((n: any) => n.name)
                  : []
              }
              {...cellProps}
            />
          )}
        />
        <Column
          field='sailingAreas'
          title='Sailing Areas'
          sortable={true}
          editable={true}
          cell={(cellProps) => (
            <MultiSelectCell data={sailingAreasForMultiSelect} {...cellProps} />
          )}
        />
        <Column
          field='mmkId'
          title='MMK ID'
          editable={false}
          cell={TextInputCell}
        />
      </Grid>
      {props.isLoading && <KendoLoader />}
    </MaterialGrid>
  );
};

export default BasesGrid;
