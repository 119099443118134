// SERVICES
import { buildLocalizedValuesArray } from '../../services/localizedValues';
//

// TYPES
import { Translation } from '../../types/typeDefinitions';
//

type InitialStateGetter = () => {
  order: number | null;
  translations: Translation[];
};

export const getInitialState: InitialStateGetter = () => ({
  order: null,
  translations: buildLocalizedValuesArray(),
});
