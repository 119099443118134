// KENDO
import {
  DateRangePicker,
  DateRangePickerChangeEvent,
} from '@progress/kendo-react-dateinputs';
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import {
  DropDownButton,
  DropDownButtonItemClickEvent,
} from '@progress/kendo-react-buttons';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
import { MdClose as CloseIcon } from 'react-icons/md';

// HOOKS
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  useGetExtrasForDropdownQuery,
  useLazyGetAllExtrasQuery,
} from './extrasApiSlice';
import { useGetBasesForDropdownQuery } from '../Discounts/discountsApiSlice';
import {
  onExtraFilterCheckboxChange,
  onExtraFilterDateRangeChange,
  onExtraFilterDropdownChange,
  onExtraFilterInputChange,
  onExtraFilterNumericTextboxChange,
  resetExtraFilterState,
  saveExtrasList,
  saveExtrasLocalFilterState,
} from './extrasStateSlice';
import useNotifications from '../../../hooks/useNotifications';
import { setExtrasFiltersHeight } from '../generalStateSlice';
//

const ExtrasFilterBar = () => {
  const { data: basesDropdownData } = useGetBasesForDropdownQuery();
  const { data: extrasDropdownData } = useGetExtrasForDropdownQuery();
  const [getAllExtras, { currentData: dataFromGet }] =
    useLazyGetAllExtrasQuery();

  const currentFilterState = useAppSelector(
    (state) => state.extrasState.extrasFilters.filter.filters
  ).find((singleFilter) => singleFilter.name === 'extraId');

  const isInManageMode = useCallback(() => {
    if (currentFilterState?.value !== null) {
      return true;
    }
    return false;
  }, [currentFilterState]);

  const initLocalFilterValues = useMemo(() => {
    return {
      wildcardName: '',
      basesName: null,
      timeUnitName: null,
      sailingDates: {
        start: null,
        end: null,
      },
      validDaysFrom: null,
      validDaysTo: null,
      inBasePrice: false,
      perPerson: false,
      required: false,
      onInvoice: false,
    };
  }, []);

  const getLocalFilterStateFromStore = useAppSelector(
    (state) => state.extrasState.extrasFilters.localFilterState
  );

  const memoizedInitLocalState = useMemo(
    () => getLocalFilterStateFromStore,
    [getLocalFilterStateFromStore]
  );

  const [localFilterValues, setLocalFilterValues] = useState<any>(
    memoizedInitLocalState
  );

  const { handleUserActionNotification } = useNotifications();

  const initOptionalFilterState = useMemo(() => {
    return [
      {
        name: 'validDaysFrom',
        displayName: 'Valid days from',
        value: 1,
      },
      {
        name: 'validDaysTo',
        displayName: 'Valid days to',
        value: 1,
      },

      {
        name: 'perPerson',
        displayName: 'Per person?',
        value: 2,
      },
      {
        name: 'required',
        displayName: 'Required?',
        value: 2,
      },
      {
        name: 'inBasePrice',
        displayName: 'Included in base price?',
        value: 2,
      },
      {
        name: 'onInvoice',
        displayName: 'On invoice?',
        value: 2,
      },
      { name: 'sailingDates', displayName: 'Sailing dates', value: 3 },
      { name: 'bases', displayName: 'Available in base', value: 4 },
    ];
  }, []);

  const [optionalFilters, setOptionalFilters] = useState<any[]>(
    localFilterValues.optionalFilters
  );
  const [tempState, setTempState] = useState<any>(initOptionalFilterState);

  const OptionalFiltersController = () => {
    return (
      <MaterialGrid item className='filter-item'>
        <DropDownButton
          items={tempState.map((singleFilter: any) => singleFilter)}
          textField={'displayName'}
          text={'More filters...'}
          onItemClick={(event: DropDownButtonItemClickEvent) => {
            const tmp = optionalFilters.some(
              (singleFilter: any) => singleFilter.name === event.item.name
            );
            setOptionalFilters((prev: any) => {
              if (tmp === true) {
                return prev;
              } else return [...prev, event.item];
            });

            setTempState((prev: any) =>
              prev.filter(
                (singleFilter: any) => singleFilter.name !== event.item.name
              )
            );
          }}
        />
      </MaterialGrid>
    );
  };

  const prepareOptionalFilters = () => {
    return (
      <>
        {optionalFilters?.length !== 0 && optionalFilters !== undefined
          ? optionalFilters.map((singleFilter: any) => {
              if (singleFilter.value === 1) {
                return (
                  <React.Fragment key={singleFilter.name}>
                    <MaterialGrid item className='filter-item'>
                      <NumericTextBox
                        label={singleFilter.displayName}
                        value={localFilterValues[singleFilter.name]}
                        onChange={(event: NumericTextBoxChangeEvent) => {
                          customSetState(singleFilter.name, event.value);

                          if (event.value && event.value < 1) {
                            handleUserActionNotification({
                              message: 'Please select a value greater than 0',
                              type: 'warning',
                              autoClose: 2500,
                            });
                          } else {
                            onExtraNumericTextboxChange(
                              event,
                              singleFilter.name
                            );
                          }
                        }}
                        min={1}
                        spinners={false}
                        disabled={isInManageMode() === true}
                      />

                      <button
                        onClick={() => handleFilterRemove(singleFilter)}
                        className='close-button'
                        style={{ paddingBottom: '15px' }}
                      >
                        <CloseIcon />
                      </button>
                    </MaterialGrid>
                  </React.Fragment>
                );
              }

              if (singleFilter.value === 2) {
                return (
                  <React.Fragment key={singleFilter.name}>
                    <MaterialGrid item className='filter-item'>
                      <Checkbox
                        className='filter-checkbox'
                        label={singleFilter.displayName}
                        onChange={(event: CheckboxChangeEvent) => {
                          onExtraCheckboxChange(event, singleFilter.name);
                          customSetState(singleFilter.name, event.value);
                        }}
                        value={
                          localFilterValues[singleFilter.name] === null
                            ? false
                            : localFilterValues[singleFilter.name]
                        }
                        disabled={isInManageMode() === true}
                      />

                      <button
                        onClick={() => handleFilterRemove(singleFilter)}
                        className='close-button'
                        style={{ paddingBottom: '15px' }}
                      >
                        <CloseIcon />
                      </button>
                    </MaterialGrid>
                  </React.Fragment>
                );
              }

              if (singleFilter.value === 3) {
                return (
                  <React.Fragment key={singleFilter.name}>
                    <MaterialGrid item className='filter-item'>
                      <DateRangePicker
                        onChange={(event: DateRangePickerChangeEvent) => {
                          onExtraDateRangeChange(event, singleFilter.name);
                          customSetState(
                            singleFilter.name,
                            { start: event.value.start, end: event.value.end },
                            true
                          );
                        }}
                        value={{
                          start: localFilterValues[singleFilter.name].start,
                          end: localFilterValues[singleFilter.name].end,
                        }}
                        startDateInputSettings={{
                          label: undefined,
                          placeholder: 'Sailing date from',
                          format: 'dd/MM/yyyy',
                        }}
                        endDateInputSettings={{
                          label: undefined,
                          placeholder: 'Sailing date to',
                          format: 'dd/MM/yyyy',
                        }}
                        disabled={isInManageMode() === true}
                      />

                      <button
                        onClick={() => handleFilterRemove(singleFilter)}
                        className='close-button'
                        style={{ paddingBottom: '15px' }}
                      >
                        <CloseIcon />
                      </button>
                    </MaterialGrid>
                  </React.Fragment>
                );
              }

              if (singleFilter.value === 4) {
                return (
                  <React.Fragment key={singleFilter.name}>
                    {basesDropdownData !== undefined &&
                      basesDropdownData !== null &&
                      basesDropdownData?.length !== 0 && (
                        <MaterialGrid item className='filter-item'>
                          <DropDownList
                            data={basesDropdownData}
                            dataItemKey={'id'}
                            textField={'name'}
                            onChange={(event: DropDownListChangeEvent) => {
                              onFilterDropdownChange(event, 'bases');
                              customSetState('basesName', event.value.name);
                            }}
                            label={'Available in base'}
                            disabled={isInManageMode() === true}
                            value={{ name: localFilterValues.basesName }}
                          />

                          <button
                            onClick={() => handleFilterRemove(singleFilter)}
                            className='close-button'
                            style={{ paddingBottom: '15px' }}
                          >
                            <CloseIcon />
                          </button>
                        </MaterialGrid>
                      )}
                  </React.Fragment>
                );
              }

              return null;
            })
          : null}
      </>
    );
  };

  const handleFilterRemove = (item: any) => {
    const tmp = optionalFilters.filter(
      (singleFilter: any) => singleFilter.name !== item.name
    );
    setOptionalFilters(tmp);

    if (item.value === 1) {
      dispatch(
        onExtraFilterNumericTextboxChange({
          name: item.name,
          eventValue: null,
        })
      );

      setLocalFilterValues((prev: any) => {
        return {
          ...prev,
          [String(item.name)]: null,
        };
      });
    }
    if (item.value === 2) {
      dispatch(
        onExtraFilterCheckboxChange({ name: item.name, eventValue: false })
      );

      setLocalFilterValues((prev: any) => {
        return {
          ...prev,
          [String(item.name)]: null,
        };
      });
    }

    if (item.value === 3) {
      dispatch(
        onExtraFilterDateRangeChange({
          name: item.name,
          eventValue: { start: null, end: null },
        })
      );

      setLocalFilterValues((prev: any) => {
        return {
          ...prev,
          [String(item.name)]: { start: null, end: null },
        };
      });
    }

    if (item.value === 4) {
      dispatch(
        onExtraFilterDropdownChange({
          eventValue: null,
          name: item.name,
        })
      );

      setLocalFilterValues((prev: any) => {
        return {
          ...prev,
          [String(item.name)]: null,
        };
      });
    }

    setTempState(() => {
      const tmp = initOptionalFilterState.find(
        (singleFilter: any) => singleFilter.name === item.name
      );
      return [...tempState, tmp].sort((a: any, b: any) => {
        if (a.name > b.name) {
          return 1;
        }
        return -1;
      });
    });
  };

  const getExtrasFilters = useAppSelector(
    (state) => state.extrasState.extrasFilters
  );
  const extrasFilters = useMemo(() => getExtrasFilters, [getExtrasFilters]);

  const dispatch = useAppDispatch();

  const onFilterDropdownChange = (
    event: DropDownListChangeEvent,
    eventName: string
  ) =>
    dispatch(
      onExtraFilterDropdownChange({
        eventValue: event.value.id,
        name: eventName,
      })
    );

  const onFilterInputChange = (event: any, eventName: string) =>
    dispatch(
      onExtraFilterInputChange({
        eventValue: event.target.value,
        name: eventName,
      })
    );

  const onExtraDateRangeChange = (
    event: DateRangePickerChangeEvent,
    eventName: string
  ) =>
    dispatch(
      onExtraFilterDateRangeChange({
        eventValue: { start: event.value.start, end: event.value.end },
        name: eventName,
      })
    );

  const onExtraCheckboxChange = (
    event: CheckboxChangeEvent,
    eventName: string
  ) =>
    dispatch(
      onExtraFilterCheckboxChange({
        name: eventName,
        eventValue: event.value,
      })
    );

  const onExtraNumericTextboxChange = (
    event: NumericTextBoxChangeEvent,
    eventName: string
  ) =>
    dispatch(
      onExtraFilterNumericTextboxChange({
        name: eventName,
        eventValue: event.value as number,
      })
    );

  const nullCheckToClearFilters = useMemo(
    () =>
      Object.values(extrasFilters.filter.filters).some(
        (singleFilter) => singleFilter.value === null
      ),
    [extrasFilters.filter.filters]
  );

  const removeNullSoonToBeInReduxStore = useMemo(
    () =>
      extrasFilters.filter.filters.filter(
        (singleFilter) => singleFilter.value !== null
      ),
    [extrasFilters.filter.filters]
  );

  const queryParamsWithoutNull = useMemo(() => {
    return {
      ...extrasFilters,
      filter: { filters: removeNullSoonToBeInReduxStore, logic: 'and' },
    };
  }, [extrasFilters, removeNullSoonToBeInReduxStore]);

  const resetFilters = useCallback(() => {
    dispatch(resetExtraFilterState());

    setLocalFilterValues(initLocalFilterValues);
    setOptionalFilters([]);
    setTempState(initOptionalFilterState);
  }, [dispatch, initLocalFilterValues, initOptionalFilterState]);

  const getExtraListBasedOnFilters = useCallback(async () => {
    try {
      if (nullCheckToClearFilters) {
        await getAllExtras(queryParamsWithoutNull).unwrap();

        dataFromGet !== undefined && dispatch(saveExtrasList(dataFromGet));
      } else {
        await getAllExtras(extrasFilters).unwrap();

        dataFromGet !== undefined && dispatch(saveExtrasList(dataFromGet));
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    extrasFilters,
    dispatch,
    nullCheckToClearFilters,
    queryParamsWithoutNull,
    getAllExtras,
    dataFromGet,
  ]);

  useEffect(() => {
    getExtraListBasedOnFilters();
  }, [getExtraListBasedOnFilters]);

  const customSetState = useCallback(
    (eventName: string, eventValue: any, isDateTime = false) => {
      if (isDateTime) {
        setLocalFilterValues((prev: any) => {
          return {
            ...prev,
            [eventName]: { start: eventValue.start, end: eventValue.end },
          };
        });
      } else {
        setLocalFilterValues((prev: any) => {
          return { ...prev, [eventName]: eventValue };
        });
      }
    },
    []
  );

  const [height, setHeight] = useState(0);
  const ref: any = useRef(null);

  useLayoutEffect(() => {
    if (ref.current.clientHeight) {
      setTimeout(() => {
        setHeight(ref.current.clientHeight);
      }, 0);
    }
  });

  useEffect(() => {
    dispatch(setExtrasFiltersHeight(height));
  }, [dispatch, height]);

  useEffect(() => {
    return () => {
      dispatch(
        saveExtrasLocalFilterState({ ...localFilterValues, optionalFilters })
      );
    };
  }, [dispatch, localFilterValues, optionalFilters]);

  return (
    <MaterialGrid container paddingTop={2} spacing={2} ref={ref}>
      <MaterialGrid item className='filter-item'>
        <Input
          onKeyDown={(event) =>
            event.key === 'Enter' ? onFilterInputChange(event, 'name') : ''
          }
          onChange={(event: any) => {
            customSetState('wildcardName', event.target.value);
            onFilterInputChange(event, 'name');
          }}
          label={'Extra name'}
          value={localFilterValues.wildcardName}
          disabled={isInManageMode() === true}
        />
      </MaterialGrid>

      <MaterialGrid item className='filter-item'>
        <DropDownList
          data={extrasDropdownData}
          onChange={(event: DropDownListChangeEvent) => {
            onFilterDropdownChange(event, 'timeUnit');
            customSetState('timeUnitName', event.value.name);
          }}
          dataItemKey={'id'}
          textField={'name'}
          label={'Time unit'}
          disabled={isInManageMode() === true}
          value={{ name: localFilterValues.timeUnitName }}
        />
      </MaterialGrid>

      {prepareOptionalFilters()}
      <OptionalFiltersController />

      <MaterialGrid item className='filter-item'>
        {!isInManageMode() && (
          <button
            onClick={resetFilters}
            className='sky-button'
            disabled={isInManageMode() === true}
          >
            Reset filters
          </button>
        )}
      </MaterialGrid>
    </MaterialGrid>
  );
};

export default ExtrasFilterBar;
