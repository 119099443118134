import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generateHeadersIfAny } from '../../services/generateHeadersIfAny';

const authApiHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

interface AuthData {
  UserName: string;
  Password: string;
  rememberMe?: boolean;
}

interface User {
  username: string;
  token: string;
  tokenExpiry: Date;
}

export const authApiSlice = createApi({
  reducerPath: 'User-Auth-State',

  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    prepareHeaders: (headers) => generateHeadersIfAny(headers),
  }),

  endpoints(builder) {
    return {
      loginUser: builder.mutation<User, AuthData>({
        query: ({ ...credentials }) => ({
          url: `/user/login`,
          method: 'POST',
          body: credentials,
          headers: authApiHeaders,
        }),
      }),
    };
  },
});

export const { useLoginUserMutation } = authApiSlice;
