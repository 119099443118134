// ICONS
import {
  MdEdit as EditIcon,
  MdDelete as DeleteIcon,
  MdOutlineManageSearch as ManageIcon,
} from 'react-icons/md';
import { RiSailboatLine as ApplyIcon } from 'react-icons/ri';
import { GrDocumentLocked as MmkIcon } from 'react-icons/gr';
//

// TYPES
import { CommandCellProps, isDefined } from '../../types/typeDefinitions';
//

export function CommandCell(props: CommandCellProps) {
  const onEditClick = () => {
    if (isDefined(props.handleEdit)) {
      props.handleEdit(props.dataItem);
    }
  };

  const onDeleteClick = () => {
    if (isDefined(props.handleDelete) && window.confirm('Delete the item?')) {
      props.handleDelete(props.dataItem);
    }
  };

  const onApplyClick = () => {
    if (isDefined(props.handleApply)) {
      props.handleApply(props.dataItem);
    }
  };

  const onManageClick = () => {
    if (isDefined(props.handleManage)) {
      props.handleManage(props.dataItem);
    }
  };

  return (
    <td>
      <div className='command-cell'>
        {props.hasApplyCommand &&
          (props.dataItem.mmkId === null ||
            props.dataItem.mmkId === 0 ||
            props.dataItem.mmkId === undefined ||
            props.isExtra) && (
            <div title='Apply'>
              <button
                aria-label='Apply'
                onClick={onApplyClick}
                className='apply-button'
              >
                <ApplyIcon />
              </button>
            </div>
          )}

        {props.hasManageCommand &&
          (props.dataItem.mmkId === null ||
            props.dataItem.mmkId === 0 ||
            props.dataItem.mmkId === undefined) && (
            <div title='Manage'>
              <button
                aria-label='Manage'
                onClick={onManageClick}
                className='apply-button'
              >
                <ManageIcon />
              </button>
            </div>
          )}

        {props.hasEditCommand &&
          (props.dataItem.mmkId === null ||
            props.dataItem.mmkId === 0 ||
            props.dataItem.mmkId === undefined ||
            props.isBoat ||
            props.isExtra ||
            props.isEquipment) && (
            <div title='Edit'>
              <button
                aria-label='Edit'
                onClick={onEditClick}
                className='edit-button'
              >
                <EditIcon />
              </button>
            </div>
          )}

        {props.hasDeleteCommand &&
          (props.dataItem.mmkId === null ||
            props.dataItem.mmkId === 0 ||
            props.dataItem.mmkId === undefined) && (
            <div title='Remove'>
              <button
                aria-label='Remove'
                onClick={onDeleteClick}
                className='remove-button'
              >
                <DeleteIcon />
              </button>
            </div>
          )}

        {(props.hasDeleteCommand ||
          props.hasEditCommand ||
          props.hasApplyCommand ||
          props.hasManageCommand) &&
          props.dataItem.mmkId !== null &&
          props.dataItem.mmkId !== 0 &&
          props.dataItem.mmkId !== undefined && (
            <div title='Edited in MMK, please import'>
              <button
                aria-label='Edited in MMK, please import'
                className='forbidden-button'
              >
                <MmkIcon />
              </button>
            </div>
          )}
      </div>
    </td>
  );
}
