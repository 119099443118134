// TYPES
import { Enumeration } from '../../types/typeDefinitions';
//

type BoatState = {
  NEW: 1;
  STOCK: 2;
  PREOWNED: 3;
  CHARTER: 4;
};

const boatStates: Enumeration & BoatState = {
  NEW: 1,
  STOCK: 2,
  PREOWNED: 3,
  CHARTER: 4,
  properties: {
    1: { name: 'New', value: 1 },
    2: { name: 'Stock', value: 2 },
    3: { name: 'Preowned', value: 3 },
    4: { name: 'Charter', value: 4 },
  },
};

export default boatStates;
