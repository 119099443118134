// TYPES
import {
  BoatBase,
  BoatDetailsContainerOwnProps,
  BoatDetailsContainerState,
  BoatEquipmentPart,
  BoatPhoto,
  BoatPrice,
  BoatSpecificationPart,
  BoatState,
  EquipmentCategoryLookup,
  EquipmentPartLookup,
  isDefined,
  Lookup,
  ModelVariationLookup,
  PhotoType,
  Translation,
} from '../../../../types/typeDefinitions';
import boatStatuses from '../../../../types/enums/boatStatus';
import boatStates from '../../../../types/enums/boatState';
import photoTypes from '../../../../types/enums/photoType';
import photoFeaturedTypes from '../../../../types/enums/photoFeaturedType';
import {
  elementTypes,
  stateChangeTypes as FormElementStateChangeTypes,
  stateChangeTypes,
} from '../../../../components/Form/FormElement';
//

// HOOKS
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useNotifications from '../../../../hooks/useNotifications';
import Reorder, { UseReorderUtils } from '../../../../hooks/useReorder';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  useAddBoatMutation,
  useDeleteBoatPhotoMutation,
  useDeleteBoatSpecificationMutation,
  useDeleteBoatEquipmentPartMutation,
  useGetAlternativeProductsForMultiSelectQuery,
  useGetDefaultProductsForDropdownQuery,
  useGetManufacturersForDropDownQuery,
  useGetSpecificationPartsForComboboxQuery,
  useLazyGetBoatDetailsQuery,
  useLazyGetModelsForDropDownQuery,
  useLazyGetModelVariationsForDropDownQuery,
  useLazyGetRegenerateSearchIndexesQuery,
  useLazyGetSpecificationPartsForComboboxQuery,
  useUpdateBoatMutation,
  useUpdateBoatPhotoMutation,
  useUpdateInventoryMutation,
  useLazyGetInventoryQuery,
} from '../boatsApiSlice';
import { useGetEquipmentForCascadingComboBoxesQuery } from '../../Equipment/equipmentApiSlice';
import { getEquipmentCategoriesAndPartsForComboBoxSuccess } from '../../Equipment/equipmentStateSlice';
import {
  addBoatPhoto,
  changeBoatPhotoUrl,
  getBoatDetailsSuccess,
} from '../selectedBoatStateSlice';
import { getSpecificationPartsForComboBoxSuccess } from '../../SpecificationParts/specificationPartsStateSlice';
import { cloneDeep } from 'lodash';
import {
  useGetBasesForDropdownQuery,
  useGetCompaniesForDropdownQuery,
} from '../../Discounts/discountsApiSlice';
//

// KENDO
import { GridItemChangeEvent } from '@progress/kendo-react-grid';
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { formatDate } from '@telerik/kendo-intl';
import { Checkbox as KendoCheckbox } from '@progress/kendo-react-inputs';
//

// COMPONENTS
import FormElement, {
  FormElementUtils,
} from '../../../../components/Form/FormElement';
import GeneralInformation from './GeneralInformation';
import CharterInventory from './CharterInventory';
import Photos from './Photos';
import Prices from './Prices';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import KendoLoader from '../../../../components/Loader/KendoLoader';
import Specification from './Specification';
import Bases from './Bases';
import Extras from './Extras';
import Discounts from './Discounts';
import SpecialOffers from './SpecialOffers';
import ReactRouterPrompt from 'react-router-prompt';
import Inventory from './Inventory';
//

// MUI
import {
  FormControlLabel,
  Grid as MaterialGrid,
  Switch,
  Tab,
  Tabs,
} from '@mui/material';
//

// ICONS
import {
  MdOutlineArrowBackIosNew as ArrowBackIcon,
  MdWifiProtectedSetup as ImportIcon,
  MdSettingsInputComponent as LockIcon,
} from 'react-icons/md';
//

// SERVICES
import { buildLocalizedValuesArray } from '../../../../services/localizedValues';
import { isValidNumber, isValidText } from '../../../../services/validations';
import {
  EditorBlurEvent,
  EditorChangeEvent,
  EditorPasteEvent,
} from '@progress/kendo-react-editor';
//

const BoatDetailsContainer = (props: BoatDetailsContainerOwnProps) => {
  const {
    handleFormVerificationError,
    handleUserActionNotification,
    handlePromiseNotification,
  } = useNotifications();
  const initialGeneralInfo = useMemo(() => {
    return {
      id: null,
      name: '',
      isDisabled: false,
      previewUrl: null,
      manufacturerId: null,
      manufacturer: null,
      modelId: null,
      model: null,
      modelVariationId: null,
      modelVariation: null,
      subvariation: null,
      type: null,
      state: props.boatState as BoatState,
      status: boatStatuses.DRAFT,
      produced: null,
      lengthOverAll: null,
      berths: null,
      cabins: null,
      draft: null,
      salePrice: null,
      salePriceHasTax: false,
      promotionalPrice: null,
      availableFrom: null,
      youTubeVideoUrl: null,
      virtualTourUrl: null,
      brochures: [
        { languageId: 1, languageCode: 'En', id: null, fileName: null },
        { languageId: 2, languageCode: 'Hr', id: null, fileName: null },
      ],
      offers: [
        { languageId: 1, languageCode: 'En', id: null, fileName: null },
        { languageId: 2, languageCode: 'Hr', id: null, fileName: null },
      ],
      descriptions: buildLocalizedValuesArray(),
      companyId: 1,
      company: 'Nava',
      source: null,
      mmkId: null,
      importedDate: null,
      mainBaseId: null,
      mainBase: null,
      deposit: 0.0,
      defaultProductId: null,
      defaultProduct: null,
      alternativeProducts: [],
      toilets: null,
      hasSkipperCabin: false,
      isLuxury: false,
      checkInTime: null,
      checkOutTime: null,
      maximumDiscount: null,
      lockedPhotos: false,
      lockedBoat: false,
    };
  }, [props.boatState]);

  const [value, setValue] = useState<BoatDetailsContainerState['value']>(0);

  const [hasUnsavedChanges, setHasUnsavedChanges] =
    useState<BoatDetailsContainerState['hasUnsavedChanges']>(false);

  const [generalInfo, setGeneralInfo] =
    useState<BoatDetailsContainerState['generalInfo']>(initialGeneralInfo);

  const [specification, setSpecification] = useState<
    BoatDetailsContainerState['specification']
  >([]);

  const [equipment, setEquipment] = useState<
    BoatDetailsContainerState['equipment']
  >([]);

  const [photos, setPhotos] = useState<BoatDetailsContainerState['photos']>([]);

  const [prices, setPrices] = useState<BoatDetailsContainerState['prices']>([]);

  const [bases, setBases] = useState<BoatDetailsContainerState['bases']>([]);

  const [extras, setExtras] = useState<any[]>([]);

  const [discounts, setDiscounts] = useState<any[]>([]);

  const [specialOffers, setSpecialOffers] = useState<any[]>([]);

  const [specificationPartInEdit, setSpecificationPartInEdit] =
    useState<BoatDetailsContainerState['specPartInEditId']>(null);

  const [editField, setEditField] =
    useState<BoatDetailsContainerState['editField']>(undefined);

  const [selectedCategory, setSelectedCategory] =
    useState<BoatDetailsContainerState['selectedCategory']>(null);

  const [isWindowVisible, setIsWindowVisible] =
    useState<BoatDetailsContainerState['isWindowVisible']>(false);

  const [isAddedBoat, setIsAddedBoat] = useState<boolean>(false);

  const params = useParams();

  const [priceInEdit, setPriceInEdit] = useState<any>(null);

  const [baseInEdit, setBaseInEdit] = useState<any>(null);

  const [inventoryEn, setInventoryEn] = useState<any>('');

  const [inventoryHr, setInventoryHr] = useState<any>('');

  const [inventoryDe, setInventoryDe] = useState<any>('');

  const [inventoryEs, setInventoryEs] = useState<any>('');

  const [inventoryFr, setInventoryFr] = useState<any>('');

  const [
    getBoatDetails,
    { data: boatDetailsData, isFetching: isBoatDetailsFetching },
  ] = useLazyGetBoatDetailsQuery();

  const [getInventory, { data: inventoryData }] = useLazyGetInventoryQuery();

  const [getSpecificationPartsForCombobox] =
    useLazyGetSpecificationPartsForComboboxQuery();

  const [getModelVariationsForDropDown, { data: allModelVariations }] =
    useLazyGetModelVariationsForDropDownQuery();

  const location = useLocation();
  const navigateTo = useNavigate();

  const { data: allManufacturers } = useGetManufacturersForDropDownQuery();
  const [getModelsForDropDown, { data: allModels }] =
    useLazyGetModelsForDropDownQuery();

  const { data: equipmentForCascadingComboBoxes } =
    useGetEquipmentForCascadingComboBoxesQuery();

  const { data: allSpecificationPartsList } =
    useGetSpecificationPartsForComboboxQuery();

  const { data: companiesForDropDown } = useGetCompaniesForDropdownQuery();

  const { data: basesForDropDown } = useGetBasesForDropdownQuery();

  const { data: defaultProductsForDropDown } =
    useGetDefaultProductsForDropdownQuery();

  const { data: alternativeProductsForMultiSelect } =
    useGetAlternativeProductsForMultiSelectQuery();

  const [addBoat] = useAddBoatMutation();
  const [updateBoat] = useUpdateBoatMutation();
  const [updateBoatPhoto] = useUpdateBoatPhotoMutation();
  const [deleteBoatPhoto] = useDeleteBoatPhotoMutation();
  const [deleteEquipmentPartReq] = useDeleteBoatEquipmentPartMutation();
  const [updateInventory] = useUpdateInventoryMutation();

  const allEquipmentCategories = useAppSelector(
    (state) => state.equipmentCategoryAndParts.data.categoriesComboBoxList
  );

  const allEquipmentParts = useAppSelector(
    (state) => state.equipmentCategoryAndParts.data.partsComboBoxList
  );

  const allSpecificationParts = useAppSelector(
    (state) => state.specificationPartsState.data.comboBoxList
  );

  const defaultSpecificationPartsTemplate = useAppSelector(
    (state) => state.boatsState.data.specificationPartsTemplate
  );

  const languagesState = useAppSelector((state) => state.languagesState.data);

  const handleCloningEntity = useCallback(
    (entity: any) => cloneDeep(entity),
    []
  );

  const dispatch = useAppDispatch();

  const navigateFromAddForm = () => setIsAddedBoat(false);

  useEffect(() => {
    if (
      allSpecificationPartsList !== null &&
      allSpecificationPartsList !== undefined
    ) {
      const specPartsForStore = handleCloningEntity(allSpecificationPartsList);
      dispatch(getSpecificationPartsForComboBoxSuccess(specPartsForStore));
    }
  }, [allSpecificationPartsList, dispatch, handleCloningEntity]);

  useEffect(() => {
    if (
      equipmentForCascadingComboBoxes !== undefined &&
      equipmentForCascadingComboBoxes !== null &&
      equipmentForCascadingComboBoxes?.length !== 0
    ) {
      dispatch(
        getEquipmentCategoriesAndPartsForComboBoxSuccess(
          equipmentForCascadingComboBoxes
        )
      );
    }
  }, [equipmentForCascadingComboBoxes, dispatch]);

  const handleGoToList = useCallback(() => {
    const listPathname: string =
      props.boatState === boatStates.NEW
        ? '/new-boats'
        : props.boatState === boatStates.STOCK
        ? '/stock-boats'
        : props.boatState === boatStates.CHARTER
        ? '/charter-boats'
        : '/preowned-boats';

    navigateTo(listPathname);
  }, [navigateTo, props.boatState]);

  const handleGoToListNewBoat = useCallback(
    (boatPayloadID: number | null) => {
      const listPathname =
        props.boatState === boatStates.NEW
          ? '/new-boats'
          : props.boatState === boatStates.STOCK
          ? '/stock-boats'
          : props.boatState === boatStates.CHARTER
          ? '/charter-boats'
          : '/preowned-boats';

      if (boatPayloadID !== null && boatPayloadID !== undefined) {
        navigateTo(`${listPathname}/details/${boatPayloadID}`);
      } else {
        navigateTo(listPathname);
      }
    },
    [props.boatState, navigateTo]
  );

  useEffect(() => {
    if (params.id) {
      getBoatDetails(Number(params.id));
      getSpecificationPartsForCombobox();

      if (props.boatState !== boatStates.CHARTER) {
        getInventory(Number(params.id));

        setInventoryEn(
          inventoryData !== undefined &&
            inventoryData !== null &&
            inventoryData?.length !== 0
            ? inventoryData?.find((item: any) => item.languageId === 1)?.text
            : ''
        );

        setInventoryHr(
          inventoryData !== undefined &&
            inventoryData !== null &&
            inventoryData?.length !== 0
            ? inventoryData?.find((item: any) => item.languageId === 2)?.text
            : ''
        );

        setInventoryDe(
          inventoryData !== undefined &&
            inventoryData !== null &&
            inventoryData?.length !== 0
            ? inventoryData?.find((item: any) => item.languageId === 3)?.text
            : ''
        );

        setInventoryFr(
          inventoryData !== undefined &&
            inventoryData !== null &&
            inventoryData?.length !== 0
            ? inventoryData?.find((item: any) => item.languageId === 4)?.text
            : ''
        );

        setInventoryEs(
          inventoryData !== undefined &&
            inventoryData !== null &&
            inventoryData?.length !== 0
            ? inventoryData?.find((item: any) => item.languageId === 5)?.text
            : ''
        );
      }
    }

    const locationState = location.state as any;

    if (locationState && locationState.modelId !== undefined) {
      getModelVariationsForDropDown(locationState.modelId);
    }
  }, [
    params,
    getBoatDetails,
    getSpecificationPartsForCombobox,
    getModelVariationsForDropDown,
    location,
    getInventory,
    inventoryData,
    props.boatState,
  ]);

  const [fieldValidityOnSubmit, setFieldValidityOnSubmit] = useState<any>({
    name: false,
    manufacturerId: false,
    modelId: false,
    modelVariationId: false,
    type: false,
    state: false,
    mainBaseId: false,
    defaultProductId: false,
    lengthOverAll: false,
    berths: false,
    cabins: false,
    draft: false,
    description: false,
  });

  const validateForm = (
    data:
      | BoatDetailsContainerState['generalInfo']
      | Omit<
          BoatDetailsContainerState['generalInfo'],
          'id' | 'mmkId' | 'importedDate' | 'source'
        >,
    defaultLanguage: string
  ) => {
    const defaultLanguageTranslation: Translation | undefined =
      data.descriptions.find((x) => x.languageCode === defaultLanguage);

    // nava 110

    if (data.name === '') {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, name: true };
      });
    }
    if (data.manufacturerId === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, manufacturerId: true };
      });
    }

    if (data.modelId === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, modelId: true };
      });
    }

    if (data.modelVariationId === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, modelVariationId: true };
      });
    }

    if (data.type === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, type: true };
      });
    }

    if (data.state === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, state: true };
      });
    }

    if (data.mainBaseId === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, mainBaseId: true };
      });
    }

    if (data.defaultProductId === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, defaultProductId: true };
      });
    }

    if (data.lengthOverAll === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, lengthOverAll: true };
      });
    }

    if (data.berths === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, berths: true };
      });
    }

    if (data.cabins === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, cabins: true };
      });
    }

    if (data.draft === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, draft: true };
      });
    }

    if (
      data.descriptions.find((item) => item.languageCode === 'En')?.text === ''
    ) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, description: true };
      });
    }

    if (data.state === 4) {
      return (
        data.name !== '' &&
        data.manufacturerId !== null &&
        data.modelId !== null &&
        data.modelVariationId !== null &&
        data.type !== null &&
        data.state !== null &&
        data.companyId !== null &&
        data.mainBaseId !== null &&
        data.defaultProductId !== null &&
        data.deposit !== null &&
        isValidText(data.lengthOverAll) &&
        isValidText(data.berths) &&
        isValidText(data.cabins) &&
        isValidText(data.draft) &&
        isDefined(defaultLanguageTranslation) &&
        isValidText(defaultLanguageTranslation.text)
      );
    } else {
      return (
        data.manufacturerId !== null &&
        data.modelId !== null &&
        data.modelVariationId !== null &&
        data.type !== null &&
        data.state !== null &&
        isValidText(data.lengthOverAll) &&
        isValidText(data.berths) &&
        isValidText(data.cabins) &&
        isValidText(data.draft) &&
        isDefined(defaultLanguageTranslation) &&
        isValidText(defaultLanguageTranslation.text)
      );
    }
  };

  const toggleWindowVisible = () =>
    setIsWindowVisible((isVisible) => !isVisible);

  const handleCopyToDraft = () => {
    const newLocation: any = {
      pathname: `${location.pathname.slice(
        0,
        location.pathname.lastIndexOf('/')
      )}`,
      state: {
        isCopy: true,
      },
    };
    navigateTo(newLocation.pathname, { state: newLocation.state });
  };

  const handleDisabledToggle = () => {
    setGeneralInfo({
      ...generalInfo,
      isDisabled: !generalInfo.isDisabled,
    });

    setHasUnsavedChanges(true);
  };

  const handleTextInputChange = (field: string) => (event: any) => {
    const newValue: string = event.target.value;

    if (field) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, [field]: false };
      });
    }

    setHasUnsavedChanges(true);
    setGeneralInfo({
      ...generalInfo,
      [field]: newValue,
    });
  };

  const handleDescriptionChange =
    (languageId: number) =>
    (event: EditorChangeEvent | EditorBlurEvent | EditorPasteEvent) => {
      const newValue: string = JSON.stringify(
        event.target.contentElement?.innerHTML
      );

      setHasUnsavedChanges(true);

      if (languageId === 1) {
        setFieldValidityOnSubmit((prev: any) => {
          return { ...prev, description: false };
        });
      }

      setGeneralInfo({
        ...generalInfo,
        descriptions: generalInfo.descriptions.map((description) => {
          if (description.languageId === languageId) {
            description.text = newValue;
          }
          return description;
        }),
      });
    };

  const handleSpecificationPartAdd = (part: Lookup) => {
    if (specification && specification?.length > 0) {
      const orderNumbers: number[] = specification.map(
        (part) => part.partOrder
      );

      const maxOrder: number = Math.max(...orderNumbers) || 0;

      const newSpecification: BoatSpecificationPart[] = [
        ...specification,
        { partId: part.id, value: null, partOrder: maxOrder + 1 },
      ];

      setHasUnsavedChanges(true);
      setSpecification(newSpecification);
    } else {
      setHasUnsavedChanges(true);
      setSpecification([{ partId: part.id, value: null, partOrder: 1 }]);
    }
  };

  const [
    removeSpecificationFromBoat,
    {
      isError: isRemoveSpecificationFromBoatError,
      isLoading: isRemoveSpecificationFromBoatLoading,
    },
  ] = useDeleteBoatSpecificationMutation();

  const handleSpecificationPartDelete = async (
    part: BoatSpecificationPart & {
      partId: number | null;
      inEdit: string | null;
    }
  ) => {
    try {
      await removeSpecificationFromBoat({
        id: part.partId,
        boatId: generalInfo.id as number,
      }).unwrap();

      if (
        !isRemoveSpecificationFromBoatError &&
        !isRemoveSpecificationFromBoatLoading
      ) {
        handleUserActionNotification({
          message: 'Specification part removed from boat successfully!',
          type: 'success',
          autoClose: 2500,
        });
        setSpecification(
          specification
            ? specification.filter((spec) => spec.partId !== part.partId)
            : []
        );
      }
    } catch (error: any) {
      handleUserActionNotification({
        message: `${error.data.message}`,
        type: 'error',
        autoClose: 2500,
      });
    }
  };

  const handleCancelChanges = () => handleGoToList();

  const addBoatPhotoF = (data: any): any => dispatch(addBoatPhoto(data));

  useEffect(() => {
    // We check if we are making a copy of existing boat.
    // If we are, this.props.location.state.isCopy will be set to true.
    // We remove all data not applicable to new boats (ID and preview URL), and set status to DRAFT.
    // We also remove brochures, offers and photos because we can't save them on Azure
    // without knowing new boat's ID.
    let locationState = location.state as any;

    if (params.id && isAddedBoat === true) {
      navigateFromAddForm();
      handleGoToList();
    }

    if (!params.id && props.boatState === boatStates.CHARTER) {
      setSpecification(cloneDeep(defaultSpecificationPartsTemplate as any));
    }

    if (locationState && locationState?.isCopy) {
      setValue(0);
      setIsWindowVisible(false);
      setHasUnsavedChanges(true);

      setGeneralInfo((prev) => {
        return {
          ...prev,
          id: null,
          previewUrl: null,
          status: boatStatuses.DRAFT,
          brochures: [
            { languageId: 1, languageCode: 'En', id: null, fileName: null },
            { languageId: 2, languageCode: 'Hr', id: null, fileName: null },
          ],
          offers: [
            { languageId: 1, languageCode: 'En', id: null, fileName: null },
            { languageId: 2, languageCode: 'Hr', id: null, fileName: null },
          ],
        };
      });

      setPhotos([]);
    }

    if (boatDetailsData !== undefined && boatDetailsData !== null) {
      const {
        specification: boatSpec,
        equipment: boatEquip,
        prices: boatPrices,
        bases: boatBases,
        extras: boatExtras,
        discounts: boatDiscounts,
        specialOffers: boatSpecialOffers,
        ...rest
      } = handleCloningEntity(boatDetailsData);

      setGeneralInfo(rest);
      setSpecification(boatSpec);
      setEquipment(boatEquip);
      setPrices(boatPrices ? boatPrices : []);
      setBases(boatBases ? boatBases : []);
      setExtras(boatExtras ? boatExtras : []);
      setDiscounts(boatDiscounts ? boatDiscounts : []);
      setSpecialOffers(boatSpecialOffers ? boatSpecialOffers : []);
      dispatch(getBoatDetailsSuccess(boatDetailsData));
    }

    if (boatDetailsData) {
      const preparePhotoArrayCopy = handleCloningEntity(boatDetailsData.photos);
      setPhotos(preparePhotoArrayCopy);
    }

    if (
      allModelVariations &&
      allModelVariations?.length === 0 &&
      boatDetailsData &&
      boatDetailsData.modelId
    ) {
      getModelVariationsForDropDown(boatDetailsData.modelId);
    }

    // There is a chance that languages are not yet retrieved from API
    // in the moment when this class is mounting, so its localized array
    // could be empty. We are therefore checking if that is the case
    // and repopulating localized array with appropriate values when
    // languages come from the API.

    if (
      languagesState.languages?.length > 0 &&
      generalInfo.descriptions?.length === 0
    ) {
      setGeneralInfo((prev) => {
        return { ...prev, descriptions: buildLocalizedValuesArray() };
      });
    }
  }, [
    handleGoToList,
    params.id,
    isAddedBoat,
    generalInfo.descriptions?.length,
    location.state,
    boatDetailsData,
    allModelVariations,
    getModelVariationsForDropDown,
    languagesState,
    handleCloningEntity,
    dispatch,
    defaultSpecificationPartsTemplate,
    props.boatState,
  ]);

  const handleStateChange =
    (stateKey: string, languageCode?: string) =>
    (changes: any, _formUtils: any) => {
      if (changes.type === FormElementStateChangeTypes.change) {
        if (stateKey === 'manufacturerId') {
          const manufacturer: Lookup | undefined = allManufacturers
            ? allManufacturers.find((x) => x.id === changes.value)
            : undefined;

          setHasUnsavedChanges(true);
          setGeneralInfo({
            ...generalInfo,
            manufacturerId: changes.value,
            manufacturer:
              manufacturer && manufacturer.name ? manufacturer.name : null,
            modelId: null,
            model: null,
            modelVariationId: null,
            modelVariation: null,
            subvariation: null,
          });

          getModelsForDropDown(changes.value);
        } else if (stateKey === 'modelId') {
          const model: Lookup | undefined = allModels
            ? allModels.find((x) => x.id === changes.value)
            : undefined;

          setHasUnsavedChanges(true);
          setGeneralInfo({
            ...generalInfo,
            modelId: changes.value,
            model: model && model.name ? model.name : null,
            modelVariationId: null,
            modelVariation: null,
            subvariation: null,
          });

          getModelVariationsForDropDown(changes.value);
        } else if (stateKey === 'modelVariationId') {
          const modelVariation: ModelVariationLookup | undefined =
            allModelVariations
              ? allModelVariations.find((x) => x.id === changes.value)
              : undefined;

          setHasUnsavedChanges(true);
          setGeneralInfo({
            ...generalInfo,
            modelVariationId: changes.value,
            modelVariation:
              modelVariation && modelVariation.name
                ? modelVariation.name
                : null,
            subvariation: modelVariation ? modelVariation.subvariation : null,
          });
        } else if (stateKey === 'descriptions' && languageCode !== undefined) {
          const changedDescriptions: Translation[] =
            generalInfo.descriptions.map((d) => {
              if (d.languageCode === languageCode) {
                d.text = changes.value;
              }
              return d;
            });

          setHasUnsavedChanges(true);
          setGeneralInfo({
            ...generalInfo,
            descriptions: changedDescriptions,
          });
        } else if (stateKey === 'companyId') {
          const companyDropDown: Lookup | undefined = companiesForDropDown
            ? companiesForDropDown.find((x: any) => x.id === changes.value)
            : undefined;

          setHasUnsavedChanges(true);
          setGeneralInfo({
            ...generalInfo,
            companyId: changes.value,
            company:
              companyDropDown && companyDropDown.name
                ? companyDropDown.name
                : null,
          });
        } else if (stateKey === 'mainBase') {
          const baseDropDown: Lookup | undefined = basesForDropDown
            ? basesForDropDown.find((x: any) => x.id === changes.value)
            : undefined;

          setHasUnsavedChanges(true);
          setGeneralInfo({
            ...generalInfo,
            mainBaseId: changes.value,
            mainBase:
              baseDropDown && baseDropDown.name ? baseDropDown.name : null,
          });
        } else if (stateKey === 'defaultProduct') {
          const defaultProductDropDown: Lookup | undefined =
            defaultProductsForDropDown
              ? defaultProductsForDropDown.find(
                  (x: any) => x.id === changes.value
                )
              : undefined;

          setHasUnsavedChanges(true);
          setGeneralInfo({
            ...generalInfo,
            defaultProductId: changes.value,
            defaultProduct:
              defaultProductDropDown && defaultProductDropDown.name
                ? defaultProductDropDown.name
                : null,
          });
        } else if (stateKey === 'alternativeProducts') {
          setHasUnsavedChanges(true);
          setGeneralInfo({
            ...generalInfo,
            alternativeProducts: changes.value.map((item: any) => {
              return {
                product: item.product,
                productId: item.productId,
              };
            }),
          });
        } else {
          setHasUnsavedChanges(true);
          setGeneralInfo({
            ...generalInfo,
            [stateKey]: changes.value,
          });
        }
      }
    };

  const formElementStateReducer =
    (stateKey: string) => (state: any, changes: any) => {
      if (
        changes.type === stateChangeTypes.change &&
        state.elementType === elementTypes.dropDown
      ) {
        if (
          stateKey === 'type' ||
          stateKey === 'state' ||
          stateKey === 'status'
        ) {
          return {
            ...changes,
            value: changes.value.value,
            // in our current implementation changes.value for these elements is an object
            // so validations.valid equals to false before calling this state reducer
            // because FormElement's isValid() function expects a number.
            // that's why we set validations.valid here
            validations: {
              ...changes.validations,
              valid: isValidNumber(changes.value.value),
            },
          };
        }

        return { ...changes, value: changes.value.id };
      } else if (
        changes.type === stateChangeTypes.change &&
        state.elementType === elementTypes.datePicker
      ) {
        const formattedDate: string = formatDate(
          changes.value,
          'yyyy-MM-ddTHH:mm:ss'
        );

        return { ...changes, value: formattedDate };
      }

      return changes;
    };

  const handleEquipmentCategorySelect = useCallback(
    (dataItem: EquipmentCategoryLookup) => setSelectedCategory(dataItem),
    []
  );

  const handleReorderStateChange = (stateKey: string) => (changes: any) => {
    if (
      changes.type === Reorder.stateChangeTypes.rootOrParentReorder ||
      changes.type === Reorder.stateChangeTypes.childReorder
    ) {
      stateKey === 'specification' && setSpecification(changes.data);
      stateKey === 'equipment' && setEquipment(changes.data);

      setHasUnsavedChanges(true);
    }
  };

  const globalLocationState = location.state as any;

  const handleSpecificationPartValueChange = (event: GridItemChangeEvent) => {
    setHasUnsavedChanges(true);
    const modifiedSpecificationState = specification?.map(
      (part: BoatSpecificationPart & { [k: string]: any }) => {
        if (part.partId === event.dataItem.partId) {
          part[event.field as string] = event.value;
        }
        return part;
      }
    );
    setSpecification(modifiedSpecificationState as any[]);
  };

  const handlePhotosReorder = (type: PhotoType, newPhotos: BoatPhoto[]) => {
    setPhotos((prev) => {
      return (
        prev!.map((photo) => {
          const newPhotoInfo: BoatPhoto | undefined = newPhotos.find(
            (p) => p.id === photo.id
          );

          if (newPhotoInfo) {
            return {
              ...photo,
              type: type,
              order: newPhotoInfo.order,
            };
          }

          return photo;
        }) || []
      );
    });
  };

  const handlePhotoUrlChange = useCallback(
    (data: any) => {
      dispatch(changeBoatPhotoUrl(data));

      setPhotos(
        (prevState) =>
          prevState?.map((photo) => {
            if (photo.id === data.id) {
              return photo;
            }
            return photo;
          }) as BoatPhoto[]
      );
    },
    [dispatch]
  );

  const handlePriceChange = (event: GridItemChangeEvent) => {
    const field = String(event.field) || '';
    setHasUnsavedChanges(true);
    const modifiedPriceState = prices?.map((price) => {
      if (price.id === event.dataItem.id && price.tempId === undefined) {
        price[field as keyof BoatPrice] = event.value;
      } else if (
        price.tempId === event.dataItem.tempId &&
        event.dataItem.id === undefined
      ) {
        price[field as keyof BoatPrice] = event.value;
      }
      return price;
    });
    setPrices(modifiedPriceState as any[]);
  };

  const maxDate = (array: BoatPrice[] | BoatBase[]) => {
    const max = new Date(
      Math.max(
        ...(array.map((element) => {
          return new Date(element.dateTo as number | string | Date);
        }) as any)
      )
    );
    return new Date(max.setDate(max.getDate() + 1));
  };

  const addDay = (date: Date) => {
    return new Date(date.setDate(date.getDate() + 1));
  };

  const addNewPrice = (temp: number) => {
    let newItem: BoatPrice = {
      dateFrom:
        prices?.length !== 0
          ? maxDate(prices as BoatPrice[])
          : new Date(Date.now()),
      dateTo:
        prices?.length !== 0
          ? addDay(maxDate(prices as BoatPrice[]))
          : addDay(new Date(Date.now())),
      price: 1000,
      tempId: temp,
      inEdit: 'dateFrom',
    };

    setPrices([newItem, ...(prices as BoatPrice[])]);
    setHasUnsavedChanges(true);
  };

  const enterPriceEdit = (dataItem: any, field: string) => {
    exitEdit();

    if ('id' in dataItem && dataItem.id !== undefined) {
      setPriceInEdit(dataItem.id);
    } else {
      setPriceInEdit(dataItem.tempId);
    }
    setEditField(field);
  };

  const removePriceFromUi = (dataItem: any) => {
    if ('tempId' in dataItem && dataItem.tempId !== undefined) {
      const updatedPriceList = prices?.filter((singlePrice) => {
        return singlePrice.tempId !== dataItem.tempId;
      });
      setPrices(updatedPriceList as BoatPrice[]);
    } else {
      const updatedPriceList = prices?.filter((singlePrice) => {
        return singlePrice.id !== dataItem.id;
      });
      setPrices(updatedPriceList as BoatPrice[]);
    }

    handleUserActionNotification({
      message: 'Price removed successfully!',
      type: 'success',
      autoClose: 2500,
    });
  };

  const assignInEditKeyToListItemPrice = (priceList: BoatPrice[]) => {
    return (
      priceList.map((singlePrice) => {
        if (singlePrice.id === priceInEdit) {
          return { ...singlePrice, inEdit: editField };
        }
        if (singlePrice.tempId === priceInEdit) {
          return { ...singlePrice, inEdit: editField };
        }
        return singlePrice;
      }) || ([] as BoatPrice[])
    );
  };

  const handleBaseChange = (event: GridItemChangeEvent) => {
    const field = String(event.field) || '';
    setHasUnsavedChanges(true);
    const modifiedBaseState = bases?.map((base) => {
      if (base.id === event.dataItem.id && base.tempId === undefined) {
        base[field as keyof BoatBase] = event.value;
        const tmp: BoatBase = basesForDropDown.find(
          (item: any) => (item.name as string) === base['base']
        );
        base['baseId'] = tmp.id;
      } else if (
        base.tempId === event.dataItem.tempId &&
        event.dataItem.id === undefined
      ) {
        base[field as keyof BoatBase] = event.value;
        const tmp: BoatBase = basesForDropDown.find(
          (item: any) => (item.name as string) === base['base']
        );
        base['baseId'] = tmp.id;
      }
      return base;
    });
    setBases(modifiedBaseState as any[]);
    setHasUnsavedChanges(true);
  };

  const addNewBase = (temp: number) => {
    let newItem: BoatBase = {
      dateFrom:
        bases?.length !== 0
          ? maxDate(bases as BoatBase[])
          : new Date(Date.now()),
      dateTo:
        bases?.length !== 0
          ? addDay(maxDate(bases as BoatBase[]))
          : addDay(new Date(Date.now())),
      base: basesForDropDown !== null ? basesForDropDown[0].name : undefined,
      baseId: basesForDropDown !== null ? basesForDropDown[0].id : undefined,
      tempId: temp,
      inEdit: 'dateFrom',
    };

    setBases([newItem, ...(bases as BoatBase[])]);
    setHasUnsavedChanges(true);
  };

  const enterBaseEdit = (dataItem: any, field: string) => {
    exitEdit();

    if ('id' in dataItem && dataItem.id !== undefined) {
      setBaseInEdit(dataItem.id);
    } else {
      setBaseInEdit(dataItem.tempId);
    }
    setEditField(field);
  };

  const removeBaseFromUi = (dataItem: any) => {
    if ('tempId' in dataItem && dataItem.tempId !== undefined) {
      const updatedBaseList = bases?.filter((singleBase) => {
        return singleBase.tempId !== dataItem.tempId;
      });
      setBases(updatedBaseList as BoatBase[]);
    } else {
      const updatedBaseList = bases?.filter((singleBase) => {
        return singleBase.id !== dataItem.id;
      });
      setBases(updatedBaseList as BoatBase[]);
    }

    handleUserActionNotification({
      message: 'Base removed successfully!',
      type: 'success',
      autoClose: 2500,
    });
  };

  const assignInEditKeyToListItemBase = (baseList: BoatBase[]) => {
    return (
      baseList.map((singleBase) => {
        if (singleBase.id === baseInEdit) {
          return { ...singleBase, inEdit: editField };
        }
        if (singleBase.tempId === baseInEdit) {
          return { ...singleBase, inEdit: editField };
        }
        return singleBase;
      }) || ([] as BoatBase[])
    );
  };

  const handleScreenChange = (_event: React.ChangeEvent<{}>, value: number) =>
    setValue(value);

  // we remove partName and inEdit from dataItem because we added them to
  // Specification's props parts array for display only
  const removeExtraPropertiesAndReturnWrappedFunction =
    (wrappedFunction: any) => (dataItem: any) => {
      const { partName, inEdit, ...rest } = dataItem;

      return wrappedFunction(rest);
    };

  const handleSaveChanges = (defaultLanguage: string) => async () => {
    setHasUnsavedChanges(false);
    if (params.id) {
      if (validateForm(generalInfo, defaultLanguage)) {
        try {
          handlePromiseNotification(
            updateBoat({
              ...generalInfo,
              specification: specification,
              equipment: equipment,
              photos: photos,
              prices: prices,
              bases: bases,
              lockedBoat: undefined,
              lockedPhotos: undefined,
            }).unwrap(),
            {
              pending: { message: 'Saving...', type: 'info' },
              success: {
                message: 'Boat successfully updated!',
                type: 'success',
              },
              error: {
                message: 'Something went wrong with your request.',
                type: 'error',
              },
            }
          );
        } catch (error: any) {
          handleUserActionNotification({
            type: 'error',
            message: `${error.status} : \n ${error.error}`,
            autoClose: 2500,
          });
        }

        if (props.boatState !== boatStates.CHARTER) {
          const prepareData = [
            {
              boatId: generalInfo.id as number,
              languageId: 1,
              text: inventoryEn,
            },
            {
              boatId: generalInfo.id as number,
              languageId: 2,
              text: inventoryHr,
            },
            {
              boatId: generalInfo.id as number,
              languageId: 3,
              text: inventoryDe,
            },

            {
              boatId: generalInfo.id as number,
              languageId: 4,
              text: inventoryFr,
            },

            {
              boatId: generalInfo.id as number,
              languageId: 5,
              text: inventoryEs,
            },
          ];

          try {
            handlePromiseNotification(updateInventory(prepareData).unwrap(), {
              pending: { message: 'Saving...', type: 'info' },
              success: {
                message: 'Inventory successfully updated!',
                type: 'success',
              },
              error: {
                message: 'Something went wrong with your request.',
                type: 'error',
              },
            });
          } catch (error: any) {
            handleUserActionNotification({
              type: 'error',
              message: `${error.status} : \n ${error.error}`,
              autoClose: 2500,
            });
          }
        }

        setHasUnsavedChanges(false);
      } else {
        handleFormVerificationError(null);
        return;
      }
    } else {
      // we remove id from boat properties because of null errors from api
      const { id, mmkId, source, importedDate, ...rest } = generalInfo;
      if (validateForm(rest, defaultLanguage)) {
        try {
          await addBoat({
            ...rest,
            specification: specification,
            equipment: equipment,
            photos: photos,
            prices: prices,
            bases: bases,
          })
            .unwrap()
            .then((data: any) => handleGoToListNewBoat(data));
          handleUserActionNotification({
            type: 'success',
            message: 'Boat successfully added!',
            autoClose: 2500,
          });
        } catch (error: any) {
          if (error.status === '401') {
            navigateTo('/login');
            handleUserActionNotification({
              type: 'error',
              message: 'User session has expired, please log back in',
              autoClose: 2500,
            });
          } else {
            handleUserActionNotification({
              type: 'error',
              message: `${error.status} : \n ${error.error}`,
              autoClose: 2500,
            });
          }
        }

        // if (props.boatState !== boatStates.CHARTER) {
        //   const prepareData = inventoryData.map((singleItem: any) => {
        //     return {
        //       ...singleItem,
        //       text: singleItem.languageId === 1 ? inventoryEn : inventoryHr,
        //     };
        //   });

        //   try {
        //     handlePromiseNotification(updateInventory(prepareData).unwrap(), {
        //       pending: { message: 'Saving...', type: 'info' },
        //       success: {
        //         message: 'Inventory successfully updated!',
        //         type: 'success',
        //       },
        //       error: {
        //         message: 'Something went wrong with your request.',
        //         type: 'error',
        //       },
        //     });
        //   } catch (error: any) {
        //     handleUserActionNotification({
        //       type: 'error',
        //       message: `${error.status} : \n ${error.error}`,
        //       autoClose: 2500,
        //     });
        //   }
        // }

        setHasUnsavedChanges(false);
      } else {
        handleFormVerificationError(null);
        return;
      }
    }
  };

  const exitEdit = () => {
    setSpecificationPartInEdit(null);
    setPriceInEdit(null);
    setBaseInEdit(null);
    setEditField(undefined);

    setPrices((prevPrices) => {
      return prevPrices?.map((singlePrice) => {
        return { ...singlePrice, inEdit: undefined };
      }) as BoatPrice[];
    });

    setBases((prevBases) => {
      return prevBases?.map((singleBase) => {
        return { ...singleBase, inEdit: undefined };
      }) as BoatBase[];
    });
  };

  const enterEdit = (dataItem: any, field: string) => {
    if (dataItem.inEdit && field === editField) {
      return;
    }

    exitEdit();

    setSpecificationPartInEdit(dataItem.partId);
    setEditField(field);
  };

  const handlePhotoUpdate = async (data: BoatPhoto & { boatId: number }) => {
    await updateBoatPhoto(data).unwrap();

    const updatedBoatPhotosState =
      photos &&
      photos.map((singlePhoto) => {
        if (singlePhoto.id === data.id) {
          return data;
        }
        if (
          singlePhoto.id !== data.id &&
          singlePhoto.featuredType === data.featuredType &&
          singlePhoto.featuredType !== photoFeaturedTypes.NONE
        ) {
          return {
            ...singlePhoto,
            featuredType: photoFeaturedTypes.NONE,
          };
        }
        return singlePhoto;
      });
    setPhotos(updatedBoatPhotosState);
  };

  const handlePhotoDelete = async (data: {
    boatId: number;
    photoId: number;
  }) => {
    try {
      await deleteBoatPhoto({ boatId: data.boatId, photoId: data.photoId });

      const updatedBoatPhotosState =
        photos &&
        photos.filter((singlePhoto) => singlePhoto.id !== data.photoId);

      setPhotos(updatedBoatPhotosState);

      handleUserActionNotification({
        type: 'success',
        message: 'Photo deleted successfully',
        autoClose: 2500,
      });
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        message: `${error.status} : \n ${error.error}`,
        autoClose: 2500,
      });
    }
  };

  const handleFeaturedTypeChange = (
    photoId: number,
    event: DropDownListChangeEvent
  ) => {
    const newValue: any = event.target.value.value;

    setHasUnsavedChanges(true);

    const updatedBoatPhotosState =
      photos &&
      photos.map((singlePhoto) => {
        if (singlePhoto.id === photoId) {
          return {
            ...singlePhoto,
            featuredType: newValue,
          };
        }

        if (
          singlePhoto.id !== photoId &&
          singlePhoto.featuredType === newValue &&
          newValue !== photoFeaturedTypes.NONE
        ) {
          return {
            ...singlePhoto,
            featuredType: photoFeaturedTypes.NONE,
          };
        }

        return singlePhoto;
      });

    setPhotos(updatedBoatPhotosState);
  };

  const handleEquipmentPartAdd = (part: EquipmentPartLookup) => {
    if (!selectedCategory) {
      return;
    }

    let newEquipment: BoatEquipmentPart[] = [];

    // we have two different scenarios when adding new equipment parts:
    // 1. boat equipment doesn't exist or it is an empty array
    // 2. boat already has one or more equipment parts
    if (!equipment || equipment?.length === 0) {
      // id is 0 for option 'Select all'
      if (part.id === 0) {
        const nonSelectedEquipmentParts: EquipmentPartLookup[] =
          allEquipmentParts.filter(
            (eqPart) =>
              selectedCategory && eqPart.categoryId === selectedCategory.id
          );

        newEquipment = [
          ...newEquipment,
          ...nonSelectedEquipmentParts.map((eqPart, index) => ({
            partId: eqPart.id,
            partName: eqPart.name || '',
            partOrder: index + 1,
            categoryId: (selectedCategory as EquipmentCategoryLookup).id,
          })),
        ];
      } else {
        newEquipment = [
          ...newEquipment,
          {
            partId: part.id,
            partName: part.name || '',
            partOrder: 1,
            categoryId: selectedCategory.id,
          },
        ];
      }
    } else {
      // if boat equipment is not empty, we also have two possible scenarios
      // 2.1. new part is the first from its category so its partOrder gets assigned to 1
      // 2.2. new part belongs to the category that already has its parts among boat equipment
      //      so we have to find the maximum partOrder in order to assign a value to new part's partOrder

      const selectedCategoryId: number = selectedCategory.id;
      const boatEquipmentPartsFromSelectedCategory: BoatEquipmentPart[] =
        equipment.filter((ep) => ep.categoryId === selectedCategoryId);

      if (boatEquipmentPartsFromSelectedCategory?.length === 0) {
        // id is 0 for option 'Select all'
        if (part.id === 0) {
          const nonSelectedEquipmentParts: EquipmentPartLookup[] =
            allEquipmentParts.filter(
              (eqPart) =>
                selectedCategory && eqPart.categoryId === selectedCategory.id
            );

          newEquipment = [
            ...newEquipment,
            ...nonSelectedEquipmentParts.map((eqPart, index) => ({
              partId: eqPart.id,
              partName: eqPart.name || '',
              partOrder: index + 1,
              categoryId: (selectedCategory as EquipmentCategoryLookup).id,
            })),
          ];
        } else {
          newEquipment = [
            ...newEquipment,
            {
              partId: part.id,
              partName: part.name || '',
              partOrder: 1,
              categoryId: selectedCategory.id,
            },
          ];
        }
      } else {
        const orderNumbers: number[] =
          boatEquipmentPartsFromSelectedCategory.map((part) => part.partOrder);
        const maxOrder: number = Math.max(...orderNumbers) || 0;

        // id is 0 for option 'Select all'
        if (part.id === 0) {
          const selectedEquipmentPartsIds: number[] = equipment.map(
            (eqPart) => eqPart.partId
          );
          const nonSelectedEquipmentParts: EquipmentPartLookup[] =
            allEquipmentParts.filter(
              (eqPart) =>
                selectedCategory &&
                eqPart.categoryId === selectedCategory.id &&
                selectedEquipmentPartsIds.includes(eqPart.id) === false
            );

          newEquipment = [
            ...newEquipment,
            ...nonSelectedEquipmentParts.map((eqPart, index) => ({
              partId: eqPart.id,
              partName: eqPart.name || '',
              partOrder: maxOrder + index + 1,
              categoryId: (selectedCategory as EquipmentCategoryLookup).id,
            })),
          ];
        } else {
          newEquipment = [
            ...newEquipment,
            {
              partId: part.id,
              partName: part.name || '',
              partOrder: maxOrder + 1,
              categoryId: selectedCategory.id,
            },
          ];
        }
      }
    }

    setHasUnsavedChanges(true);
    const updatedEquipment = [...(equipment || []), ...newEquipment];
    setEquipment(updatedEquipment);
  };

  const handleEquipmentPartDelete = (part: BoatEquipmentPart) => {
    setHasUnsavedChanges(true);

    if (generalInfo.id) {
      try {
        handlePromiseNotification(
          deleteEquipmentPartReq({
            id: part.partId,
            boatId: generalInfo.id as number,
          }),
          {
            pending: { message: 'Saving...', type: 'info' },
            success: {
              message: 'Part deleted successfully!',
              type: 'success',
            },
            error: {
              message: 'Something went wrong with your request.',
              type: 'error',
            },
          }
        );
      } catch (error: any) {
        handleUserActionNotification({
          message: error.data.message,
          autoClose: 2500,
          type: 'error',
        });
      }
    }

    const updatedEquipment =
      equipment &&
      equipment.filter((equipmentItem) => equipmentItem.partId !== part.partId);
    setEquipment(updatedEquipment);
  };

  let boatEquipmentPartForSelectedCategoryIds: number[] = [];
  if (equipment && selectedCategory) {
    const selectedCategoryId: number = selectedCategory.id;
    boatEquipmentPartForSelectedCategoryIds = equipment
      .filter((ep) => ep.categoryId === selectedCategoryId)
      .map((ep) => ep.partId);
  }

  let boatSpecificationPartsIds: number[] = [];
  if (specification !== null && specification?.length !== 0) {
    boatSpecificationPartsIds = specification.map((part) => part.partId);
  }

  const [getRegenerateSearchIndexes] = useLazyGetRegenerateSearchIndexesQuery();

  const handleRegenerate = () => {
    try {
      handlePromiseNotification(getRegenerateSearchIndexes().unwrap(), {
        pending: { message: 'Saving...', type: 'info' },
        success: {
          message: 'Public data generated successfully!',
          type: 'success',
        },
        error: {
          message: 'Something went wrong with your request.',
          type: 'error',
        },
      });
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        message: `${error.status} : \n ${error.error}`,
        autoClose: 2500,
      });
    }
  };

  return (
    <>
      <ReactRouterPrompt when={hasUnsavedChanges}>
        {({ isActive, onConfirm, onCancel }) => (
          <CustomDialog
            open={isActive}
            title='You have unsaved changes. Are you sure you want to leave?'
            confirmText='Confirm'
            onClose={onCancel}
            onConfirm={onConfirm}
          />
        )}
      </ReactRouterPrompt>

      <MaterialGrid
        container
        position='fixed'
        top={16.6}
        right={16.6}
        zIndex={1201}
        justifyContent='flex-end'
        alignItems='center'
        spacing={2}
      >
        <MaterialGrid item>
          <button onClick={handleRegenerate} className='light-button'>
            Generate public data
          </button>
        </MaterialGrid>
      </MaterialGrid>

      <MaterialGrid
        justifyContent='space-between'
        flexWrap='nowrap'
        container
        direction={'row'}
        width={'auto'}
        spacing={2}
      >
        <MaterialGrid
          item
          container
          width={'70vw'}
          direction={'row'}
          justifyContent='flex-start'
          flexWrap='nowrap'
          alignItems='center'
          spacing={1}
        >
          <MaterialGrid item>
            <div className='back-button' onClick={handleGoToList}>
              <ArrowBackIcon />
            </div>
          </MaterialGrid>
          <MaterialGrid item>
            <div className='page-title'>
              {params.id
                ? `Edit ${generalInfo.model || ''} ${
                    (generalInfo.modelVariation &&
                      generalInfo.modelVariation) ||
                    ''
                  } ${generalInfo.subvariation || ''}`
                : 'New boat'}
            </div>
          </MaterialGrid>
        </MaterialGrid>

        <MaterialGrid
          container
          item
          justifyContent={'flex-end'}
          alignItems={'center'}
          spacing={4}
        >
          <MaterialGrid item>
            <div className='import-lock-container'>
              <ImportIcon className='import-icon' />

              <LockIcon className='lock-icon' />

              <KendoCheckbox
                value={generalInfo.lockedBoat}
                label={'Data'}
                onChange={() => null}
              />
              <KendoCheckbox
                value={generalInfo.lockedPhotos}
                label={'Photos'}
                onChange={() => null}
              />
            </div>
          </MaterialGrid>
          <MaterialGrid item>
            <FormControlLabel
              control={
                <Switch
                  checked={generalInfo.isDisabled as boolean}
                  onChange={handleDisabledToggle}
                />
              }
              label='DISABLE'
            />
          </MaterialGrid>
        </MaterialGrid>
      </MaterialGrid>

      <MaterialGrid
        item
        xs={12}
        container
        justifyContent='flex-end'
        alignItems='center'
        spacing={2}
      >
        {params.id && (
          <MaterialGrid item>
            <button onClick={toggleWindowVisible} className='orange-button'>
              Copy
            </button>
          </MaterialGrid>
        )}

        {params.id &&
          generalInfo.previewUrl &&
          generalInfo.previewUrl !== '' &&
          generalInfo.isDisabled === false && (
            <MaterialGrid item>
              <a href={generalInfo.previewUrl}>
                <button className='blue-button'>Preview</button>
              </a>
            </MaterialGrid>
          )}

        <MaterialGrid item>
          <FormElement
            isLoaded={generalInfo.id !== null}
            initialElementType={elementTypes.dropDown}
            initialValue={
              generalInfo.status
                ? boatStatuses.properties[generalInfo.status]
                : null
            }
            value={
              generalInfo.status && boatStatuses.properties[generalInfo.status]
                ? boatStatuses.properties[generalInfo.status].value
                : null
            }
            onStateChange={handleStateChange('status')}
            stateReducer={formElementStateReducer('status')}
          >
            {(formElementUtils: FormElementUtils) => (
              <DropDownList
                data={Object.values(boatStatuses.properties)}
                dataItemKey='value'
                textField='name'
                value={
                  generalInfo.status
                    ? boatStatuses.properties[generalInfo.status]
                    : null
                }
                onChange={formElementUtils.change}
                required
              />
            )}
          </FormElement>
        </MaterialGrid>
      </MaterialGrid>

      {props.boatState === boatStates.CHARTER ? (
        <Tabs
          value={value}
          className='tabsRoot'
          onChange={handleScreenChange}
          variant='scrollable'
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label='General' className='tab' />
          <Tab label='Specification' className='tab' />
          <Tab label='Inventory' className='tab' />
          <Tab label='Photos' disabled={!params.id} className='tab' />
          {generalInfo.mmkId === null ? (
            <Tab label='Prices' className='tab' disabled={!params.id} />
          ) : (
            <Tab label={null} style={{ display: 'none' }} />
          )}
          <Tab label='Bases' className='tab' disabled={!params.id} />
          <Tab label='Extras' className='tab' disabled={!params.id} />
          {generalInfo.mmkId === null ? (
            <Tab label='Discounts' className='tab' disabled={!params.id} />
          ) : (
            <Tab label={null} style={{ display: 'none' }} />
          )}

          {generalInfo.source !== 'Mmk' ? (
            <Tab label='Special offers' className='tab' disabled={!params.id} />
          ) : (
            <Tab label={null} style={{ display: 'none' }} />
          )}
        </Tabs>
      ) : (
        <Tabs
          value={value}
          className='tabsRoot'
          onChange={handleScreenChange}
          variant='scrollable'
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label='General' className='tab' />
          <Tab label='Specification' className='tab' />
          <Tab label='Inventory' className='tab' disabled={!params.id} />
          <Tab label='Photos' disabled={!params.id} className='tab' />
        </Tabs>
      )}

      {isBoatDetailsFetching ? (
        <KendoLoader />
      ) : (
        <div className='page-content'>
          {value === 0 && (
            <>
              <GeneralInformation
                hasUnsavedChangesFlag={hasUnsavedChanges}
                fieldValidityOnSubmit={fieldValidityOnSubmit}
                isInEditMode={params.id ? true : false}
                isInCopyMode={
                  globalLocationState && globalLocationState.isCopy
                    ? true
                    : false
                }
                classes=''
                allManufacturers={allManufacturers as Lookup[]}
                allModels={allModels as Lookup[]}
                allModelVariations={
                  allModelVariations as ModelVariationLookup[]
                }
                defaultLanguage={languagesState.defaultLanguage}
                id={generalInfo.id}
                name={generalInfo.name}
                manufacturer={{
                  id: generalInfo.manufacturerId,
                  name: generalInfo.manufacturer,
                }}
                model={{
                  id: generalInfo.modelId,
                  name: generalInfo.model,
                }}
                modelVariation={{
                  id: generalInfo.modelVariationId,
                  name: generalInfo.modelVariation,
                }}
                subvariation={generalInfo.subvariation}
                type={generalInfo.type}
                state={generalInfo.state}
                produced={generalInfo.produced}
                lengthOverAll={generalInfo.lengthOverAll}
                berths={generalInfo.berths}
                cabins={generalInfo.cabins}
                draft={generalInfo?.draft}
                salePrice={generalInfo.salePrice}
                salePriceHasTax={generalInfo.salePriceHasTax}
                promotionalPrice={generalInfo.promotionalPrice}
                availableFrom={generalInfo.availableFrom}
                youTubeVideoUrl={generalInfo.youTubeVideoUrl}
                virtualTourUrl={generalInfo.virtualTourUrl}
                descriptions={generalInfo.descriptions}
                brochures={generalInfo.brochures}
                offers={generalInfo.offers}
                allCompanies={companiesForDropDown}
                company={{
                  id: generalInfo.companyId,
                  name: generalInfo.company,
                }}
                source={generalInfo.source}
                mmkId={generalInfo.mmkId}
                importedDate={generalInfo.importedDate}
                allBases={basesForDropDown}
                mainBase={{
                  id: generalInfo.mainBaseId,
                  name: generalInfo.mainBase,
                }}
                deposit={generalInfo.deposit}
                allDefaultProducts={defaultProductsForDropDown}
                defaultProduct={{
                  id: generalInfo.defaultProductId,
                  name: generalInfo.defaultProduct,
                }}
                allAlternativeProducts={alternativeProductsForMultiSelect}
                alternativeProducts={generalInfo.alternativeProducts}
                toilets={generalInfo.toilets}
                hasSkipperCabin={generalInfo.hasSkipperCabin}
                isLuxury={generalInfo.isLuxury}
                checkInTime={generalInfo.checkInTime}
                checkOutTime={generalInfo.checkOutTime}
                maximumDiscount={generalInfo.maximumDiscount}
                handleStateChange={handleStateChange}
                handleTextInputChange={handleTextInputChange}
                handleDescriptionChange={handleDescriptionChange}
                formElementStateReducer={formElementStateReducer}
              />
            </>
          )}

          {value === 1 && (
            <Reorder
              data={
                specification !== null && specification?.length !== 0
                  ? specification
                  : []
              }
              identifierKey='partId'
              orderKey='partOrder'
              onReorder={handleReorderStateChange('specification')}
            >
              {(reorderUtils: UseReorderUtils) => (
                <Specification
                  boatSpecificationParts={
                    specification !== null && specification?.length !== 0
                      ? specification.map((part) => {
                          return {
                            ...part,
                            partName: (
                              allSpecificationParts.find(
                                (sp) => sp.id === part.partId
                              ) || {
                                name: undefined,
                              }
                            ).name,
                            inEdit:
                              part.partId === specificationPartInEdit
                                ? editField || null
                                : null,
                          };
                        })
                      : []
                  }
                  otherSpecificationParts={allSpecificationParts.filter(
                    (part) =>
                      boatSpecificationPartsIds &&
                      boatSpecificationPartsIds.includes(part.id) === false
                  )}
                  handleAdd={handleSpecificationPartAdd}
                  handleDelete={handleSpecificationPartDelete}
                  handleValueChange={handleSpecificationPartValueChange}
                  handleItemDragStart={removeExtraPropertiesAndReturnWrappedFunction(
                    reorderUtils.dragStart
                  )}
                  handleItemReorder={reorderUtils.rootOrParentReorder}
                  enterEdit={enterEdit}
                  exitEdit={exitEdit}
                  source={generalInfo.source}
                />
              )}
            </Reorder>
          )}

          {value === 2 &&
            (props.boatState !== boatStates.CHARTER ? (
              <Inventory
                inventoryEn={
                  inventoryData !== undefined &&
                  inventoryData !== null &&
                  inventoryData?.length !== 0
                    ? inventoryData?.find((item: any) => item.languageId === 1)
                        ?.text
                    : ''
                }
                inventoryHr={
                  inventoryData !== undefined &&
                  inventoryData !== null &&
                  inventoryData?.length !== 0
                    ? inventoryData?.find((item: any) => item.languageId === 2)
                        ?.text
                    : ''
                }
                inventoryDe={
                  inventoryData !== undefined &&
                  inventoryData !== null &&
                  inventoryData?.length !== 0
                    ? inventoryData?.find((item: any) => item.languageId === 3)
                        ?.text
                    : ''
                }
                inventoryFr={
                  inventoryData !== undefined &&
                  inventoryData !== null &&
                  inventoryData?.length !== 0
                    ? inventoryData?.find((item: any) => item.languageId === 4)
                        ?.text
                    : ''
                }
                inventoryEs={
                  inventoryData !== undefined &&
                  inventoryData !== null &&
                  inventoryData?.length !== 0
                    ? inventoryData?.find((item: any) => item.languageId === 5)
                        ?.text
                    : ''
                }
                setInventoryEn={setInventoryEn}
                setInventoryHr={setInventoryHr}
                setInventoryDe={setInventoryDe}
                setInventoryFr={setInventoryFr}
                setInventoryEs={setInventoryEs}
              />
            ) : (
              <Reorder
                data={
                  equipment?.length !== 0 &&
                  equipment !== null &&
                  equipment !== undefined
                    ? equipment
                    : []
                }
                identifierKey='partId'
                orderKey='partOrder'
                parentKey='categoryId'
                onReorder={handleReorderStateChange('equipment')}
              >
                {(reorderUtils: UseReorderUtils) => (
                  <CharterInventory
                    boatEquipment={
                      equipment !== null && equipment !== undefined
                        ? equipment
                        : []
                    }
                    hasMMK={generalInfo.mmkId}
                    otherEquipmentCategories={allEquipmentCategories}
                    otherEquipmentParts={allEquipmentParts.filter(
                      (part: any) =>
                        selectedCategory &&
                        part.categoryId === selectedCategory.id &&
                        boatEquipmentPartForSelectedCategoryIds.includes(
                          part.id
                        ) === false
                    )}
                    classes=''
                    categorySelected={selectedCategory !== null}
                    handleCategorySelect={handleEquipmentCategorySelect}
                    handlePartAdd={handleEquipmentPartAdd}
                    handlePartDelete={handleEquipmentPartDelete}
                    handlePartDragStart={reorderUtils.dragStart}
                    handlePartReorder={reorderUtils.childReorder}
                  />
                )}
              </Reorder>
            ))}

          {value === 3 && (
            <Photos
              photos={photos}
              boatId={generalInfo.id}
              maxOrderNums={Object.values(photoTypes.properties).map(
                (type) => ({
                  type: type.value,
                  order: Math.max(
                    ...(photos || [])
                      .filter((p) => p.type === type.value)
                      .map((p) => p.order)
                  ),
                })
              )}
              handleReorder={handlePhotosReorder}
              handleAddPhoto={addBoatPhotoF}
              handleChangePhotoUrl={handlePhotoUrlChange}
              handleUpdatePhoto={handlePhotoUpdate}
              handleDeletePhoto={handlePhotoDelete}
              handleFeaturedTypeChange={handleFeaturedTypeChange}
              state={generalInfo.state}
              currentPhotoState={photos}
            />
          )}

          {value === 4 && generalInfo.mmkId === null && (
            <Prices
              data={assignInEditKeyToListItemPrice(prices as BoatPrice[])}
              boatId={generalInfo.id as number}
              enterEdit={enterPriceEdit}
              exitEdit={exitEdit}
              handleAddNewPrice={addNewPrice}
              handleValueChange={handlePriceChange}
              handleDelete={removePriceFromUi}
            />
          )}

          {value === 5 && (
            <Bases
              data={assignInEditKeyToListItemBase(bases as BoatBase[])}
              boatId={generalInfo.id as number}
              enterEdit={enterBaseEdit}
              exitEdit={exitEdit}
              handleAddNewBase={addNewBase}
              handleValueChange={handleBaseChange}
              handleDelete={removeBaseFromUi}
              hasMMK={generalInfo.mmkId}
            />
          )}

          {value === 6 && (
            <Extras
              boatExtras={extras}
              boatId={generalInfo.id}
              manualEntry={generalInfo.source}
            />
          )}

          {value === 7 && generalInfo.mmkId === null && (
            <Discounts
              boatDiscounts={discounts}
              boatId={generalInfo.id}
              manualEntry={generalInfo.source}
            />
          )}

          {value === 8 && (
            <SpecialOffers
              boatSpecialOffers={specialOffers}
              boatId={generalInfo.id}
              boatMmkId={generalInfo.mmkId}
              manualEntry={generalInfo.source}
            />
          )}
        </div>
      )}

      <MaterialGrid
        container
        spacing={1}
        justifyContent='flex-end'
        className='action-buttons-container'
      >
        <MaterialGrid item>
          <button onClick={handleCancelChanges} className='red-button'>
            Cancel
          </button>
        </MaterialGrid>
        <MaterialGrid item>
          <button
            onClick={handleSaveChanges(languagesState.defaultLanguage)}
            className='primary-button'
          >
            Save
          </button>
        </MaterialGrid>
      </MaterialGrid>

      <CustomDialog
        open={isWindowVisible}
        title='Are you sure you want to copy data to a new boat draft?'
        confirmText='Confirm'
        onClose={toggleWindowVisible}
        onConfirm={handleCopyToDraft}
      />
    </>
  );
};

export default React.memo(BoatDetailsContainer);
