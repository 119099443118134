// KENDO
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { Checkbox, Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { formatDate, parseDate } from '@telerik/kendo-intl';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
//

// COMPONENTS
import { Form, FormUtils } from '../../../components/Form/Form';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { FormGridElement } from '../../../components/FormGridElement/FormGridElement';
import { CommandCell } from '../../../components/CommandCell/CommandCell';
import KendoLoader from '../../../components/Loader/KendoLoader';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// TYPES
import { Discount, DiscountColumn } from '../../../types/typeDefinitions';
//

// HOOKS
import { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  useGetBasesForDropdownQuery,
  useGetDiscountsForDropdownQuery,
  useRemoveSingleDiscountMutation,
} from './discountsApiSlice';
import useNotifications from '../../../hooks/useNotifications';
import { useAppDispatch } from '../../../app/hooks';
import { removeDiscountFromUi } from './discountsStateSlice';
//

interface Props {
  data: Partial<Discount>[];
  isLoading: boolean;
  handleEdit: (dataItem: Partial<Discount>) => void;
  handleApply: (dataItem: Discount) => void;
  isWindowVisible: boolean;
  typeOfWindow: string;
  discountDataItem: Partial<Discount>;
  handleSubmit: (data: Partial<Discount>) => void;
  handleClose: () => void;
  generateTempId: () => number;
}

const DiscountsGrid = (props: Props) => {
  const { handleUserActionNotification, handlePromiseNotification } =
    useNotifications();

  const { data: discountsDropdownData } = useGetDiscountsForDropdownQuery();

  const { data: basesDropdownData } = useGetBasesForDropdownQuery();

  const handleApplyF = (dataItem: Discount) => props.handleApply(dataItem);

  const dispatch = useAppDispatch();

  const formatData = useCallback((data: DiscountColumn[] | any) => {
    if (data) {
      return data.map((item: any) => {
        item.bookingDateFrom = new Date(item.bookingDateFrom as Date);
        item.bookingDateTo = new Date(item.bookingDateTo as Date);
        item.sailingDateFrom = new Date(item.sailingDateFrom as Date);
        item.sailingDateTo = new Date(item.sailingDateTo as Date);

        item.sailingDateRange = `${formatDate(
          item.sailingDateFrom,
          'dd/MM/yyyy'
        )} - ${formatDate(item.sailingDateTo, 'dd/MM/yyyy')}`;
        item.bookingDateRange = `${formatDate(
          item.bookingDateFrom,
          'dd/MM/yyyy'
        )} - ${formatDate(item.bookingDateTo, 'dd/MM/yyyy')}`;
        item.daysRange = `${item.daysFrom} - ${item.daysTo}`;

        return item;
      });
    }
  }, []);

  const [deleteSingleDiscount] = useRemoveSingleDiscountMutation();

  const handleDeleteDiscount = (dataItem: Partial<Discount>) => {
    if (dataItem.appliedOn === 0) {
      try {
        if ('id' in dataItem) {
          handlePromiseNotification(
            deleteSingleDiscount(dataItem.id as number).unwrap(),
            {
              pending: { message: 'Processing...', type: 'info' },
              success: {
                message: 'Discount successfully deleted!',
                type: 'success',
              },
              error: {
                message: 'There was an error with your request.',
                type: 'error',
              },
            }
          );

          dispatch(removeDiscountFromUi(dataItem));
        } else {
          dispatch(removeDiscountFromUi(dataItem));
          handleUserActionNotification({
            message: 'Discount successfully deleted!',
            autoClose: 2500,
            type: 'success',
          });
        }
      } catch (error: any) {
        console.error(error);
        handleUserActionNotification({
          message: error.data.message,
          autoClose: 2500,
          type: 'error',
        });
      }
    } else {
      handleUserActionNotification({
        message:
          'Cannot delete a discount with boats applied to it! Please first remove boats through the apply window!',
        autoClose: 2500,
        type: 'error',
      });
    }
  };

  const columns = [
    { field: '_command', title: ' ' },
    { field: 'name', title: 'Name' },
    { field: 'percentage', title: 'Percentage(%)' },
    { field: 'type', title: 'Type' },
    { field: 'sailingDateRange', title: 'Sailing Dates' },
    { field: 'bookingDateRange', title: 'Booking Dates' },
    { field: 'daysRange', title: 'Days' },
    { field: 'appliedOn', title: 'Applied On' },
  ];

  const initialSort: Array<SortDescriptor> = [];

  const [sort, setSort] = useState(initialSort);

  const [dataItemInLocalState, setDataItemInLocalState] = useState<any>(
    props.discountDataItem
  );

  const [fieldValidityOnSubmit, setFieldValidityOnSubmit] = useState<any>({
    name: false,
    percentage: false,
    type: false,
    sailingDateFrom: false,
    sailingDateTo: false,
    bookingDateFrom: false,
    bookingDateTo: false,
    daysFrom: false,
    daysTo: false,
    availableInBase: false,
  });

  useEffect(() => {
    if (props.discountDataItem) {
      setDataItemInLocalState(props.discountDataItem);
    }
  }, [fieldValidityOnSubmit, props]);

  const forceValidityOnSubmit = (data: any) => {
    const tmp = Object.keys(fieldValidityOnSubmit);

    for (let i = 0; i < tmp?.length; i++) {
      if (data[tmp[i]] === '' || data[tmp[i]] === null) {
        setFieldValidityOnSubmit((prev: boolean[]) => {
          return { ...prev, [tmp[i]]: true };
        });
      }
    }
  };

  const VariationCell = (props: GridCellProps): ReactElement => {
    return (
      <td title={props.dataItem.name?.length > 50 && props.dataItem.name}>
        {props.dataItem.name?.length > 50
          ? props.dataItem.name.slice(0, 50) + '...'
          : props.dataItem.name}
      </td>
    );
  };

  return (
    <>
      <MaterialGrid width={'100%'} height={'100%'}>
        {props.isLoading && <KendoLoader />}
        <Grid
          style={{ height: '100%' }}
          scrollable='scrollable'
          sortable={true}
          resizable={true}
          data={orderBy(formatData(props.data as DiscountColumn[]), sort)}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
        >
          {columns.map((column, index) => {
            return (
              <Column
                field={column.field}
                title={column.title}
                key={index}
                width={column.field === '_command' ? 100 : undefined}
                sortable={column.field === '_command' ? false : true}
                groupable={column.field === '_command' ? false : true}
                filterable={column.field === '_command' ? false : true}
                resizable={column.field === '_command' ? false : true}
                cell={
                  column.field === '_command'
                    ? (cellProps) => (
                        <CommandCell
                          {...cellProps}
                          hasApplyCommand={true}
                          handleApply={handleApplyF}
                          hasEditCommand={true}
                          handleEdit={props.handleEdit}
                          hasDeleteCommand={true}
                          handleDelete={handleDeleteDiscount}
                        />
                      )
                    : column.field === 'name'
                    ? (cellProps) => <VariationCell {...cellProps} />
                    : undefined
                }
              />
            );
          })}
        </Grid>
      </MaterialGrid>

      {props.discountDataItem && (
        <Form
          data={dataItemInLocalState}
          requiredData={{ textFields: ['name'] }}
          onSubmit={props.handleSubmit}
          render={({
            data,
            onInputChange,
            onDateRangePickerChange,
            onDropDownChange,
            onSubmit,
          }: FormUtils) => (
            <CustomDialog
              open={props.isWindowVisible && props.typeOfWindow !== 'apply'}
              title={`${
                props.typeOfWindow === 'edit'
                  ? `Edit ${
                      props.discountDataItem.name &&
                      props.discountDataItem.name?.length > 70
                        ? props.discountDataItem.name?.slice(0, 70) + '...'
                        : props.discountDataItem.name
                    }`
                  : 'Add new discount'
              }`}
              onClose={() => {
                props.handleClose();
                setDataItemInLocalState({
                  name: '',
                  percentage: null,
                  type: '',
                  typeId: null,
                  sailingDateFrom: null,
                  sailingDateTo: null,
                  bookingDateFrom: null,
                  bookingDateTo: null,
                  daysFrom: null,
                  daysTo: null,
                  availableInBaseId: null,
                  availableInBase: '',
                  appliedTo: null,
                  includedInBasePrice: false,
                  excludesOtherDiscounts: false,
                  affectedByMaximum: false,
                  tempId: props.generateTempId(),
                  isToUpdate: true,
                });

                Object.keys(fieldValidityOnSubmit).forEach((key: string) => {
                  setFieldValidityOnSubmit((prev: any) => {
                    return { ...prev, [key]: false };
                  });
                });
              }}
              onConfirm={() => {
                onSubmit();
                forceValidityOnSubmit(data);
              }}
            >
              <FormGridElement
                component={Input}
                label={'Name*'}
                value={data.name}
                onChange={onInputChange('name')}
                additionalProps={{
                  valid: !fieldValidityOnSubmit.name,
                  validityStyles: fieldValidityOnSubmit.name,
                  onKeyDown: () =>
                    setFieldValidityOnSubmit((prev: any) => {
                      return { ...prev, name: false };
                    }),
                }}
              />
              <FormGridElement
                component={NumericTextBox}
                label={'Percentage(%)*'}
                value={data.percentage}
                onChange={onInputChange('percentage')}
                additionalProps={{
                  spinners: false,
                  valid: !fieldValidityOnSubmit.percentage,
                  validityStyles: fieldValidityOnSubmit.percentage,
                  onFocus: () =>
                    setFieldValidityOnSubmit((prev: any) => {
                      return { ...prev, percentage: false };
                    }),
                  errorMessage:
                    data.percentage !== null &&
                    (data.percentage < 0 || data.percentage > 100) &&
                    'Percentage must be between 0 and 100!',
                }}
              />
              <FormGridElement
                component={DropDownList}
                label={'Type*'}
                onChange={onDropDownChange('type')}
                additionalProps={{
                  data: discountsDropdownData || [],
                  dataItemKey: 'id',
                  textField: 'name',
                  valid: !fieldValidityOnSubmit.type,
                  validityStyles: fieldValidityOnSubmit.type,

                  onBlur: () =>
                    setFieldValidityOnSubmit((prev: any) => {
                      return { ...prev, type: false };
                    }),
                }}
                value={{ name: data.type, id: data.typeId }}
              />
              <FormGridElement
                component={DateRangePicker}
                label={'Sailing dates*'}
                value={{
                  start: data.sailingDateFrom
                    ? parseDate(data.sailingDateFrom, 'yyyy-MM-ddTHH:mm:ss')
                    : null,
                  end: data.sailingDateTo
                    ? parseDate(data.sailingDateTo, 'yyyy-MM-ddTHH:mm:ss')
                    : null,
                }}
                onChange={onDateRangePickerChange({
                  start: 'sailingDateFrom',
                  end: 'sailingDateTo',
                })}
                additionalProps={{
                  allowReverse: false,
                  format: 'dd/MM/yyyy',
                  onBlur: () =>
                    setFieldValidityOnSubmit((prev: boolean[]) => {
                      return {
                        ...prev,
                        sailingDateFrom: false,
                        sailingDateTo: false,
                      };
                    }),
                  startDateInputSettings: {
                    label: 'Sailing date from',
                    format: 'dd/MM/yyyy',
                    valid: !fieldValidityOnSubmit.sailingDateFrom,
                    validityStyles: fieldValidityOnSubmit.sailingDateFrom,
                  },
                  endDateInputSettings: {
                    label: 'Sailing date to',
                    format: 'dd/MM/yyyy',
                    valid: !fieldValidityOnSubmit.sailingDateTo,
                    validityStyles: fieldValidityOnSubmit.sailingDateTo,
                  },
                  errorMessage:
                    data.sailingDateTo !== null &&
                    data.sailingDateTo < data.sailingDateFrom &&
                    'Sailing date to must be equal to or greater than sailing date from!',
                }}
              />
              <FormGridElement
                component={DateRangePicker}
                label={'Booking dates*'}
                value={{
                  start: data.bookingDateFrom
                    ? parseDate(data.bookingDateFrom, 'yyyy-MM-ddTHH:mm:ss')
                    : null,
                  end: data.bookingDateTo
                    ? parseDate(data.bookingDateTo, 'yyyy-MM-ddTHH:mm:ss')
                    : null,
                }}
                additionalProps={{
                  allowReverse: false,
                  format: 'dd/MM/yyyy',
                  onBlur: () =>
                    setFieldValidityOnSubmit((prev: boolean[]) => {
                      return {
                        ...prev,
                        bookingDateFrom: false,
                        bookingDateTo: false,
                      };
                    }),

                  startDateInputSettings: {
                    label: 'Booking date from',
                    format: 'dd/MM/yyyy',
                    valid: !fieldValidityOnSubmit.bookingDateFrom,
                    validityStyles: fieldValidityOnSubmit.bookingDateFrom,
                  },
                  endDateInputSettings: {
                    label: 'Booking date to',
                    format: 'dd/MM/yyyy',
                    valid: !fieldValidityOnSubmit.bookingDateTo,
                    validityStyles: fieldValidityOnSubmit.bookingDateTo,
                  },
                  errorMessage:
                    data.bookingDateTo !== null &&
                    data.bookingDateTo < data.bookingDateFrom &&
                    'Booking date to must be equal to or greater than booking date from!',
                }}
                onChange={onDateRangePickerChange({
                  start: 'bookingDateFrom',
                  end: 'bookingDateTo',
                })}
              />
              <FormGridElement
                component={NumericTextBox}
                label={'Days from*'}
                value={data.daysFrom}
                onChange={onInputChange('daysFrom')}
                additionalProps={{
                  spinners: false,
                  valid: !fieldValidityOnSubmit.daysFrom,
                  validityStyles: fieldValidityOnSubmit.daysFrom,
                  onFocus: () =>
                    setFieldValidityOnSubmit((prev: boolean[]) => {
                      return { ...prev, daysFrom: false };
                    }),
                  errorMessage:
                    data.daysFrom !== null &&
                    data.daysFrom < 0 &&
                    'Days from cannot be negative!',
                }}
              />
              <FormGridElement
                component={NumericTextBox}
                label={'Days to*'}
                value={data.daysTo}
                onChange={onInputChange('daysTo')}
                additionalProps={{
                  spinners: false,
                  valid: !fieldValidityOnSubmit.daysTo,
                  validityStyles: fieldValidityOnSubmit.daysTo,
                  onFocus: () =>
                    setFieldValidityOnSubmit((prev: boolean[]) => {
                      return { ...prev, daysTo: false };
                    }),
                  errorMessage:
                    data.daysTo !== null &&
                    data.daysTo < data.daysFrom &&
                    'Days to must be equal to or greater than days from!',
                }}
              />
              <FormGridElement
                component={DropDownList}
                label={'Available in base*'}
                value={
                  basesDropdownData !== undefined
                    ? basesDropdownData.find(
                        (item: any) => item.id === data.availableInBaseId
                      )
                    : []
                }
                onChange={onDropDownChange('availableInBase')}
                additionalProps={{
                  data: basesDropdownData || [],
                  dataItemKey: 'id',
                  textField: 'name',
                  valid: !fieldValidityOnSubmit.availableInBase,
                  validityStyles: fieldValidityOnSubmit.availableInBase,
                  onFocus: () =>
                    setFieldValidityOnSubmit((prev: boolean[]) => {
                      return { ...prev, availableInBase: false };
                    }),
                }}
              />
              <FormGridElement
                component={Checkbox}
                label={'Included in base price'}
                value={data.isIncludedInBasePrice}
                onChange={onInputChange('isIncludedInBasePrice')}
              />
              <FormGridElement
                component={Checkbox}
                label={'Excludes other discounts'}
                value={data.doesExcludesOtherDiscounts}
                onChange={onInputChange('doesExcludesOtherDiscounts')}
              />
              <FormGridElement
                component={Checkbox}
                label={'Affected by maximum'}
                value={data.isAffectedByMaximum}
                onChange={onInputChange('isAffectedByMaximum')}
              />
            </CustomDialog>
          )}
        />
      )}
    </>
  );
};

export default DiscountsGrid;
