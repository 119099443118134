// KENDO
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { Checkbox, NumericTextBox, Input } from '@progress/kendo-react-inputs';
import { formatDate, parseDate } from '@telerik/kendo-intl';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import { CommandCell } from '../../../components/CommandCell/CommandCell';
import KendoLoader from '../../../components/Loader/KendoLoader';
import { FormGridElement } from '../../../components/FormGridElement/FormGridElement';
import { Form, FormUtils } from '../../../components/Form/Form';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
//

// HOOKS
import { useCallback, useState, useEffect, ReactElement } from 'react';
import {
  useGetExtrasForDropdownQuery,
  useRemoveSingleExtraMutation,
} from './extrasApiSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { removeExtraFromUi } from './extrasStateSlice';
import useNotifications from '../../../hooks/useNotifications';
import { useGetBasesForDropdownQuery } from '../Discounts/discountsApiSlice';
//

// TYPES
import {
  Extra,
  ExtraColumn,
  ExtrasGridProps,
} from '../../../types/typeDefinitions';
//

const INIT_LANG_STATE_SELECT = { id: 1, code: 'En', displayName: 'English' };

const ExtrasGrid = (props: ExtrasGridProps) => {
  const { handleUserActionNotification, handlePromiseNotification } =
    useNotifications();

  const { data: extrasDropdownData } = useGetExtrasForDropdownQuery();

  const { data: basesDropdownData } = useGetBasesForDropdownQuery();

  const handleApplyF = (dataItem: Extra) => props.handleApply(dataItem);

  const dispatch = useAppDispatch();

  // LANG SELECTOR AND STATE
  const allLanguagesList = useAppSelector(
    (state) => state.languagesState.data.languages
  );

  const [currentLanguageChosenForm, setCurrentLanguageChosenForm] =
    useState<any>(INIT_LANG_STATE_SELECT);

  const formatData = useCallback((data: ExtraColumn[] | any) => {
    if (data) {
      return data.map((item: any) => {
        item.sailingDateFrom = new Date(item.sailingDateFrom as Date);
        item.sailingDateTo = new Date(item.sailingDateTo as Date);

        item.sailingDateRange = `${formatDate(
          item.sailingDateFrom,
          'dd/MM/yyyy'
        )} - ${formatDate(item.sailingDateTo, 'dd/MM/yyyy')}`;
        item.validDaysRange = `${item.validDaysFrom} - ${item.validDaysTo}`;
        item.perPerson = item.isPerPerson ? 'yes' : 'no';
        item.required = item.isRequired ? 'yes' : 'no';

        return item;
      });
    }
  }, []);

  const [deleteSingleExtra] = useRemoveSingleExtraMutation();

  const handleDeleteExtra = (dataItem: Partial<Extra>) => {
    if (dataItem.appliedOn === 0) {
      try {
        if ('id' in dataItem) {
          handlePromiseNotification(
            deleteSingleExtra(dataItem.id as number).unwrap(),
            {
              pending: { message: 'Processing...', type: 'info' },
              success: {
                message: 'Extra successfully deleted!',
                type: 'success',
              },
              error: {
                message: 'There was an error with your request.',
                type: 'error',
              },
            }
          );

          dispatch(removeExtraFromUi(dataItem));
        } else {
          dispatch(removeExtraFromUi(dataItem));
          handleUserActionNotification({
            message: 'Extra successfully deleted!',
            autoClose: 2500,
            type: 'success',
          });
        }
      } catch (error: any) {
        console.error(error);
        handleUserActionNotification({
          message: error.data.message,
          autoClose: 2500,
          type: 'error',
        });
      }
    } else {
      handleUserActionNotification({
        message:
          'Cannot delete an extra with boats applied to it! Please first remove boats through the apply window!',
        autoClose: 2500,
        type: 'error',
      });
    }
  };

  const columns = [
    { field: '_command', title: ' ' },
    { field: 'name', title: 'Name' },
    { field: 'price', title: 'Price' },
    { field: 'timeUnit', title: 'Time Unit' },
    { field: 'perPerson', title: 'Per Person' },
    { field: 'sailingDateRange', title: 'Sailing Dates' },
    { field: 'validDaysRange', title: 'Valid Days' },
    { field: 'required', title: 'Required' },
    { field: 'appliedOn', title: 'Applied On' },
  ];

  const initialSort: Array<SortDescriptor> = [];

  const [sort, setSort] = useState(initialSort);

  const [dataItemInLocalState, setDataItemInLocalState] = useState<any>(
    props.extraDataItem
  );

  const [fieldValidityOnSubmit, setFieldValidityOnSubmit] = useState<any>({
    // name: false,
    translationEn: false,
    price: false,
    timeUnit: false,
    perPerson: false,
    sailingDateFrom: false,
    sailingDateTo: false,
    validDaysFrom: false,
    validDaysTo: false,
  });

  useEffect(() => {
    if (props.extraDataItem) {
      setDataItemInLocalState(props.extraDataItem);
    }
  }, [fieldValidityOnSubmit, props]);

  const forceValidityOnSubmit = (data: any) => {
    const tmp = Object.keys(fieldValidityOnSubmit);
    const tmpVal = Object.values(fieldValidityOnSubmit);

    for (let i = 0; i < tmp?.length; i++) {
      if (data[tmp[i]] === '' || data[tmp[i]] === null) {
        setFieldValidityOnSubmit((prev: boolean[]) => {
          return { ...prev, [tmp[i]]: true };
        });
      } else if (
        data.translations.find((tr: any) => tr.languageCode === 'En').text ===
        ''
      ) {
        setFieldValidityOnSubmit({
          ...fieldValidityOnSubmit,
          translationEn: true,
        });
      }
    }

    if (tmpVal.every((item) => item === false)) {
      setCurrentLanguageChosenForm(INIT_LANG_STATE_SELECT);
    }
  };

  const VariationCell = (props: GridCellProps): ReactElement => {
    return (
      <td title={props.dataItem.name?.length > 50 && props.dataItem.name}>
        {props.dataItem.name?.length > 50
          ? props.dataItem.name.slice(0, 50) + '...'
          : props.dataItem.name}
      </td>
    );
  };

  const engLangSelected = currentLanguageChosenForm.id === INIT_LANG_STATE_SELECT.id && currentLanguageChosenForm.code === INIT_LANG_STATE_SELECT.code
  const extraIsFromMMK = 'mmkId' in dataItemInLocalState && dataItemInLocalState.mmkId

  return (
    <>
      <MaterialGrid width={'100%'} height={'100%'}>
        {props.isLoading && <KendoLoader />}
        <Grid
          style={{ height: '100%' }}
          scrollable='scrollable'
          sortable={true}
          resizable={true}
          data={orderBy(formatData(props.data as ExtraColumn[]), sort)}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
        >
          {columns.map((column, index) => {
            return (
              <Column
                field={column.field}
                title={column.title}
                key={index}
                sortable={column.field === '_command' ? false : true}
                groupable={column.field === '_command' ? false : true}
                filterable={column.field === '_command' ? false : true}
                resizable={column.field === '_command' ? false : true}
                cell={
                  column.field === '_command'
                    ? (cellProps) => (
                        <CommandCell
                          {...cellProps}
                          isExtra={true}
                          hasApplyCommand={true}
                          handleApply={handleApplyF}
                          hasEditCommand={true}
                          handleEdit={props.handleEdit}
                          hasDeleteCommand={true}
                          handleDelete={handleDeleteExtra}
                        />
                      )
                    : column.field === 'name'
                    ? (cellProps) => <VariationCell {...cellProps} />
                    : undefined
                }
              />
            );
          })}
        </Grid>
      </MaterialGrid>

      {props.extraDataItem && (
        <Form
          data={dataItemInLocalState}
          // requiredData={{ textFields: ['name'] }}
          requiredData={{ textFields: null }}
          onSubmit={props.handleSubmit}
          render={({
            data,
            onInputChange,
            onDateRangePickerChange,
            onDropDownChange,
            onSubmit,
            onLocalizedInputChange,
          }: FormUtils) => {
            return (
              <CustomDialog
                open={props.isWindowVisible && props.typeOfWindow !== 'apply'}
                title={`${
                  props.typeOfWindow === 'edit'
                    ? `Edit ${
                        props.extraDataItem.name &&
                        props.extraDataItem.name?.length > 70
                          ? props.extraDataItem.name?.slice(0, 70) + '...'
                          : props.extraDataItem.name
                      }`
                    : 'Add new extra'
                }`}
                onClose={() => {
                  props.handleClose();
                  setDataItemInLocalState({
                    // name: '',
                    translations: [
                      {
                        languageId: 1,
                        languageCode: 'En',
                        text: '',
                      },
                      {
                        languageId: 2,
                        languageCode: 'Hr',
                        text: '',
                      },
                      {
                        languageId: 3,
                        languageCode: 'De',
                        text: '',
                      },
                      {
                        languageId: 4,
                        languageCode: 'Fr',
                        text: '',
                      },
                      {
                        languageId: 5,
                        languageCode: 'Es',
                        text: '',
                      },
                    ],
                    price: null,
                    sailingDateFrom: null,
                    sailingDateTo: null,
                    validDaysFrom: null,
                    validDaysTo: null,
                    tempId: props.generateTempId(),
                    isPerPerson: false,
                    isRequired: false,
                    isIncludedInBasePrice: false,
                    isPayableOnInovice: false,
                    availableInBaseId: null,
                    availableInBase: '',
                    appliedTo: null,
                    timeUnitId: null,
                    timeUnit: '',
                    isToUpdate: true,
                  });

                  Object.keys(fieldValidityOnSubmit).forEach((key: string) => {
                    setFieldValidityOnSubmit((prev: any) => {
                      return { ...prev, [key]: false };
                    });
                  });
                  setCurrentLanguageChosenForm(INIT_LANG_STATE_SELECT);
                }}
                onConfirm={() => {
                  onSubmit();

                  forceValidityOnSubmit(data);
                }}
              >
                <FormGridElement
                  component={DropDownList}
                  label='Extra translation'
                  additionalProps={{
                    data: allLanguagesList,
                    dataItemKey: 'id',
                    textField: 'displayName',
                    defaultValue: allLanguagesList.find(
                      (item) => item.id === currentLanguageChosenForm.id
                    ),
                  }}
                  onChange={(e: DropDownListChangeEvent) => {
                    setCurrentLanguageChosenForm(e.value);
                  }}
                />

                {dataItemInLocalState?.translations?.length > 0 &&
                  dataItemInLocalState.translations.map((translation: any) => {
                    if (
                      translation.languageCode ===
                      currentLanguageChosenForm.code
                    ) {
                      return (
                        <>
                          <FormGridElement
                            component={Input}
                            label={`Extra name (${translation.languageCode.toLowerCase()})${
                              translation.languageCode === 'En' ? '*' : ''
                            }`}
                            value={translation.text}
                            onChange={onLocalizedInputChange(
                              'translations',
                              translation.languageCode
                            )}
                            additionalProps={{
                              valid: !fieldValidityOnSubmit.translationEn,
                              validityStyles:
                                fieldValidityOnSubmit.translationEn,
                              onKeyDown: () =>
                                translation.languageCode === 'En' &&
                                setFieldValidityOnSubmit((prev: any) => {
                                  return { ...prev, translationEn: false };
                                }),
                              disabled: engLangSelected && extraIsFromMMK
                            }}
                          />
                          <hr
                            // className='divider-line'
                            style={{
                              display: 'block',
                              height: '0.063em',
                              border: '0',
                              borderTop: '0.063em solid #ccc',
                              width: '88%',
                              marginTop: '18px',
                            }}
                          />
                        </>
                      );
                    }
                    return <></>;
                  })}

                <FormGridElement
                  component={DropDownList}
                  label={'Time unit*'}
                  onChange={onDropDownChange('timeUnit')}
                  additionalProps={{
                    data: extrasDropdownData || [],
                    dataItemKey: 'id',
                    textField: 'name',
                    valid: !fieldValidityOnSubmit.timeUnit,
                    validityStyles: fieldValidityOnSubmit.timeUnit,
                    onFocus: () =>
                      setFieldValidityOnSubmit((prev: any) => {
                        return { ...prev, timeUnit: false };
                      }),
                    disabled: extraIsFromMMK
                  }}
                  value={{ name: data.timeUnit, id: data.timeUnitId }}
                />
                <FormGridElement
                  component={NumericTextBox}
                  label={'Price*'}
                  value={data.price}
                  onChange={onInputChange('price')}
                  additionalProps={{
                    spinners: false,
                    valid: !fieldValidityOnSubmit.price,
                    validityStyles: fieldValidityOnSubmit.price,
                    onFocus: () =>
                      setFieldValidityOnSubmit((prev: any) => {
                        return { ...prev, price: false };
                      }),
                    errorMessage:
                      data.price !== null &&
                      data.price < 0 &&
                      'Price cannot be negative!',
                    disabled: extraIsFromMMK
                  }}
                />
                <FormGridElement
                  component={DateRangePicker}
                  label={'Sailing dates*'}
                  value={{
                    start: data.sailingDateFrom
                      ? parseDate(data.sailingDateFrom, 'yyyy-MM-ddTHH:mm:ss')
                      : null,
                    end: data.sailingDateTo
                      ? parseDate(data.sailingDateTo, 'yyyy-MM-ddTHH:mm:ss')
                      : null,
                  }}
                  onChange={onDateRangePickerChange({
                    start: 'sailingDateFrom',
                    end: 'sailingDateTo',
                  })}
                  additionalProps={{
                    allowReverse: false,
                    format: 'dd/MM/yyyy',
                    onBlur: () =>
                      setFieldValidityOnSubmit((prev: boolean[]) => {
                        return {
                          ...prev,
                          sailingDateFrom: false,
                          sailingDateTo: false,
                        };
                      }),
                    startDateInputSettings: {
                      label: 'Sailing date from',
                      format: 'dd/MM/yyyy',
                      valid: !fieldValidityOnSubmit.sailingDateFrom,
                      validityStyles: fieldValidityOnSubmit.sailingDateFrom,
                    },
                    endDateInputSettings: {
                      label: 'Sailing date to',
                      format: 'dd/MM/yyyy',
                      valid: !fieldValidityOnSubmit.sailingDateTo,
                      validityStyles: fieldValidityOnSubmit.sailingDateTo,
                    },
                    errorMessage:
                      data.sailingDateTo !== null &&
                      data.sailingDateTo < data.sailingDateFrom &&
                      'Sailing date to must be equal to or greater than sailing date from!',
                    disabled: extraIsFromMMK
                  }}
                />

                <FormGridElement
                  component={NumericTextBox}
                  label={'Valid days from*'}
                  value={data.validDaysFrom ? data.validDaysFrom : null}
                  onChange={onInputChange('validDaysFrom')}
                  additionalProps={{
                    spinners: false,
                    valid: !fieldValidityOnSubmit.validDaysFrom,
                    validityStyles: fieldValidityOnSubmit.validDaysFrom,
                    onFocus: () =>
                      setFieldValidityOnSubmit((prev: boolean[]) => {
                        return { ...prev, validDaysFrom: false };
                      }),
                    errorMessage:
                      data.validDaysFrom !== null &&
                      data.validDaysFrom < 0 &&
                      'Valid days from cannot be negative!',
                    disabled: extraIsFromMMK
                  }}
                />

                <FormGridElement
                  component={NumericTextBox}
                  label={'Valid days to*'}
                  value={data.validDaysTo ? data.validDaysTo : null}
                  onChange={onInputChange('validDaysTo')}
                  additionalProps={{
                    valid: !fieldValidityOnSubmit.validDaysTo,
                    validityStyles: fieldValidityOnSubmit.validDaysTo,
                    onFocus: () =>
                      setFieldValidityOnSubmit((prev: boolean[]) => {
                        return { ...prev, validDaysTo: false };
                      }),
                    spinners: false,
                    errorMessage:
                      data.validDaysTo !== null &&
                      data.validDaysTo < data.validDaysFrom &&
                      'Valid days to must be equal to or greater than valid days from!',
                    disabled: extraIsFromMMK
                  }}
                />

                <FormGridElement
                  component={DropDownList}
                  label={'Available in base'}
                  value={
                    basesDropdownData !== undefined
                      ? basesDropdownData.find(
                          (item: any) => item.id === data.availableInBaseId
                        ) || { id: null, name: 'ANY' }
                      : []
                  }
                  onChange={onDropDownChange('availableInBase')}
                  additionalProps={{
                    data:
                      basesDropdownData !== undefined
                        ? [{ id: null, name: 'ANY' }, ...basesDropdownData]
                        : [],
                    dataItemKey: 'id',
                    textField: 'name',
                    disabled: extraIsFromMMK
                  }}
                />
                <FormGridElement
                  component={Checkbox}
                  label={'Per person*'}
                  value={data.isPerPerson}
                  onChange={onInputChange('isPerPerson')}
                  additionalProps={{
                    disabled: extraIsFromMMK
                  }}
                />
                <FormGridElement
                  component={Checkbox}
                  label={'Included in base price*'}
                  value={data.isIncludedInBasePrice}
                  onChange={onInputChange('isIncludedInBasePrice')}
                  additionalProps={{
                    disabled: extraIsFromMMK
                  }}
                />
                <FormGridElement
                  component={Checkbox}
                  label={'Payable on invoice*'}
                  value={data.isPayableOnInovice}
                  onChange={onInputChange('isPayableOnInovice')}
                  additionalProps={{
                    disabled: extraIsFromMMK
                  }}
                />
              </CustomDialog>
            );
          }}
        />
      )}
    </>
  );
};

export default ExtrasGrid;
