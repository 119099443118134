// KENDO
import { MultiSelect } from '@progress/kendo-react-dropdowns';
//

const MultiSelectCell = (props: any) => {
  return (
    <td>
      <MultiSelect
        {...props}
        placeholder={
          props.field === 'sailingAreas' ? 'Sailing Areas' : props.field
        }
        value={props.dataItem[props.field] || []}
        dataItemKey='id'
        className='inline-cell editable-cell'
        textField='name'
        onChange={(e) =>
          props.onChange({
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.value,
          })
        }
      />
    </td>
  );
};

export default MultiSelectCell;
