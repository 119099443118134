// TYPES
import { Enumeration } from '../../types/typeDefinitions';
//

type BoatSource = {
  ALL: 0;
  MANUAL: 1;
  MMK: 2;
};

const boatSource: Enumeration & BoatSource = {
  ALL: 0,
  MANUAL: 1,
  MMK: 2,
  properties: {
    0: { name: 'All sources', value: 0 },
    1: { name: 'Manual entry', value: 1 },
    2: { name: 'MMK', value: 2 },
  },
};

export default boatSource;
