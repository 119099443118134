// REACT
import React, { useReducer, ReactElement } from 'react';
//

// KENDO
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// ICONS
import { MdClose as CloseIcon } from 'react-icons/md';
//

// TYPES
import { GridFilter } from '../../types/typeDefinitions';
//

interface Props extends GridFilter {
  onChange: (changes: any, action: any) => void;
  onDelete: () => void;
  reducer?: (s: any, a: any) => any;
}

interface HookProps {
  inputType?: string;
  initialValue?: any;
  onChange?: any;
  reducer?: (s: any, a: any) => any;
  value?: any;
}

interface UseFilterUtils {
  filterValue: any;
  change: (newValue: any) => void;
}

const filterReducer: any = (state: any, action: any) => {
  switch (action.type) {
    case useFilter.stateChangeTypes.change: {
      return { ...state, value: action.value };
    }

    default:
      return state;
  }
};

// FILTER HOOK

function useFilter({
  inputType = useFilter.inputTypes.textInput,
  initialValue = inputType === useFilter.inputTypes.textInput ? '' : null,
  onChange = () => {},
  reducer = (_s: any, a: any) => a.changes,
  value: controlledValue,
}: HookProps = {}): UseFilterUtils {
  const combinedReducer: any = (state: any, action: any) => {
    const changes: any = filterReducer(state, action);
    return reducer(state, { ...action, changes });
  };

  const [state, dispatch]: [any, React.Dispatch<any>] = useReducer(
    combinedReducer,
    {
      value: initialValue,
    }
  );
  const value: any =
    controlledValue === undefined ? state.value : controlledValue;

  const dispatchWithOnChange: any = (action: any) => {
    dispatch(action);

    const changes: any = combinedReducer({ ...state, value }, action);
    onChange(changes, action);
  };

  const handleValueChange: any = (newValue: any) => {
    dispatchWithOnChange({
      type: useFilter.stateChangeTypes.change,
      inputType,
      value: newValue,
    });
  };

  return {
    filterValue: value,
    change: handleValueChange,
  };
}
useFilter.stateChangeTypes = {
  change: '__value_change__',
};
useFilter.inputTypes = {
  textInput: 'textInput',
  numberInput: 'numberInput',
  dropDownList: 'dropDownList',
};

// FILTER UI COMPONENT
const Filter: (props: Props) => any = ({
  placeholder,
  type,
  data,
  value,
  dataItemKey,
  textField,
  isDisabled,
  isRemovable,
  onChange = () => {},
  onDelete = () => {},
  reducer = (_s: any, a: any) => a.changes,
}: Props): any => {
  const { filterValue, change } = useFilter({
    inputType: type,
    initialValue: value,
    value,
    onChange,
    reducer,
  });

  const handleValueChange: (type: string) => (event: any) => void =
    (_type) => (event) => {
      change(event.target.value);
    };

  const renderComponent: () => ReactElement | null = () => {
    if (type === useFilter.inputTypes.numberInput) {
      return (
        <MaterialGrid item className='filter-item'>
          <NumericTextBox
            placeholder={placeholder}
            value={filterValue}
            onChange={handleValueChange(type)}
            spinners={false}
          />
        </MaterialGrid>
      );
    }

    if (type === useFilter.inputTypes.textInput) {
      return (
        <MaterialGrid item className='filter-item'>
          <Input
            placeholder={placeholder}
            value={filterValue}
            onChange={handleValueChange(type)}
          />
        </MaterialGrid>
      );
    }

    if (type === useFilter.inputTypes.dropDownList) {
      return (
        <MaterialGrid item className='filter-item'>
          <DropDownList
            data={data}
            textField={textField}
            dataItemKey={dataItemKey}
            value={filterValue}
            label={placeholder}
            disabled={isDisabled}
            onChange={handleValueChange(type)}
          />
        </MaterialGrid>
      );
    }

    return null;
  };

  const handleDelete: () => void = () => {
    onDelete();
  };

  return (
    <MaterialGrid container>
      <MaterialGrid item>{renderComponent()}</MaterialGrid>
      {isRemovable && (
        <button onClick={handleDelete} className='close-button'>
          <CloseIcon />
        </button>
      )}
    </MaterialGrid>
  );
};

export { Filter };
