// KENDO
import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
} from '@progress/kendo-react-grid';
//

// COMPONENTS
import { DragCell } from '../../../components/DragCell/DragCell';
import { CommandCell } from '../../../components/CommandCell/CommandCell';
import { LanguageCell } from '../../../components/LanguageCell/LanguageCell';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// SERVICES
import { rowRender } from '../../../services/gridRowRender';
//

// TYPES
import {
  DataState,
  SpecificationListItem,
  SpecificationPartsGridProps,
} from '../../../types/typeDefinitions';
//

// HOOKS
import { useAppDispatch } from '../../../app/hooks';
import { updateDataState } from './specificationPartsStateSlice';
import useNotifications from '../../../hooks/useNotifications';
import { useDeleteSpecificationPartMutation } from './specificationPartsApiSlice';
//

const SpecificationPartsGrid = (props: SpecificationPartsGridProps) => {
  const dispatch = useAppDispatch();

  const DragCellF = DragCell(props.handleDragStart, props.handleReorder);

  const handleDataStateChangeF = (event: GridDataStateChangeEvent) => {
    props.getSpecificationParts(event.dataState);
    dispatch(updateDataState(event.dataState as DataState));
  };

  const handleEditF = (dataItem: SpecificationListItem) =>
    props.handleEdit(dataItem);

  const { handleUserActionNotification } = useNotifications();

  const [
    deleteSpecificationPart,
    {
      isLoading: isDeleteSpecificationPartLoading,
      isError: isDeleteSpecificationPartError,
    },
  ] = useDeleteSpecificationPartMutation();

  const handleDeletePart = async (dataItem: any) => {
    try {
      await deleteSpecificationPart(dataItem.id).unwrap();

      if (
        !isDeleteSpecificationPartLoading &&
        !isDeleteSpecificationPartError
      ) {
        handleUserActionNotification({
          message: 'Specification part successfully deleted!',
          autoClose: 2500,
          type: 'success',
        });
      }
    } catch (error: any) {
      console.error(error);
      handleUserActionNotification({
        message: error.data.message,
        autoClose: 2500,
        type: 'error',
      });
    }
  };

  return (
    <MaterialGrid width={'100%'} height={'100%'}>
      <Grid
        style={{ height: '100%' }}
        scrollable='scrollable'
        data={props.specificationParts}
        {...props.dataState}
        sortable={true}
        resizable={true}
        onDataStateChange={handleDataStateChangeF}
        rowRender={rowRender}
      >
        <Column
          title=' '
          width={45}
          groupable={false}
          sortable={false}
          filterable={false}
          resizable={false}
          cell={DragCellF as any}
        />
        <Column
          field='_command'
          width={50}
          groupable={false}
          sortable={false}
          filterable={false}
          resizable={false}
          title=' '
          cell={(props) => (
            <CommandCell
              {...props}
              hasEditCommand={
                props.dataItem.name === 'Engine' ||
                props.dataItem.name === 'Fuel capacity' ||
                props.dataItem.name === 'Genoa' ||
                props.dataItem.name === 'Mainsail' ||
                props.dataItem.name === 'Water tank' ||
                props.dataItem.name === 'Hull beam'
                  ? false
                  : true
              }
              handleEdit={handleEditF}
              hasDeleteCommand={
                props.dataItem.name === 'Engine' ||
                props.dataItem.name === 'Fuel capacity' ||
                props.dataItem.name === 'Genoa' ||
                props.dataItem.name === 'Mainsail' ||
                props.dataItem.name === 'Water tank' ||
                props.dataItem.name === 'Hull beam'
                  ? false
                  : true
              }
              handleDelete={handleDeletePart}
            />
          )}
        />
        <Column field='id' title='ID' />
        <Column field='name' title='Name' />
        <Column
          field='numberOfTranslations'
          title='Name translations'
          sortable={false}
          cell={(props) => <LanguageCell {...props} />}
        />
      </Grid>
    </MaterialGrid>
  );
};

export default SpecificationPartsGrid;
