// KENDO
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { formatDate } from '@telerik/kendo-intl';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import { CommandCell } from '../../../../components/CommandCell/CommandCell';
//

// HOOKS
import { useCallback, useState } from 'react';
import useNotifications from '../../../../hooks/useNotifications';
import { useDeleteBoatExtraMutation } from '../boatsApiSlice';
//

const Extras = (props: any) => {
  const initialSort: Array<SortDescriptor> = [
    { field: 'validDaysRange', dir: 'desc' },
  ];

  const [
    removeExtraFromBoat,
    {
      isError: isRemoveExtraFromBoatError,
      isLoading: isRemoveExtraFromBoatLoading,
    },
  ] = useDeleteBoatExtraMutation();

  // const navigateTo = useNavigate();

  const [sort, setSort] = useState(initialSort);
  const [extrasList, setExtrasList] = useState<any[]>(props.boatExtras || []);

  const { handleUserActionNotification } = useNotifications();

  // const dispatch = useAppDispatch();

  const formatData = useCallback((data: any) => {
    if (data) {
      return data.map((item: any) => {
        item.sailingDateFrom = new Date(item.sailingDateFrom as Date);
        item.sailingDateTo = new Date(item.sailingDateTo as Date);

        item.sailingDateRange = `${formatDate(
          item.sailingDateFrom as Date,
          'dd/MM/yyyy'
        )} - ${formatDate(item.sailingDateTo as Date, 'dd/MM/yyyy')}`;

        item.validDaysRange = `${item.validDaysFrom} - ${item.validDaysTo}`;
        item.perPerson = item.isPerPerson ? 'yes' : 'no';
        item.required = item.isRequired ? 'yes' : 'no';

        return item;
      });
    }
  }, []);

  // const handleManage = useCallback(
  //   (dataItem: any) => {
  //     dispatch(
  //       setExtraIdForManage({ name: 'extraId', eventValue: dataItem.id })
  //     );
  //     navigateTo('/extras');
  //   },
  //   [navigateTo, dispatch]
  // );

  const removeExtraFromUi = (dataSet: any[], extraId: number) => {
    const tmp = dataSet.filter((singleItem: any) => singleItem.id !== extraId);

    setExtrasList(tmp);
  };

  const handleDelete = async (dataItem: any) => {
    try {
      await removeExtraFromBoat({
        id: dataItem.id,
        boatId: props.boatId,
      }).unwrap();

      if (!isRemoveExtraFromBoatError && !isRemoveExtraFromBoatLoading) {
        removeExtraFromUi(extrasList, dataItem.id);
        handleUserActionNotification({
          message: 'Extra removed from boat successfully!',
          type: 'success',
          autoClose: 2500,
        });
      }
    } catch (error: any) {
      handleUserActionNotification({
        message: `${error.data.message}`,
        type: 'error',
        autoClose: 2500,
      });
    }
  };

  return (
    <>
      <MaterialGrid container direction={'column'} spacing={2}>
        <MaterialGrid item>
          <Grid
            data={orderBy(formatData(extrasList), sort)}
            scrollable='none'
            sortable={true}
            resizable={true}
            sort={sort}
            onSortChange={(event) => setSort(event.sort)}
          >
            {props.manualEntry === 'Mmk' ? null : (
              <Column
                field='_command'
                width={70}
                groupable={false}
                sortable={false}
                filterable={false}
                resizable={false}
                title=' '
                cell={(cellProps) => (
                  <CommandCell
                    {...cellProps}
                    // hasManageCommand={true}
                    // handleManage={handleManage}
                    hasDeleteCommand={true}
                    handleDelete={handleDelete}
                  />
                )}
              />
            )}

            <Column field='name' title='Name' sortable={true} />

            <Column field='price' title='Price' sortable={true} />

            <Column field='timeUnit' title='Time Unit' sortable={true} />

            <Column field='perPerson' title='Per Person' sortable={true} />

            <Column
              field='sailingDateRange'
              title='Sailing Dates'
              sortable={true}
            />

            <Column field='validDaysRange' title='Valid Days' sortable={true} />

            <Column field='required' title='Required' sortable={true} />
          </Grid>
        </MaterialGrid>
      </MaterialGrid>
    </>
  );
};

export default Extras;
