// REACT
import React, { ReactElement } from 'react';
//

// KENDO
import { GridRowProps } from '@progress/kendo-react-grid';
//

export function rowRender(
  trElement: ReactElement,
  props: GridRowProps
): React.ReactNode {
  const trProps: { style: any } | null = props.isAltRow
    ? null
    : { style: { backgroundColor: '#fcfcfc' } };
  return React.cloneElement(
    trElement,
    { ...trProps },
    trElement.props.children
  );
}
