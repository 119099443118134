// HOOKS
import { useState } from 'react';
//

// MUI
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
//

// COMPONENTS
import Link from './SidebarLink';
//

// ICONS
import {
  MdOutlineWaves as WavesIcon,
  MdExpandLess as ExpandLessIcon,
  MdExpandMore as ExpandMoreIcon,
} from 'react-icons/md';
//

// ROUTES
import { Routes } from '../../routes/routes';
//

const SidebarLinks = (props: { open: boolean }) => {
  const [boatsOpen, setBoatsOpen] = useState(false);
  const [locationsOpen, setLocationsOpen] = useState(false);
  const [additionalServicesOpen, setAdditionalServicesOpen] = useState(false);

  return (
    <List className='sidebar-links'>
      {Routes.find((item) => item.name === 'Dashboard')?.children?.map(
        (item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            {!item.collapse ? (
              <Link item={item} open={props.open} />
            ) : item.name === 'Boats' ? (
              <>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  className='sidebar-button'
                  onClick={() => setBoatsOpen(!boatsOpen)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: props.open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    {item.icon ? (
                      <item.icon className='link-icon' />
                    ) : (
                      <WavesIcon className='link-icon' />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    sx={{ opacity: props.open ? 1 : 0 }}
                  />
                  {boatsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                <Collapse in={boatsOpen} timeout='auto' unmountOnExit>
                  <List disablePadding sx={{ display: 'block' }}>
                    {item.children?.map((child, childIndex) => (
                      <div key={childIndex}>
                        {!child.invisible && (
                          <ListItem
                            disablePadding
                            sx={{
                              display: 'block',
                            }}
                          >
                            <Link item={child} open={props.open} child={true} />
                          </ListItem>
                        )}
                      </div>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : item.name === 'Locations' ? (
              <>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  className='sidebar-button'
                  onClick={() => setLocationsOpen(!locationsOpen)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: props.open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                      fill: 'white',
                    }}
                  >
                    {item.icon ? (
                      <item.icon className='link-icon' />
                    ) : (
                      <WavesIcon className='link-icon' />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    sx={{ opacity: props.open ? 1 : 0 }}
                  />
                  {locationsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                <Collapse in={locationsOpen} timeout='auto' unmountOnExit>
                  <List disablePadding sx={{ display: 'block' }}>
                    {item.children?.map((child, childIndex) => (
                      <div key={childIndex}>
                        <ListItem disablePadding sx={{ display: 'block' }}>
                          <Link item={child} open={props.open} child={true} />
                        </ListItem>
                      </div>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: props.open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  className='sidebar-button'
                  onClick={() =>
                    setAdditionalServicesOpen(!additionalServicesOpen)
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: props.open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'white',
                      fill: 'white',
                    }}
                  >
                    {item.icon ? (
                      <item.icon className='link-icon' />
                    ) : (
                      <WavesIcon className='link-icon' />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    sx={{ opacity: props.open ? 1 : 0 }}
                  />
                  {additionalServicesOpen ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </ListItemButton>
                <Collapse
                  in={additionalServicesOpen}
                  timeout='auto'
                  unmountOnExit
                >
                  <List disablePadding sx={{ display: 'block' }}>
                    {item.children?.map((child, childIndex) => (
                      <div key={childIndex}>
                        <ListItem disablePadding sx={{ display: 'block' }}>
                          <Link item={child} open={props.open} child={true} />
                        </ListItem>
                      </div>
                    ))}
                  </List>
                </Collapse>
              </>
            )}
          </ListItem>
        )
      )}
    </List>
  );
};

export default SidebarLinks;
