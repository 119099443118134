// SERVICES
import { buildLocalizedValuesArray } from '../../services/localizedValues';
//

// TYPES
import { Translation } from '../../types/typeDefinitions';
//

type InitialStateGetter = () => {
  name: string;
  manufacturerId: number | null;
  photoUrl?: string;
  descriptions: Translation[];
  photoDescriptions: Translation[];
};

export const getInitialState: InitialStateGetter = () => ({
  name: '',
  manufacturerId: null,
  photoUrl: '',
  descriptions: buildLocalizedValuesArray(),
  photoDescriptions: buildLocalizedValuesArray(),
});
