// TYPES
import { Enumeration } from '../../types/typeDefinitions';
//

type PhotoFeaturedType = {
  NONE: -1;
  ONE: 1;
  TWO: 2;
  THREE: 3;
  FOUR: 4;
  FIVE: 5;
};

const photoFeaturedTypes: Enumeration & PhotoFeaturedType = {
  NONE: -1,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  properties: {
    '-1': { name: 'None', value: -1 },
    1: { name: 'One', value: 1 },
    2: { name: 'Two', value: 2 },
    3: { name: 'Three', value: 3 },
    4: { name: 'Four', value: 4 },
    5: { name: 'Five', value: 5 },
  },
};

export default photoFeaturedTypes;
