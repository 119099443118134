// HOOKS
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { useLazyGetLanguagesQuery } from './LanguagesState/languagesApiSlice';
import { setLanguagesForAppContext } from './LanguagesState/languagesStateSlice';
import { logoutUser } from '../auth/authStateSlice';
import { useGetSpecificationPartsTemplateQuery } from './Boats/boatsApiSlice';
import { setSpecificationPartsTemplate } from './Boats/boatsStateSlice';
//

// ICONS
import {
  MdMenu as MenuIcon,
  MdChevronLeft as ChevronLeftIcon,
  MdChevronRight as ChevronRightIcon,
  MdLogout as LogoutIcon,
} from 'react-icons/md';
import { IoMdLock as LockIcon, IoMdUnlock as UnlockIcon } from 'react-icons/io';
//

// MUI
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
} from '@mui/material';
//

// COMPONENTS
import Brand from '../../components/Sidebar/SidebarBrand';
import User from '../../components/Sidebar/SidebarUser';
import Links from '../../components/Sidebar/SidebarLinks';
import {
  AppBar,
  DrawerHeader,
  Drawer,
} from '../../components/Sidebar/SidebarMaterialComponents';
//

// SERVICES
import { removeUserFromStorage } from '../../services/storage';
import { cloneDeep } from 'lodash';
//

export default function AdminLayout() {
  const theme = useTheme();
  const [locked, setLocked] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const dispatch = useAppDispatch();
  const [prepareLanguages] = useLazyGetLanguagesQuery();
  const {
    data: specificationPartsTemplate,
    isSuccess: specificationPartsTemplateSuccess,
    isLoading: specificationPartsTemplateLoading,
  } = useGetSpecificationPartsTemplateQuery();

  const navigateTo = useNavigate();

  useEffect(() => {
    const prepareLanguagesContext = async () => {
      try {
        const requestLanguages = await prepareLanguages();
        if (!requestLanguages.isError && !requestLanguages.isLoading) {
          dispatch(setLanguagesForAppContext(requestLanguages.data));
        }
      } catch (error) {
        console.log(error);
      }
    };

    prepareLanguagesContext();
  }, [dispatch, prepareLanguages]);

  useEffect(() => {
    if (
      specificationPartsTemplate &&
      specificationPartsTemplateSuccess &&
      !specificationPartsTemplateLoading
    )
      dispatch(
        setSpecificationPartsTemplate(cloneDeep(specificationPartsTemplate))
      );
  }, [
    dispatch,
    specificationPartsTemplateSuccess,
    specificationPartsTemplateLoading,
    specificationPartsTemplate,
  ]);

  useEffect(() => {
    if (locked) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }, [locked]);

  const handleDrawerOpen = () => {
    setSidebarOpen(true);
  };

  const handleDrawerClose = () => {
    setSidebarOpen(false);
  };

  const handleLogout = () => {
    removeUserFromStorage();
    dispatch(logoutUser());
    navigateTo('/login');
  };

  const location = useLocation();

  const title =
    location.pathname === '/manufacturers'
      ? 'MANUFACTURERS AND MODELS'
      : location.pathname.replace('/', '').replaceAll('-', ' ').split('/')[0];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed' open={sidebarOpen} className='sidebar-appbar'>
        <Toolbar className='sidebar-header'>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: 5,
              ...(sidebarOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            noWrap
            component='div'
            textTransform='uppercase'
            fontWeight='bold'
          >
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        open={sidebarOpen}
        className='sidebar-drawer'
        onMouseEnter={() => {
          if (!sidebarOpen && !locked) handleDrawerOpen();
        }}
        onMouseLeave={() => {
          if (sidebarOpen && !locked) handleDrawerClose();
        }}
      >
        <DrawerHeader
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <IconButton onClick={() => setLocked(!locked)}>
            {locked ? (
              <LockIcon className='menu_icon' />
            ) : (
              <UnlockIcon className='menu_icon' />
            )}
          </IconButton>

          <IconButton
            onClick={handleDrawerClose}
            disabled={locked ? true : false}
          >
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon className='menu_icon' />
            ) : (
              <ChevronLeftIcon className='menu_icon' />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider className='sidebar-divider' />
        <Brand open={sidebarOpen} />
        <Divider className='sidebar-divider' />
        <Links open={sidebarOpen} />

        <div className='sidebar-footer'>
          <User open={sidebarOpen} />
          <button
            className={`${sidebarOpen ? 'light-button' : 'logout-icon'}`}
            onClick={handleLogout}
          >
            <LogoutIcon /> {sidebarOpen && <span> Logout </span>}
          </button>
        </div>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
