// HOOKS
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  useLazyGetManufacturersForDropDownQuery,
  useLazyGetBasesForDropdownQuery,
  useLazyGetCompanyForDropdownQuery,
  useGetModelVariationsForDropDownQuery,
  useGetModelsForDropDownQuery,
} from '../../features/admin/Boats/boatsApiSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  onCharterBoatDropdownFilterChange,
  onCharterBoatNameFilterChange,
  onCharterBoatNumericTextboxFilterChange,
  resetCharterBoatsFilters,
  saveLocalFilterStateInStore,
} from '../../features/admin/Boats/charterBoatsFilterSlice';
import useNotifications from '../../hooks/useNotifications';
import { setChartersFiltersHeight } from '../../features/admin/generalStateSlice';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// KENDO
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import {
  DropDownButton,
  DropDownButtonItemClickEvent,
} from '@progress/kendo-react-buttons';
import {
  Input,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
//

// TYPES
import boatTypes from '../../types/enums/boatTypes';
import boatStatuses from '../../types/enums/boatStatus';
import { Lookup } from '../../types/typeDefinitions';
//

// ICONS
import { MdClose as CloseIcon } from 'react-icons/md';
//

const CharterGridFilterBar = () => {
  const initLocalFilterState = useMemo(() => {
    return {
      wildcardName: '',
      manufacturer: null,
      model: null,
      variation: null,
      type: null,
      status: null,
      company: null,
      base: null,
      produced: null,
    };
  }, []);

  const initOptionalFilterState = useMemo(() => {
    return [
      { name: 'Type', value: 1 },
      { name: 'Status', value: 2 },
      { name: 'Company', value: 3 },
      { name: 'Base', value: 4 },
    ];
  }, []);

  const getLocalFilterValuesFromStore = useAppSelector(
    (state) => state.charterBoatsFilters.charterBoatsFilters.localFilterState
  );

  const memoizedInitLocalState = useMemo(
    () => getLocalFilterValuesFromStore,
    [getLocalFilterValuesFromStore]
  );

  const [localFilterValues, setLocalFilterValues] = useState(
    memoizedInitLocalState
  );
  const [optionalFilters, setOptionalFilters] = useState<any[]>(
    memoizedInitLocalState.optionalFilters
  );
  const [models, setModels] = useState<any[]>([]);
  const [modelIdForQuery, setModelIdForQuery] = useState<number | null>(
    memoizedInitLocalState.modelIdForQuery || null
  );
  const [modelVariations, setModelVariations] = useState<any[]>([]);
  const [manufacturerIdForQuery, setManufacturerIdForQuery] = useState<
    number | null
  >(memoizedInitLocalState.manufacturerIdForQuery || null);

  const {
    data: initModelData,
    isFetching: isGetModelsLoading,
    isError: isGetModelsError,
  } = useGetModelsForDropDownQuery(manufacturerIdForQuery as number, {
    skip: manufacturerIdForQuery === null,
  });
  const {
    data: modelVariationsData,
    isLoading: isGetModelVariationsLoading,
    isError: isGetModelVariationsError,
  } = useGetModelVariationsForDropDownQuery(modelIdForQuery as number, {
    skip: modelIdForQuery === null,
  });
  const [getManufacturersForDropdown, { currentData: initManuData }] =
    useLazyGetManufacturersForDropDownQuery();
  const [getBasesForDropdown, { currentData: initBasesData }] =
    useLazyGetBasesForDropdownQuery();
  const [getCompaniesForDropdown, { currentData: initCompaniesData }] =
    useLazyGetCompanyForDropdownQuery();

  const { handleUserActionNotification } = useNotifications();
  const dispatch = useAppDispatch();

  const prepareDropdownData = useCallback(async () => {
    try {
      await getManufacturersForDropdown().unwrap();
      await getBasesForDropdown().unwrap();
      await getCompaniesForDropdown().unwrap();
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        autoClose: 2500,
        message: error.data.message,
      });
    }
  }, [
    getManufacturersForDropdown,
    getBasesForDropdown,
    getCompaniesForDropdown,
    handleUserActionNotification,
  ]);

  const handleFilterRemove = (item: any) => {
    const tmp = optionalFilters.filter(
      (singleFilter: any) => singleFilter.name !== item
    );
    setOptionalFilters(tmp);

    dispatch(
      onCharterBoatDropdownFilterChange({
        name: String(item).toLowerCase(),
        eventValue: null,
      })
    );

    setLocalFilterValues((prev: any) => {
      return {
        ...prev,
        [String(item).toLowerCase()]: null,
      };
    });
  };

  const customSetState = useCallback((eventName: string, eventValue: any) => {
    setLocalFilterValues((prev: any) => {
      return { ...prev, [eventName]: eventValue };
    });
  }, []);

  const resetFilters = () => {
    setLocalFilterValues(initLocalFilterState);
    dispatch(resetCharterBoatsFilters());
    setModelIdForQuery(null);
    setManufacturerIdForQuery(null);
    setModels([]);
    setModelVariations([]);
    setOptionalFilters([]);
  };

  useEffect(() => {
    if (
      modelVariationsData?.length !== 0 &&
      !isGetModelVariationsError &&
      !isGetModelVariationsLoading
    ) {
      if (modelVariationsData?.some((item) => item.name === null)) {
        const filterOutNull = modelVariationsData.filter(
          (item) => item.name !== null
        );

        setModelVariations(filterOutNull);
      } else {
        setModelVariations(modelVariationsData as any);
      }
    } else {
      setModelVariations([]);
    }
  }, [
    modelVariationsData,
    isGetModelVariationsError,
    isGetModelVariationsLoading,
  ]);

  useEffect(() => {
    if (
      initModelData?.length !== 0 &&
      !isGetModelsLoading &&
      !isGetModelsError
    ) {
      setModels(initModelData as Lookup[]);
      setModelVariations([]);
    } else {
      setModels([]);
    }
  }, [initModelData, isGetModelsLoading, isGetModelsError]);

  useEffect(() => {
    prepareDropdownData();
  }, [prepareDropdownData]);

  useEffect(() => {
    return () => {
      dispatch(
        saveLocalFilterStateInStore({
          ...localFilterValues,
          manufacturerIdForQuery: manufacturerIdForQuery,
          modelIdForQuery: modelIdForQuery,
          optionalFilters: optionalFilters,
        })
      );
    };
  }, [
    dispatch,
    localFilterValues,
    manufacturerIdForQuery,
    modelIdForQuery,
    optionalFilters,
  ]);

  const OptionalFiltersController = () => {
    return (
      <MaterialGrid item className='filter-item'>
        <DropDownButton
          items={initOptionalFilterState.map(
            (singleFilter: any) => singleFilter
          )}
          textField={'name'}
          text={'More filters...'}
          onItemClick={(event: DropDownButtonItemClickEvent) => {
            const tmp = optionalFilters.some(
              (singleFilter: any) => singleFilter.name === event.item.name
            );
            setOptionalFilters((prev: any) => {
              if (tmp === true) {
                return prev;
              } else return [...prev, event.item];
            });
          }}
        />
      </MaterialGrid>
    );
  };

  const prepareOptionalFilters = () => {
    return (
      <MaterialGrid item container direction={'row'} width={'auto'} spacing={2}>
        {optionalFilters?.length !== 0 && optionalFilters !== undefined
          ? optionalFilters.map((singleItem: any) => {
              if (singleItem.name === 'Type') {
                return (
                  <React.Fragment key={singleItem.name}>
                    <MaterialGrid item className='filter-item'>
                      <DropDownList
                        data={Object.values(boatTypes.properties)}
                        textField={'name'}
                        dataItemKey={'value'}
                        label={'Boat type'}
                        value={{ name: localFilterValues.type }}
                        onChange={(event: DropDownListChangeEvent) => {
                          customSetState('type', event.value.name);
                          dispatch(
                            onCharterBoatDropdownFilterChange({
                              name: 'type',
                              eventValue: event.value.value,
                            })
                          );
                        }}
                      />
                    </MaterialGrid>

                    <button
                      onClick={() => handleFilterRemove(singleItem.name)}
                      className='close-button'
                    >
                      <CloseIcon />
                    </button>
                  </React.Fragment>
                );
              }

              if (singleItem.name === 'Status') {
                return (
                  <React.Fragment key={singleItem.name}>
                    <MaterialGrid item className='filter-item'>
                      <DropDownList
                        data={Object.values(boatStatuses.properties)}
                        textField={'name'}
                        dataItemKey={'value'}
                        label={'Boat status'}
                        value={{ name: localFilterValues.status }}
                        onChange={(event: DropDownListChangeEvent) => {
                          customSetState('status', event.value.name);

                          dispatch(
                            onCharterBoatDropdownFilterChange({
                              name: 'status',
                              eventValue: event.value.value,
                            })
                          );
                        }}
                      />
                    </MaterialGrid>

                    <button
                      onClick={() => handleFilterRemove(singleItem.name)}
                      className='close-button'
                    >
                      <CloseIcon />
                    </button>
                  </React.Fragment>
                );
              }

              if (singleItem.name === 'Base') {
                return (
                  <React.Fragment key={singleItem.name}>
                    <MaterialGrid item className='filter-item'>
                      <DropDownList
                        data={initBasesData}
                        textField={'name'}
                        dataItemKey={'id'}
                        label={'Base'}
                        value={{ name: localFilterValues.base }}
                        onChange={(event: DropDownListChangeEvent) => {
                          customSetState('base', event.value.name);

                          dispatch(
                            onCharterBoatDropdownFilterChange({
                              name: 'base',
                              eventValue: event.value.id,
                            })
                          );
                        }}
                      />
                    </MaterialGrid>

                    <button
                      onClick={() => handleFilterRemove(singleItem.name)}
                      className='close-button'
                    >
                      <CloseIcon />
                    </button>
                  </React.Fragment>
                );
              }

              if (singleItem.name === 'Company') {
                return (
                  <React.Fragment key={singleItem.name}>
                    <MaterialGrid item className='filter-item'>
                      <DropDownList
                        data={initCompaniesData}
                        textField={'name'}
                        dataItemKey={'id'}
                        label={'Company'}
                        value={{ name: localFilterValues.company }}
                        onChange={(event: DropDownListChangeEvent) => {
                          customSetState('company', event.value.name);

                          dispatch(
                            onCharterBoatDropdownFilterChange({
                              name: 'company',
                              eventValue: event.value.id,
                            })
                          );
                        }}
                      />
                    </MaterialGrid>

                    <button
                      onClick={() => handleFilterRemove(singleItem.name)}
                      className='close-button'
                    >
                      <CloseIcon />
                    </button>
                  </React.Fragment>
                );
              }

              return null;
            })
          : null}
      </MaterialGrid>
    );
  };

  const [height, setHeight] = useState(0);
  const ref: any = useRef(null);

  useLayoutEffect(() => {
    if (ref.current.clientHeight) {
      setTimeout(() => {
        setHeight(ref.current.clientHeight);
      }, 0);
    }
  });

  useEffect(() => {
    dispatch(setChartersFiltersHeight(height));
  }, [dispatch, height]);

  return (
    <MaterialGrid container columnSpacing={2} ref={ref}>
      <MaterialGrid item className='filter-item'>
        <Input
          onChange={(event: any) => {
            customSetState('wildcardName', event.target.value);
            dispatch(
              onCharterBoatNameFilterChange({
                name: 'name',
                eventValue: event.target.value,
              })
            );
          }}
          label={'Name'}
          value={localFilterValues.wildcardName}
        />
      </MaterialGrid>

      <MaterialGrid item className={'filter-item'}>
        <DropDownList
          data={initManuData}
          textField={'name'}
          dataItemKey={'id'}
          label={'Manufacturer'}
          onChange={(event: DropDownListChangeEvent) => {
            dispatch(
              onCharterBoatDropdownFilterChange({
                name: 'manufacturer',
                eventValue: event.target.value.id,
              })
            );
            customSetState('manufacturer', event.target.value.name);

            setManufacturerIdForQuery(event.target.value.id);

            setModelIdForQuery(null);

            setLocalFilterValues((prev: any) => {
              return { ...prev, model: null, variation: null };
            });

            if (event.target.value.id === null) {
              setLocalFilterValues((prev: any) => {
                return { ...prev, model: null, variation: null };
              });

              dispatch(
                onCharterBoatDropdownFilterChange({
                  name: 'model',
                  eventValue: event.target.value.id,
                })
              );

              dispatch(
                onCharterBoatDropdownFilterChange({
                  name: 'variation',
                  eventValue: event.target.value.id,
                })
              );
            }
          }}
          value={{ name: localFilterValues.manufacturer }}
          defaultItem={{ name: 'All manufacturers', id: null }}
        />
      </MaterialGrid>

      <MaterialGrid item className={'filter-item'}>
        <DropDownList
          data={models}
          textField={'name'}
          dataItemKey={'id'}
          label={'Model'}
          onChange={(event: DropDownListChangeEvent) => {
            customSetState('model', event.target.value.name);
            setModelIdForQuery(event.value.id);
            dispatch(
              onCharterBoatDropdownFilterChange({
                name: 'model',
                eventValue: event.value.id,
              })
            );

            setLocalFilterValues((prev: any) => {
              return { ...prev, variation: null };
            });

            if (event.target.value.id === null) {
              setLocalFilterValues((prev: any) => {
                return { ...prev, variation: null };
              });

              dispatch(
                onCharterBoatDropdownFilterChange({
                  name: 'variation',
                  eventValue: event.target.value.id,
                })
              );
            }
          }}
          value={{ name: localFilterValues.model }}
          defaultItem={{
            name: 'All models',
            id: null,
          }}
          disabled={manufacturerIdForQuery === null}
        />
      </MaterialGrid>

      <MaterialGrid item className={'filter-item'}>
        <DropDownList
          data={modelVariations}
          textField={'name'}
          dataItemKey={'id'}
          label={'Variation'}
          value={{ name: localFilterValues.variation }}
          disabled={modelIdForQuery === null || modelVariations.length === 0}
          defaultItem={{
            name: 'All variations',
            id: null,
          }}
          onChange={(event: DropDownListChangeEvent) => {
            customSetState('variation', event.target.value.name);
            dispatch(
              onCharterBoatDropdownFilterChange({
                name: 'variation',
                eventValue: event.target.value.id,
              })
            );
          }}
        />
      </MaterialGrid>

      <MaterialGrid item className={'filter-item'}>
        <NumericTextBox
          placeholder={'Year produced'}
          onChange={(event: NumericTextBoxChangeEvent) => {
            customSetState('produced', event.value);
            dispatch(
              onCharterBoatNumericTextboxFilterChange({
                name: 'produced',
                eventValue: event.value as number,
              })
            );
          }}
          value={localFilterValues.produced}
          max={2040}
          min={1990}
          format='##.##'
          spinners={false}
          validationMessage={'Please enter a year between 1990 and 2040'}
        />
      </MaterialGrid>

      {prepareOptionalFilters()}

      <OptionalFiltersController />

      <MaterialGrid item className='filter-item'>
        <button onClick={resetFilters} className={'sky-button'}>
          Reset filters
        </button>
      </MaterialGrid>
    </MaterialGrid>
  );
};

export default CharterGridFilterBar;
