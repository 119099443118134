// HOOKS
import { useRef, useEffect } from 'react';
//

export interface UseReorderUtils {
  dragStart: (dataItem: any) => void;
  rootOrParentReorder: (dataItem: any) => void;
  childReorder: (dataItem: any) => void;
}

interface Props {
  data: any[];
  identifierKey?: string;
  orderKey?: string;
  parentKey?: string;
  onReorder: any;
  [k: string]: any;
}

function useReorder({
  data = [],
  identifierKey = 'id',
  orderKey = 'order',
  parentKey = 'parent',
  onReorder = () => {},
}: any = {}): any {
  const activeItemRef: React.MutableRefObject<any> = useRef(null);
  const dataRef: React.MutableRefObject<any> = useRef(null);

  useEffect(() => {
    dataRef.current = data && Array.isArray(data) ? [...data] : null;
  }, [data]);

  const onDragStart: UseReorderUtils['dragStart'] = (dataItem: any) => {
    activeItemRef.current = dataItem;
  };

  const onRootOrParentReorder: UseReorderUtils['rootOrParentReorder'] = (
    dataItem: any
  ) => {
    if (activeItemRef.current[identifierKey] === dataItem[identifierKey]) {
      return;
    }

    let reorderedData: any = [...dataRef.current];

    const prevIndex: number = reorderedData.findIndex(
      (item: any) =>
        item[identifierKey] === activeItemRef.current[identifierKey]
    );
    const nextIndex: number = reorderedData.findIndex(
      (item: any) => item[identifierKey] === dataItem[identifierKey]
    );

    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, activeItemRef.current);

    reorderedData = reorderedData.map((item: any, index: number) => ({
      ...item,
      [orderKey]: index + 1,
    }));

    onReorder({
      data: [...reorderedData],
      type: useReorder.stateChangeTypes.rootOrParentReorder,
    });
  };

  const onChildReorder: UseReorderUtils['childReorder'] = (dataItem: any) => {
    if (activeItemRef.current[identifierKey] === dataItem[identifierKey]) {
      return;
    }

    let reorderedData: any = [...dataRef.current];
    const prevIndex: number = reorderedData.findIndex(
      (item: any) =>
        item[identifierKey] === activeItemRef.current[identifierKey]
    );
    const nextIndex: number = reorderedData.findIndex(
      (item: any) => item[identifierKey] === dataItem[identifierKey]
    );

    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, activeItemRef.current);

    const childrenFromSameParent: any = reorderedData
      .filter((item: any) => item[parentKey] === dataItem[parentKey])
      .map((item: any, index: number) => ({
        ...item,
        [orderKey]: index + 1,
      }));

    const changedData: any = dataRef.current
      .map((item: any) => {
        if (item[parentKey] === dataItem[parentKey]) {
          item[orderKey] = childrenFromSameParent.find(
            (x: any) => x[identifierKey] === item[identifierKey]
          )[orderKey];
        }

        return item;
      })
      .sort((a: any, b: any) => {
        if (a[parentKey] < b[parentKey]) {
          return -1;
        }
        if (a[parentKey] > b[parentKey]) {
          return 1;
        }

        if (a[orderKey] < b[orderKey]) {
          return -1;
        }
        if (a[orderKey] > b[orderKey]) {
          return 1;
        }

        return 0;
      });

    onReorder({
      data: [...changedData],
      type: useReorder.stateChangeTypes.childReorder,
    });
  };

  return {
    dragStart: onDragStart,
    rootOrParentReorder: onRootOrParentReorder,
    childReorder: onChildReorder,
  };
}
useReorder.stateChangeTypes = {
  dragStart: '__drag_start__',
  rootOrParentReorder: '__root_parent_reorder__',
  childReorder: '__child_reorder__',
};

/**
 *
 * Render props Reorder component
 */
const Reorder: any = ({ children, ...props }: Props) =>
  children(useReorder(props));
Reorder.stateChangeTypes = {
  dragStart: '__drag_start__',
  rootOrParentReorder: '__root_parent_reorder__',
  childReorder: '__child_reorder__',
};

export { useReorder };
export default Reorder;
