// TYPES
import { BoatListItem } from '../../../../types/typeDefinitions';
//

// ICONS
import { MdDone as DoneIcon } from 'react-icons/md';
//

// SERVICES
import { rowRender } from '../../../../services/gridRowRender';
//

// COMPONENTS
import KendoLoader from '../../../../components/Loader/KendoLoader';
import { CommandCell } from '../../../../components/CommandCell/CommandCell';
import { EnumCell } from './BoatGrid';
//

// HOOKS
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { useDeleteBoatMutation, useLazyGetBoatsQuery } from '../boatsApiSlice';
import { cloneDeep } from 'lodash';
import useNotifications from '../../../../hooks/useNotifications';
//

// KENDO
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { parseDate, formatDate } from '@telerik/kendo-intl';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

const BoatCharterGrid = () => {
  const currentLocation = useLocation();
  const navigateTo = useNavigate();
  const handleCloningEntity = useCallback(
    (entity: any) => cloneDeep(entity),
    []
  );

  const { handleUserActionNotification } = useNotifications();

  const getCharterBoatsFilterState = useAppSelector(
    (state) => state.charterBoatsFilters
  );

  const clearNullValuesForQueryString = useMemo(() => {
    const tmp =
      getCharterBoatsFilterState.charterBoatsFilters.filter.filters.filter(
        (singleFilter: any) => singleFilter.value !== null
      );

    if (tmp?.length === 0) {
      return null;
    } else {
      return {
        ...getCharterBoatsFilterState,
        filter: { filters: tmp, logic: 'and' },
      };
    }
  }, [getCharterBoatsFilterState]);

  const [
    getCharterBoats,
    { currentData: allCharterBoats, isFetching: isGetCharterBoatsLoading },
  ] = useLazyGetBoatsQuery();

  useEffect(() => {
    const prepareCharterBoats = async () => {
      try {
        const response = await getCharterBoats(
          clearNullValuesForQueryString
        ).unwrap();
        return response;
      } catch (error) {
        console.error(error);
      }
    };

    prepareCharterBoats();
  }, [clearNullValuesForQueryString, getCharterBoats]);

  const EnabledCell = (props: GridCellProps): ReactElement => (
    <td>
      {props.dataItem.isEnabled ? <DoneIcon className='enabled-icon' /> : null}
    </td>
  );

  const handleEditClick = (dataItem: BoatListItem) => {
    const newLocation: any = {
      pathname: `${currentLocation.pathname}/details/${dataItem.id}`,
      state: { isEdit: true, modelId: dataItem.modelId },
    };
    navigateTo(newLocation.pathname, { state: newLocation.state });
  };

  const parseData = useCallback(
    (dataSet: any) => {
      if (dataSet !== null && dataSet !== undefined && dataSet?.length !== 0) {
        const tmp = handleCloningEntity(dataSet).data.map((single: any) => {
          if (single.createdDate) {
            const tDate = parseDate(single.createdDate);
            single.createdDate = formatDate(tDate, 'dd/MM/yyyy');
          }

          if (single.modelVariation?.length > 20) {
            single.variation = single.modelVariation;
            single.modelVariation = `${single.modelVariation.slice(0, 20)}...`;
          }

          single.manufacturerModelVariation = `${single.manufacturer} ${single.model} ${single.modelVariation}`;

          return single;
        });
        return tmp;
      }
      return [];
    },
    [handleCloningEntity]
  );

  const [
    deleteBoat,
    { isLoading: isDeleteBoatLoading, isError: isDeleteBoatError },
  ] = useDeleteBoatMutation();

  const handleDeleteBoat = async (dataItem: Partial<BoatListItem>) => {
    try {
      await deleteBoat(dataItem.id as number).unwrap();

      if (!isDeleteBoatLoading && !isDeleteBoatError) {
        handleUserActionNotification({
          message: 'Boat successfully deleted!',
          autoClose: 2500,
          type: 'success',
        });
      }
    } catch (error: any) {
      console.error(error);
      handleUserActionNotification({
        message: error.data.message,
        autoClose: 2500,
        type: 'error',
      });
    }
  };

  const columns = [
    { field: 'name', title: 'Name' },
    {
      field: 'manufacturerModelVariation',
      title: 'Manufacturer Model Variation',
    },
    { field: 'company', title: 'Company' },
    { field: 'base', title: 'Base' },
    { field: 'produced', title: 'Produced' },
    { field: 'lengthOverAll', title: 'Length' },
    {
      field: 'berthsCabinsDraft',
      title: 'Berths  Cabins  Draft',
    },
    { field: 'status', title: 'Status' },
    { field: 'createdDate', title: 'Date created' },
    { field: 'isEnabled', title: 'Enabled?' },
  ];

  const VariationCell = (props: GridCellProps): ReactElement => {
    return (
      <td
        title={props.dataItem.variation ? props.dataItem.variation : undefined}
      >
        {props.dataItem.manufacturerModelVariation}
      </td>
    );
  };

  const initialSort: Array<SortDescriptor> = [];

  const [sort, setSort] = useState(initialSort);

  return (
    <MaterialGrid width={'100%'} height={'100%'} className='charters-grid'>
      <Grid
        style={{ height: '100%' }}
        data={orderBy(parseData(allCharterBoats), sort)}
        scrollable='scrollable'
        rowRender={rowRender}
        resizable={true}
        sortable={true}
        sort={sort}
        onSortChange={(e: GridSortChangeEvent) => {
          setSort(e.sort);
        }}
      >
        <Column
          field='_command'
          groupable={false}
          sortable={false}
          filterable={false}
          resizable={false}
          title=' '
          width={50}
          cell={(props) => (
            <CommandCell
              {...props}
              hasEditCommand={true}
              handleEdit={handleEditClick}
              hasDeleteCommand={true}
              handleDelete={handleDeleteBoat}
              isBoat={true}
            />
          )}
        />
        {columns.map((column, index) => {
          return (
            <Column
              field={column.field}
              title={column.title}
              key={index}
              cell={
                column.field === 'isEnabled'
                  ? (props) => <EnabledCell {...props} />
                  : column.field === 'manufacturerModelVariation'
                  ? (props) => <VariationCell {...props} />
                  : column.field === 'status'
                  ? (props) => <EnumCell {...props} />
                  : undefined
              }
            />
          );
        })}
      </Grid>
      {isGetCharterBoatsLoading && <KendoLoader />}
    </MaterialGrid>
  );
};

export default BoatCharterGrid;
