// HOOKS
import { useCallback, useState, useEffect } from 'react';
//

const useFocusNewItem = (dataset: any, isWithoutSort = false) => {
  const [newItemToFocus, setNewItemToFocus] = useState<any>(undefined);

  const handleGenerationOfNewItemInUI = useCallback(() => {
    let newTempID = new Date().getMilliseconds();
    setNewItemToFocus(newTempID);
  }, []);

  const customQuerySelectorForFirstInputOnPage = useCallback(
    (selector: string) => {
      let selectedInput = document
        ? (document.querySelectorAll(selector) as any | null)
        : null;

      if (selectedInput && selectedInput[0] !== undefined && !isWithoutSort) {
        return selectedInput[0].focus();
      } else if (
        selectedInput &&
        selectedInput !== undefined &&
        selectedInput[0] !== undefined &&
        isWithoutSort === true
      ) {
        const selectWithoutMMKfield = [...selectedInput].filter(
          (input: any) => input.ariaDisabled !== 'true'
        );

        return selectWithoutMMKfield[selectWithoutMMKfield?.length - 1].focus();
      }
    },
    [isWithoutSort]
  );

  useEffect(() => {
    if (dataset === 'dateFirst') {
      customQuerySelectorForFirstInputOnPage('[role="spinbutton"]');
    }
    if (dataset === 'nameFirst') {
      customQuerySelectorForFirstInputOnPage('.k-input');
    }
  }, [newItemToFocus, dataset, customQuerySelectorForFirstInputOnPage]);

  return { handleGenerationOfNewItemInUI };
};

export default useFocusNewItem;
