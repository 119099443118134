// KENDO
import {
  DropDownButton,
  DropDownButtonItemClickEvent,
} from '@progress/kendo-react-buttons';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import { Filter } from './Filter';
//

// TYPES
import { GridFilter } from '../../types/typeDefinitions';
//

// HOOKS
import { useAppDispatch } from '../../app/hooks';
import { setBoatsFiltersHeight } from '../../features/admin/generalStateSlice';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
//

interface Props {
  filters: GridFilter[];
  onAddFilter: (filterName: string) => void;
  onFilterChange: (filterName: string) => (changes: any, action: any) => void;
  onFilterDelete: (filterName: string) => () => void;
  onFilterReset: () => void;
}

const GridFilterBar = (props: Props) => {
  const dispatch = useAppDispatch();

  const getFilters = (filtersArray: GridFilter[], isSelected: boolean) => {
    if (!filtersArray) {
      return [];
    }
    return filtersArray.filter(
      (singleFilter) => singleFilter.selected === isSelected
    );
  };

  const handleAddFilterClick = (event: DropDownButtonItemClickEvent) =>
    props.onAddFilter(event.item.name);

  const [height, setHeight] = useState(0);
  const ref: any = useRef(null);

  useLayoutEffect(() => {
    if (ref.current.clientHeight) {
      setTimeout(() => {
        setHeight(ref.current.clientHeight);
      }, 0);
    }
  });

  useEffect(() => {
    dispatch(setBoatsFiltersHeight(height));
  }, [dispatch, height]);

  return (
    <MaterialGrid container columnSpacing={2} ref={ref}>
      {getFilters(props.filters, true).map((filter) => (
        <MaterialGrid item key={`${filter.name}-${filter.type}`}>
          <Filter
            {...filter}
            onChange={props.onFilterChange(filter.name)}
            onDelete={props.onFilterDelete(filter.name)}
          ></Filter>
        </MaterialGrid>
      ))}

      {getFilters(props.filters, false)?.length > 0 && (
        <MaterialGrid item className='filter-item'>
          <DropDownButton
            text='More filters...'
            items={getFilters(props.filters, false)}
            textField='name'
            onItemClick={handleAddFilterClick}
          />
        </MaterialGrid>
      )}

      <MaterialGrid item className='filter-item'>
        <button onClick={props.onFilterReset} className='sky-button'>
          Reset filters
        </button>
      </MaterialGrid>
    </MaterialGrid>
  );
};

export default GridFilterBar;
