// TYPES
import { User } from '../types/typeDefinitions';
//

export function getUserFromStorage(): string | null {
  return localStorage.getItem('@nava:user');
}

export function setUserToStorage(user: User): void {
  localStorage.setItem('@nava:user', JSON.stringify(user));
}

export function removeUserFromStorage(): void {
  return localStorage.removeItem('@nava:user');
}
