// TYPES
import {
  BoatDetailsPricesProps,
  BoatPrice,
} from '../../../../types/typeDefinitions';
//

// KENDO
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import { CommandCell } from '../../../../components/CommandCell/CommandCell';
import { DateCell } from '../../../../components/DateCell/DateCell';
import { NumericCell } from '../../../../components/NumericCell/NumericCell';
import { Renderers } from './Renderers';
//

// HOOKS
import { useCallback, useState } from 'react';
import { useDeleteBoatPriceMutation } from '../boatsApiSlice';
import useFocusNewItem from '../../../../hooks/useFocusNewItem';
//

const Prices = (props: BoatDetailsPricesProps) => {
  const editField = 'inEdit';

  const [deletePrice] = useDeleteBoatPriceMutation();

  const formatData = useCallback((data: BoatDetailsPricesProps['data']) => {
    if (data) {
      return data.map((item) => {
        item.dateFrom = new Date(String(item.dateFrom));
        item.dateTo = new Date(String(item.dateTo));

        return item;
      });
    }
  }, []);

  const renderers = new Renderers(props.enterEdit, props.exitEdit, 'inEdit');
  const { handleGenerationOfNewItemInUI } = useFocusNewItem('dateFirst');

  const addNew = () => {
    let newTempID = new Date().getMilliseconds();
    props.handleAddNewPrice(newTempID);
    setSort([{ field: 'dateFrom', dir: 'desc' }]);
    handleGenerationOfNewItemInUI();
  };

  const handlePriceDelete = async (dataItem: any) => {
    if ('tempId' in dataItem) {
      props.handleDelete(dataItem);
    } else {
      await deletePrice({
        priceIdToDelete: dataItem.id,
        boatIdToDelete: props.boatId,
      });
      props.handleDelete(dataItem);
    }
  };

  const initialSort: Array<SortDescriptor> = [
    { field: 'dateFrom', dir: 'asc' },
  ];

  const [sort, setSort] = useState(initialSort);

  return (
    <>
      <MaterialGrid container direction='column' spacing={1}>
        <MaterialGrid item container justifyContent='flex-end'>
          <button onClick={addNew} title='Add price' className='pink-button'>
            Add price
          </button>
        </MaterialGrid>
        <MaterialGrid item>
          <Grid
            scrollable='none'
            data={orderBy(formatData(props.data) as BoatPrice[], sort)}
            editField={editField}
            cellRender={renderers.cellRender}
            rowRender={renderers.rowRender}
            onItemChange={props.handleValueChange}
            sortable={true}
            resizable={true}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
            }}
          >
            <Column
              field='_command'
              width={45}
              groupable={false}
              sortable={false}
              filterable={false}
              resizable={false}
              title=' '
              cell={(cellProps) => (
                <CommandCell
                  {...cellProps}
                  hasDeleteCommand={true}
                  handleDelete={handlePriceDelete}
                />
              )}
            />
            <Column
              field='dateFrom'
              title='Date From'
              editable={true}
              sortable={true}
              cell={DateCell}
            />
            <Column
              field='dateTo'
              title='Date To'
              editable={true}
              sortable={true}
              cell={DateCell}
            />
            <Column
              field='price'
              title='Price'
              sortable={true}
              editable={true}
              cell={NumericCell}
            />
          </Grid>
        </MaterialGrid>
      </MaterialGrid>
    </>
  );
};

export default Prices;
