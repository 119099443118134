// REACT
import { ReactElement } from 'react';
//

// TYPES
import { CardProps } from '../../types/typeDefinitions';
//

const Card = ({ ...props }: Partial<CardProps>): ReactElement => {
  const {
    children,
    plain,
    profile,
    blog,
    raised,
    background,
    pricing,
    color,
    product,
    testimonial,
    chart,
    login,
    ...rest
  } = props;

  return <div {...rest}>{children}</div>;
};

export default Card;
