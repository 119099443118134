// HOOKS
import React, { useCallback, useEffect, useState } from 'react';
import { useLoginUserMutation } from './authApiSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { loginUserSuccess } from './authStateSlice';
import useNotifications from '../../hooks/useNotifications';
//

// COMPONENTS
import { InputAdornment } from '@mui/material';
import CustomInput from '../../components/CustomInput/CustomInput';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
//

// TYPES
import { LoginState, User } from '../../types/typeDefinitions';
//

// SERVICES
import { setUserToStorage } from '../../services/storage';
import { isValidText } from '../../services/validations';

// ICONS
import { RiUser3Fill, RiLockPasswordFill } from 'react-icons/ri';
//

const Login = () => {
  const [userName, setUserName] = useState<LoginState['userName']>('');
  const [userNameState, setUserNameState] =
    useState<LoginState['userNameState']>('');
  const [password, setPassword] = useState<LoginState['password']>('');
  const [passwordState, setPasswordState] =
    useState<LoginState['passwordState']>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const { handleUserActionNotification, handlePromiseNotification } =
    useNotifications();

  const [
    loginUser,
    {
      data: userAuthData,
      isError: isUserLoginError,
      isLoading: isUserLoginLoading,
    },
  ] = useLoginUserMutation();

  const navigateTo = useNavigate();

  const dispatch = useAppDispatch();

  const saveUserToStorageAndMemory = useCallback(
    (userData: User) => {
      setUserToStorage(userData);
      dispatch(loginUserSuccess(userData));
      navigateTo('/new-boats');
    },
    [dispatch, navigateTo]
  );

  const handleFormInputChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsButtonDisabled(false);
      switch (name) {
        case 'userName':
          setUserName(event.target.value);
          setUserNameState(
            isValidText(event.target.value) ? 'success' : 'error'
          );
          break;
        case 'password':
          setPassword(event.target.value);
          setPasswordState(
            isValidText(event.target.value) ? 'success' : 'error'
          );
          break;
        default:
          return '';
      }
    };

  const handleFormSubmit = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsButtonDisabled(true);

    if (userNameState === '') {
      setUserNameState('error');
    }
    if (passwordState === '') {
      setPasswordState('error');
    }

    if (userNameState === 'success' && passwordState === 'success') {
      try {
        handlePromiseNotification(
          loginUser({ UserName: userName, Password: password }).unwrap(),
          {
            success: {
              message: 'Welcome!',
              type: 'success',
            },
            pending: {
              message: 'Logging in...',
              type: 'info',
            },
            error: {
              message: 'There was an error with your request',
              type: 'error',
            },
          }
        );
      } catch (error: any) {
        setIsButtonDisabled(false);
        handleUserActionNotification({
          message: `${error.data.message}`,
          type: 'error',
          autoClose: 2000,
        });
      }
    }
  };

  useEffect(() => {
    if (
      userAuthData !== null &&
      userAuthData !== undefined &&
      !isUserLoginError &&
      !isUserLoginLoading
    ) {
      saveUserToStorageAndMemory(userAuthData as User);
    }
  }, [
    isUserLoginError,
    isUserLoginLoading,
    userAuthData,
    saveUserToStorageAndMemory,
  ]);

  return (
    <div className='wrapper'>
      <div className='full-page'>
        <div className='container'>
          <GridContainer justifyContent='center'>
            <GridItem>
              <form>
                <Card login className='card'>
                  <CardHeader className='card-header'>
                    <h4 className='card_title'>LOGIN</h4>
                  </CardHeader>

                  <CardBody className='card-body'>
                    <CustomInput
                      success={userNameState === 'success'}
                      error={userNameState === 'error'}
                      labelText='Username'
                      id='username'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: userName,
                        onChange: handleFormInputChange('userName'),
                        endAdornment: (
                          <InputAdornment position='end'>
                            <RiUser3Fill />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <CustomInput
                      success={passwordState === 'success'}
                      error={passwordState === 'error'}
                      labelText='Password'
                      id='password'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        className: 'login-input',
                        value: password,
                        onChange: handleFormInputChange('password'),
                        type: 'password',
                        endAdornment: (
                          <InputAdornment position='end'>
                            <RiLockPasswordFill />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardBody>

                  <CardFooter className='card-footer'>
                    <button
                      className='card-button'
                      onClick={handleFormSubmit}
                      disabled={isButtonDisabled}
                    >
                      Login
                    </button>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
};

export default Login;
