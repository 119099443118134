// KENDO
import {
  DateRangePicker,
  DateRangePickerChangeEvent,
} from '@progress/kendo-react-dateinputs';
import {
  AutoCompleteChangeEvent,
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import {
  NumericTextBox,
  Input,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import {
  DropDownButton,
  DropDownButtonItemClickEvent,
} from '@progress/kendo-react-buttons';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// HOOKS
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  useGetSpecialOfferTypesForDropdownQuery,
  useLazyGetAllSpecialOffersQuery,
  useLazyGetBoatsAutoCompleteSearchQuery,
} from './specialOffersApiSlice';
import { useGetBasesForDropdownQuery } from '../Discounts/discountsApiSlice';
import {
  onSpecialOffersFilterAutoCompleteChange,
  onSpecialOffersFilterDateRangeChange,
  onSpecialOffersFilterDropdownChange,
  onSpecialOffersFilterNumericTextBoxChange,
  onSpecialOffersFilterTextInputChange,
  resetSpecialOffersFilterState,
  saveBoatsAutoCompleteList,
  saveSpecialOffersLocalFilterState,
  saveSpecialOffersList,
  setFilterOfferById,
} from './specialOffersStateSlice';
import useNotifications from '../../../hooks/useNotifications';
import { cloneDeep, debounce } from 'lodash';
import { setSpecialOffersFiltersHeight } from '../generalStateSlice';
//

// TYPES
import { SpecialOfferBoat } from '../../../types/typeDefinitions';
import boatSource from '../../../types/enums/boatSource';
//

// ICONS
import { MdClose as CloseIcon } from 'react-icons/md';
//

const SpecialOffersFilterBar = () => {
  const { data: basesDropdownData } = useGetBasesForDropdownQuery();

  const { data: typesDropdownData } = useGetSpecialOfferTypesForDropdownQuery();

  const [getAllSpecialOffers, { currentData: dataFromGet }] =
    useLazyGetAllSpecialOffersQuery();

  const initLocalFilterValues = useMemo(() => {
    return {
      wildcardName: '',
      type: null,
      boatName: '',
      dates: {
        start: null,
        end: null,
      },
      startBase: null,
      endBase: null,
      discount: null,
      boatSource: null,
    };
  }, []);

  const currentFilterState = useAppSelector(
    (state) => state.specialOffersState.specialOffersFilterState.filter.filters
  ).find((singleFilter) => singleFilter.name === 'specialOfferId');

  const getLocalFilterStateFromStore = useAppSelector(
    (state) =>
      state.specialOffersState.specialOffersFilterState.localFilterState
  );

  const memoizedInitLocalState = useMemo(
    () => getLocalFilterStateFromStore,
    [getLocalFilterStateFromStore]
  );

  const isInManageMode = useCallback(() => {
    if (currentFilterState?.value !== null) {
      return true;
    }
    return false;
  }, [currentFilterState]);

  const [localFilterValues, setLocalFilterValues] = useState<any>(
    memoizedInitLocalState
  );

  // 1 = numeric text box, 2 = dropdown, 3 = date range
  const initOptionalFilterState = useMemo(() => {
    return [
      {
        name: 'type',
        displayName: 'Type',
        value: 2,
      },
      {
        name: 'dates',
        displayName: 'Dates',
        value: 3,
      },
      {
        name: 'startBase',
        displayName: 'Base from',
        value: 2,
      },
      {
        name: 'endBase',
        displayName: 'Base to',
        value: 2,
      },
      {
        name: 'discount',
        displayName: 'Discount(%)',
        value: 1,
      },
    ];
  }, []);

  const [optionalFilters, setOptionalFilters] = useState<any[]>(
    localFilterValues.optionalFilters
  );
  const [tempState, setTempState] = useState<any>(initOptionalFilterState);

  const OptionalFiltersController = () => {
    return (
      <MaterialGrid item className='filter-item'>
        <DropDownButton
          items={tempState.map((singleFilter: any) => singleFilter)}
          textField={'displayName'}
          text={'More filters...'}
          onItemClick={(event: DropDownButtonItemClickEvent) => {
            const tmp = optionalFilters.some(
              (singleFilter: any) => singleFilter.name === event.item.name
            );
            setOptionalFilters((prev: any) => {
              if (tmp === true) {
                return prev;
              } else return [...prev, event.item];
            });

            setTempState((prev: any) =>
              prev.filter(
                (singleFilter: any) => singleFilter.name !== event.item.name
              )
            );
          }}
        />
      </MaterialGrid>
    );
  };

  let dropdownData = null;

  const prepareOptionalFilters = () => {
    return (
      <>
        {optionalFilters?.length !== 0 && optionalFilters !== undefined
          ? optionalFilters.map((singleFilter: any) => {
              if (singleFilter.value === 1) {
                return (
                  <React.Fragment key={singleFilter.name}>
                    <MaterialGrid item className='filter-item'>
                      <NumericTextBox
                        label={singleFilter.displayName}
                        value={localFilterValues[singleFilter.name]}
                        onChange={(event: NumericTextBoxChangeEvent) => {
                          customSetState(singleFilter.name, event.value);

                          if (event.value && event.value < 1) {
                            handleUserActionNotification({
                              message: 'Please select a value greater than 0',
                              type: 'warning',
                              autoClose: 2500,
                            });
                          } else {
                            onSpecialOfferNumericTextBoxChange(
                              event,
                              singleFilter.name
                            );
                          }
                        }}
                        min={1}
                        spinners={false}
                        disabled={isInManageMode() === true}
                      />

                      <button
                        onClick={() => handleFilterRemove(singleFilter)}
                        className='close-button'
                        style={{ paddingBottom: '15px' }}
                      >
                        <CloseIcon />
                      </button>
                    </MaterialGrid>
                  </React.Fragment>
                );
              }

              if (singleFilter.value === 2) {
                if (singleFilter.name === 'type')
                  dropdownData = typesDropdownData;
                else dropdownData = basesDropdownData;
                return (
                  <React.Fragment key={singleFilter.name}>
                    {dropdownData !== undefined &&
                      dropdownData !== null &&
                      dropdownData?.length !== 0 && (
                        <MaterialGrid item className='filter-item'>
                          <DropDownList
                            data={dropdownData || []}
                            dataItemKey={'id'}
                            textField={'name'}
                            onChange={(event: DropDownListChangeEvent) => {
                              onFilterDropdownChange(event, singleFilter.name);
                              customSetState(
                                singleFilter.name,
                                event.value.name
                              );
                            }}
                            label={singleFilter.displayName}
                            value={{
                              name: localFilterValues[singleFilter.name],
                            }}
                            disabled={isInManageMode() === true}
                          />

                          <button
                            onClick={() => handleFilterRemove(singleFilter)}
                            className='close-button'
                            style={{ paddingBottom: '15px' }}
                          >
                            <CloseIcon />
                          </button>
                        </MaterialGrid>
                      )}
                  </React.Fragment>
                );
              }

              if (singleFilter.value === 3) {
                return (
                  <React.Fragment key={singleFilter.name}>
                    <MaterialGrid item className='filter-item'>
                      <DateRangePicker
                        onChange={(event: DateRangePickerChangeEvent) => {
                          onSpecialOfferDateRangeChange(event, 'dates');
                          customSetState(
                            'dates',
                            { start: event.value.start, end: event.value.end },
                            true
                          );
                        }}
                        value={{
                          start: localFilterValues.dates.start,
                          end: localFilterValues.dates.end,
                        }}
                        startDateInputSettings={{
                          label: undefined,
                          placeholder: 'Date from',
                          format: 'dd/MM/yyyy',
                        }}
                        endDateInputSettings={{
                          label: undefined,
                          placeholder: 'Date to',
                          format: 'dd/MM/yyyy',
                        }}
                        disabled={isInManageMode() === true}
                      />

                      <button
                        onClick={() => handleFilterRemove(singleFilter)}
                        className='close-button'
                        style={{ paddingBottom: '15px' }}
                      >
                        <CloseIcon />
                      </button>
                    </MaterialGrid>
                  </React.Fragment>
                );
              }
              return null;
            })
          : null}
      </>
    );
  };

  const handleFilterRemove = (item: any) => {
    const tmp = optionalFilters.filter(
      (singleFilter: any) => singleFilter.name !== item.name
    );
    setOptionalFilters(tmp);

    if (item.value === 1) {
      dispatch(
        onSpecialOffersFilterNumericTextBoxChange({
          name: item.name,
          eventValue: null,
        })
      );

      setLocalFilterValues((prev: any) => {
        return {
          ...prev,
          [String(item.name)]: null,
        };
      });
    }

    if (item.value === 2) {
      dispatch(
        onSpecialOffersFilterDropdownChange({
          name: item.name,
          eventValue: null,
        })
      );

      setLocalFilterValues((prev: any) => {
        return {
          ...prev,
          [String(item.name)]: null,
        };
      });
    }

    if (item.value === 3) {
      dispatch(
        onSpecialOffersFilterDateRangeChange({
          name: item.name,
          eventValue: { start: null, end: null },
        })
      );

      setLocalFilterValues((prev: any) => {
        return {
          ...prev,
          [String(item.name)]: { start: null, end: null },
        };
      });
    }

    setTempState(() => {
      const tmp = initOptionalFilterState.find(
        (singleFilter: any) => singleFilter.name === item.name
      );
      return [...tempState, tmp].sort((a: any, b: any) => {
        if (a.name > b.name) {
          return 1;
        }
        return -1;
      });
    });
  };

  const getSpecialOffersFilters = useAppSelector(
    (state) => state.specialOffersState.specialOffersFilterState
  );
  const specialOffersFilters = useMemo(
    () => getSpecialOffersFilters,
    [getSpecialOffersFilters]
  );

  const dispatch = useAppDispatch();

  const onFilterDropdownChange = (
    event: DropDownListChangeEvent,
    eventName: string
  ) =>
    dispatch(
      onSpecialOffersFilterDropdownChange({
        eventValue: event.value.id,
        name: eventName,
      })
    );

  const onFilterEnumDropdownChange = (
    event: DropDownListChangeEvent,
    eventName: string
  ) =>
    dispatch(
      onSpecialOffersFilterDropdownChange({
        eventValue: event.value.value,
        name: eventName,
      })
    );

  const onFilterInputChange = (event: any, eventName: string) =>
    dispatch(
      onSpecialOffersFilterTextInputChange({
        eventValue: event.target.value,
        name: eventName,
      })
    );

  const onSpecialOfferDateRangeChange = (
    event: DateRangePickerChangeEvent,
    eventName: string
  ) =>
    dispatch(
      onSpecialOffersFilterDateRangeChange({
        eventValue: { start: event.value.start, end: event.value.end },
        name: eventName,
      })
    );

  const onSpecialOfferNumericTextBoxChange = (
    event: NumericTextBoxChangeEvent,
    eventName: string
  ) =>
    dispatch(
      onSpecialOffersFilterNumericTextBoxChange({
        name: eventName,
        eventValue: event.value as number,
      })
    );

  const nullCheckToClearFilters = useMemo(
    () =>
      Object.values(specialOffersFilters.filter.filters).some(
        (singleFilter) => singleFilter.value === null
      ),
    [specialOffersFilters.filter.filters]
  );

  const removeNullSoonToBeInReduxStore = useMemo(
    () =>
      specialOffersFilters.filter.filters.filter(
        (singleFilter) => singleFilter.value !== null
      ),
    [specialOffersFilters.filter.filters]
  );

  const queryParamsWithoutNull = useMemo(() => {
    return {
      ...specialOffersFilters,
      filter: { filters: removeNullSoonToBeInReduxStore, logic: 'and' },
    };
  }, [specialOffersFilters, removeNullSoonToBeInReduxStore]);

  const resetFilters = useCallback(() => {
    dispatch(resetSpecialOffersFilterState());

    setLocalFilterValues(initLocalFilterValues);
    setOptionalFilters([]);
    setTempState(initOptionalFilterState);
  }, [dispatch, initLocalFilterValues, initOptionalFilterState]);

  const getSpecialOffersListBasedOnFilters = useCallback(async () => {
    //
    try {
      if (nullCheckToClearFilters) {
        await getAllSpecialOffers(queryParamsWithoutNull).unwrap();

        dataFromGet !== undefined &&
          dispatch(saveSpecialOffersList(dataFromGet.data));
      } else {
        await getAllSpecialOffers(specialOffersFilters).unwrap();

        dataFromGet !== undefined &&
          dispatch(saveSpecialOffersList(dataFromGet.data));
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    specialOffersFilters,
    dispatch,
    nullCheckToClearFilters,
    queryParamsWithoutNull,
    getAllSpecialOffers,
    dataFromGet,
  ]);

  useEffect(() => {
    getSpecialOffersListBasedOnFilters();
  }, [getSpecialOffersListBasedOnFilters]);

  const customSetState = useCallback(
    (eventName: string, eventValue: any, isDateTime = false) => {
      if (isDateTime) {
        setLocalFilterValues((prev: any) => {
          return {
            ...prev,
            [eventName]: { start: eventValue.start, end: eventValue.end },
          };
        });
      } else {
        setLocalFilterValues((prev: any) => {
          return { ...prev, [eventName]: eventValue };
        });
      }
    },
    []
  );

  // BOATS //

  const [getBoatsAutoCompleteSearch] = useLazyGetBoatsAutoCompleteSearchQuery();

  const getBoatsSpecialOffersFilters = useAppSelector(
    (state) => state.specialOffersState.boatsForAutoCompleteFilterState
  );
  const boatsSpecialOffersFilters = useMemo(
    () => getBoatsSpecialOffersFilters,
    [getBoatsSpecialOffersFilters]
  );

  const handleCloningEntity = useCallback(
    (entity: any) => cloneDeep(entity),
    []
  );

  const getBoatsSpecialOffersListFromStore = useAppSelector(
    (state) => state.specialOffersState.data.boatsAutoCompleteList
  );

  const boatsSpecialOffersList: SpecialOfferBoat[] | any = useMemo(
    () => handleCloningEntity(getBoatsSpecialOffersListFromStore),
    [getBoatsSpecialOffersListFromStore, handleCloningEntity]
  );

  const onFilterAutoCompleteChange = debounce(
    (event: any, eventName: string) =>
      dispatch(
        onSpecialOffersFilterAutoCompleteChange({
          eventValue: event.value,
          name: eventName,
        })
      ),
    300
  );

  const nullCheckFiltersForBoats = useMemo(
    () =>
      Object.values(boatsSpecialOffersFilters.filter.filters).some(
        (singleFilter) => singleFilter.value === null
      ),
    [boatsSpecialOffersFilters.filter.filters]
  );

  const getBoatsSpecialOffersListBasedOnFilters = useCallback(async () => {
    if (!nullCheckFiltersForBoats) {
      try {
        const req = await getBoatsAutoCompleteSearch(
          boatsSpecialOffersFilters
        ).unwrap();
        dispatch(saveBoatsAutoCompleteList(req));
      } catch (error) {
        console.error(error);
      }
    }
  }, [
    boatsSpecialOffersFilters,
    dispatch,
    nullCheckFiltersForBoats,
    getBoatsAutoCompleteSearch,
  ]);

  useEffect(() => {
    getBoatsSpecialOffersListBasedOnFilters();
  }, [getBoatsSpecialOffersListBasedOnFilters]);

  const { handleUserActionNotification } = useNotifications();

  const logEventValue = (event: AutoCompleteChangeEvent) => {
    // const tmp = event.value.split(',')[0];

    // novo

    if (event.value === '') {
      dispatch(
        setFilterOfferById({
          name: 'containsBoatWithId',
          // eventValue: tmpVal[0].id,
          eventValue: null,
        })
      );
    }

    const tmp = event.value.slice(0, event.value.indexOf('(')).trim();
    //
    if (boatsSpecialOffersList && 'data' in boatsSpecialOffersList) {
      const tmpVal = boatsSpecialOffersList.data.filter(
        (single: any) => single.name === tmp
      );

      tmpVal &&
        tmpVal?.length > 0 &&
        'id' in tmpVal[0] &&
        dispatch(
          setFilterOfferById({
            name: 'containsBoatWithId',
            eventValue: tmpVal[0].id,
          })
        );
    }
  };

  const prepareAutoCompleteData = (dataset: any) => {
    if (dataset && 'data' in dataset) {
      const tmpString = dataset.data.map((singleItem: any) => {
        return {
          name: `${singleItem.name} (${singleItem.manufacturer} ${singleItem.model} ${singleItem.variation})`,
          data: singleItem,
        };
      });

      return tmpString;
    }
    return [];
  };

  const [height, setHeight] = useState(0);
  const ref: any = useRef(null);

  useLayoutEffect(() => {
    if (ref.current.clientHeight) {
      setTimeout(() => {
        setHeight(ref.current.clientHeight);
      }, 0);
    }
  });

  useEffect(() => {
    dispatch(setSpecialOffersFiltersHeight(height));
  }, [dispatch, height]);

  useEffect(() => {
    return () => {
      dispatch(
        saveSpecialOffersLocalFilterState({
          ...localFilterValues,
          optionalFilters,
        })
      );
    };
  }, [dispatch, localFilterValues, optionalFilters]);

  return (
    <MaterialGrid container columnSpacing={2} ref={ref}>
      <MaterialGrid item className='filter-item'>
        <Input
          onKeyDown={(event) =>
            event.key === 'Enter' ? onFilterInputChange(event, 'name') : ''
          }
          onChange={(event: any) => {
            customSetState('wildcardName', event.target.value);
            onFilterInputChange(event, 'name');
          }}
          label={'Offer name'}
          value={localFilterValues.wildcardName}
          disabled={isInManageMode() === true}
        />
      </MaterialGrid>

      <MaterialGrid item className='filter-item'>
        <AutoComplete
          data={prepareAutoCompleteData(boatsSpecialOffersList)}
          placeholder='Boat name'
          textField='name'
          value={localFilterValues.boatName}
          onChange={(event: AutoCompleteChangeEvent) => {
            customSetState('boatName', event.value);
            onFilterAutoCompleteChange(event, 'boatModel');
            logEventValue(event);
          }}
          disabled={isInManageMode() === true}
        />
      </MaterialGrid>

      <MaterialGrid item className='filter-item'>
        <DropDownList
          data={Object.values(boatSource.properties)}
          dataItemKey={'value'}
          textField={'name'}
          label={'Offer source'}
          onChange={(event: DropDownListChangeEvent) => {
            onFilterEnumDropdownChange(event, 'boatSource');
            customSetState('boatSource', event.value.name);
          }}
          value={{ name: localFilterValues.boatSource }}
          disabled={isInManageMode() === true}
        />
      </MaterialGrid>

      {prepareOptionalFilters()}
      <OptionalFiltersController />

      <MaterialGrid item className='filter-item'>
        <button
          onClick={resetFilters}
          disabled={isInManageMode() === true}
          className='sky-button'
        >
          Reset filters
        </button>
      </MaterialGrid>
    </MaterialGrid>
  );
};

export default SpecialOffersFilterBar;
