// REACT
import React, { ReactElement } from 'react';
//

// KENDO
import {
  Grid,
  GridColumn as Column,
  GridDetailRowProps,
} from '@progress/kendo-react-grid';
//

// MUI
import { Grid as GridLayout } from '@mui/material';
//

// COMPONENTS
import { CommandCell } from '../../../components/CommandCell/CommandCell';
import { DragCell } from '../../../components/DragCell/DragCell';
import ReactRouterPrompt from 'react-router-prompt';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
//

// SERVICES
import { rowRender } from '../../../services/gridRowRender';
//

// TYPES
import { ModelVariationListItem } from '../../../types/typeDefinitions';
//

interface Props extends GridDetailRowProps {
  variations: ModelVariationListItem[];
  handleCreate: (itemType: string) => (modelIdForVariation: number) => void;
  handleEdit: (itemType: string) => (dataItem: any) => void;
  updateVariations: (data: ModelVariationListItem[]) => void;
  handleDelete?: (dataItem: any) => void;
}

interface State {
  variations: ModelVariationListItem[];
  variationActiveItem: ModelVariationListItem | null;
  hasUnsavedListChanges: boolean;
}

class ModelDetailRow extends React.Component<Props, State> {
  DragCell: any;
  constructor(props: Props) {
    super(props);

    this.DragCell = DragCell(this.handleDragStart, this.handleReorder);

    this.state = {
      variations: [],
      variationActiveItem: null,
      hasUnsavedListChanges: false,
    };
  }

  componentDidMount(): void {
    this.setState((_state) => ({
      variations: this.props.variations.filter(
        (v) => v.modelId === this.props.dataItem.id
      ),
    }));
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.variations !== this.props.variations) {
      this.setState((_state) => ({
        variations: this.props.variations.filter(
          (v) => v.modelId === this.props.dataItem.id
        ),
      }));
    }
  }

  // We left reordering logic here and we don't use Reorder component
  // because changing would be complex. Might change in future iterations.
  handleDragStart = (dataItem: any) => {
    this.setState((_prevState) => ({ variationActiveItem: dataItem }));
  };

  handleReorder = (dataItem: any) => {
    if (
      !this.state.variationActiveItem ||
      this.state.variationActiveItem.id === dataItem.id
    ) {
      return;
    }

    let reorderedData: ModelVariationListItem[] = [...this.state.variations];
    const prevIndex: number =
      reorderedData.findIndex(
        (item) =>
          this.state.variationActiveItem &&
          item.id === this.state.variationActiveItem.id
      ) || 0;
    const nextIndex: number = reorderedData.findIndex(
      (item) => item.id === dataItem.id
    );

    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, this.state.variationActiveItem);

    this.setState((_prevState) => ({
      hasUnsavedListChanges: true,
      variations: reorderedData
        .map((item, index) => ({
          ...item,
          order: index + 1,
        }))
        .sort((a, b) => {
          if (!a.modelId || !a.order) {
            return 1;
          }

          if (!b.modelId || !b.order) {
            return -1;
          }

          if (a.modelId < b.modelId) {
            return -1;
          }
          if (a.modelId > b.modelId) {
            return 1;
          }

          if (a.order < b.order) {
            return -1;
          }
          if (a.order > b.order) {
            return 1;
          }

          return 0;
        }),
    }));
  };

  handleCreate = (itemType: string) => () => {
    this.props.handleCreate(itemType)(this.props.dataItem.id);
  };

  handleEdit = (itemType: string) => (dataItem: any) => {
    this.props.handleEdit(itemType)(dataItem);
  };

  handleCancelListChanges = () => {
    this.setState((_prevState) => ({
      variations: this.props.variations.filter(
        (v) => v.modelId === this.props.dataItem.id
      ),
      variationActiveItem: null,
      hasUnsavedListChanges: false,
    }));
  };

  handleSaveListChanges = () =>
    this.props.updateVariations(this.state.variations);

  handleDeleteVariation = (dataItem: any) =>
    this.props.handleDelete ? this.props.handleDelete(dataItem) : null;

  render(): ReactElement {
    return (
      <GridLayout container direction='column' spacing={1}>
        <ReactRouterPrompt when={this.state.hasUnsavedListChanges}>
          {({ isActive, onConfirm, onCancel }) => (
            <CustomDialog
              open={isActive}
              title='You have unsaved changes. Are you sure you want to leave?'
              confirmText='Confirm'
              onClose={onCancel}
              onConfirm={onConfirm}
            />
          )}
        </ReactRouterPrompt>

        <GridLayout item>
          <button
            onClick={this.handleCreate('Variation')}
            className='pink-button'
          >
            Add new variation
          </button>
        </GridLayout>

        <GridLayout item>
          <Grid
            data={this.state.variations}
            scrollable='none'
            resizable={true}
            rowRender={rowRender}
          >
            <Column
              title=''
              width={45}
              groupable={false}
              sortable={false}
              filterable={false}
              resizable={false}
              cell={this.DragCell}
            />
            <Column
              field='_command'
              groupable={false}
              sortable={false}
              filterable={false}
              resizable={false}
              title=' '
              width={45}
              cell={(props) => (
                <CommandCell
                  {...props}
                  hasEditCommand={true}
                  handleEdit={this.handleEdit('Variation')}
                />
              )}
            />
            <Column
              field='_command'
              groupable={false}
              sortable={false}
              filterable={false}
              resizable={false}
              title=' '
              width={45}
              cell={(props) => (
                <CommandCell
                  {...props}
                  // hasEditCommand={true}
                  // handleEdit={this.handleEdit('Variation')}
                  hasDeleteCommand={true}
                  handleDelete={this.handleDeleteVariation}
                />
              )}
            />
            <Column field='id' title='ID' />
            <Column field='name' title='Variation' />
            <Column field='subvariation' title='Subvariation' />
          </Grid>
        </GridLayout>

        <GridLayout item container spacing={1} justifyContent='flex-end'>
          <GridLayout item>
            <button
              disabled={!this.state.hasUnsavedListChanges}
              onClick={this.handleCancelListChanges}
              className='red-button'
            >
              Cancel
            </button>
          </GridLayout>

          <GridLayout item>
            <button
              onClick={this.handleSaveListChanges}
              disabled={!this.state.hasUnsavedListChanges}
              className='primary-button'
            >
              Save
            </button>
          </GridLayout>
        </GridLayout>
      </GridLayout>
    );
  }
}

export default ModelDetailRow;
