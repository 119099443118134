// KENDO
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import { DragCell } from '../../../components/DragCell/DragCell';
import { CommandCell } from '../../../components/CommandCell/CommandCell';
import { LanguageCell } from '../../../components/LanguageCell/LanguageCell';
//

// SERVICES
import { rowRender } from '../../../services/gridRowRender';
//

// TYPES
import {
  CategoryDetailRowProps,
  EquipmentPartListItem,
} from '../../../types/typeDefinitions';
//

// HOOKS
import useNotifications from '../../../hooks/useNotifications';
import { useDeleteEquipmentPartMutation } from './equipmentApiSlice';
//

export function CategoryDetailRow(
  handleDragStart: CategoryDetailRowProps['handleDragStart'],
  handleReorder: CategoryDetailRowProps['handleReorder'],
  handleCreate: CategoryDetailRowProps['handleCreate'],
  handleEdit: CategoryDetailRowProps['handleEdit']
) {
  const GridRow = (props: CategoryDetailRowProps) => {
    const DragCellF = DragCell(handleDragStart, handleReorder);

    const handleCreateF = () => handleCreate(props.dataItem.id);

    const handleEditF = (dataItem: EquipmentPartListItem) =>
      handleEdit(dataItem);

    const { handleUserActionNotification } = useNotifications();

    const [
      deleteEquipmentPart,
      {
        isLoading: isDeleteEquipmentPartLoading,
        isError: isDeleteEquipmentPartError,
      },
    ] = useDeleteEquipmentPartMutation();

    const handleDeletePart = async (dataItem: any) => {
      if (dataItem.hasBoat === true) {
        handleUserActionNotification({
          type: 'error',
          message:
            'Boats with this equipment part exist. Please remove this part from boats before deleting.',
          autoClose: 2500,
        });
      } else {
        try {
          await deleteEquipmentPart(dataItem.id).unwrap();

          if (!isDeleteEquipmentPartLoading && !isDeleteEquipmentPartError) {
            handleUserActionNotification({
              message: 'Equipment part successfully deleted!',
              autoClose: 2500,
              type: 'success',
            });
          }
        } catch (error: any) {
          console.error(error);
          handleUserActionNotification({
            message: error.data.message,
            autoClose: 2500,
            type: 'error',
          });
        }
      }
    };

    return (
      <MaterialGrid container direction='column' spacing={1}>
        <MaterialGrid item>
          <button className='pink-button' onClick={handleCreateF}>
            Add new part
          </button>
        </MaterialGrid>

        <MaterialGrid item width={'100%'}>
          <Grid
            data={props.dataItem.parts}
            scrollable='none'
            resizable={true}
            rowRender={rowRender}
          >
            <Column
              title=''
              width={45}
              groupable={false}
              sortable={false}
              filterable={false}
              resizable={false}
              cell={DragCellF as any}
            />
            <Column
              field='_command'
              width={50}
              groupable={false}
              sortable={false}
              filterable={false}
              resizable={false}
              title=' '
              cell={(props) => (
                <CommandCell
                  {...props}
                  isEquipment={true}
                  hasEditCommand={true}
                  handleEdit={handleEditF}
                  hasDeleteCommand={true}
                  handleDelete={handleDeletePart}
                />
              )}
            />
            <Column field='id' title='ID' />
            <Column field='name' title='Part' />
            <Column
              field='numberOfTranslations'
              title='Name translations'
              cell={(props) => <LanguageCell {...props} />}
            />
          </Grid>
        </MaterialGrid>
      </MaterialGrid>
    );
  };
  return GridRow;
}
