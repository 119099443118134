// KENDO
import { FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  GridDetailRowProps,
  GridItemChangeEvent,
  GridRowClickEvent,
} from '@progress/kendo-react-grid';
import { UploadFileInfo } from '@progress/kendo-react-upload';
import React from 'react';
//

export type BoatType = 1 | 2 | 3;
export type BoatState = 1 | 2 | 3 | 4;
export type BoatStatus = 1 | 2 | 3 | 4;
export type PhotoType = 1 | 2 | 3 | 4 | 5;
export type PhotoFeaturedType = -1 | 1 | 2 | 3;

export interface Enumeration {
  [key: string]: any;
  properties: {
    [nestedKey: number]: { name: string; value: number };
  };
}

export interface GridFilter {
  name: string;
  placeholder: string;
  type: string;
  data: any;
  value: any;
  dataItemKey?: string;
  textField?: string;
  selected: boolean;
  isDisabled: boolean;
  isRemovable: boolean;
  [k: string]: any;
}

export interface ModelVariationLookup extends Lookup {
  subvariation: string | null;
}

export interface Filter {
  value: number | string | null;
  operator: string;
  condition: string;
  serverKey: string;
  serverValue: number | string | null;
  type: string;
}

export interface AlternativeProduct {
  productId: number | null;
  product: string | null;
}

export interface Boat
  extends Omit<BoatListItem, 'berthsCabinsDraft' | 'isEnabled' | 'order'> {
  state: BoatState | null;
  isDisabled: boolean | null;
  manufacturerId: number | null;
  modelVariationId: number | null;
  berths: string | null;
  cabins: string | null;
  draft: string | null;
  promotionalPrice: number | null;
  availableFrom: string | null;
  youTubeVideoUrl: string | null;
  virtualTourUrl: string | null;
  previewUrl?: string | null;
  descriptions: Translation[];
  brochures: Document[];
  offers: Document[];
  companyId: number | null;
  company: string | null;
  source: string | null;
  mmkId: number | null;
  importedDate: string | Date | null;
  mainBaseId: number | null;
  mainBase: string | null;
  deposit: number | null;
  defaultProductId: number | null;
  defaultProduct: string | null;
  alternativeProducts: AlternativeProduct[];
  toilets: number | null;
  hasSkipperCabin: boolean | null;
  isLuxury: boolean | null;
  checkInTime: string | null;
  checkOutTime: string | null;
  specification: BoatSpecificationPart[] | null;
  equipment: BoatEquipmentPart[] | null;
  photos: BoatPhoto[] | null;
  prices: BoatPrice[] | null;
  bases: BoatBase[] | null;
  maximumDiscount: number | null;
  lockedPhotos?: boolean | null;
  lockedBoat?: boolean | null;
}

export interface NewBoat
  extends Omit<Boat, 'id' | 'mmkId' | 'importedDate' | 'source'> {}

export interface LocalizedValue {
  languageId: number;
  languageCode: string;
}

export interface EquipmentPartListItem {
  id: number;
  name: string;
  order: number | null;
  numberOfTranslations: number;
  categoryId: number;
  mmkId: number | null;
  hasEquipment?: boolean;
}

export interface EquipmentCategoryListItem {
  id: number;
  name: string;
  order: number | null;
  numberOfTranslations: number;
}

export interface Translation extends LocalizedValue {
  text: string | null;
}

export interface EquipmentCategory {
  id: number;
  order: number | null;
  translations: Translation[];
}

export interface EquipmentPart {
  id: number;
  order: number | null;
  translations: Translation[];
  categoryId: number;
  mmkId: number | null;
}

export interface NewEquipmentCategory
  extends Pick<EquipmentCategory, 'order' | 'translations'> {}

export interface NewEquipmentPart
  extends Pick<EquipmentPart, 'order' | 'translations' | 'categoryId'> {}

export interface Manufacturer {
  id: number;
  name: string;
  websiteIds?: number[] | null;
}

export interface ManufacturerListItem {
  id: number;
  name: string;
  websites?: Website[];
}

export interface NewManufacturer
  extends Pick<Manufacturer, 'name' | 'websiteIds'> {}

export interface Model {
  id: number;
  name: string;
  manufacturerId: number | null;
  photoUrl?: string;
  descriptions: Translation[];
  photoDescriptions: Translation[];
}

export interface ModelListItem {
  id: number;
  name: string;
  manufacturer: string;
  hasPhoto: boolean;
  numberOfDescriptionTranslations: number;
}

export interface NewModel
  extends Pick<
    Model,
    | 'name'
    | 'manufacturerId'
    | 'descriptions'
    | 'photoUrl'
    | 'photoDescriptions'
  > {}

export interface ModelVariation {
  id: number;
  name: string;
  subvariation: string | null;
  modelId: number | null;
  order?: number | null;
}

export interface ModelVariationListItem extends ModelVariation {}

export interface NewModelVariation
  extends Pick<ModelVariation, 'name' | 'subvariation' | 'modelId' | 'order'> {}

export interface SpecificationListItem {
  id: number;
  name: string;
  order: number | null;
  numberOfTranslations: number;
}

export interface SpecificationListItem {
  id: number;
  name: string;
  order: number | null;
  numberOfTranslations: number;
}

export interface Specification {
  id: number;
  order: number | null;
  translations: Translation[];
}

export interface NewSpecification
  extends Pick<Specification, 'order' | 'translations'> {}

export interface LocalizedValue {
  languageId: number;
  languageCode: string;
}

export interface Translation extends LocalizedValue {
  text: string | null;
}

type LookupField = 'name' | 'code';
export type Lookup = {
  id: number;
} & {
  [k in LookupField]?: string;
};

export interface Language extends Lookup {}

export interface Website extends Lookup {}

export function isDefined<T>(arg: T | undefined): arg is T {
  return typeof arg !== 'undefined';
}

export type Validator = (value: any) => boolean;

export interface Specification {
  id: number;
  order: number | null;
  translations: Translation[];
}

export interface SpecificationListItem {
  id: number;
  name: string;
  order: number | null;
  numberOfTranslations: number;
  hasBoat?: boolean;
}

export interface NewSpecification
  extends Pick<Specification, 'order' | 'translations'> {}

export interface CommonState {
  data: {
    [k: string]: any;
  };
  [x: string]: any;
}

export interface EquipmentPartLookup extends Lookup {
  order: number | null;
  categoryId: number;
}

export interface EquipmentCategoryLookup extends Lookup {
  order: number | null;
}

export interface User {
  username: string;
  token: string;
  tokenExpiry: Date;
}

export interface DataState {
  sort: SortDescriptor[];
  filter: {
    filters: FilterDescriptor[];
  };
  skip: number;
  take: number;
}

export interface Document extends LocalizedValue {
  id: number | null;
  fileName: string | null;
}

export interface BoatSpecificationPart {
  partId: number;
  value: string | null;
  partOrder: number;
}

export interface BoatSpecificationPartsTemplate {
  partId: number;
  partName: string;
}

export interface BoatPrice {
  dateTo: Date | string | null | undefined;
  dateFrom: Date | string | null | undefined;
  price: number | null | undefined;
  id?: number;
  tempId?: number;
  inEdit?: boolean | string | undefined;
}

export interface BoatBase {
  dateTo: Date | string | null | undefined;
  dateFrom: Date | string | null | undefined;
  base: string | null | undefined;
  id?: number;
  baseId?: number;
  tempId?: number;
  inEdit?: boolean | string | undefined;
}

export interface BoatEquipmentPart {
  partId: number;
  partName: string | null;
  partOrder: number;
  categoryId: number;
}

export interface BoatPhoto {
  id: number;
  url: string;
  order: number;
  type: PhotoType;
  featuredType: PhotoFeaturedType;
  descriptions: Translation[];
}

export interface BoatListItem {
  id: number | null;
  name: string | null;
  type: BoatType | null;
  status: BoatStatus | null;
  manufacturer: string | null;
  modelId: number | null;
  model: string | null;
  variation?: string | null;
  manufacturerModelVariation?: string | null;
  modelVariation: string | null;
  subvariation: string | null;
  produced: number | null;
  lengthOverAll: string | null;
  berthsCabinsDraft: string | null;
  salePrice: number | null;
  salePriceHasTax: boolean | null;
  order: number | null;
  isEnabled: boolean | null;
}

export interface Filter {
  value: number | string | null;
  operator: string;
  condition: string;
  serverKey: string;
  serverValue: number | string | null;
  type: string;
}

export interface Discount {
  name: string | null;
  typeId: number | null;
  type: string | null;
  sailingDateFrom: Date | null;
  sailingDateTo: Date | null;
  bookingDateFrom: Date | null;
  bookingDateTo: Date | null;
  daysFrom: number | null;
  daysTo: number | null;
  availableInBaseId: number | null;
  availableInBase: string | null;
  includedInBasePrice: boolean | null;
  excludesOtherDiscounts: boolean | null;
  affectedByMaximum: boolean | null;
  appliedOn: number | null;
  id?: number;
  tempId?: number;
  isToUpdate?: boolean;
  isNew?: boolean;
}

export interface DiscountColumn extends Discount {
  sailingDateRange?: string | null;
  bookingDateRange?: string | null;
  daysRange?: string | null;
}

export interface DiscountBoatDetails {
  id: number;
  name: string | null;
  manufacturer: string | null;
  model: string | null;
  variation: string | null;
  type: string | null;
  yearProduced: number | null;
  companyName: string | null;
  appliedOn?: any;
}

export interface UpdatedExtraName {
  languageId: number;
  languageCode: string;
  text: string;
}

export interface Extra {
  name: string | null;
  translations: UpdatedExtraName[];
  price: number | null;
  timeUnitId: number | null;
  timeUnit: string | any;
  isPerPerson: boolean | null;
  sailingDateFrom: Date | null;
  sailingDateTo: Date | null;
  validDaysFrom: Date | null;
  validDaysTo: Date | null;
  isRequired: boolean | null;
  isIncludedInBasePrice: boolean | null;
  isPayableOnInovice: boolean | null;
  availableInBaseId: number | null;
  availableInBase: string | null;
  appliedOn: number | null;
  id: number;
  tempId?: number;
  isToUpdate?: boolean;
  isNew?: boolean;
}

export interface ExtraColumn extends Extra {
  sailingDateRange?: string | null;
  validDaysRange?: string | null;
  perPerson?: string | null;
  required?: string | null;
}

export interface ExtraBoatDetails {
  id: number;
  name: string | null;
  manufacturer: string | null;
  model: string | null;
  variation: string | null;
  type: string | null;
  yearProduced: number | null;
  companyName: string | null;
  company?: string | null;
  appliedOn?: any;
  yearOfProduction?: number | null;
}

export interface SpecialOffer {
  id: number;
  name: string | null;
  mmkId: number | null;
  typeName: string | null;
  typeId: number | null;
  boat: {
    id: number;
    manufacturer: string | null;
    model: string | null;
    variation: string | null;
    yearOfProduction: number | null;
    name: string | null;
    type: string | null;
    company: string | null;
  };
  startBase: string | null;
  startBaseId: number | null;
  endBase: string | null;
  endBaseId: number | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  validDateFrom?: Date | null;
  validDateTo?: Date | null;
  product: string | null;
  productId: number | null;
  price: number | null;
  discountPercentage: number | null;
  discountedPrice: string | number | null;
  tempId?: number;
  isToUpdate?: boolean;
  isNew?: boolean;
}

export interface SpecialOfferColumn extends SpecialOffer {
  boatName?: string | null;
  dates?: string | null;
  validDates?: string | null;
  bases?: string | null;
}

export interface SpecialOfferBoat {
  id: number;
  manufacturer: string | null;
  model: string | null;
  variation: string | null;
  yearOfProduction: number | null;
  name: string | null;
  typeId: number | null;
  typeName: string | null;
  company: string | null;
  boatName?: string | null;
}

export interface BoatAutoComplete extends SpecialOfferBoat {
  boatName: string | null;
}

export interface Base {
  id: number;
  name: string;
  countryId: number | null;
  countryName: string | null;
  sailingAreas: [
    {
      name: string;
    }
  ];
  mmkId: number | null;
}

export interface Country {
  id: number;
  name: string;
  mmkId: number | null;
}

export interface SailingArea {
  id: number;
  name: string;
  mmk: number | null;
}

export interface Company {
  id: number;
  name: string;
  mmkId: number | null;
}

export interface CardProps {
  children: any;
  plain: any;
  profile: any;
  blog: any;
  raised: any;
  background: any;
  pricing: any;
  color: any;
  product: any;
  testimonial: any;
  chart: any;
  login: any;
  [k: string]: any;
}

export interface CardBodyProps {
  children: any;
  background: any;
  plain: any;
  formHorizontal: any;
  pricing: any;
  signup: any;
  color: any;
  profile: any;
  calendar: any;
  [k: string]: any;
}

export interface CardFooterProps {
  children: any;
  plain: any;
  profile: any;
  pricing: any;
  testimonial: any;
  stats: any;
  chart: any;
  product: any;
  [k: string]: any;
}

export interface CardHeaderProps {
  children: any;
  color: any;
  plain: any;
  image: any;
  contact: any;
  signup: any;
  stats: any;
  icon: any;
  text: any;
  [k: string]: any;
}

export interface CommandCellProps {
  dataItem?: any;
  className?: any;
  hasEditCommand?: boolean;
  hasDeleteCommand?: boolean;
  hasApplyCommand?: boolean;
  hasManageCommand?: boolean;
  isExtra?: boolean;
  isEquipment?: boolean;
  isBoat?: boolean;
  handleEdit?: (dataItem: any) => any;
  handleDelete?: (dataItem: any) => any;
  handleApply?: (dataItem: any) => any;
  handleManage?: (dataItem: any) => any;
}

export interface CustomDialogProps {
  open: boolean;
  title: string;
  closeText?: string;
  confirmText?: string;
  dialogProps?: any;
  onClose?: any;
  onConfirm?: any;
  children?: any;
}

export interface CustomInputProps {
  formControlProps?: any;
  labelText?: string;
  id?: string;
  labelProps?: any;
  inputProps?: any;
  error?: boolean;
  white?: boolean;
  success?: boolean;
  helpText?: string;
}

export interface DragCellProps {
  dataItem: any;
  handleItemDragStart: (dataItem: any, type?: string) => any;
  handleItemReorder: (dataItem: any, type?: string) => any;
}

export interface FormGridElementProps {
  label?: string;
  topLabel?: boolean;
  children?: any;
  component?: any;
  value?: any;
  additionalProps?: any;
  onChange?: any;
}

export interface ToastContentInterface {
  typeOfToast: string;
  toastBody: string | any;
}

export interface BoatDetailsBasesProps {
  data: BoatBase[] | null;
  enterEdit: (dataItem: any, field: any) => void;
  exitEdit: () => void;
  handleAddNewBase: (tempID: number) => void;
  handleValueChange: (event: GridItemChangeEvent) => void;
  handleDelete: (dataItem: any) => void;
  boatId: number;
  hasMMK?: number | null;
}

export interface BoatDetailsContainerOwnProps {
  classes?: any;
  boatState: number;
}

export interface BoatDetailsContainerState {
  value: number;
  hasUnsavedChanges: boolean;
  isFormValid: boolean;
  generalInfo: Omit<
    Boat,
    'specification' | 'equipment' | 'photos' | 'prices' | 'bases'
  >;
  specification: BoatSpecificationPart[] | null;
  equipment: BoatEquipmentPart[] | null;
  photos: BoatPhoto[] | null;
  prices: BoatPrice[] | null;
  bases: BoatBase[] | null;
  specPartInEditId: number | null;
  editField?: string;
  selectedCategory: EquipmentCategoryLookup | null;
  isWindowVisible: boolean;
  [k: string]: any;
}

export interface DocumentUploadProps {
  classes?: any;
  documentType: number;
  boatId: number;
  documents: Document[];
  state: BoatState | null;
  currentChosenLang: any;
}

export interface DocumentUploadState {
  [k: string]: UploadFileInfo[];
}

export interface BoatDetailsGeneralInformationProps {
  classes: any;
  isInEditMode: boolean;
  isInCopyMode: boolean;
  allManufacturers: Lookup[];
  allModels: Lookup[];
  allModelVariations: ModelVariationLookup[];
  defaultLanguage: string;
  id: number | null;
  name: string | null;
  manufacturer: {
    id: number | null;
    name: string | null;
  };
  model: {
    id: number | null;
    name: string | null;
  };
  modelVariation: {
    id: number | null;
    name: string | null;
  };
  subvariation: string | null;
  type: BoatType | null;
  state: BoatState | null;
  produced: number | null;
  lengthOverAll: string | null;
  berths: string | null;
  cabins: string | null;
  draft: string | null;
  salePrice: number | null;
  salePriceHasTax: boolean | null;
  promotionalPrice: number | null;
  availableFrom: string | null;
  youTubeVideoUrl: string | null;
  virtualTourUrl: string | null;
  descriptions: Translation[];
  brochures: Document[];
  offers: Document[];
  allCompanies: { id: number | null; name: string | null }[];
  company: {
    id: number | null;
    name: string | null;
  };
  source: string | null;
  mmkId: number | null;
  importedDate: string | Date | null;
  allBases: { id: number | null; name: string | null }[];
  mainBase: {
    id: number | null;
    name: string | null;
  };
  deposit: number | null;
  allDefaultProducts: { id: number | null; name: string | null }[];
  defaultProduct: {
    id: number | null;
    name: string | null;
  };
  allAlternativeProducts: AlternativeProduct[];
  alternativeProducts: AlternativeProduct[];
  toilets: number | null;
  hasSkipperCabin: boolean | null;
  isLuxury: boolean | null;
  checkInTime: string | null;
  checkOutTime: string | null;
  maximumDiscount: number | null;
  handleStateChange: (
    stateKey: string,
    languageCode?: string
  ) => (changes: any, formUtils: any) => void;
  handleTextInputChange: (field: string) => (event: any) => void;
  handleDescriptionChange: (languageId: number) => (event: any) => void;
  formElementStateReducer: (
    stateKey: string
  ) => (state: any, changes: any) => any;
  hasUnsavedChangesFlag?: boolean;
  fieldValidityOnSubmit?: any;
}

export interface BoatEquipmentCategoryGridItem extends EquipmentCategoryLookup {
  expanded: boolean;
  parts: BoatEquipmentPart[];
}

export interface BoatDetailsInventoryProps {
  classes: any;
  boatEquipment: BoatEquipmentPart[] | null;
  otherEquipmentCategories: EquipmentCategoryLookup[];
  otherEquipmentParts: EquipmentPartLookup[];
  categorySelected: boolean;
  handleCategorySelect: (dataItem: EquipmentCategoryLookup) => void;
  handlePartAdd: (part: EquipmentPartLookup) => void;
  handlePartDelete: (part: BoatEquipmentPart) => void;
  handlePartDragStart: (dataItem: any, type?: string) => void;
  handlePartReorder: (dataItem: any, type?: string) => void;
  hasMMK?: number | null;
}

export interface BoatDetailsInventoryState {
  categoryValue: EquipmentCategoryLookup | null;
  partValue: EquipmentPartLookup | null;
  expandedCategoryIds: number[];
}

export interface DetailRowProps extends GridDetailRowProps {
  handleDragStart: BoatDetailsInventoryProps['handlePartDragStart'];
  handleReorder: BoatDetailsInventoryProps['handlePartReorder'];
  handleDelete: BoatDetailsInventoryProps['handlePartDelete'];
  hasMMK?: number | null;
}

export type LocalizedDescriptionInput = ({
  value,
  language,
  isRequired,
  isValid,
  change,
  isValidOnSubmit,
}: {
  value: string | null;
  language: string;
  isRequired?: boolean;
  isValid?: boolean;
  change: (event: any) => any;
  isValidOnSubmit?: boolean;
}) => JSX.Element;

export interface BoatDetailsPhotosProps {
  photos: BoatPhoto[] | null;
  state: BoatState | null;
  boatId: number | null;
  maxOrderNums: {
    type: number;
    order: number;
  }[];
  handleReorder: (type: PhotoType, newPhotos: BoatPhoto[]) => void;
  handleAddPhoto: (data: any) => void;
  handleChangePhotoUrl: (data: any) => void;
  handleUpdatePhoto: (data: BoatPhoto & { boatId: number }) => void;
  handleDeletePhoto: (data: any) => void;
  handleFeaturedTypeChange: (
    photoId: number,
    event: DropDownListChangeEvent
  ) => void;
  currentPhotoState: BoatPhoto[] | null;
}

export interface BoatDetailsPhotosState {
  addWindowVisible: boolean;
  editWindowVisible: boolean;
  confirmDeleteWindowVisible: boolean;
  photoIdToUpdate: number | null;
  [k: string]: any;
}

export interface BoatDetailsEditPhotoModalProps {
  photo: BoatPhoto;
  state: BoatState | null;
  isWindowVisible: boolean;
  boatId: number | null;
  handleEdit: (data: BoatPhoto & { boatId: number }) => void;
  handleChangePhotoUrl: (data: any) => void;
  handleCloseWindow: () => void;
}

export interface EditPhotoModalState {
  photo: BoatPhoto | null;
  newFile: UploadFileInfo[];
  imagePreviewUrl: string | null;
}

export interface DeletePhotoModalProps {
  isWindowVisible: boolean;
  handleDelete: () => void;
  handleCloseWindow: () => void;
}

export interface PhotoCardProps {
  photo: BoatPhoto;
  handleOpenWindow: (name: string) => (photoIdToUpdate: number) => void;
  handleDropDownChange: (event: DropDownListChangeEvent) => void;
  handleEdit: (data: any) => void;
}

export interface BoatDetailsPhotoUploadModalProps {
  isWindowVisible: boolean;
  state: BoatState | null;
  boatId: number | null;
  maxOrderNums: { type: number; order: number }[];
  handleCloseWindow: () => void;
  handleAddPhoto: (data: any) => void;
}

export interface BoatDetailsPhotoUploadModalState {
  newPhotos: {
    files: UploadFileInfo[];
    type: { name: string; value: number };
  };
}

export interface BoatDetailsPricesProps {
  data: BoatPrice[] | null;
  // handleEdit: (dataItem: any) => void;
  enterEdit: (dataItem: any, field: any) => void;
  exitEdit: () => void;
  handleAddNewPrice: (tempID: number) => void;
  handleValueChange: (event: GridItemChangeEvent) => void;
  handleDelete: (dataItem: any) => void;
  boatId: number;
}

export interface BoatSpecificationPartGridItem extends BoatSpecificationPart {
  partName: string | undefined;
  inEdit: string | null;
}

export interface BoatDetailsSpecificationProps {
  boatSpecificationParts: BoatSpecificationPartGridItem[];
  otherSpecificationParts: Lookup[];
  handleAdd: (part: Lookup) => void;
  handleDelete: (part: BoatSpecificationPartGridItem) => void;
  handleValueChange: (event: GridItemChangeEvent) => void;
  handleItemDragStart: (dataItem: any) => any;
  handleItemReorder: (dataItem: any, type?: string) => void;
  enterEdit: (dataItem: any, field: any) => void;
  exitEdit: () => void;
  source: string | null;
}

export interface BoatDetailsSpecificationState {
  value: Lookup | null;
}

export type BoatDetailsTextInputFormElementType = ({
  label,
  value,
  isRequired,
  isValid,
  change,
  isSubmit,
}: {
  label: string;
  value: string;
  isRequired?: boolean;
  isValid?: boolean;
  className?: string;
  change: (event: any) => any;
  isSubmit?: boolean;
}) => JSX.Element;

export interface BoatListGridProps {
  boats: BoatListItem[];
  loading: boolean;
  total: number;
  dataState: any;
  boatState: number;
  getBoats: (gridDataState: any) => void;
  handleDragStart?: any;
  handleReorder?: any;
  gridPropForBoats?: any;
}

export interface BoatListContainerState {
  boats: BoatListItem[];
  hasUnsavedListChanges: boolean;
  filters: GridFilter[];
}

export interface LazyInterface extends Company, Base, Country, SailingArea {
  tempId: number;
  hasBoat?: boolean;
  hasBase?: boolean;
}

export interface CompaniesGridProps {
  data: Company[];
  isLoading: boolean;
  enterEdit: (dataItem: any, field: string) => void;
  exitEdit: () => void;
  handleAddNew: () => void;
  handleValueChange: (event: GridItemChangeEvent) => void;
  handleRemoveFromUi: (dataItemId: LazyInterface) => void;
}

export interface CategoryDetailRowProps extends GridDetailRowProps {
  dataItem: EquipmentCategoryGridItem;
  handleDragStart: (dataItem: any, type?: string) => void;
  handleReorder: (dataItem: any, type?: string) => void;
  handleCreate: (categoryId: number) => void;
  handleEdit: (dataItem: EquipmentPartListItem) => void;
}

export interface EquipmentCategoryGridItem extends EquipmentCategoryListItem {
  expanded: boolean;
  parts: EquipmentPartListItem[];
}

export interface EquipmentCategoryGridProps {
  categories: EquipmentCategoryListItem[];
  parts: EquipmentPartListItem[];
  handleCategoryEdit: (dataItem: EquipmentCategoryGridItem) => void;
  handlePartEdit: (dataItem: EquipmentPartListItem) => void;
  handleCreatePart: (categoryId: number) => void;
  handleCategoryDragStart: (dataItem: any, type?: string) => void;
  handleCategoryReorder: (dataItem: any, type?: string) => void;
  handlePartDragStart: (dataItem: any, type?: string) => void;
  handlePartReorder: (dataItem: any, type?: string) => void;
}

export interface EquipmentCategoryGridState {
  expandedCategoryIds: number[];
}

export interface EquipmentContainerState {
  hasUnsavedListChanges: boolean;
  categories: EquipmentCategoryListItem[];
  parts: EquipmentPartListItem[];
  isWindowVisible: boolean;
  isCreateOrEdit: string;
  newOrSelectedItem:
    | NewEquipmentCategory
    | EquipmentCategory
    | NewEquipmentPart
    | EquipmentPart
    | null;
  categoryIdForPart: number | null;
  [k: string]: any;
}

export interface ExtrasGridProps {
  data: Partial<Extra>[];
  isLoading: boolean;
  handleEdit: (dataItem: Partial<Extra>) => void;
  handleApply: (dataItem: Extra) => void;
  isWindowVisible: boolean;
  typeOfWindow: string;
  extraDataItem: Partial<Extra>;
  handleSubmit: (data: Partial<Extra>) => void;
  handleClose: () => void;
  generateTempId: () => number;
}

export interface BasesGridProps {
  data: Base[];
  isLoading: boolean;
  enterEdit: (dataItem: any, field: string) => void;
  exitEdit: () => void;
  handleAddNew: () => void;
  handleValueChange: (event: GridItemChangeEvent) => void;
  handleRemoveFromUi: (dataItemId: LazyInterface) => void;
}

export interface CountriesGridProps {
  data: Country[];
  isLoading: boolean;
  enterEdit: (dataItem: any, field: string) => void;
  exitEdit: () => void;
  handleAddNew: () => void;
  handleValueChange: (event: GridItemChangeEvent) => void;
  handleRemoveFromUi: (dataItemId: LazyInterface) => void;
}

export interface SailingAreasGridProps {
  data: SailingArea[];
  isLoading: boolean;
  enterEdit: (dataItem: any, field: string) => void;
  exitEdit: () => void;
  handleAddNew: () => void;
  handleValueChange: (event: GridItemChangeEvent) => void;
  handleRemoveFromUi: (dataItemId: LazyInterface) => void;
}

export interface ManufacturerAndModelContainerState {
  manufacturers: ManufacturerListItem[];
  models: ModelListItem[];
  variations: ModelVariationListItem[];
  selectedManufacturerId: number | null;
  isWindowVisible: boolean;
  isCreateOrEdit: string;
  itemTypeInWindow: string;
  newOrSelectedManufacturer: NewManufacturer | Manufacturer | null;
  newOrSelectedModel: NewModel | Model | null | any;
  newOrSelectedVariation: NewModelVariation | ModelVariation | null | any;
  modelPhotoFile: UploadFileInfo[];
  [k: string]: any;
}

export interface ManufacturerGridProps {
  manufacturers: ManufacturerListItem[];
  total: number;
  selectedManufacturerId: number | null;
  handleManufacturerSelect: (event: GridRowClickEvent) => void;
  handleEdit: (itemType: string, dataItem: any) => void;
  dataState?: any;
  getManufacturers: (dataState: any) => void;
  handleDelete?: (dataItem: any) => void;
}

export interface ManufacturerGridItem extends ManufacturerListItem {
  selected: boolean;
}

export interface ModelGridProps {
  models: ModelListItem[];
  dataState: any;
  total: number;
  variations: ModelVariationListItem[];
  handleCreate: (itemType: string) => (modelIdForVariation: number) => void;
  handleEdit: (itemType: string, dataItem: any) => void;
  updateVariations: (data: ModelVariationListItem[]) => void;
  getModels: (dataState: any) => void;
  isLoading?: boolean;
  handleDelete?: (dataItem: any) => void;
  handleDeleteVariation?: (dataItem: any) => void;
}

export interface ModelGridState {
  expandedModelIds: number[];
}

export interface ModelGridItem extends ModelListItem {
  expanded: boolean;
}

export interface SpecialOffersGridProps {
  data: Partial<SpecialOffer>[];
  isLoading: boolean;
  handleEdit: (dataItem: Partial<SpecialOffer>) => void;
}

export interface SpecificationPartsContainerState {
  hasUnsavedListChanges: boolean;
  specificationParts: SpecificationListItem[];
  isWindowVisible: boolean;
  isCreateOrEdit: string;
  newOrSelectedPart: NewSpecification | Specification | null;
}

export interface SpecificationPartsGridProps {
  specificationParts: SpecificationListItem[];
  dataState: any;
  getSpecificationParts: (dataState: any) => void;
  handleDragStart: (dataItem: any, type?: string) => void;
  handleReorder: (dataItem: any, type?: string) => void;
  handleEdit: (dataItem: SpecificationListItem) => void;
}

export interface LoginState {
  redirectToReferrer: boolean;
  cardAnimation: string;
  userName: string;
  userNameState: string;
  password: string;
  passwordState: string;
  rememberMe: boolean;
  [k: string]: any;
}

export interface InventoryProps {
  inventoryEn: string;
  inventoryHr: string;
  inventoryDe: string;
  inventoryFr: string;
  inventoryEs: string;
  setInventoryEn: React.Dispatch<string>;
  setInventoryHr: React.Dispatch<string>;
  setInventoryDe: React.Dispatch<string>;
  setInventoryFr: React.Dispatch<string>;
  setInventoryEs: React.Dispatch<string>;
}

export interface Inventory {
  boatId: number;
  languageId: number;
  text: string;
}
