// HOOKS
import { useState } from 'react';
//

const useFocus: () => [boolean, () => void] = () => {
  const [isTouched, setIsTouched] = useState(false);

  const handleFocus: () => void = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  return [isTouched, handleFocus];
};

export default useFocus;
