// MUI
import { FormLabel, Grid as MaterialGrid } from '@mui/material';
//

// KENDO
import {
  Editor,
  EditorBlurEvent,
  EditorChangeEvent,
  EditorPasteEvent,
  EditorTools,
} from '@progress/kendo-react-editor';
//

// TYPES
import { InventoryProps } from '../../../../types/typeDefinitions';
import { FormGridElement } from '../../../../components/FormGridElement/FormGridElement';
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { useAppSelector } from '../../../../app/hooks';
import { useState } from 'react';
//

const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
} = EditorTools;

const INIT_LANG_STATE_SELECT = { id: 1, code: 'En', displayName: 'English' };

const Inventory = (props: InventoryProps) => {
  // LANG SELECTOR AND STATE
  const allLanguagesList = useAppSelector(
    (state) => state.languagesState.data.languages
  );

  const [currentLanguageChosenForm, setCurrentLanguageChosenForm] =
    useState<any>(INIT_LANG_STATE_SELECT);

  return (
    <MaterialGrid container className='page-content-container-inventory'>
      <MaterialGrid item container className='form-element' xs={12} lg={6}>
        <MaterialGrid item>
          <FormGridElement
            component={DropDownList}
            label='Language'
            additionalProps={{
              data: allLanguagesList,
              dataItemKey: 'id',
              textField: 'displayName',
              defaultValue: allLanguagesList.find(
                (item) => item.id === currentLanguageChosenForm.id
              ),
            }}
            onChange={(e: DropDownListChangeEvent) => {
              setCurrentLanguageChosenForm(e.value);
            }}
          />
        </MaterialGrid>
      </MaterialGrid>

      <MaterialGrid
        item
        xs={16}
        lg={16}
        container
        className={
          currentLanguageChosenForm.code === 'En'
            ? 'form-element'
            : 'form-element-hidden'
        }
      >
        <MaterialGrid item xs={12}>
          <FormLabel>Equipment (en)</FormLabel>
          <Editor
            tools={[
              [Bold, Italic, Underline],
              [AlignLeft, AlignCenter, AlignRight],
              [Indent, Outdent],
              [OrderedList, UnorderedList],
              [Undo, Redo],
            ]}
            onChange={(event: EditorChangeEvent) => {
              props.setInventoryEn(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryEn
              );
            }}
            onPasteHtml={(event: EditorPasteEvent) => {
              props.setInventoryEn(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryEn
              );
            }}
            onBlur={(event: EditorBlurEvent) => {
              props.setInventoryEn(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryEn
              );
            }}
            defaultContent={
              props.inventoryEn && props.inventoryEn !== ''
                ? JSON.parse(props.inventoryEn)
                : ''
            }
          />
        </MaterialGrid>
      </MaterialGrid>

      <MaterialGrid
        item
        xs={12}
        lg={6}
        container
        className={
          currentLanguageChosenForm.code === 'Hr'
            ? 'form-element'
            : 'form-element-hidden'
        }
      >
        <MaterialGrid item xs={12}>
          <FormLabel>Equipment (hr)</FormLabel>
          <Editor
            tools={[
              [Bold, Italic, Underline],
              [AlignLeft, AlignCenter, AlignRight],
              [Indent, Outdent],
              [OrderedList, UnorderedList],
              [Undo, Redo],
            ]}
            onChange={(event: EditorChangeEvent) => {
              props.setInventoryHr(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryHr
              );
            }}
            onPasteHtml={(event: EditorPasteEvent) => {
              props.setInventoryHr(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryHr
              );
            }}
            onBlur={(event: EditorBlurEvent) => {
              props.setInventoryHr(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryHr
              );
            }}
            defaultContent={
              props.inventoryHr && props.inventoryHr !== ''
                ? JSON.parse(props.inventoryHr)
                : ''
            }
          />
        </MaterialGrid>
      </MaterialGrid>

      <MaterialGrid
        item
        xs={12}
        lg={6}
        container
        className={
          currentLanguageChosenForm.code === 'De'
            ? 'form-element'
            : 'form-element-hidden'
        }
      >
        <MaterialGrid item xs={12}>
          <FormLabel>Equipment (de)</FormLabel>
          <Editor
            tools={[
              [Bold, Italic, Underline],
              [AlignLeft, AlignCenter, AlignRight],
              [Indent, Outdent],
              [OrderedList, UnorderedList],
              [Undo, Redo],
            ]}
            onChange={(event: EditorChangeEvent) => {
              props.setInventoryDe(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryDe
              );
            }}
            onPasteHtml={(event: EditorPasteEvent) => {
              props.setInventoryDe(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryDe
              );
            }}
            onBlur={(event: EditorBlurEvent) => {
              props.setInventoryDe(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryDe
              );
            }}
            defaultContent={
              props.inventoryDe && props.inventoryDe !== ''
                ? JSON.parse(props.inventoryDe)
                : ''
            }
          />
        </MaterialGrid>
      </MaterialGrid>

      <MaterialGrid
        item
        xs={12}
        lg={6}
        container
        className={
          currentLanguageChosenForm.code === 'Es'
            ? 'form-element'
            : 'form-element-hidden'
        }
      >
        <MaterialGrid item xs={12}>
          <FormLabel>Equipment (es)</FormLabel>
          <Editor
            tools={[
              [Bold, Italic, Underline],
              [AlignLeft, AlignCenter, AlignRight],
              [Indent, Outdent],
              [OrderedList, UnorderedList],
              [Undo, Redo],
            ]}
            onChange={(event: EditorChangeEvent) => {
              props.setInventoryEs(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryEs
              );
            }}
            onPasteHtml={(event: EditorPasteEvent) => {
              props.setInventoryEs(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryEs
              );
            }}
            onBlur={(event: EditorBlurEvent) => {
              props.setInventoryEs(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryEs
              );
            }}
            defaultContent={
              props.inventoryEs && props.inventoryEs !== ''
                ? JSON.parse(props.inventoryEs)
                : ''
            }
          />
        </MaterialGrid>
      </MaterialGrid>

      <MaterialGrid
        item
        xs={12}
        lg={6}
        container
        className={
          currentLanguageChosenForm.code === 'Fr'
            ? 'form-element'
            : 'form-element-hidden'
        }
      >
        <MaterialGrid item xs={12}>
          <FormLabel>Equipment (fr)</FormLabel>
          <Editor
            tools={[
              [Bold, Italic, Underline],
              [AlignLeft, AlignCenter, AlignRight],
              [Indent, Outdent],
              [OrderedList, UnorderedList],
              [Undo, Redo],
            ]}
            onChange={(event: EditorChangeEvent) => {
              props.setInventoryFr(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryFr
              );
            }}
            onPasteHtml={(event: EditorPasteEvent) => {
              props.setInventoryFr(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryFr
              );
            }}
            onBlur={(event: EditorBlurEvent) => {
              props.setInventoryFr(
                JSON.stringify(event.target.contentElement?.innerHTML) !== ''
                  ? JSON.stringify(event.target.contentElement?.innerHTML)
                  : props.inventoryFr
              );
            }}
            defaultContent={
              props.inventoryFr && props.inventoryFr !== ''
                ? JSON.parse(props.inventoryFr)
                : ''
            }
          />
        </MaterialGrid>
      </MaterialGrid>
    </MaterialGrid>
  );
};

export default Inventory;
