// TYPES
import { Website } from '../../types/typeDefinitions';
//

type InitialStateGetter = () => {
  name: string;
  websites?: Website[];
  websiteIds?: number[] | null;
};

export const getInitialState: InitialStateGetter = () => ({
  name: '',
  websites: [],
});
