// REACT
import React from 'react';
//

// TYPES
import { Language } from '../../types/typeDefinitions';
import { useAppSelector } from '../../app/hooks';
//

export function LanguageCell(props: any): React.ReactElement {
  // LANG SELEKTOR
  const allLanguagesList = useAppSelector(
    (state) => state.languagesState.data.languages
  );
  //

  return (
    <td>
      languages ({`${props.dataItem[props.field]}/${allLanguagesList?.length}`})
    </td>
  );
}
