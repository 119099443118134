// TYPES
import { Enumeration } from '../../types/typeDefinitions';
//

type BoatType = {
  MOTORBOAT: 1;
  SAILBOAT: 2;
  CATAMARAN: 3;
  LUXURY: 4;
};

const boatTypes: Enumeration & BoatType = {
  MOTORBOAT: 1,
  SAILBOAT: 2,
  CATAMARAN: 3,
  LUXURY: 4,
  properties: {
    1: { name: 'Motor boat', value: 1 },
    2: { name: 'Sail boat', value: 2 },
    3: { name: 'Catamaran', value: 3 },
    4: { name: 'Luxury', value: 4 },
  },
};

export default boatTypes;
