// KENDO
import { DatePicker } from '@progress/kendo-react-dateinputs';
//

// HOOKS
import useNotifications from '../../hooks/useNotifications';
//

export const DateCell = (props: any) => {
  const { handleUserActionNotification } = useNotifications();

  return (
    <td>
      <DatePicker
        width='100%'
        value={props.dataItem[props.field]}
        defaultValue={null}
        format='dd/MM/yyyy'
        disabled={props.disabled}
        className='inline-cell'
        onChange={(e) => {
          if (
            String(props.field) === 'dateTo' &&
            (e.value as Date) <= props.dataItem['dateFrom']
          ) {
            handleUserActionNotification({
              type: 'warning',
              message: 'Date To cannot be less than Date From',
              autoClose: 2500,
            });
          } else if (
            String(props.field) === 'dateFrom' &&
            (e.value as Date) >= props.dataItem['dateTo']
          ) {
            handleUserActionNotification({
              type: 'warning',
              message: 'Date From cannot be greater than Date To!',
              autoClose: 2500,
            });
          } else {
            props.onChange({
              dataItem: props.dataItem,
              field: props.field,
              syntheticEvent: e.syntheticEvent,
              value: e.value,
            });
          }
        }}
      />
    </td>
  );
};
