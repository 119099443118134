// REACT ROUTER
import { NavLink } from 'react-router-dom';
//

// MUI
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
//

// ICONS
import { MdOutlineWaves as WavesIcon } from 'react-icons/md';
//

// ROUTES
import { CustomRouteObject } from '../../routes/routes';
//

const SidebarLink = (props: {
  item: CustomRouteObject;
  open: boolean;
  child?: boolean;
}) => {
  return (
    <NavLink
      to={String(props.item.path)}
      className={({ isActive }) =>
        isActive ? 'active-sidebar-link' : 'default-sidebar-link'
      }
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: props.open ? 'initial' : 'center',
          px: 2.5,
        }}
        className='sidebar-button'
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: props.open ? 3 : 'auto',
            justifyContent: 'center',
            color: 'white',
            marginLeft: props.child && props.open ? 2 : 0,
          }}
        >
          {props.item.icon ? (
            <props.item.icon className='link-icon' />
          ) : (
            <WavesIcon className='link-icon' />
          )}
        </ListItemIcon>
        <ListItemText
          primary={props.item.name}
          sx={{ opacity: props.open ? 1 : 0 }}
        />
      </ListItemButton>
    </NavLink>
  );
};

export default SidebarLink;
