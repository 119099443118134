// KENDO
import {
  DateRangePicker,
  DateRangePickerChangeEvent,
} from '@progress/kendo-react-dateinputs';
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
import {
  DropDownButton,
  DropDownButtonItemClickEvent,
} from '@progress/kendo-react-buttons';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
import { MdClose as CloseIcon } from 'react-icons/md';
//

// HOOKS
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  useGetBasesForDropdownQuery,
  useGetDiscountsForDropdownQuery,
  useLazyGetAllDiscountsQuery,
} from './discountsApiSlice';
import {
  onDiscountFilterCheckboxChange,
  onDiscountFilterDateRangeChange,
  onDiscountFilterDropdownChange,
  onDiscountFilterInputChange,
  onDiscountFilterNumericTextboxChange,
  resetDiscountFilterState,
  saveDiscountsList,
  saveDiscountsLocalFilterState,
} from './discountsStateSlice';
import useNotifications from '../../../hooks/useNotifications';
import { setDiscountsFiltersHeight } from '../generalStateSlice';
//

const DiscountsFilterBar = () => {
  const { data: basesDropdownData } = useGetBasesForDropdownQuery();
  const { data: discountsDropdownData } = useGetDiscountsForDropdownQuery();
  const [getAllDiscounts, { currentData: dataFromGet }] =
    useLazyGetAllDiscountsQuery();

  const initLocalFilterValues = useMemo(() => {
    return {
      wildcardName: '',
      basesName: null,
      typeName: null,
      sailingDates: {
        start: null,
        end: null,
      },
      bookingDates: {
        start: null,
        end: null,
      },
      inBasePrice: false,
      affectedByMax: false,
      isApplied: false,
      doesExcludesOtherDiscounts: false,
      daysFrom: null,
      daysTo: null,
    };
  }, []);

  const initOptionalFilterState = useMemo(() => {
    return [
      {
        displayName: 'Days from',
        name: 'daysFrom',
        value: 1,
      },
      {
        displayName: 'Days to',
        name: 'daysTo',
        value: 1,
      },

      {
        displayName: 'Included in base price?',
        name: 'inBasePrice',
        value: 2,
      },

      {
        displayName: 'Affected by max?',
        name: 'affectedByMax',
        value: 2,
      },
      {
        displayName: 'Is applied?',
        name: 'isApplied',
        value: 2,
      },

      {
        displayName: 'Excludes other discounts',
        name: 'doesExcludesOtherDiscounts',
        value: 2,
      },

      {
        displayName: 'Sailing dates',
        name: 'sailingDates',
        value: 3,
      },
      {
        displayName: 'Booking dates',
        name: 'bookingDates',
        value: 3,
      },
    ];
  }, []);

  const getLocalFilterStateFromStore = useAppSelector(
    (state) => state.discountsState.discountFilters.localFilterState
  );

  const memoizedInitLocalState = useMemo(
    () => getLocalFilterStateFromStore,
    [getLocalFilterStateFromStore]
  );

  const currentFilterState = useAppSelector(
    (state) => state.discountsState.discountFilters.filter.filters
  ).find((singleFilter) => singleFilter.name === 'discountId');

  const isInManageMode = useCallback(() => {
    if (currentFilterState?.value !== null) {
      return true;
    }
    return false;
  }, [currentFilterState]);

  const [localFilterValues, setLocalFilterValues] = useState<any>(
    memoizedInitLocalState
  );

  const getDiscountFilters = useAppSelector(
    (state) => state.discountsState.discountFilters
  );
  const discountFilters = useMemo(
    () => getDiscountFilters,
    [getDiscountFilters]
  );

  const [optionalFilters, setOptionalFilters] = useState<any[]>(
    localFilterValues.optionalFilters
  );
  const [tempState, setTempState] = useState<any>(initOptionalFilterState);

  const OptionalFiltersController = () => {
    return (
      <MaterialGrid item className='filter-item'>
        <DropDownButton
          items={tempState.map((singleFilter: any) => singleFilter)}
          textField={'displayName'}
          text={'More filters...'}
          onItemClick={(event: DropDownButtonItemClickEvent) => {
            const tmp = optionalFilters.some(
              (singleFilter: any) => singleFilter.name === event.item.name
            );

            setTempState((prev: any) =>
              prev.filter(
                (singleFilter: any) => singleFilter.name !== event.item.name
              )
            );

            setOptionalFilters((prev: any) => {
              if (tmp === true) {
                return prev;
              } else {
                return [...prev, event.item];
              }
            });
          }}
        />
      </MaterialGrid>
    );
  };

  const prepareOptionalFilters = () => {
    return (
      <>
        {optionalFilters?.length !== 0 && optionalFilters !== undefined
          ? optionalFilters.map((singleFilter: any) => {
              if (singleFilter.value === 1) {
                return (
                  <React.Fragment key={singleFilter.name}>
                    <MaterialGrid item className='filter-item'>
                      <NumericTextBox
                        label={singleFilter.displayName}
                        onChange={(event: NumericTextBoxChangeEvent) => {
                          customSetState(singleFilter.name, event.value);

                          if (event.value && event.value < 1) {
                            handleUserActionNotification({
                              message: 'Please select a value greater than 0',
                              type: 'warning',
                              autoClose: 2500,
                            });
                          } else {
                            onDiscountNumericTextboxChange(
                              event,
                              singleFilter.name
                            );
                          }
                        }}
                        min={1}
                        spinners={false}
                        value={localFilterValues[singleFilter.name]}
                        disabled={isInManageMode() === true}
                      />

                      <button
                        onClick={() => handleFilterRemove(singleFilter)}
                        className='close-button'
                        style={{ paddingBottom: '15px' }}
                      >
                        <CloseIcon />
                      </button>
                    </MaterialGrid>
                  </React.Fragment>
                );
              }
              if (singleFilter.value === 2) {
                return (
                  <React.Fragment key={singleFilter.name}>
                    <MaterialGrid item className='filter-item'>
                      <Checkbox
                        className='filter-checkbox'
                        label={singleFilter.displayName}
                        onChange={(event: CheckboxChangeEvent) => {
                          onDiscountCheckboxChange(event, singleFilter.name);
                          customSetState(singleFilter.name, event.value);
                        }}
                        value={
                          localFilterValues[singleFilter.name] === null
                            ? false
                            : localFilterValues[singleFilter.name]
                        }
                        disabled={isInManageMode() === true}
                      />

                      <button
                        onClick={() => handleFilterRemove(singleFilter)}
                        className='close-button'
                        style={{ paddingBottom: '15px' }}
                      >
                        <CloseIcon />
                      </button>
                    </MaterialGrid>
                  </React.Fragment>
                );
              }

              if (singleFilter.value === 3) {
                return (
                  <React.Fragment key={singleFilter.name}>
                    <MaterialGrid item className='filter-item'>
                      <DateRangePicker
                        onChange={(event: DateRangePickerChangeEvent) => {
                          onDiscountDateRangeChange(event, singleFilter.name);
                          customSetState(
                            singleFilter.name,
                            { start: event.value.start, end: event.value.end },
                            true
                          );
                        }}
                        value={{
                          start: localFilterValues[singleFilter.name].start,
                          end: localFilterValues[singleFilter.name].end,
                        }}
                        startDateInputSettings={{
                          label: undefined,
                          placeholder:
                            singleFilter.name === 'sailingDates'
                              ? 'Sailing date from'
                              : 'Booking date from',
                          format: 'dd/MM/yyyy',
                        }}
                        endDateInputSettings={{
                          label: undefined,
                          placeholder:
                            singleFilter.name === 'sailingDates'
                              ? 'Sailing date to'
                              : 'Booking date to',

                          format: 'dd/MM/yyyy',
                        }}
                        disabled={isInManageMode() === true}
                      />

                      <button
                        onClick={() => handleFilterRemove(singleFilter)}
                        className='close-button'
                        style={{ paddingBottom: '15px' }}
                      >
                        <CloseIcon />
                      </button>
                    </MaterialGrid>
                  </React.Fragment>
                );
              }

              return null;
            })
          : null}
      </>
    );
  };

  const dispatch = useAppDispatch();

  const onFilterDropdownChange = (
    event: DropDownListChangeEvent,
    eventName: string
  ) =>
    dispatch(
      onDiscountFilterDropdownChange({
        eventValue: event.value.id,
        name: eventName,
      })
    );

  const onFilterInputChange = (event: any, eventName: string) =>
    dispatch(
      onDiscountFilterInputChange({
        eventValue: event.target.value,
        name: eventName,
      })
    );

  const onDiscountDateRangeChange = (
    event: DateRangePickerChangeEvent,
    eventName: string
  ) =>
    dispatch(
      onDiscountFilterDateRangeChange({
        eventValue: { start: event.value.start, end: event.value.end },
        name: eventName,
      })
    );

  const onDiscountCheckboxChange = (
    event: CheckboxChangeEvent,
    eventName: string
  ) =>
    dispatch(
      onDiscountFilterCheckboxChange({
        name: eventName,
        eventValue: event.value,
      })
    );

  const onDiscountNumericTextboxChange = (
    event: NumericTextBoxChangeEvent,
    eventName: string
  ) =>
    dispatch(
      onDiscountFilterNumericTextboxChange({
        name: eventName,
        eventValue: event.value as number,
      })
    );

  const nullCheckToClearFilters = useMemo(
    () =>
      Object.values(discountFilters.filter.filters).some(
        (singleFilter) => singleFilter.value === null
      ),
    [discountFilters.filter.filters]
  );

  const removeNullSoonToBeInReduxStore = useMemo(
    () =>
      discountFilters.filter.filters.filter(
        (singleFilter) => singleFilter.value !== null
      ),
    [discountFilters.filter.filters]
  );

  const queryParamsWithoutNull = useMemo(() => {
    return {
      ...discountFilters,
      filter: { filters: removeNullSoonToBeInReduxStore, logic: 'and' },
    };
  }, [discountFilters, removeNullSoonToBeInReduxStore]);

  const handleFilterRemove = (item: any) => {
    const tmp = optionalFilters.filter(
      (singleFilter: any) => singleFilter.name !== item.name
    );
    setOptionalFilters(tmp);

    if (item.value === 1) {
      dispatch(
        onDiscountFilterNumericTextboxChange({
          name: item.name,
          eventValue: null,
        })
      );

      setLocalFilterValues((prev: any) => {
        return {
          ...prev,
          [String(item.name)]: null,
        };
      });
    }
    if (item.value === 2) {
      dispatch(
        onDiscountFilterCheckboxChange({ name: item.name, eventValue: false })
      );

      setLocalFilterValues((prev: any) => {
        return {
          ...prev,
          [String(item.name)]: null,
        };
      });
    }

    if (item.value === 3) {
      dispatch(
        onDiscountFilterDateRangeChange({
          name: item.name,
          eventValue: { start: null, end: null },
        })
      );

      setLocalFilterValues((prev: any) => {
        return {
          ...prev,
          [String(item.name)]: { start: null, end: null },
        };
      });
    }

    setTempState(() => {
      const tmp = initOptionalFilterState.find(
        (singleFilter: any) => singleFilter.name === item.name
      );
      return [...tempState, tmp].sort((a: any, b: any) => {
        if (a.name > b.name) {
          return 1;
        }
        return -1;
      });
    });
  };

  const resetFilters = useCallback(() => {
    dispatch(resetDiscountFilterState());
    setOptionalFilters([]);

    setLocalFilterValues(initLocalFilterValues);
    setTempState(initOptionalFilterState);
  }, [dispatch, initLocalFilterValues, initOptionalFilterState]);

  const { handleUserActionNotification } = useNotifications();

  const getDiscountListBasedOnFilters = useCallback(async () => {
    //
    try {
      if (nullCheckToClearFilters) {
        await getAllDiscounts(queryParamsWithoutNull).unwrap();
        dataFromGet !== undefined &&
          dispatch(saveDiscountsList(dataFromGet.data));
      } else {
        await getAllDiscounts(discountFilters).unwrap();
        dataFromGet !== undefined &&
          dispatch(saveDiscountsList(dataFromGet.data));
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    discountFilters,
    dispatch,
    nullCheckToClearFilters,
    queryParamsWithoutNull,
    getAllDiscounts,
    dataFromGet,
  ]);

  useEffect(() => {
    getDiscountListBasedOnFilters();
  }, [getDiscountListBasedOnFilters]);

  const customSetState = useCallback(
    (eventName: string, eventValue: any, isDateTime = false) => {
      if (isDateTime) {
        setLocalFilterValues((prev: any) => {
          return {
            ...prev,
            [eventName]: { start: eventValue.start, end: eventValue.end },
          };
        });
      } else {
        setLocalFilterValues((prev: any) => {
          return { ...prev, [eventName]: eventValue };
        });
      }
    },
    []
  );

  const [height, setHeight] = useState(0);
  const ref: any = useRef(null);

  useLayoutEffect(() => {
    if (ref.current.clientHeight) {
      setTimeout(() => {
        setHeight(ref.current.clientHeight);
      }, 0);
    }
  });
  useEffect(() => {
    dispatch(setDiscountsFiltersHeight(height));
  }, [dispatch, height]);

  useEffect(() => {
    return () => {
      dispatch(
        saveDiscountsLocalFilterState({ ...localFilterValues, optionalFilters })
      );
    };
  }, [dispatch, localFilterValues, optionalFilters]);

  return (
    <MaterialGrid container columnSpacing={2} ref={ref}>
      <MaterialGrid item className='filter-item'>
        <Input
          onKeyDown={(event) =>
            event.key === 'Enter' ? onFilterInputChange(event, 'name') : ''
          }
          onChange={(event: any) => {
            customSetState('wildcardName', event.target.value);
            onFilterInputChange(event, 'name');
          }}
          label={'Discount name'}
          value={localFilterValues.wildcardName}
          disabled={isInManageMode() === true}
        />
      </MaterialGrid>
      {basesDropdownData !== undefined &&
        basesDropdownData !== null &&
        basesDropdownData?.length !== 0 && (
          <MaterialGrid item className='filter-item'>
            <DropDownList
              data={basesDropdownData}
              dataItemKey={'id'}
              textField={'name'}
              onChange={(event: DropDownListChangeEvent) => {
                onFilterDropdownChange(event, 'bases');
                customSetState('basesName', event.value.name);
              }}
              label={'Available in base'}
              value={{ name: localFilterValues.basesName }}
              disabled={isInManageMode() === true}
            />
          </MaterialGrid>
        )}
      <MaterialGrid item className='filter-item'>
        <DropDownList
          data={discountsDropdownData}
          onChange={(event: DropDownListChangeEvent) => {
            onFilterDropdownChange(event, 'type');
            customSetState('typeName', event.value.name);
          }}
          dataItemKey={'id'}
          textField={'name'}
          label={'Type'}
          value={{ name: localFilterValues.typeName }}
          disabled={isInManageMode() === true}
        />
      </MaterialGrid>

      {prepareOptionalFilters()}
      <OptionalFiltersController />

      <MaterialGrid item className='filter-item'>
        {!isInManageMode() && (
          <button onClick={resetFilters} className='sky-button'>
            Reset filters
          </button>
        )}
      </MaterialGrid>
    </MaterialGrid>
  );
};

export default DiscountsFilterBar;
