// REACT
import React from 'react';
//

export class Renderers {
  preventExit?: boolean;
  preventExitTimeout?: any;
  blurTimeout?: any;
  constructor(
    public enterEdit: any,
    public exitEdit: any,
    public editFieldName: any
  ) {}

  cellRender = (tdElement: any, cellProps: any) => {
    const dataItem: any = cellProps.dataItem;
    const field: any = cellProps.field;
    const additionalProps: any =
      cellProps.dataItem[this.editFieldName] &&
      cellProps.field === cellProps.dataItem[this.editFieldName]
        ? {
            ref: (td: any) => {
              const input: any = td && td.querySelector('input');
              if (!input || input === document.activeElement) {
                return;
              }
              if (input.type === 'checkbox') {
                input.focus();
              } else {
                input.select();
              }
            },
          }
        : {
            onClick: () => {
              this.enterEdit(dataItem, field);
            },
          };
    return React.cloneElement(
      tdElement,
      { ...tdElement.props, ...additionalProps },
      tdElement.props.children
    );
  };

  rowRender = (trElement: any, dataItem: any) => {
    let trProps: any = {
      ...trElement.props,
      onMouseDown: () => {
        this.preventExit = true;
        clearTimeout(this.preventExitTimeout);
        this.preventExitTimeout = setTimeout(() => {
          this.preventExit = undefined;
        });
      },
      onBlur: () => {
        clearTimeout(this.blurTimeout);
        if (!this.preventExit) {
          this.blurTimeout = setTimeout(() => {
            this.exitEdit();
          });
        }
      },
      onFocus: () => {
        clearTimeout(this.blurTimeout);
      },
    };

    trProps = dataItem.isAltRow
      ? trProps
      : { ...trProps, style: { backgroundColor: '#fcfcfc' } };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };
}
