// KENDO
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
  InputChangeEvent,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from '@progress/kendo-react-inputs';
//

// HOOKS
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  useGetManufacturersForDropDownQuery,
  useGetModelsForDropDownQuery,
  useGetModelVariationsForDropDownQuery,
} from '../Boats/boatsApiSlice';
import { useGetCompaniesForDropdownQuery } from './discountsApiSlice';
import {
  onApplyWindowDropdownChange,
  onApplyWindowFilterCheckboxChange,
  onApplyWindowInputChange,
  onApplyWindowNumericTextboxChange,
  resetDiscountApplyWindowFilterState,
  setLocalFilterStatus,
} from './discountsStateSlice';
import useNotifications from '../../../hooks/useNotifications';
//

// TYPES
import boatTypes from '../../../types/enums/boatTypes';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

const DiscountsApplyFilterBar = () => {
  const initLocalFilterValues = useMemo(() => {
    return {
      boatType: null,
      wildcardName: '',
      yearProduced: null,
      companyName: null,
      manufacturer: null,
      model: null,
      variation: null,
      boatsWithService: true,
    };
  }, []);

  const [localFilterValues, setLocalFilterValues] = useState<any>(
    initLocalFilterValues
  );
  const [manufacturerId, setManufacturerId] = useState<number | null>(null);

  const [modelIdForQuery, setModelIdForQuery] = useState<any>(null);

  const { data: manufacturersDropdownData } =
    useGetManufacturersForDropDownQuery();
  const { data: companiesDropdownData } = useGetCompaniesForDropdownQuery();
  const { data: modelsDropdownData } = useGetModelsForDropDownQuery(
    manufacturerId as number,
    { skip: manufacturerId === null }
  );

  const { data: modelVariationsDropdownData } =
    useGetModelVariationsForDropDownQuery(modelIdForQuery, {
      skip: modelIdForQuery === null,
    });

  const dispatch = useAppDispatch();

  const customSetState = useCallback(
    (eventName: string, eventValue: any) => {
      setLocalFilterValues((prev: any) => {
        return { ...prev, [eventName]: eventValue };
      });
      dispatch(setLocalFilterStatus());
    },
    [dispatch]
  );

  const filtersNeedReset = useAppSelector(
    (state) => state.discountsState.resetFilters
  );

  useEffect(() => {
    if (filtersNeedReset === true) {
      setLocalFilterValues(initLocalFilterValues);
    }
  }, [filtersNeedReset, initLocalFilterValues]);

  const resetFilters = useCallback(() => {
    setLocalFilterValues(initLocalFilterValues);
    dispatch(resetDiscountApplyWindowFilterState());
    setModelIdForQuery(null);
    setManufacturerId(null);
  }, [initLocalFilterValues, dispatch]);

  const { handleUserActionNotification } = useNotifications();

  return (
    <MaterialGrid container direction={'column'}>
      <MaterialGrid
        item
        container
        direction='row'
        spacing={2}
        className='filter-row'
      >
        <MaterialGrid item className='filter-item'>
          <Input
            placeholder='Search by name'
            onChange={(event: InputChangeEvent) => {
              customSetState('wildcardName', event.target.value);
              dispatch(
                onApplyWindowInputChange({
                  name: 'name',
                  eventValue: event.target.value as string,
                })
              );
            }}
            value={localFilterValues.wildcardName}
            onKeyDown={(event) =>
              event.key === 'Enter'
                ? dispatch(
                    onApplyWindowInputChange({
                      name: 'name',
                      eventValue: event.target.value,
                    })
                  )
                : ''
            }
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <NumericTextBox
            placeholder='Year produced'
            onChange={(event: NumericTextBoxChangeEvent) => {
              customSetState('yearProduced', event.value);

              if (event.value && event.value < 1996) {
                handleUserActionNotification({
                  message: 'Please select a year after 1996',
                  type: 'warning',
                  autoClose: 2500,
                });
              } else {
                dispatch(
                  onApplyWindowNumericTextboxChange({
                    name: 'yearProduced',
                    eventValue: event.value as number,
                  })
                );
              }
            }}
            value={localFilterValues.yearProduced}
            format={'#'}
            spinners={false}
            min={1996}
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <DropDownList
            data={Object.values(boatTypes.properties)}
            textField={'name'}
            dataItemKey={'value'}
            label={'Boat type'}
            onChange={(event: DropDownListChangeEvent) => {
              customSetState('boatType', event.value.name);

              dispatch(
                onApplyWindowDropdownChange({
                  name: 'type',
                  eventValue: event.value.value,
                })
              );
            }}
            value={{ name: localFilterValues.boatType }}
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <DropDownList
            data={manufacturersDropdownData ? manufacturersDropdownData : []}
            textField={'name'}
            dataItemKey={'id'}
            label={'Manufacturer'}
            onChange={(event: DropDownListChangeEvent) => {
              customSetState('manufacturer', event.value.name);
              setManufacturerId(event.value.id);
              setModelIdForQuery(null);
              setLocalFilterValues((prev: any) => {
                return { ...prev, model: null, variation: null };
              });
              dispatch(
                onApplyWindowDropdownChange({
                  name: 'manufacturer',
                  eventValue: event.value.id,
                })
              );

              dispatch(
                onApplyWindowDropdownChange({
                  name: 'model',
                  eventValue: null,
                })
              );

              dispatch(
                onApplyWindowDropdownChange({
                  name: 'variation',
                  eventValue: null,
                })
              );

              if (event.target.value.id === null) {
                setLocalFilterValues((prev: any) => {
                  return { ...prev, model: null, variation: null };
                });

                dispatch(
                  onApplyWindowDropdownChange({
                    name: 'model',
                    eventValue: event.value.id,
                  })
                );
              }
            }}
            value={{ name: localFilterValues.manufacturer }}
            defaultItem={{ name: 'All manufacturers', id: null }}
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <DropDownList
            data={modelsDropdownData ? modelsDropdownData : []}
            textField={'name'}
            dataItemKey={'id'}
            label={'Model'}
            onChange={(event: DropDownListChangeEvent) => {
              customSetState('model', event.value.name);

              setModelIdForQuery(event.value.id);
              dispatch(
                onApplyWindowDropdownChange({
                  name: 'model',
                  eventValue: event.value.id,
                })
              );

              dispatch(
                onApplyWindowDropdownChange({
                  name: 'variation',
                  eventValue: null,
                })
              );

              setLocalFilterValues((prev: any) => {
                return { ...prev, variation: null };
              });

              if (event.target.value.id === null) {
                dispatch(
                  onApplyWindowDropdownChange({
                    name: 'variation',
                    eventValue: event.target.value.id,
                  })
                );
              }
            }}
            value={{ name: localFilterValues.model }}
            disabled={manufacturerId === null}
            defaultItem={{ name: 'All models', id: null }}
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <DropDownList
            data={
              modelVariationsDropdownData ? modelVariationsDropdownData : []
            }
            textField={'name'}
            dataItemKey={'id'}
            label={'Variation'}
            onChange={(event: DropDownListChangeEvent) => {
              customSetState('variation', event.value.name);

              dispatch(
                onApplyWindowDropdownChange({
                  name: 'variation',
                  eventValue: event.value.id,
                })
              );
            }}
            value={{ name: localFilterValues.variation }}
            disabled={modelIdForQuery === null}
            defaultItem={{ name: 'All variations', id: null }}
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <DropDownList
            data={companiesDropdownData ? companiesDropdownData : []}
            textField={'name'}
            dataItemKey={'id'}
            label={'Company name'}
            onChange={(event: DropDownListChangeEvent) => {
              customSetState('companyName', event.value.name);
              dispatch(
                onApplyWindowDropdownChange({
                  name: 'companyName',
                  eventValue: event.value.id,
                })
              );
            }}
            value={{ name: localFilterValues.companyName }}
          />
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <button className='light-button'>
            <Checkbox
              label={'Boats with this discount?'}
              onChange={(event: CheckboxChangeEvent) => {
                customSetState('boatsWithService', event.target.value);
                dispatch(
                  onApplyWindowFilterCheckboxChange({
                    name: 'boatsWithService',
                    eventValue: event.target.value as boolean,
                  })
                );
              }}
              defaultValue={filtersNeedReset}
              value={localFilterValues.boatsWithService}
            />
          </button>
        </MaterialGrid>

        <MaterialGrid item className='filter-item'>
          <button onClick={resetFilters} className='sky-button'>
            Reset filters
          </button>
        </MaterialGrid>
      </MaterialGrid>
    </MaterialGrid>
  );
};

export default DiscountsApplyFilterBar;
