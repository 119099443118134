// TYPES
import {
  GridFilter,
  BoatListItem,
  Lookup,
  ModelVariationLookup,
  BoatListContainerState,
} from '../../../../types/typeDefinitions';
import boatStates from '../../../../types/enums/boatState';
//

// HOOKS
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useNotifications from '../../../../hooks/useNotifications';
import Reorder, { UseReorderUtils } from '../../../../hooks/useReorder';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  useLazyGetBoatsQuery,
  useLazyGetManufacturersForDropDownQuery,
  useLazyGetModelsForDropDownQuery,
  useLazyGetModelVariationsForDropDownQuery,
  useLazyGetRegenerateSearchIndexesQuery,
  useUpdateBoatsMutation,
} from '../boatsApiSlice';
import {
  addBoatFilter,
  removeBoatFilter,
  resetBoatStateFilter,
  setBoatStateFilter,
} from '../boatsFilterStateSlice';
import {
  getBoatsSuccess,
  resetBoatDataState,
  setTotalBoats,
} from '../boatsStateSlice';
import { getManufacturersForDropDownListSuccess } from '../../ManufacturersAndModels/manufacturersStateSlice';
import {
  getModelsForDropDownIsEmpty,
  getModelsForDropDownSuccess,
} from '../../ManufacturersAndModels/modelsStateSlice';
import { getModelVariationsForDropdownSuccess } from '../../ManufacturersAndModels/modelVariationsStateSlice';

import { cloneDeep } from 'lodash';
//

// COMPONENTS
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import BoatGrid from './BoatGrid';
import BoatCharterGrid from './BoatCharterGrid';
import GridFilterBar from '../../../../components/GridFilters/GridFilterBar';
import CharterGridFilterBar from '../../../../components/GridFilters/CharterGridFilterbar';
import ReactRouterPrompt from 'react-router-prompt';
//

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// SERVICES
import {
  buildGridFilters,
  constructGridFiltersForRender,
} from '../../../../services/gridFilters';
//

const BoatListContainer = (props: { boatState: number }) => {
  const handleCloningEntity = useCallback(
    (entity: any) => cloneDeep(entity),
    []
  );

  const dispatch = useAppDispatch();
  const { handleUserActionNotification, handlePromiseNotification } =
    useNotifications();

  const boatsFromStore = useAppSelector((state) => state.boatsState.data.boats);

  const allBoats: BoatListItem[] = useMemo(() => {
    return handleCloningEntity(boatsFromStore);
  }, [boatsFromStore, handleCloningEntity]);

  const dataState = useAppSelector((state) => state.boatsState.data.dataState);

  const filtersFromStore = useAppSelector((state) => state.boatsFilterState);

  const total = useAppSelector((state) => state.boatsState.data.total);

  const allManufacturers = useAppSelector(
    (state) => state.manufacturersState.data.dropDownList
  );

  const allModels = useAppSelector(
    (state) => state.modelsState.data.dropDownList
  );

  const allModelVariations = useAppSelector(
    (state) => state.modelVariationsState.data.dropDownlist
  );

  const [
    getAllBoats,
    { isFetching: isBoatsLoading, currentData: boatListData },
  ] = useLazyGetBoatsQuery();

  const [getRegenerateSearchIndexes] = useLazyGetRegenerateSearchIndexesQuery();

  const [getManufacturersForDropDown, { currentData: initManuData }] =
    useLazyGetManufacturersForDropDownQuery();

  const [getModelsForDropDown, { currentData: initModelData }] =
    useLazyGetModelsForDropDownQuery();

  const [getModelVariationsForDropDown, { currentData: initVariationsData }] =
    useLazyGetModelVariationsForDropDownQuery();

  const [updateBoats] = useUpdateBoatsMutation();

  const [hasUnsavedListChanges, setHasUnsavedListChanges] =
    useState<BoatListContainerState['hasUnsavedListChanges']>(false);

  const [filters, setFilters] = useState(constructGridFiltersForRender() || []);

  const currentLocation = useLocation();

  const navigateTo = useNavigate();

  const [needsReset, setNeedsReset] = useState<boolean>(false);

  const prevBoatListRef = useRef<BoatListItem[]>([]);

  const prepareManufacturersDropdownList = useCallback(async () => {
    const getManufacturersRequest = await getManufacturersForDropDown();
    if (
      !getManufacturersRequest.isError &&
      !getManufacturersRequest.isLoading &&
      getManufacturersRequest.data?.length !== 0
    ) {
      dispatch(
        getManufacturersForDropDownListSuccess(getManufacturersRequest.data)
      );
    }
  }, [dispatch, getManufacturersForDropDown]);

  const prepareModelsDropDownList = useCallback(
    async (id: number) => {
      const getModelsRequest = await getModelsForDropDown(id);
      if (
        !getModelsRequest.isError &&
        !getModelsRequest.isLoading &&
        getModelsRequest.data?.length !== 0
      ) {
        dispatch(getModelsForDropDownSuccess(getModelsRequest.data));
      } else dispatch(getModelsForDropDownIsEmpty());
    },
    [dispatch, getModelsForDropDown]
  );

  const prepareModelVariationsDropDownList = useCallback(
    async (id: number) => {
      const getModelVariationsRequest = await getModelVariationsForDropDown(id);
      if (
        !getModelVariationsRequest.isError &&
        !getModelVariationsRequest.isLoading &&
        getModelVariationsRequest.data?.length !== 0
      ) {
        dispatch(
          getModelVariationsForDropdownSuccess(getModelVariationsRequest.data)
        );
      }
    },
    [dispatch, getModelVariationsForDropDown]
  );

  const prepareBoats = useCallback(
    async (gridDataState: any = null) => {
      if (props.boatState === boatStates.CHARTER) {
        return;
      }
      if (gridDataState && gridDataState.filter.filters?.length !== 0) {
        try {
          const requestBoatsList = await getAllBoats(gridDataState);
          if (
            !requestBoatsList.isError &&
            !requestBoatsList.isLoading &&
            requestBoatsList.isSuccess &&
            boatListData !== undefined &&
            boatListData
          ) {
            dispatch(getBoatsSuccess(boatListData.data));
            dispatch(setTotalBoats(requestBoatsList.data.total));
            prevBoatListRef.current = requestBoatsList.data.data;
          }
        } catch (error: any) {
          console.error(error);
          handleUserActionNotification({
            type: 'error',
            message: error.data.message,
            autoClose: 2500,
          });
        }
      } else if (gridDataState.filter.filters?.length !== 0) {
        const { sort, skip, take } = dataState;

        const dataStateObj = {
          sort,
          filter: buildGridFilters(filtersFromStore),
          skip,
          take,
        };

        try {
          const requestBoatsList = await getAllBoats(dataStateObj);
          if (
            !requestBoatsList.isError &&
            !requestBoatsList.isLoading &&
            requestBoatsList.isSuccess &&
            boatListData !== undefined &&
            boatListData
          ) {
            dispatch(getBoatsSuccess(boatListData.data as BoatListItem[]));
            dispatch(setTotalBoats(requestBoatsList.data.total));
            prevBoatListRef.current = requestBoatsList.data.data;
          }
        } catch (error: any) {
          console.error(error);
          handleUserActionNotification({
            type: 'error',
            message: error.data.message,
            autoClose: 2500,
          });
        }
      }
    },
    [
      getAllBoats,
      dataState,
      filtersFromStore,
      dispatch,
      handleUserActionNotification,
      props.boatState,
      boatListData,
    ]
  );

  useEffect(() => {
    /*
     * CHANGE STATE VALUE WHEN TRANSITIONING BETWEEN DIFFERENT BOAT LISTS
     */

    dispatch(
      setBoatStateFilter({
        currentState: filtersFromStore.State.value,
        newState: props.boatState,
      })
    );

    /*
     * FETCH MANUFACTURERS FOR DROP DOWN IF NOT ALREADY IN STORE
     */

    if (allManufacturers!.length === 0) {
      prepareManufacturersDropdownList();
    }

    /*
     * FETCH MODELS FOR DROP DOWN IF NOT ALREADY IN STORE
     * AND IF MANUFACTURER IS SELECTED
     */
    const manufacturerFilter: GridFilter | undefined = filters.find(
      (x: any) => x.name === 'Manufacturer'
    );

    if (
      manufacturerFilter &&
      manufacturerFilter.value &&
      manufacturerFilter.value.id &&
      (!allModels || allModels?.length === 0)
    ) {
      prepareModelsDropDownList(manufacturerFilter.value.id);
    }

    /*
     * FETCH MODEL VARIATIONS FOR DROP DOWN IF NOT ALREADY IN STORE
     * AND IF MODEL IS SELECTED
     */
    const modelFilter: GridFilter | undefined = filters.find(
      (x: any) => x.name === 'Model'
    );
    if (
      modelFilter &&
      modelFilter.value &&
      modelFilter.value.id &&
      (!allModelVariations || allModelVariations?.length === 0)
    ) {
      prepareModelVariationsDropDownList(modelFilter.value.id);
    }
  }, [
    allManufacturers,
    allModels,
    dispatch,
    filters,
    filtersFromStore,
    prepareManufacturersDropdownList,
    prepareModelVariationsDropDownList,
    prepareModelsDropDownList,
    props.boatState,
    allModelVariations,
  ]);

  /*
   * FETCH BOATS IF FILTERS HAVE CHANGED
   */
  useEffect(() => {
    if (allManufacturers) {
      setFilters((prevFilterState) => {
        return prevFilterState.map((filter) => {
          if (filter.name === 'Manufacturer') {
            filter.data = [
              { id: null, name: 'All manufacturers' },
              ...allManufacturers,
            ];

            // If manufacturer value is already set, populate name property
            if (filter.value && filter.value.id && !filter.value.name) {
              const manufacturer: Lookup | undefined = allManufacturers!.find(
                (x) => x.id === filter.value.id
              );
              filter.value.name = manufacturer ? manufacturer.name : '';
            }
          }

          return filter;
        });
      });
    }

    if (allModels) {
      setFilters((prevFilterState) => {
        return prevFilterState.map((filter) => {
          if (filter.name === 'Model') {
            filter.data = [{ id: null, name: 'All models' }, ...allModels];

            // If model value is already set, populate name property
            if (filter.value && filter.value.id && !filter.value.name) {
              const model: Lookup | undefined = allModels!.find(
                (x) => x.id === filter.value.id
              );
              filter.value.name = model ? model.name : '';
            }
          }

          return filter;
        });
      });
    }

    if (allModelVariations) {
      setFilters((prevFilterState) => {
        return prevFilterState.map((filter) => {
          if (filter.name === 'ModelVariation') {
            filter.data = [
              { id: null, name: 'All variations' },
              ...allModelVariations,
            ];

            // If variation is already set, populate name property
            if (filter.value && filter.value.id && !filter.value.name) {
              const variation: ModelVariationLookup | undefined =
                allModelVariations!.find((x) => x.id === filter.value.id);
              filter.value.name = variation ? variation.name : '';
            }
          }

          return filter;
        });
      });
    }
  }, [
    filtersFromStore,
    allManufacturers,
    allModels,
    allModelVariations,
    initManuData,
    initModelData,
    initVariationsData,
  ]);

  useEffect(() => {
    const dataStateObj =
      props.boatState === boatStates.STOCK
        ? { sort: dataState.sort, filter: buildGridFilters(filtersFromStore) }
        : {
            sort: dataState.sort,
            filter: buildGridFilters(filtersFromStore),
            skip: dataState.skip,
            take: dataState.take,
          };

    prepareBoats(dataStateObj);
  }, [
    dataState.skip,
    dataState.take,
    dataState.sort,
    filtersFromStore,
    prepareBoats,
    props.boatState,
    dataState,
  ]);

  /*
   * Add new filter from drop down list button
   */
  const handleFilterAdd = (filterName: string) => {
    const lookupSelectedFilterNameLocally = filters.map((singleFilter: any) => {
      if (singleFilter.name === filterName) {
        singleFilter.selected = true;
      }

      return singleFilter;
    });

    dispatch(setBoatStateFilter(lookupSelectedFilterNameLocally));
  };

  const handleReorderStateChange = (changes: any) => {
    if (changes.type === Reorder.stateChangeTypes.rootOrParentReorder) {
      setHasUnsavedListChanges(true);
      if (changes.data !== boatListData) {
        dispatch(getBoatsSuccess(changes.data as BoatListItem[]));
      }
    }
  };

  const handleBoatAdd = () => {
    const pathname: string = `${currentLocation.pathname}/details`;
    navigateTo(pathname);
  };

  const handleFilterChange =
    (filterName: string) => async (changes: any, _action: any) => {
      const filter: GridFilter | undefined = filters.find(
        (f: any) => f.name === filterName
      );

      const dataItemKey: string = filter ? filter.dataItemKey || 'id' : 'id';

      dispatch(
        addBoatFilter({
          filterName,
          value: changes.value[dataItemKey],
        })
      );

      if (filterName === 'Manufacturer' && changes.value.id) {
        prepareModelsDropDownList(changes.value.id);
      }

      if (filterName === 'Model' && changes.value.id) {
        prepareModelVariationsDropDownList(changes.value.id);
      }

      const updatedFilterValues = filters.map((f) => {
        if (f.name === filterName) {
          f.value = changes.value;
        }

        if (filterName === 'Manufacturer') {
          if (changes.value.id !== null && f.name === 'Model') {
            f.isDisabled = false;
            f.value = null;
          } else if (changes.value.id === null && f.name === 'Model') {
            f.isDisabled = true;
            f.value = null;
          }

          if (f.name === 'ModelVariation') {
            f.isDisabled = true;
            f.value = null;
          }
        }

        if (filterName === 'Model' && f.name === 'ModelVariation') {
          if (changes.value.id !== null) {
            f.isDisabled = false;
            f.value = null;
          } else {
            f.isDisabled = true;
            f.value = null;
          }
        }

        return f;
      });

      setFilters(updatedFilterValues);
    };

  const filterChangeCB = useCallback(handleFilterChange, [
    dispatch,
    filters,
    prepareModelsDropDownList,
    prepareModelVariationsDropDownList,
  ]);

  useEffect(() => {
    if (needsReset === true) {
      setNeedsReset(false);
      dispatch(resetBoatStateFilter());
      setFilters(constructGridFiltersForRender());
    }
  }, [dispatch, needsReset]);

  const handleFilterReset = () => {
    setNeedsReset(true);
    dispatch(resetBoatDataState());
    dispatch(resetBoatStateFilter());
  };

  const handleCancelListChanges = () => {
    setHasUnsavedListChanges((state) => !state);
    dispatch(getBoatsSuccess(prevBoatListRef.current));
  };

  const handleSaveListChanges = () => {
    try {
      handlePromiseNotification(updateBoats(allBoats).unwrap(), {
        pending: { message: 'Processing...', type: 'info' },
        success: {
          message: 'Boat list successfully updated!',
          type: 'success',
        },
        error: {
          message: 'There was an error with your request.',
          type: 'error',
        },
      });
    } catch (error: any) {
      handleUserActionNotification({
        message: error.data.message,
        autoClose: 2500,
        type: 'error',
      });
    }

    setHasUnsavedListChanges(false);
  };

  /*
   * Remove filter and make it appear in drop down list button again
   */

  const handleFilterDelete = (filterName: string) => () => {
    const updatedFilterValues = filters.map((singleFilter: any) => {
      if (singleFilter.name === filterName) {
        singleFilter.value = null;
        singleFilter.selected = false;
      }
      return singleFilter;
    });

    setFilters(updatedFilterValues);
    dispatch(removeBoatFilter(filterName));
  };

  const handleRegenerate = () => {
    try {
      handlePromiseNotification(getRegenerateSearchIndexes().unwrap(), {
        pending: { message: 'Saving...', type: 'info' },
        success: {
          message: 'Public data generated successfully!',
          type: 'success',
        },
        error: {
          message: 'Something went wrong with your request.',
          type: 'error',
        },
      });
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        message: `${error.status} : \n ${error.error}`,
        autoClose: 2500,
      });
    }
  };

  const chartersFiltersHeight = useAppSelector(
    (state) => state.generalState.data.chartersFiltersHeight
  );

  const boatsFiltersHeight = useAppSelector(
    (state) => state.generalState.data.boatsFiltersHeight
  );

  return (
    <>
      <MaterialGrid container direction='column' spacing={2}>
        <ReactRouterPrompt when={hasUnsavedListChanges}>
          {({ isActive, onConfirm, onCancel }) => (
            <CustomDialog
              open={isActive}
              title='You have unsaved changes. Are you sure you want to leave?'
              confirmText='Confirm'
              onClose={onCancel}
              onConfirm={onConfirm}
            />
          )}
        </ReactRouterPrompt>

        <MaterialGrid
          container
          position='absolute'
          top={16.6}
          right={16.6}
          zIndex={1201}
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
        >
          <MaterialGrid item>
            <button onClick={handleRegenerate} className='light-button'>
              Generate public data
            </button>
          </MaterialGrid>

          <MaterialGrid item>
            <button onClick={handleBoatAdd} className='pink-button'>
              Add new boat
            </button>
          </MaterialGrid>
        </MaterialGrid>

        <MaterialGrid pl={2} pr={2}>
          {props.boatState === boatStates.CHARTER ? (
            <CharterGridFilterBar />
          ) : (
            <GridFilterBar
              filters={filters}
              onAddFilter={handleFilterAdd}
              onFilterChange={filterChangeCB}
              onFilterDelete={handleFilterDelete}
              onFilterReset={handleFilterReset}
            />
          )}
        </MaterialGrid>

        <MaterialGrid
          item
          width={'100%'}
          height={
            props.boatState === boatStates.CHARTER
              ? `calc(100vh - ${chartersFiltersHeight}px - 100px)`
              : props.boatState === boatStates.STOCK
              ? `calc(100vh - ${boatsFiltersHeight}px - 150px)`
              : `calc(100vh - ${boatsFiltersHeight}px - 100px)`
          }
        >
          {props.boatState === boatStates.STOCK ? (
            <Reorder data={allBoats} onReorder={handleReorderStateChange}>
              {(reorderUtils: UseReorderUtils) => (
                <BoatGrid
                  boats={allBoats}
                  loading={isBoatsLoading}
                  total={total}
                  dataState={{
                    ...dataState,
                    filter: buildGridFilters(filtersFromStore),
                  }}
                  boatState={props.boatState}
                  getBoats={prepareBoats}
                  handleDragStart={reorderUtils.dragStart}
                  handleReorder={reorderUtils.rootOrParentReorder}
                />
              )}
            </Reorder>
          ) : props.boatState === boatStates.CHARTER ? (
            <BoatCharterGrid />
          ) : (
            <BoatGrid
              boats={allBoats}
              loading={isBoatsLoading}
              total={total}
              dataState={{
                ...dataState,
                filter: buildGridFilters(filtersFromStore),
              }}
              getBoats={prepareBoats}
              boatState={props.boatState}
            />
          )}
        </MaterialGrid>

        {props.boatState === boatStates.STOCK && (
          <MaterialGrid item container spacing={1} justifyContent='flex-end'>
            <MaterialGrid item>
              <button
                disabled={!hasUnsavedListChanges}
                onClick={handleCancelListChanges}
                className='red-button'
              >
                Cancel
              </button>
            </MaterialGrid>
            <MaterialGrid item>
              <button
                onClick={handleSaveListChanges}
                disabled={!hasUnsavedListChanges}
                className='primary-button'
              >
                Save
              </button>
            </MaterialGrid>
          </MaterialGrid>
        )}
      </MaterialGrid>
    </>
  );
};

export default BoatListContainer;
