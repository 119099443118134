// HOOKS
import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { boatsApiSlice } from '../boatsApiSlice';
//

// MUI
import {
  Grid as MaterialGrid,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
//

// KENDO
import {
  Upload,
  UploadFileStatus,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
  UploadOnProgressEvent,
  UploadOnStatusChangeEvent,
  UploadOnBeforeUploadEvent,
} from '@progress/kendo-react-upload';
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
//

// ICONS
import { MdClose as CloseIcon } from 'react-icons/md';
//

// SERVICES
import { getUserFromStorage } from '../../../../services/storage';
import { isValidNumber } from '../../../../services/validations';
//

// TYPES
import photoTypes from '../../../../types/enums/photoType';
import photoFeaturedTypes from '../../../../types/enums/photoFeaturedType';

import {
  BoatDetailsPhotoUploadModalProps,
  BoatDetailsPhotoUploadModalState,
  User,
} from '../../../../types/typeDefinitions';
import useNotifications from '../../../../hooks/useNotifications';
//

const PhotoUploadModal = (props: BoatDetailsPhotoUploadModalProps) => {
  const [newPhotos, setNewPhotos] = useState<
    BoatDetailsPhotoUploadModalState['newPhotos']
  >({
    files: [],
    type: photoTypes.properties[1],
  });

  const dispatch = useAppDispatch();

  const handleCloseWindow = () => {
    setNewPhotos({
      files: [],
      type: photoTypes.properties[1],
    });

    props.handleCloseWindow();
  };

  const handleDropDownChange =
    (name: string) => (event: DropDownListChangeEvent) => {
      const newValue: any = event.target.value;

      setNewPhotos({
        ...newPhotos,
        [name]: newValue,
      });
    };

  const handleUploadAdd = (event: UploadOnAddEvent) => {
    setNewPhotos({
      ...newPhotos,
      files: event.newState,
    });
  };

  const handleUploadRemove = (event: UploadOnRemoveEvent) => {
    setNewPhotos({
      ...newPhotos,
      files: event.newState,
    });
  };

  const handleUploadProgress = (event: UploadOnProgressEvent) => {
    setNewPhotos({
      ...newPhotos,
      files: event.newState,
    });
  };

  const { handleUserActionNotification } = useNotifications();

  const handleUploadStatusChange = (event: UploadOnStatusChangeEvent) => {
    if (event.response && event.response.response) {
      props.handleAddPhoto(event.response.response);
    }

    if (
      event.newState.every((file) => file.status === UploadFileStatus.Uploaded)
    ) {
      handleUserActionNotification({
        message: 'Photo uploaded successfully!',
        type: 'success',
        autoClose: 2500,
      });
      dispatch(boatsApiSlice.util.invalidateTags(['Boat-Details']));
    } else {
      setNewPhotos({
        ...newPhotos,
        files: event.newState,
      });
    }
  };

  const handleBeforeUpload = (event: UploadOnBeforeUploadEvent) => {
    event.additionalData.type = newPhotos.type.value;
    event.additionalData.featuredType = photoFeaturedTypes.NONE;

    const maxOrderNum:
      | {
          type: number;
          order: number;
        }
      | undefined = props.maxOrderNums.find(
      (x) => x.type === newPhotos.type.value
    );

    const orderNum: number =
      maxOrderNum && isValidNumber(maxOrderNum.order)
        ? maxOrderNum.order + 1
        : 1;

    event.additionalData.order = orderNum;

    let userJson: string | null = getUserFromStorage();
    let user: User = userJson ? JSON.parse(userJson) : {};
    event.headers = { Authorization: `Bearer ${user.token}` };
  };

  return (
    <Dialog
      open={props.isWindowVisible}
      maxWidth='sm'
      title='New photos'
      onClose={handleCloseWindow}
      className='dialog'
    >
      <DialogTitle className='dialog-title'>
        <div className='action-name'>New photos</div>

        <button onClick={handleCloseWindow} className='close-button'>
          <CloseIcon />
        </button>
      </DialogTitle>

      <DialogContent>
        <MaterialGrid container spacing={2}>
          <MaterialGrid item container justifyContent='center'>
            <h5>
              Please choose appropriate type before uploading photos. The only
              accepted file format is .jpg.
            </h5>
            <Upload
              autoUpload={false}
              files={newPhotos.files}
              restrictions={{
                allowedExtensions: ['.jpg', '.jpeg'],
              }}
              batch={true}
              multiple={true}
              onAdd={handleUploadAdd}
              onRemove={handleUploadRemove}
              onProgress={handleUploadProgress}
              onStatusChange={handleUploadStatusChange}
              onBeforeUpload={handleBeforeUpload}
              saveUrl={`${process.env.REACT_APP_BASE_URL}/boats/${props.boatId}/photo/upload`}
              withCredentials={false}
            />
          </MaterialGrid>

          <MaterialGrid
            item
            xs={12}
            container
            spacing={2}
            justifyContent='center'
          >
            <MaterialGrid item xs={3} className='form-label'>
              <FormLabel>Type</FormLabel>
            </MaterialGrid>
            <MaterialGrid item xs={7}>
              <DropDownList
                data={Object.values(photoTypes.properties)}
                value={newPhotos.type}
                dataItemKey='value'
                textField='name'
                onChange={handleDropDownChange('type')}
              />
            </MaterialGrid>
          </MaterialGrid>
        </MaterialGrid>
      </DialogContent>
    </Dialog>
  );
};

export default PhotoUploadModal;
