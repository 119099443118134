// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import SpecialOffersFilterBar from './SpecialOffersFilterBar';
import SpecialOffersGrid from './SpecialOffersGrid';
import { Form, FormUtils } from '../../../components/Form/Form';
import { FormGridElement } from '../../../components/FormGridElement/FormGridElement';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
//

// KENDO
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import {
  AutoComplete,
  AutoCompleteChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { parseDate } from '@telerik/kendo-intl';
//

// TYPES
import { SpecialOffer, SpecialOfferBoat } from '../../../types/typeDefinitions';
//

// HOOKS
import { cloneDeep, debounce, isArray } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import useNotifications from '../../../hooks/useNotifications';
import { useGetBasesForDropdownQuery } from '../Discounts/discountsApiSlice';
import {
  useAddSingleSpecialOfferMutation,
  useGetSpecialOfferTypesForDropdownQuery,
  useLazyGetBoatsAutoCompleteSearchQuery,
  useLazyGetSpecialOffersDetailsQuery,
  useUpdateSingleSpecialOfferMutation,
} from './specialOffersApiSlice';
import {
  onSpecialOffersFilterAutoCompleteChange,
  resetSpecialOfferIdForManage,
  saveBoatsAutoCompleteList,
} from './specialOffersStateSlice';
import {
  useGetDefaultProductsForDropdownQuery,
  useLazyGetBoatDetailsForSpecialOffersQuery,
  useLazyGetRegenerateSearchIndexesQuery,
} from '../Boats/boatsApiSlice';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
//

const SpecialOffersContainer = () => {
  const dispatch = useAppDispatch();

  const currentFilterState = useAppSelector(
    (state) => state.specialOffersState.specialOffersFilterState.filter.filters
  ).find((singleFilter) => singleFilter.name === 'specialOfferId');

  const isInManageMode = useCallback(() => {
    if (currentFilterState?.value !== null) {
      return true;
    }
    return false;
  }, [currentFilterState]);
  //

  const [isWindowVisible, setIsWindowVisible] = useState<boolean>(false);

  const [itemInEdit, setItemInEdit] = useState<Partial<SpecialOffer> | null>(
    null
  );
  const [typeOfWindowAction, setTypeOfWindowAction] = useState<string | null>(
    null
  );
  const { handleUserActionNotification, handlePromiseNotification } =
    useNotifications();
  const [chosenBoat, setChosenBoat] = useState<any | null>(null);

  const initSpecOfferState = useMemo(() => {
    return {
      name: '',
      offerId: null,
      startBase: '',
      startBaseId: null,
      endBase: '',
      endBaseId: null,
      dateFrom: null,
      dateTo: null,
      validDateFrom: null,
      validDateTo: null,
      product: '',
      productId: null,
      price: null,
      discountPercentage: null,
    };
  }, []);

  const [newOrSelectedSpecialOffer, setNewOrSelectedSpecialOffer] =
    useState(initSpecOfferState);

  const [updateSpecialOffer] = useUpdateSingleSpecialOfferMutation();
  const [addSpecialOffer] = useAddSingleSpecialOfferMutation();

  const handleCloningEntity = useCallback(
    (entity: any) => cloneDeep(entity),
    []
  );

  const getSpecialOffersListFromStore = useAppSelector(
    (state) => state.specialOffersState.data.specialOffersList
  );

  const allSpecialOffersList = useMemo(
    () =>
      getSpecialOffersListFromStore && 'data' in getSpecialOffersListFromStore
        ? handleCloningEntity(getSpecialOffersListFromStore.data)
        : handleCloningEntity(getSpecialOffersListFromStore),
    [getSpecialOffersListFromStore, handleCloningEntity]
  );

  const [getSpecialOfferDetails] = useLazyGetSpecialOffersDetailsQuery();
  const [getBoatForEditWindow] = useLazyGetBoatDetailsForSpecialOffersQuery();

  const [fieldValidityOnSubmit, setFieldValidityOnSubmit] = useState<any>({
    dateFrom: false,
    dateTo: false,
    name: false,
    price: false,
    discountPercentage: false,
    offer: false,
    boatField: false,
    validDateFrom: false,
    validDateTo: false,
  });

  const handleFieldValidity = (data: any) => {
    if (data.name === '') {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, name: true };
      });
    }
    if (data.dateFrom === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, dateFrom: true };
      });
    }

    if (data.dateTo === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, dateTo: true };
      });
    }

    if (data.price === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, price: true };
      });
    }
    if (
      data.discountPercentage === null ||
      data.discountPercentage * 100 > 100 ||
      data.discountPercentage * 100 < 1
    ) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, discountPercentage: true };
      });
    }

    if (data.offer === null || !data.offer) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, offer: true };
      });
    }

    if (chosenBoat === null) {
      setFieldValidityOnSubmit((prev: any) => {
        return { ...prev, boatField: true };
      });
    }

    if ('validDateFrom' in data && 'validDateTo' in data) {
      if (data.validDateFrom > data.validDateTo) {
        setFieldValidityOnSubmit((prev: any) => {
          return { ...prev, validDateFrom: true, validDateTo: true };
        });
      }
    }
  };

  const validateForm = (data: any | Partial<SpecialOffer>) => {
    if (
      'validDateFrom' in data &&
      data.validDateFrom !== null &&
      data.validDateTo !== null
    ) {
      return (
        data.name !== '' &&
        data.dateFrom !== null &&
        data.dateTo !== null &&
        data.price !== null &&
        data.price >= 0 &&
        new Date(data.dateFrom) <= new Date(data.dateTo) &&
        data.discountPercentage * 100 >= 0 &&
        data.discountPercentage * 100 <= 100 &&
        data.validDateFrom < data.validDateTo
      );
    }

    return (
      data.name !== '' &&
      data.dateFrom !== null &&
      data.dateTo !== null &&
      data.price !== null &&
      data.price >= 0 &&
      new Date(data.dateFrom) <= new Date(data.dateTo) &&
      data.discountPercentage * 100 >= 0 &&
      data.discountPercentage * 100 <= 100
    );
  };

  const onSpecialOfferSubmit = useCallback(
    (data: any | Partial<SpecialOffer>) => {
      if (validateForm(data)) {
        if (chosenBoat !== null && 'id' in chosenBoat) {
          try {
            if (typeOfWindowAction === 'NEW') {
              handlePromiseNotification(
                addSpecialOffer({
                  ...data,
                  boatId: chosenBoat.id,
                  discountPercentage: data.discountPercentage * 100,
                  discountedPrice:
                    data.price -
                    (data.price * data.discountPercentage * 100) / 100,
                }).unwrap(),
                {
                  pending: { message: 'Processing...', type: 'info' },
                  success: {
                    message: 'Special offer added successfully!',
                    type: 'success',
                  },
                  error: {
                    message: 'There was an error with your request.',
                    type: 'error',
                  },
                }
              );

              setNewOrSelectedSpecialOffer(initSpecOfferState);
              setChosenBoat(null);
              setItemInEdit(null);
              setIsWindowVisible(false);
              setTypeOfWindowAction(null);
            } else {
              const tmp = {
                ...data,
                discountPercentage: data.discountPercentage * 100,
                discountedPrice:
                  data.price -
                  (data.price * data.discountPercentage * 100) / 100,
                boats: undefined,
                type: undefined,
                boatId: chosenBoat.id,
              };

              handlePromiseNotification(updateSpecialOffer(tmp).unwrap(), {
                pending: { message: 'Processing...', type: 'info' },
                success: {
                  message: 'Special offer updated successfully!',
                  type: 'success',
                },
                error: {
                  message: 'There was an error with your request.',
                  type: 'error',
                },
              });

              setNewOrSelectedSpecialOffer(initSpecOfferState);
              setChosenBoat(null);
              setItemInEdit(null);
              setIsWindowVisible(false);
              setTypeOfWindowAction(null);
            }
          } catch (error: any) {
            handleUserActionNotification({
              message: 'There was an error with your request',
              type: 'error',
              autoClose: 2500,
            });
          }
        } else {
          handleUserActionNotification({
            message: 'Please fill out all fields before submitting!',
            type: 'warning',
            autoClose: 2500,
          });
        }
      } else {
        handleUserActionNotification({
          type: 'warning',
          autoClose: 2500,
          message: 'One or more validation errors occurred!',
        });
      }
    },
    [
      updateSpecialOffer,
      handleUserActionNotification,
      addSpecialOffer,
      initSpecOfferState,
      typeOfWindowAction,
      chosenBoat,
      handlePromiseNotification,
    ]
  );

  const handleEdit = async (dataItem: any) => {
    setItemInEdit(dataItem);

    try {
      const tmp = await getSpecialOfferDetails(dataItem.id).unwrap();
      setNewOrSelectedSpecialOffer({
        ...tmp,
        discountPercentage: tmp.discountPercentage / 100,
      });

      await getBoatForEditWindow(tmp.boatId)
        .unwrap()
        .then((boatFromResponse) => {
          if (!boatFromResponse || boatFromResponse === undefined) {
            handleUserActionNotification({
              type: 'error',
              autoClose: 2500,
              message:
                'There was a problem with getting information about this special offer',
            });
          }
          setChosenBoat(boatFromResponse);
          setIsWindowVisible(true);
        });
    } catch (error: any) {
      console.log(error);
      handleUserActionNotification({
        type: 'error',
        autoClose: 2500,
        message: 'There was a problem with your request',
      });
    }
  };

  const handleClose = () => {
    setNewOrSelectedSpecialOffer(initSpecOfferState);
    setItemInEdit(null);
    setTypeOfWindowAction(null);
    setChosenBoat(null);
    setIsWindowVisible(false);
    setFieldValidityOnSubmit({
      dateFrom: false,
      dateTo: false,
      name: false,
      price: false,
      discountPercentage: false,
      offer: false,
      boatField: false,
      validDateFrom: false,
      validDateTo: false,
    });
  };

  const handleAddNewSpecialOffer = () => {
    setNewOrSelectedSpecialOffer((prev) => {
      return { ...prev, tempId: new Date().getMilliseconds() };
    });
    setIsWindowVisible(true);
    setTypeOfWindowAction('NEW');
    setItemInEdit(null);
  };

  const { data: basesDropdownData } = useGetBasesForDropdownQuery();

  const { data: productsDropdownData } =
    useGetDefaultProductsForDropdownQuery();

  const { data: specialOfferTypesDropdown } =
    useGetSpecialOfferTypesForDropdownQuery();

  const getBoatsSpecialOffersListFromStore = useAppSelector(
    (state) => state.specialOffersState.data.boatsAutoCompleteList
  );

  //

  const getBoatsSpecialOffersFilters = useAppSelector(
    (state) => state.specialOffersState.boatsForAutoCompleteFilterState
  );
  const boatsSpecialOffersFilters = useMemo(
    () => getBoatsSpecialOffersFilters,
    [getBoatsSpecialOffersFilters]
  );

  const nullCheckFiltersForBoats = useMemo(
    () =>
      Object.values(boatsSpecialOffersFilters.filter.filters).some(
        (singleFilter) => singleFilter.value === null
      ),
    [boatsSpecialOffersFilters.filter.filters]
  );

  const [
    getBoatsAutoCompleteSearch,
    { isFetching: isGetBoatsAutoCompleteLoading },
  ] = useLazyGetBoatsAutoCompleteSearchQuery();

  const boatsSpecialOffersList: SpecialOfferBoat[] | any = useMemo(
    () => handleCloningEntity(getBoatsSpecialOffersListFromStore),
    [getBoatsSpecialOffersListFromStore, handleCloningEntity]
  );

  const prepareAutoCompleteData = (dataset: any) => {
    if (dataset && 'data' in dataset) {
      const tmpString = dataset.data.map((singleItem: any) => {
        return {
          name: `${singleItem.name} (${singleItem.manufacturer} ${singleItem.model} ${singleItem.variation})`,
          data: singleItem,
        };
      });

      return tmpString;
    }
    return [];
  };

  const onFilterAutoCompleteChange = debounce(
    (event: any, eventName: string) =>
      dispatch(
        onSpecialOffersFilterAutoCompleteChange({
          eventValue: event.value,
          name: eventName,
        })
      ),
    300
  );

  const handleUserChoice = (event: AutoCompleteChangeEvent) => {
    // const tmp = event.value.split(',')[0];
    const tmp = event.value.slice(0, event.value.indexOf('(')).trim();

    if (boatsSpecialOffersList && 'data' in boatsSpecialOffersList) {
      const userChoice = boatsSpecialOffersList.data.filter(
        (single: any) => single.name.trim() === tmp
      );

      setChosenBoat({ ...userChoice[0] });
    }

    if (event.value === '') {
      setChosenBoat(null);
    }
  };

  const getBoatsSpecialOffersListBasedOnFilters = useCallback(async () => {
    if (!nullCheckFiltersForBoats) {
      try {
        const req = await getBoatsAutoCompleteSearch(
          boatsSpecialOffersFilters
        ).unwrap();
        dispatch(saveBoatsAutoCompleteList(req));
      } catch (error) {
        console.error(error);
      }
    }
  }, [
    nullCheckFiltersForBoats,
    boatsSpecialOffersFilters,
    dispatch,
    getBoatsAutoCompleteSearch,
  ]);

  useEffect(() => {
    getBoatsSpecialOffersListBasedOnFilters();
  }, [getBoatsSpecialOffersListBasedOnFilters]);

  const prepareBoatNameForUiAutoComplete = (data: any) => {
    if (!data) {
      return '';
    }
    return `${data.name} (${data.manufacturer} ${data.model} ${data.variation})`;
  };

  // optional
  useEffect(() => {
    return () => {
      dispatch(resetSpecialOfferIdForManage());
    };
  }, [dispatch]);

  useEffect(() => {
    if ('tempID' in newOrSelectedSpecialOffer) {
      setNewOrSelectedSpecialOffer(initSpecOfferState);
    }
  }, [newOrSelectedSpecialOffer, initSpecOfferState]);

  const [getRegenerateSearchIndexes] = useLazyGetRegenerateSearchIndexesQuery();

  const handleRegenerate = () => {
    try {
      handlePromiseNotification(getRegenerateSearchIndexes().unwrap(), {
        pending: { message: 'Saving...', type: 'info' },
        success: {
          message: 'Public data generated successfully!',
          type: 'success',
        },
        error: {
          message: 'Something went wrong with your request.',
          type: 'error',
        },
      });
    } catch (error: any) {
      handleUserActionNotification({
        type: 'error',
        message: `${error.status} : \n ${error.error}`,
        autoClose: 2500,
      });
    }
  };

  //
  const isLoading = useAppSelector((state) => {
    return Object.values(state.SpecialOffersApiSlice.queries).some(
      (query) =>
        query &&
        query.endpointName === 'getAllSpecialOffers' &&
        query.status === QueryStatus.pending
    );
  });

  const specialOffersFiltersHeight = useAppSelector(
    (state) => state.generalState.data.specialOffersFiltersHeight
  );

  return (
    <>
      <MaterialGrid container direction='column' spacing={2}>
        <MaterialGrid
          container
          position='fixed'
          top={16.6}
          right={16.6}
          zIndex={1201}
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
        >
          <MaterialGrid item>
            <button onClick={handleRegenerate} className='light-button'>
              Generate public data
            </button>
          </MaterialGrid>

          <MaterialGrid item>
            {!isInManageMode() && (
              <button
                className='pink-button'
                onClick={handleAddNewSpecialOffer}
              >
                Add new special offer
              </button>
            )}
          </MaterialGrid>
        </MaterialGrid>

        <MaterialGrid pl={2} pr={2}>
          <SpecialOffersFilterBar />
        </MaterialGrid>

        <MaterialGrid
          item
          width={'100%'}
          height={`calc(100vh - ${specialOffersFiltersHeight}px - 100px)`}
        >
          <SpecialOffersGrid
            data={
              isInManageMode() === true
                ? isArray(allSpecialOffersList)
                  ? allSpecialOffersList.filter(
                      (singleSpecialOffer) =>
                        singleSpecialOffer.id === currentFilterState?.value
                    )
                  : []
                : (allSpecialOffersList as any)
            }
            isLoading={isLoading}
            handleEdit={handleEdit}
          />
        </MaterialGrid>

        {isInManageMode() && (
          <MaterialGrid
            item
            container
            gap={1}
            justifyContent={'flex-end'}
            alignContent={'center'}
            direction={'row'}
          >
            <MaterialGrid item>
              <button
                onClick={() => {
                  dispatch(resetSpecialOfferIdForManage());
                }}
                className='red-button'
              >
                See all special offers
              </button>
            </MaterialGrid>
          </MaterialGrid>
        )}
      </MaterialGrid>

      {/* ADD/EDIT FORM */}
      <Form
        data={newOrSelectedSpecialOffer}
        requiredData={{ textFields: ['name'] }}
        onSubmit={onSpecialOfferSubmit}
        render={({
          data,
          onInputChange,
          onDateRangePickerChange,
          onDropDownChange,
          onSubmit,
        }: FormUtils) => {
          return (
            <>
              <CustomDialog
                title={`${
                  typeOfWindowAction === 'NEW'
                    ? 'Add new special offer'
                    : `Edit ${itemInEdit && itemInEdit.name}`
                }`}
                open={isWindowVisible}
                onClose={handleClose}
                onConfirm={() => {
                  onSubmit();
                  handleFieldValidity(data);
                }}
              >
                <FormGridElement
                  component={Input}
                  label={'Name*'}
                  value={data.name}
                  onChange={onInputChange('name')}
                  additionalProps={{
                    validityStyles: fieldValidityOnSubmit.name,
                    valid: !fieldValidityOnSubmit.name,
                    onKeyDown: () =>
                      setFieldValidityOnSubmit((prev: any) => {
                        return { ...prev, name: false };
                      }),
                  }}
                />
                <FormGridElement
                  component={DropDownList}
                  label={'Type*'}
                  value={
                    specialOfferTypesDropdown
                      ? specialOfferTypesDropdown.find(
                          (singleItem: any) => singleItem.id === data.offerId
                        )
                      : []
                  }
                  onChange={onDropDownChange('offer')}
                  additionalProps={{
                    data: specialOfferTypesDropdown,
                    dataItemKey: 'id',
                    textField: 'name',
                    valid: !fieldValidityOnSubmit.offer,
                    validityStyles: fieldValidityOnSubmit.offer,
                    onBlur: () =>
                      setFieldValidityOnSubmit((prev: any) => {
                        return { ...prev, offer: false };
                      }),
                  }}
                />

                <FormGridElement
                  component={DropDownList}
                  label={'Product'}
                  value={
                    productsDropdownData
                      ? productsDropdownData.find(
                          (singleItem: any) => singleItem.id === data.productId
                        ) || { id: null, name: 'ANY' }
                      : []
                  }
                  onChange={onDropDownChange('product')}
                  additionalProps={{
                    data:
                      productsDropdownData !== undefined
                        ? [{ id: null, name: 'ANY' }, ...productsDropdownData]
                        : [],
                    dataItemKey: 'id',
                    textField: 'name',
                  }}
                />

                <FormGridElement
                  component={DropDownList}
                  label={'Start base'}
                  additionalProps={{
                    data:
                      basesDropdownData !== undefined
                        ? [{ id: null, name: 'ANY' }, ...basesDropdownData]
                        : [],
                    dataItemKey: 'id',
                    textField: 'name',
                  }}
                  onChange={onDropDownChange('startBase')}
                  value={
                    basesDropdownData
                      ? basesDropdownData.find(
                          (singleItem: any) =>
                            singleItem.id === data.startBaseId
                        ) || { id: null, name: 'ANY' }
                      : []
                  }
                />

                <FormGridElement
                  component={DropDownList}
                  label={'End base'}
                  additionalProps={{
                    data:
                      basesDropdownData !== undefined
                        ? [{ id: null, name: 'ANY' }, ...basesDropdownData]
                        : [],
                    dataItemKey: 'id',
                    textField: 'name',
                  }}
                  value={
                    basesDropdownData
                      ? basesDropdownData.find(
                          (singleItem: any) => singleItem.id === data.endBaseId
                        ) || { id: null, name: 'ANY' }
                      : []
                  }
                  onChange={onDropDownChange('endBase')}
                />
                <FormGridElement
                  component={DateRangePicker}
                  label={'Dates*'}
                  value={{
                    start: data.dateFrom
                      ? parseDate(data.dateFrom, 'yyyy-MM-ddTHH:mm:ss')
                      : null,
                    end: data.dateTo
                      ? parseDate(data.dateTo, 'yyyy-MM-ddTHH:mm:ss')
                      : null,
                  }}
                  onChange={onDateRangePickerChange({
                    start: 'dateFrom',
                    end: 'dateTo',
                  })}
                  additionalProps={{
                    allowReverse: false,
                    format: 'dd/MM/yyyy',
                    onBlur: () =>
                      setFieldValidityOnSubmit((prev: any) => {
                        return { ...prev, dateFrom: false, dateTo: false };
                      }),

                    startDateInputSettings: {
                      label: 'Date from',
                      format: 'dd/MM/yyyy',
                      defaultValue: null,
                      defaultShow: false,
                      validityStyles: fieldValidityOnSubmit.dateFrom,
                      valid: !fieldValidityOnSubmit.dateFrom,
                    },
                    endDateInputSettings: {
                      label: 'Date to',
                      format: 'dd/MM/yyyy',
                      defaultValue: null,
                      defaultShow: false,
                      validityStyles: fieldValidityOnSubmit.dateTo,
                      valid: !fieldValidityOnSubmit.dateTo,
                    },
                    errorMessage:
                      data.dateTo !== null &&
                      data.dateTo !== undefined &&
                      data.dateTo < data.dateFrom &&
                      'Date to must be equal to or greater than date from!',
                  }}
                />
                <FormGridElement
                  component={DateRangePicker}
                  label={'Valid dates'}
                  value={{
                    start: data?.validDateFrom
                      ? parseDate(data?.validDateFrom, 'yyyy-MM-ddTHH:mm:ss')
                      : null,
                    end: data?.validDateTo
                      ? parseDate(data?.validDateTo, 'yyyy-MM-ddTHH:mm:ss')
                      : null,
                  }}
                  additionalProps={{
                    allowReverse: false,
                    format: 'dd/MM/yyyy',
                    onBlur: () =>
                      setFieldValidityOnSubmit((prev: any) => {
                        return {
                          ...prev,
                          validDateFrom: false,
                          validDateTo: false,
                        };
                      }),

                    startDateInputSettings: {
                      label: 'Valid date from',
                      format: 'dd/MM/yyyy',
                      defaultValue: null,
                      defaultShow: false,
                      validityStyles: fieldValidityOnSubmit.validDateFrom,
                      valid: !fieldValidityOnSubmit.validDateFrom,
                    },
                    endDateInputSettings: {
                      label: 'Valid date to',
                      format: 'dd/MM/yyyy',
                      defaultValue: null,
                      defaultShow: false,
                      validityStyles: fieldValidityOnSubmit.validDateTo,
                      valid: !fieldValidityOnSubmit.validDateTo,
                    },
                    errorMessage:
                      data.validDateTo !== null &&
                      data.validDateTo !== undefined &&
                      data.validDateTo < data.validDateFrom &&
                      'Valid date to must be equal to or greater than valid date from!',
                  }}
                  onChange={onDateRangePickerChange({
                    start: 'validDateFrom',
                    end: 'validDateTo',
                  })}
                />
                <FormGridElement
                  component={NumericTextBox}
                  label={'Price*'}
                  value={data.price}
                  onChange={onInputChange('price')}
                  additionalProps={{
                    spinners: false,
                    format: '##,##',
                    validityStyles: fieldValidityOnSubmit.price,
                    valid: !fieldValidityOnSubmit.price,
                    onFocus: () =>
                      setFieldValidityOnSubmit((prev: any) => {
                        return { ...prev, price: false };
                      }),
                    errorMessage:
                      data.price !== null &&
                      data.price < 0 &&
                      'Price cannot be negative!',
                  }}
                />

                <FormGridElement
                  component={NumericTextBox}
                  label={'Discount percentage*'}
                  value={data.discountPercentage}
                  onChange={onInputChange('discountPercentage')}
                  additionalProps={{
                    spinners: false,
                    format: 'p2',
                    validityStyles: fieldValidityOnSubmit.discountPercentage,
                    valid: !fieldValidityOnSubmit.discountPercentage,
                    step: 0.05,
                    onFocus: () =>
                      setFieldValidityOnSubmit((prev: any) => {
                        return { ...prev, discountPercentage: false };
                      }),
                    errorMessage:
                      data.discountPercentage !== null &&
                      data.discountPercentage !== undefined &&
                      (data.discountPercentage * 100 < 1 ||
                        data.discountPercentage * 100 > 100) &&
                      'Discount percentage must be between 0 and 100!',
                  }}
                />

                <FormGridElement
                  component={NumericTextBox}
                  label={'Discounted price'}
                  value={
                    data.price -
                    (data.price * data.discountPercentage * 100) / 100
                  }
                  onChange={onInputChange('discountedPrice')}
                  additionalProps={{
                    disabled: true,
                    spinners: false,
                    format: '##,##',
                  }}
                />
                <FormGridElement
                  component={AutoComplete}
                  label={'Boat*'}
                  onChange={(event: AutoCompleteChangeEvent) => {
                    onFilterAutoCompleteChange(event, 'boatModel');
                    handleUserChoice(event);
                    setFieldValidityOnSubmit((prev: any) => {
                      return { ...prev, boatField: false };
                    });
                  }}
                  additionalProps={{
                    data: prepareAutoCompleteData(boatsSpecialOffersList),
                    textField: 'name',

                    defaultValue:
                      chosenBoat !== null
                        ? prepareBoatNameForUiAutoComplete(chosenBoat)
                        : null,
                    loading: isGetBoatsAutoCompleteLoading,
                    required: true,
                    validityStyles: fieldValidityOnSubmit.boatField,
                    valid: !fieldValidityOnSubmit.boatField,
                  }}
                />
              </CustomDialog>
            </>
          );
        }}
      />
    </>
  );
};

export default SpecialOffersContainer;
