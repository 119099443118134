// ASSETS
import logo from '../../assets/img/NAVA.png';
//

const SidebarBrand = (props: { open: boolean }) => {
  return (
    <div className='sidebar-brand'>
      <div>
        <img src={logo} alt='logo' className='sidebar-logo' />
      </div>
      {props.open && <div>BOATS BACKOFFICE</div>}
    </div>
  );
};

export default SidebarBrand;
