// HOOKS
import React, { ReactElement, useState } from 'react';
//

// KENDO
import {
  ComboBox,
  ComboBoxChangeEvent,
  ListItemProps,
} from '@progress/kendo-react-dropdowns';
import {
  Grid,
  GridColumn as Column,
  GridExpandChangeEvent,
} from '@progress/kendo-react-grid';

// MUI
import { Grid as MaterialGrid } from '@mui/material';
//

// COMPONENTS
import { DragCell } from '../../../../components/DragCell/DragCell';
import { CommandCell } from '../../../../components/CommandCell/CommandCell';
//

// TYPES
import {
  BoatDetailsInventoryProps,
  BoatDetailsInventoryState,
  BoatEquipmentCategoryGridItem,
  DetailRowProps,
} from '../../../../types/typeDefinitions';
//

// SERVICES
import { rowRender } from '../../../../services/gridRowRender';
//

function CategoryDetailComponent(
  props: BoatDetailsInventoryProps,
  hasMMKID: number | null,
  handleDelete: (dataItem: any) => void
) {
  const handleDragS = props.handlePartDragStart;
  const handleR = props.handlePartReorder;

  if (hasMMKID !== null && hasMMKID !== undefined) {
    return (props: DetailRowProps) => {
      return (
        <MaterialGrid container item>
          <Grid
            data={props.dataItem.parts}
            scrollable='none'
            resizable={true}
            rowRender={rowRender}
          >
            <Column field='partName' title='Name' />
          </Grid>
        </MaterialGrid>
      );
    };
  }

  return (props: DetailRowProps) => {
    const DragCellF = DragCell(handleDragS, handleR);

    return (
      <MaterialGrid container item>
        <Grid
          data={props.dataItem.parts}
          scrollable='none'
          resizable={true}
          rowRender={rowRender}
        >
          <Column
            title=''
            width={40}
            groupable={false}
            sortable={false}
            filterable={false}
            resizable={false}
            cell={DragCellF as any}
          />

          <Column
            field='_command'
            width={40}
            groupable={false}
            sortable={false}
            filterable={false}
            resizable={false}
            title=' '
            cell={(cellProps) => (
              <CommandCell
                {...cellProps}
                hasDeleteCommand={true}
                handleDelete={handleDelete}
              />
            )}
          />

          <Column field='partName' title='Name' />
        </Grid>
      </MaterialGrid>
    );
  };
}

const CharterInventory = (props: BoatDetailsInventoryProps) => {
  const [categoryValue, setCategoryValue] =
    useState<BoatDetailsInventoryState['categoryValue']>(null);
  const [partValue, setPartValue] =
    useState<BoatDetailsInventoryState['partValue']>(null);
  const [expandedCategoryIds, setExpandedCategoryIds] = useState<
    BoatDetailsInventoryState['expandedCategoryIds']
  >([]);

  const CategoryDetailComponentF = CategoryDetailComponent(
    props,
    props.hasMMK as number | null,
    props.handlePartDelete
  );

  const handleExpandChange = (event: GridExpandChangeEvent) => {
    if (expandedCategoryIds.includes(event.dataItem.id) === false) {
      setExpandedCategoryIds([...expandedCategoryIds, event.dataItem.id]);
    } else {
      setExpandedCategoryIds(
        expandedCategoryIds.filter((id) => id !== event.dataItem.id)
      );
    }
  };

  const handleCategoryChange = (event: ComboBoxChangeEvent) => {
    props.handleCategorySelect(event.target.value);

    setCategoryValue(event.target.value);
    setPartValue(null);
  };

  const handlePartChange = (event: ComboBoxChangeEvent) => {
    props.handlePartAdd(event.target.value);
    setPartValue(null);
  };

  const itemRender = (
    li: ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    if (itemProps.dataItem.id !== 0) {
      return li;
    }

    const itemChildren: JSX.Element = (
      <span
        style={{
          fontWeight: 500,
        }}
      >
        {li.props.children as any}
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  let boatCategories: BoatEquipmentCategoryGridItem[] = [];

  if (props.boatEquipment && props.boatEquipment?.length > 0) {
    const uniqueBoatEquipmentCategoryIds: number[] = [
      ...props.boatEquipment.map((ep) => ep.categoryId),
    ];

    boatCategories = props.otherEquipmentCategories
      .filter((c) => uniqueBoatEquipmentCategoryIds.includes(c.id))
      .map((c) => ({
        ...c,
        expanded: expandedCategoryIds.includes(c.id),
        parts: (props.boatEquipment || []).filter((p) => p.categoryId === c.id),
      }));
  }

  return (
    <>
      <MaterialGrid container direction='column' spacing={3}>
        <MaterialGrid item container spacing={3}>
          <MaterialGrid item>
            <ComboBox
              data={props.otherEquipmentCategories}
              value={categoryValue}
              textField='name'
              dataItemKey='id'
              suggest
              label='Select category...'
              onChange={handleCategoryChange}
              disabled={props.hasMMK !== null && props.hasMMK !== undefined}
            />
          </MaterialGrid>

          <MaterialGrid item>
            <ComboBox
              disabled={!props.categorySelected}
              data={
                props.otherEquipmentParts?.length > 0
                  ? [
                      { id: 0, name: 'Select all' },
                      ...props.otherEquipmentParts,
                    ]
                  : []
              }
              value={partValue}
              textField='name'
              dataItemKey='id'
              suggest
              label='Select part...'
              itemRender={itemRender}
              onChange={handlePartChange}
            />
          </MaterialGrid>
        </MaterialGrid>

        <MaterialGrid item>
          <Grid
            data={boatCategories}
            scrollable='none'
            detail={CategoryDetailComponentF as any}
            expandField='expanded'
            onExpandChange={handleExpandChange}
            rowRender={rowRender}
          >
            <Column field='id' title='ID' />
            <Column field='name' title='Category' />
          </Grid>
        </MaterialGrid>
      </MaterialGrid>
    </>
  );
};

export default CharterInventory;
